import * as angular from 'angular';
import {IFilterCurrency, IFilterService} from "angular";

function currencyFilter($filter: IFilterService) {
    console.debug('ding');
    var ngCurrencyFilter = $filter<IFilterCurrency>('currency');
    return function (amount: number, symbol?: string, fractionSize?: number): string {
        var result = ngCurrencyFilter(amount, symbol, fractionSize);
        if (result == null) return result;
        if (result[0] === '-') {
            result = '(' + result.substr(1) + ')';
        }
        return result;
    };
}

export default angular.module('directives.currency', [])
    .filter('currency2', currencyFilter)
    .name;
