import * as angular from 'angular';
import {StateProvider} from '@uirouter/angularjs';

import UploadController from "./UploadController";
import LinkController from "./LinkController";
import InstructorListController from "./InstructorListController";

class Routes {
    static $inject = ['$stateProvider'];

    constructor($stateProvider: StateProvider) {
        $stateProvider
            .state('document', {
                abstract: true,
                url: '/document'
            })

            .state('document.instructorList', {
                url: '/list',
                template: require('./InstructorList.html'),
                controller: InstructorListController,
                controllerAs: 'vm',
                data: {title: 'Instructor Documents'}
            })

            .state('document.upload', {
                url: '/upload',
                template: require('./Upload.html'),
                controller: UploadController,
                controllerAs: 'vm',
                data: {title: 'Upload Documents'}
            })

            .state('document.link', {
                url: '/link/:id?userId',
                params: {id: {dynamic: true}},
                template: require('./Link.html'),
                controller: LinkController,
                controllerAs: 'vm',
                data: {title: 'Link Uploaded Documents'}
            })
        ;
    }
}

export default angular.module('app.document', [])
    .config(Routes)
    .name;
