import {DataHubService} from "../modules/DataHubModule";
import {StudentReleaseDto} from "../model/StudentReleaseDto";
import {SpinnerService} from "../modules/SpinnerModule";

export default class ReleaseReadyController {
    records = new Array<StudentReleaseDto>();
    today = new Date();

    constructor(
        private $dataHub: DataHubService,
        private toaster: any,
        private $spinner: SpinnerService
    ) {

        $dataHub.bindAllStudentReleaseReady(() => this.records);

        $dataHub.connected(() => {
            this.records = [];
            this.$spinner.show();
            return $dataHub.studentReleaseReadyQuery()
                .catch(this.$dataHub.defaultErrorHandler)
                .finally(() => this.$spinner.hide());
        });
    }


    release = (record: StudentReleaseDto) => this.$dataHub.studentRelease(record)
        .then(() => this.toaster.success(record.fullName + ' released'))
        .catch(this.$dataHub.defaultErrorHandler);

    releaseGroup = (records) => {
        for (let record of records)
            this.release(record);
    }

};
