import * as angular from 'angular';
import {StudentDisplayDto} from "../model/StudentDisplayDto";
import {DataHubService} from "../modules/DataHubModule";
import {StudentSearchDto} from "../model/StudentSearchDto";
import {INgModelController, IScope} from "angular";

class studentSelectController {
    students: StudentDisplayDto[] = [];

    constructor(private $dataHub: DataHubService, private $scope) {
    }

    refresh(search) {
        if (!search) return;
        this.students = [];
        let dto = new StudentSearchDto();
        dto.text = search;
        dto.classId = this.$scope.classId;
        this.$dataHub.studentSearch(dto)
            .then((response) => {
                this.students = response;
            });
    };

    onSelect(item) {
        this.$scope.selectedStudent = item;
    }
}


function studentSelect() {
    return {
        template: require('./StudentSelect.html'),
        restrict: 'E',
        scope: {placeholder: '@', classId: '=', selectedStudent: '='},
        controller: studentSelectController,
        controllerAs: '$ctrl',
    };
}


export default angular.module('directives.studentSelect', [])
    .directive('studentSelect', studentSelect)
    .name;
