// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html

import { DocumentTypeDto } from './DocumentTypeDto';
import { UserDisplayDto } from './UserDisplayDto';

// Myers.StudentManagementSystem.Data.Models.DocumentSearchDto
export class DocumentSearchDto {
    public documentType: DocumentTypeDto = null;
    public user: UserDisplayDto = null;
    public fileName: string = null;
}


    



