import {DataHubService} from "../modules/DataHubModule";
import {SpinnerService} from "../modules/SpinnerModule";
import {LocationDto} from "../model/LocationDto";

export default class LocationTokenController {
    locations: LocationDto[] = [];

    constructor(
        private $dataHub: DataHubService,
        private $spinner: SpinnerService
    ) {

        $dataHub.bindAllLocation(() => this.locations);

        $dataHub.onConnected(() => {
            this.$spinner.show();
            $dataHub.location()
                .finally(() => this.$spinner.hide());
        });
    }
};
