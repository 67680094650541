import {Ng1Controller, StateService, TargetState, Transition} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {ContractFullDto} from "../model/ContractFullDto";
import {IHttpService, IScope} from "angular";
import {ConfigService} from "../modules/ConfigModule";
import {SpinnerService} from "../modules/SpinnerModule";

export default class ListController implements Ng1Controller {
    private contracts: ContractFullDto[] = [];
    private contract: ContractFullDto;
    private iframe: any;
    private error: any;

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(private $dataHub: DataHubService,
                private toaster: any,
                private $config: ConfigService,
                private $spinner: SpinnerService,
                private $http: IHttpService,
                private $scope: IScope,
                private $state: StateService,
                private $stateParams: any) {
        $dataHub.connected(this.refresh);
        $dataHub.client.bindAllContractFull(() => this.contracts);

    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;
        this.$dataHub.server.contractFullSubscribe()
            .then(() => {
                this.contract = this.contracts[0];
                this.preview();
            });
    };

    preview = () => {
        if (!this.contract) return;
        if (!this.iframe) return;
        var data = {
            html: this.contract.html,
            studentId: 0
        };
        this.$http.post(this.$config.apiBase + 'report/contract/preview',
            data,
            {responseType: 'blob'})
            .then(r => {
                this.iframe[0].src = window.URL.createObjectURL(r.data);
                this.error = null;
            })
            .catch(e => {
                console.error(e);
                this.iframe[0].src = null;
                e.data.text().then(str => this.error = str);
            });
    }

    save = () => {
        this.$spinner.show('Saving');
        this.$dataHub.server.contractSave(this.contract)
            .catch(this.$dataHub.defaultErrorHandler)
            .finally(() => this.$spinner.hide());
    }

}
