import * as angular from 'angular';

function highlightWords() {
    return function (input, search) {
        if (!input || !search) return input;
        let words = search.split(' ');
        if (!words.length) return input;

        for (var word of words) {
            // only search outside existing tags. example: `search` = 'span' - don't match existing span tags.
            var pattern = new RegExp(word + '(?![^<]*>)', 'gi');
            input = input.replace(pattern, '<span class="highlighted">$&</span>');
        }

        return input;
    };
}

export default angular.module('directives.highlight', [])
    .filter('highlightWords', highlightWords)
    .name;
