import {Ng1Controller, Transition, TargetState} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {GiftCertificateDto} from "../model/GiftCertificateDto";
import {ConfigService} from "../modules/ConfigModule";
import {UserStorageService} from "../modules/UserStorageModule";
import {DebounceFactory} from "../modules/DebounceModule";

export default class ListController implements Ng1Controller {
    private giftCertificates: GiftCertificateDto[] = [];
    private searchQuery: string;

    //private templates = [{value:}]

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(private $dataHub: DataHubService,
                private toaster: any,
                private $config: ConfigService,
                private $userStorage: UserStorageService,
                private $debounce: DebounceFactory,
    ) {
        $dataHub.client.bindAllGiftCertificate(() => this.giftCertificates);
        $dataHub.connected(this.refresh);
        this.refresh();
    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;
        this.$dataHub.server.giftCertificateSubscribe().catch(this.$dataHub.defaultErrorHandler);
    };

    print = (template: string) => {
        var ids = this.giftCertificates.filter(x => x["toPrint"]).map(x => x.id);
        let url = this.$config.apiBase + 'report/giftCertificate?' + $.param({
            giftCertificateIds: ids,
            template: template
        });
        window.open(url);
    };

    markPrinted = (row: GiftCertificateDto) => {
        row.printed = true;
        this.$dataHub.server.giftCertificateModify(row)
            .then(() => row["toPrint"] = false);
    };

    reprint = (row: GiftCertificateDto) => {
        row.printed = false;
        this.$dataHub.server.giftCertificateModify(row)
            .then(() => row["toPrint"] = true);
    };

    search = (query: string) => {
        if(!query) return;
        this.searchQuery = query;
        this._search();
    };

    _search = this.$debounce(() => {
        this.$dataHub.server.giftCertificateSubscribeSearch(this.searchQuery)
    }, 500, false);

    clearToPrint = () => {
        this.giftCertificates.forEach(gc => {
            gc["toPrint"] = false;
        })
    }

}
