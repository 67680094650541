import * as angular from 'angular';
import {IIntervalService, ILocationService, ITimeoutService} from 'angular';
import {SpinnerService} from '../modules/SpinnerModule';
import {DataHubService} from "../modules/DataHubModule";
import {ApiService} from '../modules/ApiModule';
import {ConfigService} from '../modules/ConfigModule';
import {HookResult, Ng1Controller, Transition} from "@uirouter/angularjs";
import {Janus} from 'janus-gateway';
import {VirtualClassDto} from "../model/VirtualClassDto";
import {VirtualClassParticipantDto} from "../model/VirtualClassParticipantDto";
import {FormDisplayDto} from "../model/FormDisplayDto";
import {StudentFormDto} from "../model/StudentFormDto";
import {VideoDto} from "../model/VideoDto";
import {throttle, mean} from "lodash";
import {VirtualClassMode} from "../model/VirtualClassMode";
import {VirtualClassVideoPlayback} from "../model/VirtualClassVideoPlayback";
import {ngStorage} from "ngstorage";
import {VirtualClassMessageController} from "./VirtualClassMessageController";
import {VirtualClassMessageDto} from "../model/VirtualClassMessageDto";
import {DebounceFactory} from "../modules/DebounceModule";
import Promise = JQuery.Promise;
import StorageService = ngStorage.StorageService;
import {VirtualClassDetailDto} from "../model/VirtualClassDetailDto";
import {StateService} from "@uirouter/core";
import {LoggerService} from "../modules/LoggerModule";


export default class VirtualController implements Ng1Controller {
    private endpoints: string[];
    private janusConnected: boolean;
    private janus: Janus;
    private videoPlayer: JQuery<HTMLVideoElement>;
    private virtualClasses: VirtualClassDetailDto[] = [];
    private virtualClassDetail: VirtualClassDetailDto;
    private virtualClass: VirtualClassDto;
    private virtualClassId: number;
    private participants: VirtualClassParticipantDto[] = [];
    private participantSelf: VirtualClassParticipantDto;
    private participantSelfBeforeVideoPlay: VirtualClassParticipantDto;

    private videoOwnerId: string;
    private screenStream: MediaStream;
    private cameraStream: MediaStream;
    private forms: FormDisplayDto[];
    private studentForms: StudentFormDto[] = [];
    private videos: VideoDto[] = [];
    private videoPlaylist: VideoDto[] = [];
    private formResults: JQuery<HTMLDivElement>[] = [];

    private soundHandRaised: JQuery<HTMLAudioElement>;
    private soundMessageReceive: JQuery<HTMLAudioElement>;
    private unreadMessageCount = {};

    private messageContainer: JQuery<HTMLElement>;
    private messages: VirtualClassMessageDto[] = [];
    private messagesUnread: number;

    private watchList: any = {};
    private fullscreen: boolean;
    private userMediaError: Error;
    private mediaDevices: MediaDeviceInfo[] = [];
    private browser: string;
    private mediaQualities = [
        {id: 'default', label: 'Default', width: 640, height: 480},
        {id: 'low', label: 'Low', width: 320, height: 240},
        {id: 'hd', label: '720p', width: 1280, height: 720},
        {id: 'fhd', label: '1080p', width: 1920, height: 1080},
        {id: '4k', label: '4k', width: 3840, height: 2160},
    ];
    private isDirecting: boolean;
    private performanceInterval = 1000;
    private performanceLast: Date;
    private performanceCounters: number[] = [];
    private performanceAlertToast: any;
    private debugMode = false;
    private spyAny: boolean;
    private sidebarVisible = true;

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
        console.debug('uiOnParamsChanged', newValues);
        if (newValues.id !== undefined)
            this.joinClass(newValues.id);
        if (newValues.debug !== undefined)
            this.debugMode = newValues.debug
    }

    $onInit(): void {
        this.refresh();
        window.maximize();
    }

    uiCanExit(transition: Transition): HookResult {
        this.janusDestroy();
        this.stopCamera();
        this.stopScreen();
        return true;
    }

    constructor(
        private $scope: ng.IScope,
        private $api: ApiService,
        private $config: ConfigService,
        private $spinner: SpinnerService,
        private $dataHub: DataHubService,
        private $timeout: ITimeoutService,
        private $interval: IIntervalService,
        private $location: ILocationService,
        private $debounce: DebounceFactory,
        private $logger: LoggerService,
        private toaster: any,
        private $localStorage: StorageService,
        private $uibModal,
        private $state: StateService,
        private $stateParams: any
    ) {

        this.$logger.logHandler = (severity, messages) => {
            if (!this.$dataHub.isConnected) return;
            this.$dataHub.virtualClassParticipantLog(severity, messages);
        };

        $dataHub.bindAllVirtualClassActive(() => this.virtualClasses);
        $dataHub.onVirtualClassActiveUpdated(e => {
            if (this.virtualClassId == e.id)
                this.virtualClassDetail = e;
        })
        $dataHub.onVirtualClassUpdated(this.virtualClassUpdated);
        $dataHub.onJanusParticipantUpdated(this.participantUpdated);
        $dataHub.bindJanusParticipantRemoved(() => this.participants);

        let removed = {};
        $dataHub.onJanusParticipantUpdated(e => {
            if (removed[e.id])
                this.$logger.debug(`${e.id} updated after removed`, e);
        });
        $dataHub.onJanusParticipantRemoved(e => removed[e.id] = true);

        $dataHub.bindAllVirtualClassStudentForm(() => this.studentForms);
        $dataHub.bindAllVirtualClassMessage(() => this.messages);
        $dataHub.onVirtualClassMessageUpdated(() => this.messageReceived())

        $dataHub.connected(this.refresh);

        $interval(this.ping, 60000);
        $interval(this.performanceCheck, this.performanceInterval);

        this.$api.get<string[]>('janus/endpoint')
            .then(r => {
                this.endpoints = r.data;
                this.janusInit();
            });

        window.onFullScreenChange(isFullScreen => {
            this.$timeout(() => {
                this.fullscreen = isFullScreen;
            });
        });

        if ($stateParams.id !== undefined)
            this.virtualClassId = $stateParams.id;
        if ($stateParams.debug !== undefined)
            this.debugMode = $stateParams.debug;
    }

    ping = () => {
        if (this.$dataHub.isConnected && this.virtualClass)
            this.$dataHub.virtualClassParticipantPing(this.virtualClass.id)
                // .then(() => this.$logger.log('ping pong'))
                .catch(e => this.$logger.error('ping error', e));
    }

    performanceCheck = () => {
        if (!this.performanceLast) {
            this.performanceLast = new Date();
            return;
        }
        let now = new Date().valueOf();
        let latency = Math.max(now - this.performanceLast.valueOf() - this.performanceInterval, 0);
        this.performanceCounters.push(latency);
        let samples = 10;
        while (this.performanceCounters.length > samples)
            this.performanceCounters.shift();
        if (this.performanceCounters.length >= samples) {
            var avg = mean(this.performanceCounters);
            // this.$logger.debug('latency', latency, 'avg', avg);
            if (avg > 1000) {
                if (!this.performanceAlertToast) {
                    this.performanceAlertToast = this.toaster.error('Performance Alert', 'The page is responding slowly. You may need to reduce the number of video streams visible by resizing the window.', -1)
                }
            } else if (avg < 200 && this.performanceAlertToast) {
                this.toaster.clear(this.performanceAlertToast);
                this.performanceAlertToast = null;
                this.toaster.info('Performance Alert', 'The page is responding normally again.');
            }
        }

        this.performanceLast = new Date();
    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;
        this.$dataHub.server.virtualClassActiveSubscribe().catch(this.$dataHub.defaultErrorHandler);
        if (this.virtualClassId) this.joinClass(this.virtualClassId);
    };

    joinClass = (virtualClassId: number) => {
        this.virtualClassId = virtualClassId;
        this.virtualClassDetail = this.virtualClasses.filter(i => i.id == virtualClassId)[0];
        this.$dataHub.virtualClassJoin(virtualClassId)
            .catch(this.$dataHub.defaultErrorHandler);
        this.participants = [];
        this.$dataHub.virtualClassParticipantSubscribe(virtualClassId);
        this.$dataHub.virtualClassFormGet(virtualClassId)
            .then(forms => this.forms = forms)
            .catch(this.$dataHub.defaultErrorHandler);

        this.studentForms = [];
        this.$dataHub.virtualClassStudentFormSubscribe(virtualClassId)
            .catch(this.$dataHub.defaultErrorHandler);

        this.refreshVideos();

        this.messages = [];
        this.$dataHub.virtualClassMessageSubscribe(virtualClassId);
    }

    refreshVideos = () => {
        this.videos = [];
        this.$dataHub.videoList().then(r => {
            this.videos = r;
            this.refreshVideoPlaylist();
        });
    }

    refreshVideoPlaylist = () => {
        this.videoPlaylist = [];
        if (!this.virtualClassDetail) return;
        if (!this.virtualClassDetail.class) return;
        if (!this.virtualClassDetail.class.classInfo) return;
        this.videoPlaylist = this.videos
            .filter(x => x.classInfoId == this.virtualClassDetail.class.classInfo.classInfoId)
            .sort((a, b) => a.sortOrder - b.sortOrder);
    }

    virtualClassUpdated = (arg: VirtualClassDto) => {
        if (this.virtualClassId != arg.id) return;
        if (!arg.classInProgress) {
            this.leaveClass();
            return;
        }
        let oldVc = this.virtualClass;
        this.virtualClass = arg;
        this.ping();
        var rejoin = !oldVc || oldVc.roomId != arg.roomId;
        if (rejoin)
            this.janusJoin();
        this.updateBitrate();
        this.$timeout(() => {
            this.updateVideoPlaybackState(arg.videoPlayback);
        });
    }


    formSet = (form: FormDisplayDto) => {
        this.$dataHub.virtualClassFormSet(this.virtualClass.id, form ? form.id : null)
            .catch(this.$dataHub.defaultErrorHandler);
    }

    modeSetFinal = () => {
        this.$dataHub.virtualClassModeSet(this.virtualClass.id, VirtualClassMode.FinalExamCourseEvalAttendanceCertificate);
    }

    modeSetSignature = () => {
        this.$dataHub.virtualClassModeSet(this.virtualClass.id, VirtualClassMode.AttendanceSignature);
    }
    modeSetNone = () => {
        this.$dataHub.virtualClassModeSet(this.virtualClass.id, VirtualClassMode.None);
    }


    leaveClass = () => {
        this.virtualClass = null;
        this.virtualClassId = null;
        this.janusJoin();
        this.$state.go('.', {id: null});
    };

    janusInit = () => {
        Janus.init({
            debug: false,
            dependencies: Janus.useDefaultDependencies(),
            callback: () => {
                this.$logger.log('janus initialized');
                this.browser = Janus.webRTCAdapter.browserDetails.browser;
                this.janusConnect();
            }
        });
    };

    janusDestroy = () => {
        try {
            if (this.janus) this.janus.destroy();
        } catch (e) {
            this.$logger.error('error destroying old janus', e);
        }
        this.janusConnected = false;
    }

    janusConnect = () => {
        if (!this.endpoints) return;
        this.$spinner.show('Connecting');
        this.janus = new Janus({
                server: this.endpoints,
                success: () => {
                    this.$logger.debug('janus connected');
                    this.$timeout(() => {
                        this.janusConnected = true;
                        this.$spinner.hide();
                        this.janusJoin();
                    });
                },
                error: (cause) => {
                    this.$logger.error('janus error', cause);
                    this.$spinner.hide();
                    this.janus.destroy();
                    this.$spinner.show('Video Server Reconnecting');
                    this.$timeout(() => {
                        this.$spinner.hide();
                        this.janusConnect();
                    }, 5000);
                },
                destroyed: () => {
                    this.$logger.debug('janus destroyed');
                    this.$scope.$apply(() => {
                        this.janusConnected = false;
                    });
                }
            }
        );
    };

    janusJoin = () => {
        this.janusPublish("screen", this.screenStream);
        this.janusPublish("camera", this.cameraStream);
    }

    shareScreen = (frameRate: number) => {

        var promise;
        let mediaConstraints = {video: {frameRate: frameRate || 3}, audio: false};
        if (this.screenStream && this.screenStream.active) {
            promise = Promise.resolve(this.screenStream);
        } else if (navigator.mediaDevices.getDisplayMedia)
            promise = navigator.mediaDevices.getDisplayMedia(mediaConstraints);
        else if (navigator.getDisplayMedia)
            promise = navigator.getDisplayMedia(mediaConstraints);
        else {
            this.toaster.error("Your browser does not support screen sharing.");
            return;
        }

        promise.then((stream: MediaStream) => {
            let track = stream.getTracks().filter(t => t.kind == "video")[0];
            if (!track) return;
            track.onended = () => this.stopScreen();
            this.screenStream = stream;
            this.janusPublish("screen", stream);
            this.participantToggle(this.participantSelf, 'screenOn');
        })
            .catch((e: MediaStreamError) => {
                this.$timeout(() => {
                    var msg;
                    switch (e.name) {
                        case "SourceUnavailableError":
                            msg = "Error Sharing Screen - please restart your browser or computer."
                    }
                    this.toaster.error(msg || e.message || e.name || "Error sharing screen");
                });
            })
            .finally(() => {
            });

    };

    get videoDeviceId() {
        return this.$localStorage.videoDeviceId || null;
    }

    set videoDeviceId(value) {
        this.$localStorage.videoDeviceId = value;
    }

    get videoDeviceQuality() {
        return this.$localStorage.videoDeviceQuality || null;
    }

    set videoDeviceQuality(value) {
        this.$localStorage.videoDeviceQuality = value;
    }

    get audioDeviceId() {
        return this.$localStorage.audioDeviceId || null;
    }

    set audioDeviceId(value) {
        this.$localStorage.audioDeviceId = value;
    }

    get audioOutputDeviceId() {
        return this.$localStorage.audioOutputDeviceId || 'default';
    }

    set audioOutputDeviceId(value) {
        this.$localStorage.audioOutputDeviceId = value;
    }

    getUserMedia = () => {
        this.stopCamera();
        let quality = this.videoDeviceQuality || this.mediaQualities[0];
        this.userMediaError = null;
        navigator.mediaDevices.getUserMedia({
            video: {deviceId: this.videoDeviceId, width: {ideal: quality.width}, height: {ideal: quality.height}},
            audio: {deviceId: this.audioDeviceId}
        })
            .then(stream => {
                this.$timeout(() => {
                    // this.$logger.debug('getUserMedia returned a stream:', stream, stream.getTracks())
                    this.cameraStream = stream;
                    this.janusPublish("camera", stream);
                });
                navigator.mediaDevices.enumerateDevices().then(devices => {
                    this.$timeout(() => {
                        this.mediaDevices = devices;
                    });
                });
            })
            .catch(e => {
                this.$timeout(() => {
                    this.videoDeviceId = null;
                    this.audioDeviceId = null;
                    this.userMediaError = e;
                    this.$logger.error(e, e.name, e.message);
                });
            });
    };

    stopCamera = () => {
        try {
            if (this.cameraStream)
                this.cameraStream.getTracks().forEach(track => track.stop());
            this.cameraStream = null;
            this.janusPublish("camera", null);
        } catch (e) {
            this.$logger.error(e);
        }
    }

    stopScreen = () => {
        try {
            if (this.screenStream)
                this.screenStream.getTracks().forEach(track => track.stop());
            this.screenStream = null;
            this.participantToggle(this.participantSelf, 'screenOff');
            this.janusPublish("screen", null);
        } catch (e) {
            this.$logger.error(e);
        }
    }

    janusPublish = (mediaType, stream: MediaStream) => {
        if (!this.janus || !this.janus.isConnected()) {
            return;
        }

        if (!this.virtualClass) return;

        var handle;
        if (this[mediaType] && this[mediaType].handle) {
            try {
                this[mediaType].handle.detach();
                this[mediaType].handle = null;
            } catch (e) {
                this.$logger.error('error detaching', e);
            }
        }

        if (!stream) return;

        this[mediaType] = {};
        this.janus.attach({
            plugin: "janus.plugin.videoroom",
            opaqueId: JSON.stringify({virtual: true, userId: this.$config.userInfo.id, media: mediaType}),
            success: (pluginHandle) => {
                this[mediaType].handle = pluginHandle;
                handle = pluginHandle;
                this.$logger.log("Plugin attached! (" + handle.getPlugin() + ", handleId=" + handle.getId() + ")");

                var register = {
                    "request": "join",
                    "room": this.virtualClass.roomId,
                    "pin": this.virtualClass.pin,
                    "ptype": "publisher",
                    "display": mediaType
                };
                handle.send({"message": register});
            },
            error: (error) => {
                this.$logger.error("  -- Error attaching plugin...", error);
            },
            consentDialog: (on) => {
                this.$logger.debug("Consent dialog should be " + (on ? "on" : "off") + " now");
                if (on) {
                    this.$spinner.show('Waiting for authorization');
                } else {
                    this.$spinner.hide();
                    this.$spinner.show('Preparing stream');
                }
            },
            webrtcState: (on) => {
                this.$logger.log(`${mediaType} WebRTC PeerConnection is ${on ? "up" : "down"} now`);
                this.$scope.$apply(() => {
                    this[mediaType].webrtcState = on;
                    if (on) {
                        this.$spinner.reset();
                    } else {
                        this.$spinner.show('Stream Reconnecting');
                    }
                });

            },
            onmessage: (msg, jsep) => {
                if (this.debugMode)
                    this.$logger.debug(" ::: Got a message (publisher) :::", msg);
                var event = msg["videoroom"];
                if (event != undefined && event === "joined") {
                    this[mediaType].joined = true;
                    let sessionId = this.janus.getSessionId();
                    this[mediaType].sessionId = sessionId;
                    let roomId = msg["room"];
                    this[mediaType].roomId = roomId;
                    let publisherId = msg["id"];
                    this[mediaType].publisherId = publisherId;
                    let privateId = msg["private_id"];
                    this[mediaType].privateId = privateId;
                    this.$logger.log(`Successfully joined sessionId:${sessionId} roomId:${roomId} publisherId:${publisherId} privateId:${privateId}`);

                    if (stream)
                        handle.createOffer({
                            stream: stream,
                            simulcast: this.simulcast,
                            //simulcast2: mediaType == "camera",
                            //simulcast2: true,
                            trickle: true,

                            success: (jsep) => {
                                // this.$logger.debug("Got publisher SDP!");
                                // this.$logger.debug(jsep);
                                var publish = {"request": "configure", "audio": true, "video": true};
                                handle.send({"message": publish, "jsep": jsep});
                            },
                            error: (error) => {
                                this.$logger.error("WebRTC error:", error);
                                this.$spinner.hide();
                                this.toaster.error(error);
                            }
                        });

                }
                if (jsep !== undefined && jsep !== null) {
                    // this.$logger.debug("Handling SDP as well...");
                    // this.$logger.debug(jsep);
                    handle.handleRemoteJsep({jsep: jsep});
                }
            },
            mediaState: (type, on) => {
                // this.$logger.debug('mediaState', mediaType, type, on);
                this.$scope.$apply(() => {
                    this[mediaType][type] = on;
                });
            },
            slowLink: (uplink) => {
                // this.$logger.debug('slowLink', mediaType, uplink)
            },

            oncleanup: () => {
                this.$logger.log(" ::: Got a cleanup notification :::");
            },
        });
    };

    toggle = (mediaType, avType) => {
        let handle = this[mediaType].handle;
        if (avType == "video") {
            if (handle.isVideoMuted())
                handle.unmuteVideo();
            else
                handle.muteVideo();
            this[mediaType].videoMuted = handle.isVideoMuted();
        } else {
            if (handle.isAudioMuted())
                handle.unmuteAudio();
            else
                handle.muteAudio();
            this[mediaType].audioMuted = handle.isAudioMuted();
        }

    };

    participantToggle = (participant: VirtualClassParticipantDto, av: string) => {
        if (!participant) return;
        var dto = angular.copy(participant);
        participant["updating"] = true;
        switch (av) {
            case "audioAndVideo":
                dto.videoEnabled = true;
                dto.audioEnabled = true;
                dto.handRaised = false;
                dto.upNext = false;
                break;
            case "audioNoVideo":
                dto.videoEnabled = false;
                dto.audioEnabled = true;
                dto.handRaised = false;
                dto.upNext = false;
                break;
            case "noAudioNoVideo":
                dto.videoEnabled = false;
                dto.audioEnabled = false;
                break;
            case "video":
                dto.videoEnabled = !dto.videoEnabled;
                break;
            case "videoOn":
                dto.videoEnabled = true;
                break;
            case "videoOff":
                dto.videoEnabled = false;
                break;
            case "audio":
                dto.audioEnabled = !dto.audioEnabled;
                break;
            case "audioOn":
                dto.audioEnabled = true;
                break;
            case "audioOff":
                dto.audioEnabled = false;
                break;
            case "screen":
                dto.screenEnabled = !dto.screenEnabled;
                break;
            case "screenOn":
                dto.screenEnabled = true;
                break;
            case "screenOff":
                dto.screenEnabled = false;
                break;
            case "allOff":
                dto.videoEnabled = false;
                dto.audioEnabled = false;
                dto.screenEnabled = false;
                break;
            case "allowUnmute":
                dto.allowUnmute = !dto.allowUnmute;
                break;
            case "upNext":
                dto.upNext = !dto.upNext;
                break;
            case "watchList":
                dto.watchList = !dto.watchList;
                break;
        }
        this.$dataHub.virtualClassParticipantUpdate(dto)
            .catch(this.$dataHub.defaultErrorHandler)
            .finally(() => {
                participant["updating"] = false;
            });
    }

    participantHandLower = (participant: VirtualClassParticipantDto) => {
        var dto = angular.copy(participant);
        dto.handRaised = false;
        this.$dataHub.virtualClassParticipantUpdate(dto)
            .catch(this.$dataHub.defaultErrorHandler);
    }

    participantModeSet = (participant: VirtualClassParticipantDto, mode: string) => {
        var dto = angular.copy(participant);
        dto.mode = mode;
        this.$dataHub.virtualClassParticipantUpdate(dto)
            .catch(this.$dataHub.defaultErrorHandler);
    };


    updateVideoPlaybackState = (video: VirtualClassVideoPlayback) => {
        if (!this.videoPlayer) {
            this.$logger.error('updateVideoPlaybackState: no videoPlayer element');
            return;
        }
        let player = this.videoPlayer[0];
        var src = encodeURI(video.url);
        if (video.url == null) {
            player.removeAttribute('src');
            player.pause();
            player.currentTime = 0;
            player.load();
            return;
        } else if (src != player.src) {
            this.$logger.log('update video src', player.src, src);
            player.src = src;
        }
        switch (video.mode) {
            case "play":
                if (player.paused || player.ended) {
                    this.$logger.log('video.play');
                    player.play();
                }
                break;
            case "pause":
                if (!player.paused) {
                    this.$logger.log('video.pause');
                    player.pause();
                }
        }
        let diff = Math.abs(video.currentTimestamp - player.currentTime);
        if (diff > 5) {
            this.$logger.log('sync time', player.currentTime, video.currentTimestamp, diff);
            player.currentTime = video.currentTimestamp;
        }
    }

    get isVideoOwner() {
        return this.virtualClass && this.virtualClass.videoPlayback && this.virtualClass.videoPlayback.owner == this.videoOwnerId;
    }

    updateVideoPlayback = (video: VideoDto = undefined, mode: string = undefined, currentTimestamp: number = undefined) => {
        let dto = new VirtualClassDto();
        dto.id = this.virtualClass.id;
        dto.videoPlayback = angular.copy(this.virtualClass.videoPlayback) || new VirtualClassVideoPlayback();
        if (video !== undefined) {
            dto.videoPlayback.id = video ? video.id : null;
            dto.videoPlayback.url = video ? video.url : null;
        }
        if (mode !== undefined)
            dto.videoPlayback.mode = mode;
        if (currentTimestamp !== undefined)
            dto.videoPlayback.lastTimestamp = currentTimestamp;

        if (!this.videoOwnerId)
            this.videoOwnerId = `${this.$config.userInfo.name}_${new Date().valueOf()}`;

        dto.videoPlayback.owner = this.videoOwnerId;

        if (this.debugMode)
            this.$logger.debug(`updateVideoPlayback ${mode} ${currentTimestamp}`);

        this.$dataHub.virtualClassUpdate(dto)
            .catch(this.$dataHub.defaultErrorHandler);
    }

    videoLoad = (video: VideoDto) => {
        this.updateVideoPlayback(video, "stop", video.startTimeIndex || -1);

    }

    // play video and mute self
    videoPlayerPlay = () => {
        this.updateVideoPlayback(undefined, "play", this.videoPlayer[0].currentTime);

        // save current state to restore after video finishes
        if (!this.participantSelfBeforeVideoPlay && this.participantSelf) {
            this.participantSelfBeforeVideoPlay = angular.copy(this.participantSelf);
        }

        // disable audio/video/screen for all participants
        this.participantToggleAllOff();
    }

    // pause video and unmute self
    videoPlayerPause = () => {
        this.updateVideoPlayback(undefined, "pause", this.videoPlayer[0].currentTime);

        if (this.participantSelf && this.participantSelf.camera.published)
            this.participantToggle(this.participantSelf, 'audioOn');
    }

    videoSeek = ($event: JQueryEventObject) => {
        var pct = $event.offsetX / $event.currentTarget["offsetWidth"];
        if (!this.videoPlayer[0] || !this.videoPlayer[0].duration) return;
        var newTime = this.videoPlayer[0].duration * pct;
        this.updateVideoPlayback(undefined, undefined, newTime);
        this.videoPlayer[0].currentTime = newTime;
    }

    videoPlayerStop = () => {
        // check if this video is in the playlist and
        // load the next item in the playlist if available
        let currentPlaylistIndex = this.videoPlaylist.findIndex(x => x.id == this.virtualClass.videoPlayback.id);
        if (currentPlaylistIndex >= 0 && this.videoPlaylist.length > (currentPlaylistIndex + 1)) {
            let next = this.videoPlaylist[currentPlaylistIndex + 1];
            this.videoLoad(next);
        } else {
            this.updateVideoPlayback(null, "stop", -1);
        }

        // restore state
        if (this.participantSelfBeforeVideoPlay) {
            {
                let dto = {
                    id: this.participantSelfBeforeVideoPlay.id,
                    audioEnabled: this.participantSelfBeforeVideoPlay.audioEnabled,
                    videoEnabled: this.participantSelfBeforeVideoPlay.videoEnabled,
                    screenEnabled: this.participantSelfBeforeVideoPlay.screenEnabled
                }
                this.participantSelfBeforeVideoPlay = null;
                this.$dataHub.virtualClassParticipantUpdate(<VirtualClassParticipantDto>dto);
            }
        }
    }

    videoPlayerOnPlay = () => {
        this.toaster.success('Video playing');
    }

    videoPlayerOnPause = () => {
        this.toaster.warning('Video Paused')
    }

    videoPlayerOnEnded = ($event: JQueryEventObject) => {
        this.toaster.info('Video ended');
        if (this.isVideoOwner)
            this.videoPlayerStop();
    }

    videoPlayerOnTimeUpdate = throttle(($event: JQueryEventObject) => {
        if (!this.isVideoOwner) return;
        let currentTime = Number($event.target["currentTime"]);
        // check if the currentTime has passed the requested stop time
        // stop the video if it has
        let video = this.videos.filter(x => x.id == this.virtualClass.videoPlayback.id)[0];
        if (video && video.stopTimeIndex && currentTime >= video.stopTimeIndex) {
            this.videoPlayerStop();
        } else {
            this.updateVideoPlayback(undefined, undefined, currentTime);
        }
    }, 5000);


    participantDupe = (participant: VirtualClassParticipantDto) => {
        let copy = angular.copy(participant);
        copy.id = (new Date()).valueOf();
        copy.displayName += " dupe";
        copy["dupe"] = true;
        this.participants.push(copy);
    }
    participantDupeRemove = (participant: VirtualClassParticipantDto) => {
        this.participants.remove(participant, x => x)
    }

    participantDisconnect = (participant: VirtualClassParticipantDto) => {
        let reason = prompt('Enter reason for temporary disconnection:');
        if (!reason) return;
        let dto = angular.copy(participant);
        dto.reasonDisconnected = reason;
        this.$dataHub.virtualClassParticipantRemove(dto)
            .catch(this.$dataHub.defaultErrorHandler);
    }

    participantRemove = (participant: VirtualClassParticipantDto) => {
        let reason = prompt('Enter reason for permanent removal:');
        if (!reason) return;
        let dto = angular.copy(participant);
        dto.reasonRemoved = reason;
        this.$dataHub.virtualClassParticipantRemove(dto)
            .catch(this.$dataHub.defaultErrorHandler);
    }


    getStudentForm = (participant: VirtualClassParticipantDto) => {
        if (!this.virtualClass || !this.virtualClass.currentForm) return null;
        if (!participant || !participant.studentId) return null;
        let form = participant['form'];
        if (form && form.form.id == this.virtualClass.currentForm.id) return form;

        form = this.studentForms.filter(x => x.student.id == participant.studentId && x.form.id == this.virtualClass.currentForm.id)[0];
        participant['form'] = form;
        return form;
    }

    formResultsScroll = throttle((event) => {
        this.formResults.forEach(element => {
            if (event.target === element[0]) {
                return;
            }
            element.scrollTop(event.target.scrollTop);
        });
    }, 50);

    virtualClassUpdate = () => {
        this.$dataHub.virtualClassUpdate(this.virtualClass)
            .catch(this.$dataHub.defaultErrorHandler);
    }

    updateBitrate = () => {
        ["camera", "screen"].forEach(media => {
            if (!this[media] || !this[media].handle) return;
            if (!this.virtualClass) return;
            let bitrate = this.participantSelf.isInstructing
                ? this.virtualClass.instructorBitrate
                : this.virtualClass.studentBitrate;
            if (this.participantSelf[media].bitrate == bitrate) return;
            this.$logger.log(`${media}: setting bitrate to ${bitrate} (was ${this.participantSelf[media].bitrate})`);
            this[media].handle.send({
                "message": {
                    request: "configure",
                    "bitrate": bitrate
                }
            });
        })

    }

    muteAll = (muted: boolean) => {
        this.participants.filter(x => x.studentId).forEach(participant => {
            let dto = {
                id: participant.id,
                audioEnabled: !muted,
            }
            this.$dataHub.virtualClassParticipantUpdate(<VirtualClassParticipantDto>dto);
        })
    }

    participantToggleAllOff = () => {
        this.participants
            .filter(x => x.audioEnabled || x.videoEnabled || x.screenEnabled)
            .forEach(participant => {
                this.participantToggle(participant, 'allOff');
            });
    }

    lockAll = (lock: boolean) => {
        this.participants.filter(x => x.studentId).forEach(participant => {
            let dto = {
                id: participant.id,
                allowUnmute: !lock,
            }
            this.$dataHub.virtualClassParticipantUpdate(<VirtualClassParticipantDto>dto);
        })
    }

    spyAll = (enabled: boolean) => {
        this.participants.filter(x => x.studentId).forEach(participant => {
            participant["spy"] = enabled;
        })
    }

    participantUpdateSelf = () => {
        this.$dataHub.virtualClassParticipantUpdate(this.participantSelf);
    }

    participantMonitor = (participant: VirtualClassParticipantDto) => {
        let dto = {
            id: participant.id,
            monitorId: this.participantSelf.id
        }
        this.$dataHub.virtualClassParticipantUpdate(<VirtualClassParticipantDto>dto);
    }

    participantWatchList = (participant: VirtualClassParticipantDto) => {
        this.watchList[participant.id] = !this.watchList[participant.id];
        this.participantUpdated(participant);
    }

    chat = (participant: VirtualClassParticipantDto) => {
        var modalInstance = this.$uibModal.open({
            template: require('./VirtualClassMessageModal.html'),
            controller: VirtualClassMessageController,
            controllerAs: '$ctrl',
            bindToController: true,
            resolve: {
                participant: () => participant,
                readOnly: () => false
            }
        });
        modalInstance.result.then(e => {
            this.$logger.warn('then', e)
        })
            .catch(e => this.$logger.warn('catch', e));
    };


    messageReceived = () => {
        this.messageScrollToBottom();
        this.messageCountUnread();
    }

    messageCountUnread = throttle(() => {
        var maxRead = this.$localStorage.virtualClassoomLastReadMsgId;

        var unread = this.messages
            .filter(i => i.userId != this.$config.userInfo.id)
            .filter(i => !maxRead || i.id > maxRead).length;
        if (unread > this.messagesUnread)
            this.messagePlayReceivedSound();
        this.messagesUnread = unread;

    }, 500);

    messageMarkRead = () => {
        this.$localStorage.virtualClassoomLastReadMsgId = Math.max(...this.messages.map(i => i.id));
        this.messagesUnread = 0;
    }

    messagePlayReceivedSound = throttle(() => {
        if (this.soundMessageReceive && this.soundMessageReceive[0] && this.messageSoundEnabled) {
            this.soundMessageReceive[0].play();
        }
    }, 1000);

    messageSend = (msg: string) => {
        let dto = {
            virtualClassId: this.virtualClass.id,
            participantId: this.participantSelf.id,
            message: msg
        };
        this.$dataHub.virtualClassMessageSubmit(dto as VirtualClassMessageDto)
            .then(() => {
                this.messageMarkRead();
            })
            .catch(this.$dataHub.defaultErrorHandler);
    }

    messageScrollToBottom = () => {
        this.$timeout(() => {
            if (!this.messageContainer) return;
            let e = this.messageContainer[0];
            if (!e) return;
            e.scrollTop = e.scrollHeight;
        });
    }

    participantStudentUpdateAgain = this.$debounce(() => {
        this.participants.filter(i => i.studentId).forEach(this.participantUpdated);
    }, 500, false);

    participantUpdated = (newValue: VirtualClassParticipantDto) => {
        if (newValue.userId && newValue.userId == this.$config.userInfo.id) {
            let old = this.participantSelf;
            if (old) {
                if (old.videoEnabled != newValue.videoEnabled) {
                    if (newValue.videoEnabled) this.toaster.success('Your camera is LIVE to all participants');
                    else this.toaster.warning('Your camera is OFF')
                }
                if (old.audioEnabled != newValue.audioEnabled) {
                    if (newValue.audioEnabled) this.toaster.success('Your mic is LIVE to all participants');
                    else this.toaster.warning('Your mic is MUTED');
                }
                if (old.screenEnabled != newValue.screenEnabled) {
                    if (newValue.screenEnabled) this.toaster.success('Your screen is LIVE to all participants');
                    else this.toaster.warning('Your screen is OFF');
                }
            }
            this.participantSelf = newValue;
            this.updateBitrate();
            this.participantStudentUpdateAgain();
        }

        if (newValue.monitorId)
            newValue["monitor"] = this.participants.filter(x => x.id == newValue.monitorId)[0];
        else
            newValue["monitor"] = null;

        if (newValue.monitorId && this.participantSelf && newValue.monitorId == this.participantSelf.id) {
            if (newValue.messagesUnreadInstructor > this.unreadMessageCount[newValue.id]) {
                if (this.soundMessageReceive && this.soundMessageReceive[0]) {
                    this.soundMessageReceive[0].play();
                }
            }
            this.unreadMessageCount[newValue.id] = newValue.messagesUnreadInstructor;
        }

        let oldValue = this.participants.filter(x => x.id == newValue.id)[0];
        if (oldValue && !oldValue.handRaised && newValue.handRaised) {
            this.soundHandRaisedPlay();
        }

        let participant = this.participants.merge(newValue, e => e.id);

        participant["displayGroup"] = this.getDisplayGroup(participant);
        this.displayGroupUpdateCount();
    }

    displayGroupUpdateCount = this.$debounce(() => {
        for (let displayGroupsKey in this.displayGroups) {
            this.displayGroups[displayGroupsKey].count = this.participants
                .filter(p => p["displayGroup"] && p["displayGroup"].id == displayGroupsKey).length;
        }
    }, 500, false);


    private displayGroups = {
        5: {title: 'My Watch List', visible: true, count: null},
        10: {title: 'My Students / Monitoring', visible: true, count: null},
        20: {title: 'Broadcasting', visible: true, count: null},
        30: {title: 'Hand Raised', visible: true, count: null},
        40: {title: 'Up Next', visible: true, count: null},
        50: {title: 'All Other Students', visible: false, visibleToggle: true, count: null},
        90: {title: 'Staff', visible: true, count: null},
    }

    getDisplayGroup = (e: VirtualClassParticipantDto) => {
        var sort = e['displayGroup'] ? e['displayGroup'].sort : new Date().valueOf();
        if (e.userId)
            return {id: 90};
        if (this.watchList[e.id])
            return {id: 5};
        if (e.monitorId && this.participantSelf && e.monitorId == this.participantSelf.id)
            return {id: 10};
        if (e.videoEnabled || e.audioEnabled)
            return {id: 20, sort: sort};
        if (e.handRaised)
            return {id: 30, sort: sort};
        if (e.upNext)
            return {id: 40, sort: sort};

        return {id: 50};
    }

    removeAllDisco = () => {
        this.$dataHub.virtualClassParticipantRemoveAllDisconnected(this.virtualClass)
            .catch(this.$dataHub.defaultErrorHandler);
    }

    get canDirect() {
        return this.isDirecting || (this.participantSelf && this.participantSelf.isInstructing);
    }

    get messageSoundEnabled() {
        return this.$localStorage.virtualClassroomMessageSoundEnabled;
    }

    set messageSoundEnabled(value: boolean) {
        this.$localStorage.virtualClassroomMessageSoundEnabled = value;
    }

    get videoPlayerVolume() {
        var value = this.$localStorage.virtualClassroomVideoPlayerVolume;
        if (value == undefined) {
            return 0.2;
        }
        return value;
    }

    set videoPlayerVolume(value: number) {
        this.$localStorage.virtualClassroomVideoPlayerVolume = value;
    }

    spyToggle = (row: VirtualClassParticipantDto) => {
        row["spy"] = !row["spy"];
        let newValue = !!this.participants.filter(i => i["spy"]).length;
        if (this.spyAny != newValue && newValue) {
            this.toaster.info('Listen Enabled', 'You will not hear other participants while Listen is enabled');
        }
        this.spyAny = newValue;
    }

    get simulcast() {
        return this.$localStorage.virtualClassroomSimulcast;
    }

    set simulcast(value: boolean) {
        this.$localStorage.virtualClassroomSimulcast = value;
    }

    get soundHandRaisedEnabled() {
        return this.$localStorage.virtualClassroomSoundHandRaisedEnabled;
    }

    set soundHandRaisedEnabled(value: boolean) {
        this.$localStorage.virtualClassroomSoundHandRaisedEnabled = value;
    }

    soundHandRaisedPlay = () => {
        if (this.soundHandRaisedEnabled &&
            this.soundHandRaised &&
            this.soundHandRaised[0] &&
            this.soundHandRaised[0].play) {
            this.soundHandRaised[0].play();
        }
    }

};
