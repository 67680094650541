import {Ng1Controller, Transition, TargetState, StateService} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {CertificateRequestDto} from "../model/CertificateRequestDto";
import {StudentDto} from "../model/StudentDto";
import {subMonths, subYears} from "date-fns";

export default class RequestController implements Ng1Controller {
    private model: CertificateRequestDto;
    dateOptions = {maxDate: subMonths(subYears(new Date(), 15), 6)};
    private student: StudentDto;
    private step = 1;

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(private $dataHub: DataHubService,
                private toaster: any,
                private $state: StateService,
                private $stateParams: any) {

        $dataHub.client.onStudentUpdated(arg => this.student = arg);
        $dataHub.client.onCertificateRequestUpdated(arg => this.model = arg);

        $dataHub.connected(this.refresh);
    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;

        this.$dataHub.server.studentGet(this.$stateParams.studentId)
            .catch(this.$dataHub.defaultErrorHandler);

        if (this.$stateParams.requestId) {
            this.step = 3;
            this.$dataHub.server.certificateRequestSubscribeOne(this.$stateParams.requestId);
        } else {
            this.$dataHub.server.certificateRequestGet(this.$stateParams.studentId)
                .then(result => this.model = result)
                .catch(this.$dataHub.defaultErrorHandler);
        }
    };

    submit = () => {
        this.$dataHub.server.certificateRequestAdd(this.model)
            .then(result => {
                this.model = result;
                this.step = 3;
                window.close();
            })
            .catch(this.$dataHub.defaultErrorHandler);
    };

}
