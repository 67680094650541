import {HubProxy, HubProxyFactory} from '../modules/HubProxyModule';

export default class DebugController {
    datas = [];
    hub: HubProxy;
    invokeCommand: string;
    invokeParamsJson: string;
    result;


    constructor(hubProxyFactory: HubProxyFactory, private $location: ng.ILocationService, private toaster: any) {
        this.hub = hubProxyFactory.create('DataHub', true);
        this.hub.onStateChanged(this.stateChanged);
        this.hub.on('noop', () => {});
        this.hub.on('studentUpdated', () => {});
        this.hub.connection.received((e) => this.datas.push(e));
        this.hub.start().then(() => this.restoreLocation());


    }


    stateChanged = (change: SignalR.StateChanged) => {
        this.datas = [];
        switch (change.newState) {
            case SignalR.ConnectionState.Connected:
                this.toaster.success('Connected!');

                break;
        }
    };

    validateAllStudents = () => this.hub.invoke('DevValidateAllStudents')
        .then(r => this.result = r)
        .catch(e => this.result = e);

    get invokeParamsObj() {
        try {
            var p = JSON.parse(this.invokeParamsJson);
            if (p && !Array.isArray(p))
                p = [p];
            else
                p = [];
            p.unshift(this.invokeCommand);
            return p;
        } catch (e) {
            return 'invalid json'
        }
    }

    invoke() {
        this.updateLocation();
        this.hub.invoke.apply(this.hub, this.invokeParamsObj)
            .then(e => this.result = e)
            .catch(e => this.result = e);
    }

    updateLocation() {
        this.$location.search({
            invokeCommand: this.invokeCommand,
            invokeParamsJson: this.invokeParamsJson
        })
    }

    restoreLocation() {
        var search = this.$location.search();
        this.invokeCommand = search.invokeCommand;
        this.invokeParamsJson = search.invokeParamsJson;

        if (this.invokeCommand) this.invoke();
    }

    shutdown() {
        this.hub.invoke('appShutdown');
    }

    gcCollect() {
        this.hub.invoke('DevGcCollect');
    }
}
