import {DataHubService} from "../modules/DataHubModule";
import {SpinnerService} from "../modules/SpinnerModule";
import {StudentDto} from "../model/StudentDto";
import {TransactionDto} from "../model/TransactionDto";
import {AttendanceRecordDto} from "../model/AttendanceRecordDto";
import {DrivingRecordDto} from "../model/DrivingRecordDto";
import {StudentNoteDto} from "../model/StudentNoteDto";
import {CertificateDto} from "../model/CertificateDto";
import {StudentLogDto} from "../model/StudentLogDto";
import {AsteriskCallDto} from "../model/AsteriskCallDto";
import {ConfigService} from "../modules/ConfigModule";
import {TestScoreDto} from "../model/TestScoreDto";
import {StudentContractSummaryDto} from "../model/StudentContractSummaryDto";
import {StudentReleaseDto} from "../model/StudentReleaseDto";
import {DocumentDto} from "../model/DocumentDto";
import {SignoutRecordDto} from "../model/SignoutRecordDto";
import RestartController from "./RestartController";
import {StateService, Transition} from "@uirouter/angularjs";
import {VirtualClassParticipantDetailDto} from "../model/VirtualClassParticipantDetailDto";
import {VirtualClassParticipantLogController} from "./VirtualClassParticipantLogController";
import {VirtualClassMessageController} from "../classroom/VirtualClassMessageController";
import {StudentExamDto} from "../model/StudentExamDto";

export default class StudentViewController {
    student: StudentDto;
    transactions: TransactionDto[] = [];
    attendance: AttendanceRecordDto[] = [];
    drivingRecords: DrivingRecordDto[] = [];
    notes: StudentNoteDto[] = [];
    certificates: CertificateDto[] = [];
    logs: StudentLogDto[] = [];
    private calls: AsteriskCallDto[] = [];
    private testScores: TestScoreDto[] = [];
    private contracts: StudentContractSummaryDto[] = [];
    private addNoteVisible: boolean;
    private documents: DocumentDto[] = [];
    private signoutRecords: SignoutRecordDto[] = [];
    private tabClassroomLoaded: boolean;
    private virtualClassRecords: VirtualClassParticipantDetailDto[] = [];
    private exams: StudentExamDto[] = [];

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
        this.$stateParams = Object.assign({}, this.$stateParams, newValues);
    }

    constructor(
        private $dataHub: DataHubService,
        private $spinner: SpinnerService,
        private $config: ConfigService,
        private toaster: any,
        private $uibModal: any,
        private $state: StateService,
        private $stateParams: any
    ) {
        $dataHub.onStudentUpdated(e => {
            this.student = e;
            this.passwordGet();
            this.$spinner.hide();
        });

        $dataHub.bindAllTransaction(() => this.transactions);
        $dataHub.bindAllAttendanceRecord(() => this.attendance);
        $dataHub.bindAllDrivingRecord(() => this.drivingRecords);
        $dataHub.bindAllStudentNote(() => this.notes);
        $dataHub.bindAllCertificate(() => this.certificates);
        $dataHub.bindAllStudentLog(() => this.logs);
        $dataHub.bindAllAsteriskCall(() => this.calls);
        $dataHub.onStudentWithTestScoreUpdated(e => this.testScores = e.testScores);
        $dataHub.bindAllStudentContractSummary(() => this.contracts);
        $dataHub.bindAllDocument(() => this.documents);
        $dataHub.bindAllSignoutRecord(() => this.signoutRecords);
        $dataHub.bindAllVirtualClassParticipantDetail(() => this.virtualClassRecords)
        $dataHub.bindAllStudentExam(() => this.exams)

        $dataHub.connected(this.load);

        this.load();

        if ($stateParams.action == "addNote")
            this.addNoteVisible = true;
    }

    load = () => {
        if (!this.$dataHub.isConnected) return;
        this.$spinner.show();
        this.$dataHub
            .server.studentSubscribeAll(this.$stateParams.studentId)
            .finally(() => this.$spinner.hide());
    };


    addNote = (text) => {
        this.$dataHub.studentNoteAdd(this.student.id, text);
    };

    archive = () => {
        if (!this.student.archived)
            if (!confirm('Archiving a student will remove it from all lists and reports. Do you want to do this?'))
                return;
        this.$dataHub.studentArchive(this.student.id);
    };

    certificateDuplicate = (cert: CertificateDto) => {
        if (!confirm('Do you really want to duplicate certificate #' + cert.serial + ' issued on ' + cert.assignDate.toDateString() + '?'))
            return;

        this.$dataHub.certificateDuplicate(cert)
            .catch(this.$dataHub.defaultErrorHandler);
    };

    certificateVoid = (cert: CertificateDto) => {
        if (!confirm('Do you really want to void certificate #' + cert.serial + ' issued on ' + cert.assignDate.toDateString() + '?'))
            return;

        this.$dataHub.certificateVoid(cert)
            .catch(this.$dataHub.defaultErrorHandler);
    };

    contractSend = () => {
        this.$dataHub.studentContractSend(this.student.id)
            .then(() => this.toaster.success('Contract sent'))
            .catch(this.$dataHub.defaultErrorHandler);
    };

    contractVoid = (row: StudentContractSummaryDto) => {
        var reason = prompt('Enter reason for voiding:');
        if (!reason) return;
        this.$dataHub.studentContractVoid(row.id, reason)
            .then(() => this.toaster.success('Contract Voided'))
            .catch(this.$dataHub.defaultErrorHandler);
    };

    sendTestLink = () => {
        this.$dataHub.studentExamLinkSend(this.student.id)
            .then(() => this.toaster.success('Exam link sent'))
            .catch(this.$dataHub.defaultErrorHandler);
    };

    copyTestLink = () => {
        this.$dataHub.studentExamLinkGet(this.student.id)
            .then(url => {
                navigator.clipboard.writeText(url).then(() => {
                    return this.toaster.success('Exam copied to clipboard');
                })
            })
            .catch(this.$dataHub.defaultErrorHandler);
    };

    changeReleaseReadyDate = () => {
        var input = prompt('Enter a new release-ready date');
        if (input == null) return;
        // @ts-ignore
        this.$dataHub.studentReleaseReadyDateUpdate(this.student.id, input)
            .then(() => this.toaster.success('Release-Ready Date Updated'))
            .catch(this.$dataHub.defaultErrorHandler);

    };

    release = () => {
        var dto = new StudentReleaseDto();
        dto.id = this.student.id;
        this.$dataHub.studentRelease(dto)
            .then(() => this.toaster.success('Student Released'))
            .catch(this.$dataHub.defaultErrorHandler);

    };

    unRelease = () => {
        var dto = new StudentReleaseDto();
        dto.id = this.student.id;
        this.$dataHub.studentUnRelease(dto)
            .then(() => this.toaster.success('Student Un-Released'))
            .catch(this.$dataHub.defaultErrorHandler);

    }

    contractSigned = () => {
        this.$dataHub.contractSigned(this.student.id, true)
            .then(() => this.toaster.success('Contract marked as signed'))
            .catch(this.$dataHub.defaultErrorHandler);
    }

    restart = () => {
        this.$uibModal.open({
            template: require('./Restart.html'),
            controller: RestartController,
            controllerAs: 'vm',
            bindToController: true,
            resolve: {}
        });
    }

    get currentTab() {
        return this.$stateParams.tab;
    }

    set currentTab(value) {
        if (this.$stateParams.tab === value) return;
        this.$state.go('.', {tab: value})
    }

    get classroomStatus() {
        if (this.student.classDoneDate && this.student.testPassed) return 'complete';
        if (this.student.classDoneDate && !this.student.testPassed) return 'needsTest';
        if (this.student.startDate) return 'inProgress';
        if (this.student.startingClass) return 'scheduled';
        return 'notScheduled';
    }

    get inCarStatus() {
        if (this.student.driveDoneDate) return 'complete';
        if (this.student.btwHoursEntered > 0) return 'inProgress';
        if (this.student.instructorAssigned) return 'instructorAssigned';
        if (this.student.driveReadyDate) return 'waitingForInstructor';
        if (this.student.releaseReadyDate) return 'waitingForRelease';

    }

    tabClassroomSelected = () => {
        if (this.tabClassroomLoaded) return;
        this.$dataHub.studentVirtualClassParticipantSubscribe(this.student.id)
            .catch(this.$dataHub.defaultErrorHandler);
        this.$dataHub.studentExamSubscribe(this.student.id)
            .catch(this.$dataHub.defaultErrorHandler);

        this.tabClassroomLoaded = true;
    }

    getVirtualClassRecord = (row: AttendanceRecordDto) => {
        if (!row || !row.class || !row.class.virtualClassId) return;
        return this.virtualClassRecords.filter(x => x.virtualClass.id == row.class.virtualClassId)[0];
    }

    showVirtualClassEventLog = (row: VirtualClassParticipantDetailDto) => {
        var modalInstance = this.$uibModal.open({
            template: require('./VirtualClassParticipantLogModal.html'),
            controller: VirtualClassParticipantLogController,
            size: 'lg',
            controllerAs: '$ctrl',
            bindToController: true,
            resolve: {
                dto: () => row,
            }
        }).result.catch(e => {
        });
    }
    showVirtualClassChatLog = (row: VirtualClassParticipantDetailDto) => {
        var modalInstance = this.$uibModal.open({
            template: require('../classroom/VirtualClassMessageModal.html'),
            controller: VirtualClassMessageController,
            controllerAs: '$ctrl',
            bindToController: true,
            resolve: {
                participant: () => row,
                readOnly: () => true,
            }
        });
        modalInstance.result.then(e => {
            console.warn('then', e)
        })
            .catch(e => console.warn('catch', e));
    }

    voidTest = (record: TestScoreDto) => {
        var reason = prompt(`A voided test no longer counts towards the student's total number of test attempts.
         
Please enter the reason for voiding this test score.`);
        if (!reason) return;

        this.$spinner.show('Voiding Test')
        this.$dataHub.studentTestScoreVoid(record.id, reason)
            .catch(this.$dataHub.defaultErrorHandler)
            .finally(() => this.$spinner.hide());
    }

    passwordGet = () => {
        this.$dataHub.studentPasswordGet(this.student.id)
            .then(pwd => this.student["password"] = pwd)
            .catch(e=> this.student["password"] = `<error: ${e}>`);
    }
    passwordReset = () => {
        this.$dataHub.studentPasswordReset(this.student.id)
            .then(pwd => this.student["password"] = pwd)
            .catch(e=> this.student["password"] = `<error: ${e}>`);
    }
};
