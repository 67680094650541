import * as angular from 'angular';
import {Ng1Controller, Transition, TargetState, StateService} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {ConfigService} from "../modules/ConfigModule";
import {IFormController, IScope} from "angular";
import {VideoDto} from "../model/VideoDto";
import {ClassInfoDto} from "../model/ClassInfoDto";
import {ClassTypeDto} from "../model/ClassTypeDto";

export default class VideoListController implements Ng1Controller {
    private videos: VideoDto[] = [];
    private classTypes: ClassTypeDto[] = [];
    private classInfos: ClassInfoDto[] = [];
    private selected: VideoDto;
    private videoPlayer: JQuery<HTMLVideoElement>;
    private videoForm: IFormController;

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
        this.select(newValues.id);
    }

    constructor(private $dataHub: DataHubService,
                private $state: StateService,
                private $stateParams: any) {
        $dataHub.connected(this.refresh);

    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;

        this.$dataHub.classTypeList()
            .then(r => this.classTypes = r);
        this.$dataHub.classInfoList()
            .then(r => this.classInfos = r);
        this.refreshVideos();
    };

    refreshVideos = () => {
        this.$dataHub.videoList()
            .then(r => this.videos = r);
    }

    select = (video: VideoDto) => {
        this.selected = angular.copy(video);
    };

    save = () => {
        this.$dataHub.videoUpdate(this.selected)
            .then(() => {
                this.selected = null;
                this.refreshVideos();
            })
            .catch(this.$dataHub.defaultErrorHandler);
    };

    getCurrentTime = () => {
        if (!this.videoPlayer || !this.videoPlayer[0]) return;
        let currentTime = this.videoPlayer[0].currentTime;
        if (!angular.isNumber(currentTime)) {
            alert('Unable to read current time from video');
            return;
        }

        return Math.round(currentTime);
    }

    videoPlayerEvent = ($event: JQueryEventObject) => {
        if (!this.selected) return;
        console.debug($event);
        if(this.videoForm) {
            switch ($event.type) {
                case "canplay":
                    this.videoForm["url"].$setValidity('video', true);
                    this.selected["urlValid"] = true;
                    break;
                case "emptied":
                    this.videoForm["url"].$setValidity('video', false);
                    this.selected["urlValid"] = false;
                    break;
            }
        }
        this.calculateRuntime();
    }

    calculateRuntime = () => {
        if (!this.selected) return;
        if (!this.videoPlayer || !this.videoPlayer[0]) return;
        let duration = this.videoPlayer[0].duration;
        if (!angular.isNumber(duration)) {
            this.selected.runTime = null;
            return;
        }

        this.selected.runTime = Math.round(duration);
    }


}
