// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html

import { UserDisplayDto } from './UserDisplayDto';
import { LocationDto } from './LocationDto';
import { ClassInfoDto } from './ClassInfoDto';
import { VirtualClassType } from './VirtualClassType';
import { VirtualClassDto } from './VirtualClassDto';

// Myers.StudentManagementSystem.Data.Models.ClassDto
export class ClassDto {
    public id: number = 0;
    public teacher: UserDisplayDto = null;
    public location: LocationDto = null;
    public classInfo: ClassInfoDto = null;
    public classDate: Date = new Date(0);
    public cTimeStart: string = null;
    public cTimeEnd: string = null;
    public classStart: Date = new Date(0);
    public classEnd: Date = new Date(0);
    public virtualClassType: VirtualClassType = null;
    public virtualClassTypeString: string = null;
    public virtualClassAttendanceComplete: boolean = false;
    public virtualClassId: number = null;
    public virtualClass: VirtualClassDto = null;
    public weekNumber: string = null;
}


    



