import * as angular from 'angular';

import {SpinnerService} from '../modules/SpinnerModule';
import {ClassDto} from "../model/ClassDto";
import {AttendanceStatus} from "../model/AttendanceStatus";
import {AttendanceQueryDto} from "../model/AttendanceQueryDto";
import {AttendanceRecordDto} from "../model/AttendanceRecordDto";
import {DataHubService} from "../modules/DataHubModule";
import {ApiService} from '../modules/ApiModule';
import {ConfigService} from '../modules/ConfigModule';
import {Ng1Controller, HookResult, Transition} from "@uirouter/angularjs";
import {StudentAttendanceDto} from "../model/StudentAttendanceDto";

export default class AttendanceController implements Ng1Controller {
    private classId: number;
    private selectedClass: ClassDto;
    attendanceRecords: AttendanceRecordDto[] = [];
    students = [];

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
        console.debug('uiOnParamsChanged', newValues);
        this.classId = newValues.classId;
        this.refreshAttendance();
    }

    $onInit(): void {
        console.debug('AttendanceCtrl onInit');
    }

    uiCanExit(transition: Transition): HookResult {
        return true;
    }


    constructor(
        $scope: ng.IScope,
        private $api: ApiService,
        private $config: ConfigService,
        private $spinner: SpinnerService,
        private $dataHub: DataHubService,
        $stateParams: any,
    ) {
        $dataHub.client.onAttendanceRecordUpdated(e => this.attendanceRecords.merge(e, x => x.student.id));
        $dataHub.client.onAttendanceRecordRemoved(e => this.attendanceRecords.remove(e, x => x.student.id));
        $dataHub.connected(() => this.refreshAttendance());

        this.classId = $stateParams.classId;
        if ($dataHub.isConnected)
            this.refreshAttendance();
    }

    refreshAttendance = () => {
        this.attendanceRecords = [];
        this.selectedClass = null;
        if (!this.classId) return;
        var q: AttendanceQueryDto = {
            classId: this.classId
        };

        if (!this.$dataHub.isConnected) return;

        this.$dataHub.invoke('ClassGet', this.classId)
            .then((cls: ClassDto) => {
                this.selectedClass = cls;
            })
            .catch(this.$dataHub.defaultErrorHandler);

        this.$dataHub.server.attendanceQuery(q)
            .then(e => console.debug('AttendanceQuery finished', e))
            .catch(this.$dataHub.defaultErrorHandler);


    };

    set(record: AttendanceRecordDto, status: AttendanceStatus) {
        var newRecord = angular.copy(record);
        newRecord.attendanceStatus = status;

        this.$dataHub.attendanceUpdate(newRecord)
            .catch(this.$dataHub.defaultErrorHandler)
    };

    addStudent(student: StudentAttendanceDto) {
        var record = new AttendanceRecordDto();
        record.class = new ClassDto();
        record.class.id = this.classId;
        record.student = student;
        this.set(record, AttendanceStatus.Present);
    };

};
