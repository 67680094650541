import * as angular from 'angular';

// automatically "camel case" a string if it is all upper or lower case
function autoCase() {
    return {
        restrict: 'A',
        require: '^ngModel',
        link: function (scope, element, attr, ctrl: ng.INgModelController) {
            ctrl.$overrideModelOptions({updateOn: 'blur'});
            ctrl.$parsers.push(function (viewValue) {
                if (!viewValue) return viewValue;
                let formattedValue = fixCase(viewValue);
                if (ctrl.$viewValue !== formattedValue) {
                    ctrl.$setViewValue(formattedValue);
                    ctrl.$render();
                }
                return formattedValue;
            })

        }
    };
}

let match = /^([A-Z-'\s\d]+|[a-z-'\s\d]+)$/g;
let re = /(\b[a-z](?!\s))/g;

function fixCase(s){
    console.debug(s);
    if(!s.match(match)) return s;
    return s.toLowerCase().replace(re, function(x){return x.toUpperCase();});
}

export default angular.module('directives.autoCase', [])
    .directive('autoCase', autoCase)
    .name;
