// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html

import { UserDisplayDto } from './UserDisplayDto';

// Myers.StudentManagementSystem.Data.Models.EntityBaseDto
export class EntityBaseDto {
    public id: number = 0;
    public rowVersion: number = 0;
    public rowInsertedTime: Date = new Date(0);
    public rowInsertedByUser: UserDisplayDto = null;
    public rowUpdatedTime: Date = null;
    public rowUpdatedByUser: UserDisplayDto = null;
    public rowDeletedTime: Date = null;
    public rowDeletedByUser: UserDisplayDto = null;
    public rowDeleted: boolean = false;
}


    



