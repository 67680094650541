import {DataHubService} from "../modules/DataHubModule";
import {SpinnerService} from "../modules/SpinnerModule";
import {PreRegDto} from "../model/PreRegDto";

export default class ListController {
    records: PreRegDto[] = [];

    constructor(
        private $dataHub: DataHubService,
        private $spinner: SpinnerService
    ) {

        $dataHub.client.onPreRegUpdated(e => this.records.merge(e, x => x.id));
        $dataHub.client.onPreRegRemoved(e => this.records.remove(e, x => x.id));

        $dataHub.connected(this.load);
        this.load();
    }

    load = () => {
        if (!this.$dataHub.isConnected) return;
        this.$spinner.show();
        this.$dataHub.server.preRegSubscribe()
            .finally(() => this.$spinner.hide());
    };


};
