import * as angular from 'angular';

function tel() {
    return {
        template: '<button ng-if="number" click-to-dial="number"   title="Dial {{number|tel}}" class="btn btn-default btn-xs"><i class="fad fa-phone-plus"></i> {{number|tel}}</button>',
        restrict: 'E',
        scope: {number: '<'},
    };
}
export default angular.module('directives.tel', [])
    .directive('tel', tel)
    .name;
