// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html

import { FormDisplayDto } from './FormDisplayDto';
import { VirtualClassMode } from './VirtualClassMode';
import { VirtualClassVideoPlayback } from './VirtualClassVideoPlayback';

// Myers.StudentManagementSystem.Data.Models.VirtualClassDto
export class VirtualClassDto {
    public id: number = 0;
    public demoClass: boolean = false;
    public classInProgress: boolean = false;
    public roomActive: boolean = false;
    public roomId: number = 0;
    public pin: string = null;
    public currentForm: FormDisplayDto = null;
    public mode: VirtualClassMode = null;
    public modeString: string = null;
    public instructorBitrate: number = null;
    public studentBitrate: number = null;
    public videoPlayback: VirtualClassVideoPlayback = null;
}


    



