import {Ng1Controller, StateService, TargetState, Transition} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {UserStorageService} from "../modules/UserStorageModule";
import {SpinnerService} from "../modules/SpinnerModule";
import {ConfigService} from "../modules/ConfigModule";
import {StudentDto} from "../model/StudentDto";
import {ITimeoutService} from "angular";
import * as moment from "moment";
import {chain} from "lodash";

export default class SignupController implements Ng1Controller {
    private students: StudentDto[];
    private debug: any;
    private charts: any[];
    private chartTypes = ['Bar', 'Bar-Stacked', 'Bar-Horizontal', 'Bar-Horizontal-Stacked', 'Line', 'Doughnut', 'Pie'];
    private selectorOptions = ['Date', 'Day', 'Week', 'Month', 'Year', 'PreReg', 'User', 'Location', 'Referral', 'Type'];

    $onInit(): void {
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(private $dataHub: DataHubService,
                private $userStorage: UserStorageService,
                private $spinner: SpinnerService,
                private toaster: any,
                private $config: ConfigService,
                private $timeout: ITimeoutService,
                private $state: StateService, private $stateParams: any) {
        $dataHub.connected(() => $timeout(this.refresh, 1000));
    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;
        if (!this.options.fromDate || !this.options.toDate) return;

        this.$spinner.show();
        this.$dataHub.studentSignupList(this.options.fromDate, this.options.toDate)
            .then(x => {
                this.students = x;
                this.updateCharts();
            })
            .catch(this.$dataHub.defaultErrorHandler)
            .finally(() => this.$spinner.hide());
    };


    getSelector = (s) => {
        switch (s) {
            case 'Date':
                return (student: StudentDto) => moment(student.rowInsertedTime).format("MM/DD");
            case 'Day':
                return (student: StudentDto) => moment(student.rowInsertedTime).format("E-ddd");
            case 'Week':
                return (student: StudentDto) => moment(student.rowInsertedTime).format("WW");
            case 'Month':
                return (student: StudentDto) => moment(student.rowInsertedTime).format("MM-MMM");
            case 'Year':
                return (student: StudentDto) => moment(student.rowInsertedTime).format("YYYY");

            case 'Location':
                return (student: StudentDto) => student.startingClass ? student.startingClass.location.name : student.studentType.name;
            case 'User':
                return (student: StudentDto) => student.rowInsertedByUser.fullName;
            case 'Referral':
                return (student: StudentDto) => student.referralHeardFrom ? student.referralHeardFrom.name : 'Other';
            case 'Type':
                return (student: StudentDto) => student.studentType.name;
        }
        return () => null;
    };

    updateCharts = () => {

        if (!this.options.charts)
            this.options.charts = [];

        this.charts = this.options.charts.map(conf => this.getChart(this.students, conf.type,
            conf.title || 'Students' + (conf.category ? ' by ' + conf.category : '') + (conf.series ? ' by ' + conf.series : ''),
            this.getSelector(conf.series), this.getSelector(conf.category)));

    };


    getChart = (data, type, title, seriesSelector, datasetSelector) => {


        var seriesValues = chain(data)
            .map(seriesSelector)
            .uniq()
            .orderBy()
            .value();

        var datasets = chain(data)
            .groupBy(datasetSelector)
            .map((value, key) => {
                return {
                    label: key,
                    data: seriesValues.map(sv => value.filter(v => seriesSelector(v) == sv).length)
                }
            })
            .orderBy(x => x.label)
            .value();

        var config = {
            type: '',
            data: {
                labels: seriesValues,
                datasets: datasets
            },
            options: {
                scales: {
                    xAxes: [{
                        stacked: false
                    }],
                    yAxes: [{
                        stacked: false
                    }]
                },
                legend: {
                    display: false
                },
                title: {
                    display: true,
                    text: title
                }
            }
        };
        switch (type.toLowerCase()) {
            case 'bar-stacked':
                config.type = 'bar';
                config.options.scales.xAxes[0].stacked = true;
                config.options.scales.yAxes[0].stacked = true;
                break;
            case 'bar-horizontal':
                config.type = "horizontalBar";
                break;
            case 'bar-horizontal-stacked':
                config.type = 'horizontalBar';
                config.options.scales.xAxes[0].stacked = true;
                config.options.scales.yAxes[0].stacked = true;
                break;
            default:
                config.type = type.toLowerCase();
                break;
        }

        return config;
    };

    get options() {
        return this.$userStorage.$get<{ fromDate: Date, toDate: Date, charts: any[] }>
        ('studentSignupReportOptions', {fromDate: new Date(), toDate: new Date(), charts: []});
    }

    chartRemove = (row) => {
        this.options.charts = this.options.charts.filter(x => x != row);
    }
}
