import * as angular from 'angular';
import {IQService, IScope} from "angular";
import {UserStorageService} from "../modules/UserStorageModule";
import {DataHubService} from "../modules/DataHubModule";
import {SpinnerService} from "../modules/SpinnerModule";

function clickToDial($q: IQService, $userStorage: UserStorageService, $dataHub: DataHubService, $spinner: SpinnerService) {
    function dial(destination: string) {
        var q = $q.defer<DialOption>();
        q.promise
            .then(result => {
                if (result == null || result.Value == null) return;
                $userStorage[result.Type] = result.Value;
                $spinner.show('Dialing');
                $dataHub.server.asteriskCallOriginate(result.Value, destination)
                    .finally(() => $spinner.hide());
            });
        dialModal(q, $userStorage.myExtension, $userStorage.myCellPhone);
    }

    return {
        restrict: 'A',
        scope: {clickToDial: '<'},
        link(scope, element, attrs) {
            element.bind('click', event => {
                dial(scope.clickToDial);
                event.preventDefault();
                event.stopPropagation();
            });
        }
    };
}


function dialModal(q: angular.IDeferred<DialOption>, extension: string, cell: string) {
    var dialog = document.createElement('div');
    dialog.innerHTML = require('./ClickToDial.html');
    document.body.appendChild(dialog);

    const $modal = $(dialog)
        .find('.modal')
        .modal({show: true, keyboard: false, backdrop: 'static'});

    $modal.find('#myExtension').val(extension);
    $modal.find('#myCellPhone').val(cell);

    $modal.find('button[data-dial-ext]').on('click', () => {
        q.resolve({
            Type: 'myExtension',
            Value: $modal.find('#myExtension').val().toString()
        });
    });

    $modal.find('button[data-dial-cell]').on('click', () => {
        q.resolve({
            Type: 'myCellPhone',
            Value: $modal.find('#myCellPhone').val().toString()
        });
    });

    $modal.find('button[data-cancel]').on('click', () => {
        q.resolve(null);
    });

    $modal.on('hidden.bs.modal', function (e) {
        document.body.removeChild(dialog);
    });

    q.promise.finally(() => {
        $modal.modal('hide');
    });
}

class DialOption {
    public Type: string;
    public Value: string;
}

export default angular.module('directives.clickToDial', [])
    .directive('clickToDial', clickToDial)
    .name;
