import './globals';

/* global css imports */
import 'angular/angular-csp.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'ui-select/dist/select.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import './css/nga.all.min.css';
import 'angularjs-toaster/toaster.css';
import 'text-security/dist/text-security.css';
import 'chosen-js/chosen.css';

import './css/site.less';

/* angular modules */
import * as angular from 'angular';
import * as ngAnimate from 'angular-animate';
import * as ngSanitize from 'angular-sanitize';
import {ISCEDelegateProvider} from "angular";

/* 3p modules */
import 'ngStorage';
import {ngStorage} from 'ngStorage';
import 'angular-filter';
import 'angular-moment';
import 'ui-select';
import 'angular-ui-bootstrap';
import uirouter, {StateService, Trace} from '@uirouter/angularjs';
import 'angular-credit-cards';
import 'angularjs-toaster';
import 'ng-file-upload';
import 'chosen-js/chosen.jquery';
import 'angular-chosen-localytics';

/* our modules */
import DirectivesModule from './directive';
import ApiModule from './modules/ApiModule';
import ConfigModule, {ConfigService} from './modules/ConfigModule';
import DebounceModule from './modules/DebounceModule';
import HubProxyModule from './modules/HubProxyModule';
import SpinnerModule from './modules/SpinnerModule';
import PrintModule from './modules/PrintModule';
import MenuModule from "./modules/MenuModule";
import DataHubModule from "./modules/DataHubModule";
import ExamHubModule from "./modules/HubModule";
import UserStorageModule from "./modules/UserStorageModule";
import LoggerModule from './modules/LoggerModule'

import HomeModule from './home/index';
import LoginModule from './login';
import AdminModule from './admin';
import ReportModule from './report';
import ClassroomModule from './classroom';
import DeveloperModule from './developer';
import StudentModule from './student';
import PreRegModule from './prereg';
import AsteriskModule from './asterisk';
import InCarModule from './inCar';
import CertificateModule from './certificate';
import GiftCertificateModule from './giftCertificate';
import DocumentModule from './document';
import ContractModule from './contract';
import UserModule from './user';
import ChangeLogModule from './changeLog';
import TransactionModule from './transaction';

/* directives */



import routing from './routing';
import UserActivityModule, {UserActivityService} from "./modules/UserActivityModule";

import 'json.date-extensions';
// @ts-ignore
JSON.useDateParser();

let app = angular.module('app',
    [
        /* angular modules */
        ngSanitize, ngAnimate,

        /* 3p modules */
        'ngStorage', 'angular.filter', 'angularMoment', 'ui.select', 'ui.bootstrap',
        'credit-cards',
        'toaster',
        'ngFileUpload',
        uirouter,
        'localytics.directives',
        require('angular-input-masks/us'),

        /* our modules */
        ApiModule,
        ConfigModule, DebounceModule, HubProxyModule, SpinnerModule,
        PrintModule, MenuModule, DataHubModule, UserStorageModule,
        ExamHubModule,
        DeveloperModule, StudentModule,
        UserActivityModule,
        DirectivesModule,
        LoggerModule,

        HomeModule,
        LoginModule,
        AdminModule,
        ReportModule,
        ClassroomModule,
        PreRegModule,
        AsteriskModule,
        InCarModule,
        CertificateModule,
        GiftCertificateModule,
        DocumentModule,
        ContractModule,
        UserModule,
        ChangeLogModule,
        TransactionModule,
    ])
    .config(uiSelectConfig => {
        uiSelectConfig.theme = 'bootstrap';
    })
    .config((moment) => {
        moment.updateLocale('en', {
            week: {
                dow: 0,
                doy: 0
            }
        });
    })
    .config((uibDatepickerConfig) => {
        uibDatepickerConfig.showWeeks=false;
    })
    .config(routing)
    .config(($httpProvider: ng.IHttpProvider) => {
        $httpProvider.defaults.withCredentials = true;
    })
    .config(($sceDelegateProvider:ISCEDelegateProvider) => {
        $sceDelegateProvider.resourceUrlWhitelist([
            'self',
            window['apiBase'] + '**'
        ])
    })
    .run((
        $rootScope: ng.IRootScopeService,
        $localStorage: ngStorage.StorageService,
        $sessionStorage: ngStorage.StorageService,
        $state: StateService,
        $config: ConfigService,
        $userActivity: UserActivityService,
        $trace: Trace) => {
        $rootScope['$config'] = $config;
        $rootScope['$state'] = $state;
        if (!$sessionStorage.started)
            $sessionStorage.started = new Date();
        $sessionStorage.loaded = new Date();

        if(window['environment'] === 'development') {
            $trace.enable('TRANSITION');
            $rootScope['digestCount']=0;
            $rootScope.$watch(() => {
                setTimeout(() => {
                    $rootScope['digestCount']++;
                });
            })
        }





    });

