import {DataHubService} from "../modules/DataHubModule";
import {SpinnerService} from "../modules/SpinnerModule";
import {TransactionDto} from "../model/TransactionDto";
import {Ng1Controller, StateService, Transition} from "@uirouter/angularjs";

export default class ViewController implements Ng1Controller {
    transactions: TransactionDto[] = [];
    txnId: number;
    studentId: number;
    txn: TransactionDto;

    constructor(
        private $dataHub: DataHubService,
        private $spinner: SpinnerService,
        private $state: StateService,
        $stateParams: any
    ) {
        $dataHub.onTransactionUpdated(e => {
            this.transactions.merge(e, x => x.id);
            if (e.id == this.txnId)
                this.txn = e;
        });
        $dataHub.connected(this.reload);
        this.parseValues($stateParams);
    }

    $onInit(): void {
        this.reload();
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
        this.parseValues(newValues);
    }

    uiCanExit(transition: Transition): import("@uirouter/angularjs").HookResult {
    }

    parseValues(paramValues) {
        if (paramValues.txnId) this.txnId = paramValues.txnId;
        if (paramValues.studentId) this.studentId = paramValues.studentId;
    }

    reload = () => {
        if (!this.$dataHub.isConnected) return;

        if (!this.txnId) return;

        this.$spinner.show('Loading Transaction');
        this.$dataHub.transactionSubscribe(this.txnId, true)
            .finally(() => this.$spinner.hide());
    };

    selectTxn = (txn) => {
        this.txn = txn;
        this.$state.go('.', {txnId: txn.id}, {notify: false, reload: false})
    };


    voidTxn = (txn: TransactionDto) => {
        this.$dataHub.invoke('TransactionVoid', txn);
    }

};
