import {Ng1Controller, Transition, TargetState, StateService} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {StudentExamTranscriptDto} from "../model/StudentExamTranscriptDto";

export default class ExamTranscriptController implements Ng1Controller {
    private model: StudentExamTranscriptDto;

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(private $dataHub: DataHubService,
                private $state: StateService,
                private $stateParams: any) {
        $dataHub.connected(this.refresh);
        this.$dataHub.onStudentExamTranscriptUpdated(e => this.model = e);
    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;

        this.$dataHub.studentExamTranscriptSubscribe(this.$stateParams.examId)
            .catch(this.$dataHub.defaultErrorHandler);
    }
}
