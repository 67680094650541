import * as angular from 'angular';
import {ApiService} from "../modules/ApiModule";
import {ngStorage} from "ngstorage";
import StorageService = ngStorage.StorageService;
import {DataHubService} from "../modules/DataHubModule";
import {PrinterDto} from "../model/PrinterDto";
import {ConfigService} from "../modules/ConfigModule";
import {PrintService} from "../modules/PrintModule";
import {popWindow} from "./Popout";
import {PrintJobResultDto} from "../model/PrintJobResultDto";
import {PrintJobRequestDto} from "../model/PrintJobRequestDto";

class buttonController {
    constructor(private $api: ApiService,
                private $localStorage: StorageService,
                private $uibModal,
                private $scope) {
    }


    print = () => {
        this.showDialog(true, false);
    };

    showDialog = (autoPrint, preview) => {
        var modalInstance = this.$uibModal.open({
            template: require('./PrintReportModal.html'),
            controller: modalController,
            controllerAs: '$ctrl',
            bindToController: true,
            resolve: {
                options: () => this.$scope.$parent.$eval(this.$scope.options),
                autoPrint: () => autoPrint,
                autoPreview: () => preview,
                caption: () => this.$scope.caption,
            }
        });

        modalInstance.result.then((result) => {
            if (this.$scope.onPrinted && typeof this.$scope.onPrinted === "function") {
                this.$scope.onPrinted();
            }
        }, function () {
            console.debug('Modal dismissed at: ' + new Date());
        });
    };

    preview = () => {
        this.showDialog(false, true);
    };

    printOptions = () => {
        this.showDialog(false, false);
    }
}

class modalController {
    private printers: PrinterDto[] = [];
    private printerName: string;
    private result: PrintJobResultDto;
    private printing: boolean;
    private printCount = 0;
    private changePrinter: boolean;
    private previewing: boolean;

    constructor(private $uibModalInstance,
                private $localStorage: StorageService,
                private $config: ConfigService,
                private $dataHub: DataHubService,
                private $print: PrintService,
                private toaster: any,
                private options: PrintJobRequestDto,
                private autoPrint: boolean,
                private autoPreview: boolean,
                private caption: string) {

        this.$dataHub.client.bindAllPrinter(() => this.printers);
        this.$dataHub.server.printerSubscribe();

        this.printerName = this.$localStorage.printerName || this.$localStorage.printerNameBackup;

        if (this.autoPreview) this.preview();
        else if (!this.$localStorage.printerName) this.change();
        else if (this.autoPrint) this.reprint();
    }

    get selectedPrinter(): PrinterDto {
        return this.printers.filter(x => x.fullName == this.printerName)[0];
    }

    get selectedPrinterIsDefault() {
        return this.printerName === this.$localStorage.printerName;
    }

    get selectedPrinterIsBackup() {
        return this.printerName === this.$localStorage.printerNameBackup;
    }

    get defaultPrinter() {
        return this.$localStorage.printerName;
    }

    get backupPrinter() {
        return this.$localStorage.printerNameBackup;
    }

    resetSelectedPrinter = () => {
        this.$dataHub.printerPurgeJobs(this.selectedPrinter)
            .then(() => {
                this.toaster.success('Print Jobs Cleared - please wait a moment for printer status to update, then try printing again');
                this.printerChanged();
            })
            .catch(this.$dataHub.defaultErrorHandler);
    }

    useDefaultPrinter = () => {
        this.printerName = this.defaultPrinter;
        this.printerChanged();
    }

    useBackupPrinter = () => {
        this.printerName = this.backupPrinter;
        this.printerChanged();
    }

    printerChanged = () => {
        this.result = null;
        this.printCount = 0;
    }

    change = () => {
        this.changePrinter = true;
    };

    reprint = () => {
        if (!this.printerName) {
            this.change();
            return;
        }

        this.options.printerName = this.printerName;
        this.printing = true;
        this.$dataHub.server.printJobSubmit(this.options)
            .then(r => {
                this.result = r;
                if (!this.result.error) {
                    this.printCount++;
                }
            })
            .catch(e => {
                this.result = new PrintJobResultDto();
                this.result.error = e;
            })
            .finally(() => this.printing = false);
    };

    printLocal = () => {
        this.options.printerName = null;
        this.printing = true;
        this.$dataHub.server.printJobSubmit(this.options)
            .then(r => {
                this.result = r;
                if (!this.result.error) {
                    this.printCount++;
                    let url = this.$config.apiBase + 'report/view/' + r.guid;
                    this.$print.printPdf(url).then(r => {
                        if (r) this.done();
                    });
                }
            })
            .catch(e => {
                this.result = new PrintJobResultDto();
                this.result.error = e;
            })
            .finally(() => this.printing = false);
    };

    preview = () => {
        this.options.printerName = null;
        this.previewing = true;
        this.$dataHub.server.printJobSubmit(this.options)
            .then(r => {
                this.result = r;
                let url = this.$config.apiBase + 'report/view/' + r.guid;
                console.log(url);
                popWindow(url, null);
            })
            .catch(e => {
                this.result = new PrintJobResultDto();
                this.result.error = e;
            })
            .finally(() => this.previewing = false);
    };

    done = () => {
        this.$uibModalInstance.close('1');
    };

    cancel = () => {
        this.$uibModalInstance.dismiss('cancel');
    };

}


function printReportDirective() {
    return {
        template: require('./PrintReportButton.html'),
        restrict: 'E',
        transclude: true,
        scope: {
            options: '@',
            icon: '@',
            caption: '@',
            dropup: '@',
            context: '@',
            btnSize: '@',
            onPrinted: '&'
        },
        controller: buttonController,
        controllerAs: '$ctrl'
    };
};


export default angular.module('directives.printReportDirective', [])
    .directive('printReport', printReportDirective)
    .name;
