import * as angular from 'angular';
import {StateProvider} from '@uirouter/angularjs';

import ListController from "./ListController";
import EditController from "./EditController";
import ViewController from "./ViewController";
import ProfileController from "./ProfileController";

class Routes {
    static $inject = ['$stateProvider'];

    constructor($stateProvider: StateProvider) {
        $stateProvider
            .state('user', {
                abstract: true,
                url: '/user',
            })
            .state('user.list', {
                url: '/',
                template: require('./List.html'),
                controller: ListController,
                controllerAs: 'vm',
                data: {title: 'User List'}
            })
            .state('user.add', {
                url: '/add',
                template: require('./Edit.html'),
                controller: EditController,
                controllerAs: 'vm',
                data: {title: 'Add New User'}
            })
            .state('user.edit', {
                url: '/:id/edit',
                template: require('./Edit.html'),
                controller: EditController,
                controllerAs: 'vm',
                data: {title: 'Edit User'}
            })
            .state('user.view', {
                url: '/:id/view?tab',
                template: require('./View.html'),
                controller: ViewController,
                controllerAs: 'vm',
                data: {title: 'View User'},
                params: {
                    tab: {
                        dynamic: true,
                        value: 'info'
                    }
                }
            })
            .state('user.profile', {
                url: '/profile',
                template: require('./Profile.html'),
                controller: ProfileController,
                controllerAs: 'vm',
                data: {title: 'My Profile'},
            })




        ;
    }
}

export default angular.module('app.user', [])
    .config(Routes)
    .name;
