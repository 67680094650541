import {Ng1Controller, StateService, TargetState, Transition} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {ConfigService} from "../modules/ConfigModule";

export default class UploadController implements Ng1Controller {
    private queue: Queue;

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(private $dataHub: DataHubService,
                private Upload: any,
                private toaster: any,
                private $config: ConfigService,
                private $state: StateService,
                private $stateParams: any) {
        $dataHub.connected(this.refresh);
        this.queue = new Queue(Upload, this.$config.apiBase + 'document/upload');
    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;
    };

    filesChanged = ($files: [File]) => {
        $files.forEach(file => this.queue.add(file));
    };
}

class Queue {
    public items: QueueItem[] = [];
    private currentItems: QueueItem[] = [];

    constructor(private Upload: any, private url: string) {
    }

    add = (file: File) => {
        this.items.push(new QueueItem(this, file));
    };

    remove = (item: QueueItem) => {
        this.items.remove(item, x => x);
    };


    get progress() {
        if (!this.currentItems.length) return 0;
        return this.currentItems
            .map(i => i.progress)
            .reduce((l, r) => l + r, 0) / this.currentItems.length;
    }

    get isUploading() {
        return this.currentItems.length;
    }

    get pendingItems() {
        return this.items.filter(x => !x.isSuccess);
    }

    uploadAll = () => {
        this.currentItems = this.items.slice(0);
        this.currentItems.forEach(item => {
            item.isReady = true;
            this.upload(item);
        });
    };

    upload = (item: QueueItem) => {
        item.isUploading = true;
        this.Upload.upload({
            url: this.url + '?splitPdf=' + item.splitPdf,
            data: {file: item.file}
        }).then(() => {
            item.isSuccess = true;
            item.isUploading = false;
            this.currentItems.remove(item, x => x);
        }, resp => {
            console.log('Error status: ' + resp.status);
            console.debug(resp);
            item.isError = true;
            item.error = resp;
            item.isUploading = false;
            this.currentItems.remove(item, x => x);
        }, function (evt) {
            item.progress = (100.0 * evt.loaded / evt.total);
        });

    };

    clear = () => {
        this.items = [];
    }
}

class QueueItem {
    isUploading: boolean;
    isSuccess: boolean;
    isError: boolean;
    error: any;
    progress: number;
    isReady: boolean;
    splitPdf = true;

    constructor(private queue: Queue, public file: File) {
    }

    remove = () => {
        this.queue.remove(this);
    };

    upload = () => {
        this.queue.upload(this);
    };
}
