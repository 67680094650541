// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html

import { LocationDto } from './LocationDto';

// Myers.StudentManagementSystem.Data.Models.TransactionRefundRequestDto
export class TransactionRefundRequestDto {
    public transactionId: number = 0;
    public refundAmount: number = 0;
    public remoteIp: string = null;
    public userAgent: string = null;
    public location: LocationDto = null;
}


    



