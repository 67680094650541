import * as angular from 'angular';
import {addMonths, addYears, intervalToDuration} from "date-fns";

function factory() {
    return {
        template: require('./VirtualParticipant.html'),
    };
}

export default angular.module('classroom.virtualParticipant', [])
    .directive('virtualParticipant', factory)
    .name;
