import * as angular from 'angular';

function ngEnter() {
    return {
        link(scope, element, attrs) {
            element.bind('keypress', event => {
                if (event.which === 13) {
                    scope.$apply(() => {
                        scope.$eval(attrs.ngEnter);
                    });

                    event.preventDefault();
                }
            });
        }
    };
};

export default angular.module('directives.ngEnter', [])
    .directive('ngEnter', ngEnter)
    .name;
