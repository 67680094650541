import * as angular from 'angular';
import {ngStorage} from "ngstorage";
import {IQService, IRootScopeService, ITimeoutService, IWindowService} from "angular";
import {SpinnerService} from "./SpinnerModule";
import {ExamHub} from "./ExamHub";
import {VirtualClassHub} from "./VirtualClassHub";

export class ExamHubService extends ExamHub {

    constructor($timeout: ITimeoutService,
                $q: IQService,
                $rootScope: IRootScopeService,
                $window: IWindowService,
                $spinner: SpinnerService,
                private $localStorage: ngStorage.StorageService,
                private toaster: any) {
        super($q, $rootScope, $timeout, $window, $spinner);
        $timeout(() => this.start());
    }


    defaultErrorHandler = (e: Error) => {
        console.error(e);
        this.toaster.error(e.message);
    };

}

export class VirtualClassHubService extends VirtualClassHub {

    constructor($timeout: ITimeoutService,
                $q: IQService,
                $rootScope: IRootScopeService,
                $window: IWindowService,
                $spinner: SpinnerService,
                private $localStorage: ngStorage.StorageService,
                private toaster: any) {
        super($q, $rootScope, $timeout, $window, $spinner);
        $timeout(() => this.start());
    }


    defaultErrorHandler = (e: Error) => {
        console.error('defaultErrorHandler', e);
        this.toaster.error(e.message);
    };

}



export default angular.module('services.examHub', [])
    .service('$examHub', ExamHubService)
    .service('$virtualClassHub', VirtualClassHubService)
    .name;
