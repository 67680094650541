import * as angular from 'angular';
import {ISCEService} from "angular";

function trustedUrl($sce: ISCEService) {
    return function (input) {
        console.log('trustedUrl', input);
        return $sce.trustAsResourceUrl(input);
    };
}

export default angular.module('filters.trustedUrl', [])
    .filter('trustedUrl', trustedUrl)
    .name;
