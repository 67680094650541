import * as angular from 'angular';

function ngMuted() {
    return {
        link(scope, element, attrs) {
            scope.$watch(attrs.ngMuted, newValue => {
                element[0].muted = newValue;
            })
        }
    };
};

export default angular.module('directives.ngMuted', [])
    .directive('ngMuted', ngMuted)
    .name;
