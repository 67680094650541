import {Ng1Controller, Transition, TargetState, StateService} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {StudentDto} from "../model/StudentDto";
import {ngStorage} from "ngstorage";
import StorageService = ngStorage.StorageService;

export default class WithoutSignedContractController implements Ng1Controller {
    private students: StudentDto[] = [];

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(private $dataHub: DataHubService,
                private toaster: any,
                private $localStorage: StorageService,
                private $state: StateService,
                private $stateParams: any) {
        this.$dataHub.bindAllStudentWithoutSignedContract(() => this.students);
        $dataHub.connected(this.refresh);

    }

    get sortField() {
        return this.$localStorage.withoutSignedContractSortField
    }
    set sortField(value) {
        this.$localStorage.withoutSignedContractSortField = value;
    }

    refresh = () => {
        this.students = [];
        if (!this.$dataHub.isConnected) return;

        this.$dataHub.studentWithoutSignedContractSubscribe([1, 10])
            .catch(this.$dataHub.defaultErrorHandler);
    };

    sendLink = (student:StudentDto) => {
        this.$dataHub.server.studentContractSend(student.id)
            .then(() => this.toaster.success('Contract link sent'))
            .catch(this.$dataHub.defaultErrorHandler);
    }
}
