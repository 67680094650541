import * as angular from 'angular';

function dateDuration() {

    return {
        restrict: 'E',
        template: require('./DateDuration.html'),
        scope: {
            value: '<',
            dangerDays: '<',
            warningDays: '<'
        },
        link(scope, element, attrs) {
        }
    };
}


export default angular.module('directives.dateDuration', [])
    .directive('dateDuration', dateDuration)
    .name;
