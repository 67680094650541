import {DataHubService} from "../modules/DataHubModule";
import {SpinnerService} from "../modules/SpinnerModule";
import {PreRegDto} from "../model/PreRegDto";
import {StateService} from "@uirouter/core";
import {StudentDto} from "../model/StudentDto";

export default class ViewController {
    record: PreRegDto;
    private dupes: StudentDto[];

    constructor(
        private $dataHub: DataHubService,
        private $spinner: SpinnerService,
        private $state: StateService,
        private $stateParams: any
    ) {
        $dataHub.client.onPreRegUpdated(e => {
            this.record = e;
            let dto = new StudentDto();
            dto.firstName = e.firstName;
            dto.middleName = e.middleName;
            dto.lastName = e.lastName;
            dto.dob = e.dob;
            dto.licenseNumber = e.licenseNumber;
            this.$dataHub.server.studentDupeCheck(dto)
                .then(dupes => this.dupes = dupes);
        });
        $dataHub.connected(this.load);
        this.load();
    }

    load = () => {
        if (!this.$dataHub.isConnected) return;
        this.$spinner.show();
        this.$dataHub.server.preRegGet(this.$stateParams.id)
            .finally(() => this.$spinner.hide());
    };

    addNote = (text) => {
        this.$dataHub.server.preRegAddNote(this.record.id, text);
    };

    archive = () => {
        this.$dataHub.server
            .preRegArchive(this.record.id)
            .then(() => window.close());
    };

    convert = () => {
        this.$spinner.show('Converting Pre-Registration');
        this.$dataHub.server.preRegConvert(this.record.id)
            .then(studentId => {
                this.$spinner.hide();
                this.$state.go('student.edit', {studentId: studentId})
            })
            .catch(e => {
                this.$spinner.hide();
                console.error(e);
                alert(e);
            });
    }


};
