import * as angular from 'angular';
import {DataHubService} from "./DataHubModule";
import {UserStorageDto} from "../model/UserStorageDto";
import {ConfigService} from "./ConfigModule";
import {ngStorage} from "ngstorage";
import {DebounceFactory} from "./DebounceModule";
import {UserActivityDto} from "../model/UserActivityDto";

export class UserActivityService {
    store = new Array<UserStorageDto>();

    constructor(private $dataHub: DataHubService,
                private $sessionStorage: ngStorage.StorageService,
                private $localStorage: ngStorage.StorageService,
                private $debounce: DebounceFactory,
                private $config: ConfigService,
                private toaster: any,
                $interval: ng.IIntervalService,
                $location: ng.ILocationService,
                $rootScope: ng.IRootScopeService) {

        $dataHub.client.onUserActivityForceRefresh(() => window.location.reload());
        $dataHub.client.onUserActivityForceClose(() => window.close());

        $dataHub.connected(this.pushUpdate);

        $rootScope.$on('$locationChangeSuccess', this.pushUpdate);

        // watch document.title
        new MutationObserver(this.pushUpdate).observe(
            document.querySelector('title'),
            {subtree: true, characterData: true}
        );

        window.addEventListener("click", this.activityDetected, {once: false, passive: true});
        window.addEventListener("mousemove", this.activityDetected, {once: false, passive: true});
        window.addEventListener("mousedown", this.activityDetected, {once: false, passive: true});
        window.addEventListener("keydown", this.activityDetected, {once: false, passive: true});
        window.addEventListener("touchmove", this.activityDetected, {once: false, passive: true});

        $interval(this.checkActivity, 5000);
    }

    private activityDetected = (ev) => {
        this.$localStorage.activity = new Date();
        if (this.toast) {
            this.toaster.clear(this.toast);
            this.toast = null;
            this.toaster.success('Welcome back!');
        }
        this.updateActivity();
    };

    private toast: any;


    private checkActivity = () => {
        if (!this.$config.loggedIn || !this.$config.userInfo) return;
        var idleWarning;
        if (this.$config.locationTokenCurrent && this.$config.locationTokenCurrent.privateComputer) {
            idleWarning = 60 * 4; // 4 hr
        } else {
            idleWarning = 20; // 20 mins
        }

        if (this.$config.userInfo.hasActiveClass && idleWarning < 90) {
            idleWarning = 90;
        }

        idleWarning = idleWarning * 60 * 1000;
        var idleLogout = idleWarning + (5 * 60 * 1000);

        var idleTime = new Date().valueOf() - this.$localStorage.activity.valueOf();

        if (idleTime < idleWarning) {
            if (this.toast) {
                this.toaster.clear(this.toast);
                this.toast = null;
            }
            return;
        }
        if (idleTime > idleLogout) {
            this.toaster.clear();
            this.toast = null;
            this.$config.logout();
            return;
        }
        if (this.toast) return;
        var toastHandler = () => this.toast = null;
        this.toast = this.toaster.warning(
            'Are You Still There?',
            'You will be logged out in a few minutes',
            0,
            null,
            toastHandler,
            null,
            true,
            null,
            toastHandler);
    };

    private updateActivity = this.$debounce(() => {
        this.pushUpdate();
    }, 15000, true);

    private pushUpdate = () => {
        if (!this.$dataHub.isConnected) return;

        let data = {
            title: window.document.title,
            url: window.location.pathname + window.location.search,
            version: window["version"],
            started: this.$sessionStorage.started,
            loaded: this.$sessionStorage.loaded,
            activity: this.$localStorage.activity,
            now: new Date(),
            locationTokenId: null,
            browserGuid: this.$localStorage.browserGuid
        };
        if (this.$localStorage.locationToken)
            data.locationTokenId = this.$localStorage.locationToken.id;
        this.$dataHub.server.userActivityUpdate(<UserActivityDto><unknown>data);
    }

}

export default angular.module('services.userActivity', [])
    .service('$userActivity', UserActivityService)
    .name;
