import * as moment from 'moment';

import {DataHubService} from "../modules/DataHubModule";
import {SpinnerService} from "../modules/SpinnerModule";
import {DrivingRecordDto} from "../model/DrivingRecordDto";
import {IFormController} from "angular";
import {UserDto} from "../model/UserDto";
import {StudentDto} from "../model/StudentDto";
import {ConfigService} from "../modules/ConfigModule";
import {CertificateRequestDto} from "../model/CertificateRequestDto";
import {DrivingRecordTypeDto} from "../model/DrivingRecordTypeDto";
import {DebounceFactory} from "../modules/DebounceModule";
import {StudentDisplayDto} from "../model/StudentDisplayDto";


export default class RecordsController {
    user: UserDto;
    records: DrivingRecordDto[] = [];
    student: StudentDto;
    dateOptions = {maxDate: new Date()};
    dead: boolean = false;
    private instructors: UserDto[] = [];
    private certificateRequest: CertificateRequestDto;
    private recordTypes: DrivingRecordTypeDto[] = [];
    private completionAllowed: boolean;

    constructor(
        private $dataHub: DataHubService,
        private $spinner: SpinnerService,
        private $config: ConfigService,
        private $debounce: DebounceFactory,
        private $stateParams: any
    ) {
        $dataHub.client.onDrivingRecordUpdated(e => {
            this.records.merge(e, x => x.id);
            this.ensureAddRow();
        });
        $dataHub.client.onDrivingRecordRemoved(e => {
            this.records.remove(e, x => x.id);
            this.ensureAddRow();
        });
        $dataHub.client.onStudentUpdated(arg => {
            if (this.$config && this.$config.userInfo && !this.$config.userInfo.isAdministrator && this.$config.userInfo.id !== arg.instructor.id) {
                alert('This is not your student - you may not enter driving records');
                this.dead = true;
                window.close();
                return;
            }

            this.student = arg;
            this.ensureAddRow();
        });

        $dataHub.client.bindAllUser(() => this.instructors);
        $dataHub.client.onCertificateRequestUpdated(arg => {
            if (!this.certificateRequest || arg.id >= this.certificateRequest.id)
                this.certificateRequest = arg;
        });
        $dataHub.client.onCertificateRequestRemoved(arg => {
            if (this.certificateRequest && this.certificateRequest.id == arg.id)
                this.certificateRequest = null;
        });
        $dataHub.client.onDrivingRecordTypeUpdated(e => {
            this.recordTypes.merge(e, x => x.id);
            this.ensureAddRow();
        });
        $dataHub.connected(this.load);
        this.load();
    }

    load = () => {
        if (!this.$dataHub.isConnected) return;

        this.completionAllowed = this.$config.userInfo && this.$config.userInfo.isAdministrator || this.$config.locationTokenCurrent != null;

        this.$spinner.show();

        this.ensureAddRow();
        this.$dataHub.server.userDrivingInstructorsSubscribe()
            .catch(this.$dataHub.defaultErrorHandler);
        this.$dataHub.server.drivingRecordTypeSubscribe()
            .catch(this.$dataHub.defaultErrorHandler);
        this.$dataHub.server.studentGet(this.$stateParams.studentId)
            .catch(this.$dataHub.defaultErrorHandler);
        this.$dataHub.server.studentDrivingRecords(this.$stateParams.studentId)
            .catch(this.$dataHub.defaultErrorHandler)
            .finally(() => this.$spinner.hide());
        this.$dataHub.server.studentCertificateRequestSubscribe(this.$stateParams.studentId)
            .catch(this.$dataHub.defaultErrorHandler);
    };

    detailSave(row: any, rowForm: IFormController) {
        var isAddRow = row.id <= 0;
        this.$spinner.show();
        return this.$dataHub.server.studentDrivingRecordUpdate(row)
            .then(() => {
                rowForm.$setPristine();
                if (isAddRow) {
                    this.records = this.records.filter(value => value.id > 0);
                    this.ensureAddRow();
                }
            }).then(value => row.error = "").catch(e => {
                console.warn(e);
                row.error = e;
            }).finally(() => this.$spinner.hide());
    }

    totalTimeMs = (drivingRecord: DrivingRecordDto) => {
        if (drivingRecord && drivingRecord.durationEffective)
            return moment.duration(drivingRecord.durationEffective).asMilliseconds();
        if (!drivingRecord ||
            !drivingRecord.drivingRecordType ||
            !drivingRecord.drivingRecordType.validForBtwHoursEntered)
            return 0;
        return moment(drivingRecord.recordEnd, "H:m").valueOf() -
            moment(drivingRecord.recordStart, "H:m").valueOf();
    };

    bigTotalTimeMs = () => this.records
        .map(value => this.totalTimeMs(value))
        .filter(value => !isNaN(value))
        .reduce((a, b) => a + b, 0);

    rowEditable = (drivingRecord: DrivingRecordDto) => {
        if (this.$config.userInfo.isAdministrator) {
            return true;
        }
        if (drivingRecord.id <= 0) return true;
        if (this.$config.userInfo &&
            drivingRecord.rowInsertedByUser &&
            drivingRecord.rowInsertedByUser.id == this.$config.userInfo.id &&
            drivingRecord.rowInsertedTime) {
            return moment(drivingRecord.rowInsertedTime) > moment().add(-2, 'minutes');
        }
        return false;
    };

    detailDelete(id: number) {
        if (id <= 0) return;
        this.$spinner.show();
        return this.$dataHub.server.studentDrivingRecordDelete(id)
            .catch(e => {
                console.warn(e);
                alert(e);
            }).finally(() => this.$spinner.hide());
    }

    ensureAddRow = this.$debounce(() => {
        if (this.records.some(value => value.id <= 0)) return;
        if (!this.student) return;
        if (!this.recordTypes.length) return;

        let row = new DrivingRecordDto();
        row.student = new StudentDisplayDto();
        row.student.id = this.$stateParams.studentId;
        row.instructor = this.student.instructor;
        row.drivingRecordType = this.recordTypes.filter(x => x.name === 'Standard')[0];
        this.records.push(row);
    }, 500, false);

    remarksChanged = () => {
        this.$dataHub.server.studentRemarksUpdate(this.student.id, this.student.btwRemarks)
            .catch(this.$dataHub.defaultErrorHandler);
    }

};
