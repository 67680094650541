import {SpinnerService} from '../modules/SpinnerModule';
import {ngStorage} from 'ngStorage';
import {LocationDto} from "../model/LocationDto";
import {ClassDto} from "../model/ClassDto";
import {ClassQueryDto} from "../model/ClassQueryDto";
import {AttendanceOptionsDto} from "../model/AttendanceOptionsDto";
import {DataHubService} from "../modules/DataHubModule";
import {Ng1Controller, StateService, TargetState, Transition} from "@uirouter/angularjs";
import {ConfigService} from "../modules/ConfigModule";
import {format, isValid, parseISO} from "date-fns";

export default class ClassroomController implements Ng1Controller {

    model: { locationId: number, classDate: Date, classId: number } = {
        locationId: null,
        classDate: new Date(),
        classId: null
    };
    locations = new Array<LocationDto>();
    classes = new Array<ClassDto>();
    datepickerOptions = {minDate: null, maxDate: null};
    private options: AttendanceOptionsDto;

    $onInit(): void {
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
        console.debug('uiParamsChanged', newValues);
        if (newValues.locationId)
            this.model.locationId = parseInt(newValues.locationId);
        if (newValues.classDate)
            this.model.classDate = parseISO(newValues.classDate);
        if (newValues.classId)
            this.model.classId = parseInt(newValues.classId) || this.model.classId;
    }

    constructor(
        $scope: ng.IScope,
        private $http: ng.IHttpService,
        private $localStorage: ngStorage.StorageService,
        private $spinner: SpinnerService,
        private $dataHub: DataHubService,
        private $config: ConfigService,
        private $state: StateService,
        $stateParams: any,
    ) {
        $dataHub.client.onLocationUpdated(this.locationUpdated);
        $dataHub.client.bindAllClass(() => this.classes);

        $dataHub.connected(this.onConnected);
        if ($dataHub.isConnected)
            this.onConnected();
        var token = $config.locationTokenCurrent;
        this.model.locationId = parseInt($stateParams.locationId) ||
            (token && token.location ? token.location.id : null);
        this.model.classDate = parseISO($stateParams.classDate);
        if (!isValid(this.model.classDate))
            this.model.classDate = new Date();
        this.model.classId = parseInt($stateParams.classId);

        console.debug('ClassRoomCtrl $stateParams', $stateParams);
        console.debug('ClassRoomCtrl model', this.model);

        $scope.$watch(() => this.model.locationId, this.locationChanged);
        $scope.$watch(() => this.model.classDate, this.dateChanged);
        $scope.$watch(() => this.model.classId, this.classChanged);
    }

    onConnected = () => {
        this.refreshOptions();
        this.refreshLocations();
    };

    locationChanged = (x, y) => {
        console.debug('locationChanged', x, y);
        this.refreshClasses();
    };

    dateChanged = (x, y) => {
        console.debug('dateChanged', x, y);
        this.refreshClasses();
    };

    classChanged = (x, y) => {
        console.debug('classChanged', x, y);
    };


    updateStateParams = () => {
        console.debug('updateStateParams')
        var classDate = format(this.model.classDate, 'yyyy-MM-dd');
        let params = {
            'locationId': this.model.locationId,
            'classDate': classDate,
            'classId': this.model.classId
        };

        this.$state.go('.', params);
    };


    refreshOptions = () => {
        console.debug('refreshOptions');
        this.$dataHub.server.attendanceOptions()
            .then(r => {
                this.options = r;
                this.datepickerOptions.minDate = r.minDate ? new Date(r.minDate) : null;
                this.datepickerOptions.maxDate = r.maxDate ? new Date(r.maxDate) : null;

            })
            .catch(this.$dataHub.defaultErrorHandler);

        if (!this.model.classId) {
            this.$dataHub.server.classMyClasses()
                .then((r) => {
                    if (!r.current) return;
                    this.model.locationId = r.current.location.id;
                    this.model.classDate = new Date(r.current.classDate);
                    this.model.classId = r.current.id;
                });
            return;
        }
        this.$dataHub.server.classGet(this.model.classId)
            .then(cls => {
                this.model.locationId = cls.location.id;
                this.model.classDate = new Date(cls.classDate);
                this.refreshClasses();
            })
    };

    refreshLocations = () => {
        this.$dataHub.server.location()
            .then(this.refreshClasses)
            .catch(this.$dataHub.defaultErrorHandler);
    };

    refreshClasses = () => {
        this.classes = [];

        if (!this.$dataHub.isConnected)
            return;

        if (!this.model.locationId) return;
        if (!this.model.classDate) return;

        var q = new ClassQueryDto();
        q.locationId = this.model.locationId;
        q.classDate = this.model.classDate;

        this.$dataHub.server.classQuery(q)
            .catch(this.$dataHub.defaultErrorHandler);
    };

    locationUpdated = (location: LocationDto) => {
        this.locations.merge(location, x => x.id);
    };


    get selectedClass() {
        return this.classes.filter(x => x.id == this.model.classId)[0];
    }

    isLocationAllowed = (location: LocationDto) => {
        if (!this.options || !this.options.locations || !this.options.locations.length) return true;
        return this.options.locations.some(x => x == location.id);
    };

    isClassAllowed = (c: ClassDto) => {
        if (!this.options || !this.options.classes || !this.options.classes.length) return true;
        return this.options.classes.some(x => x == c.id);
    };

};
