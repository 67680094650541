import {LogDto} from '../model/LogDto';
import {DataHubService} from "../modules/DataHubModule";
import {DebounceFactory} from "../modules/DebounceModule";

export default class LogController {
    error: any;
    logs: LogDto[];
    notifications = false;
    private loadCompleted: boolean;
    private lastException: LogDto;

    constructor(private $dataHub: DataHubService, private $debounce: DebounceFactory) {
        $dataHub.onLogUpdated(this.logUpdated);
        $dataHub.connected(this.reload);

        try {
            if (!Notification || !Notification.requestPermission)
                return;
            Notification.requestPermission()
                .then(result => {
                    this.notifications = result === 'granted';
                    console.log(result);
                });
        } catch (e) {
            console.error(e);
        }
    }

    logUpdated = (log: LogDto) => {
        while (this.logs.length > 500)
            this.logs.shift();
        this.logs.push(log);
        if (log.exception && this.loadCompleted) {
            this.lastException = log;
            this.notifyDebounce();
        }
        this.loadComplete();
    };

    reload = () => {
        this.logs = [];
        this.loadCompleted = false;
        if (!this.$dataHub.isConnected) return;
        this.$dataHub.invoke('Logs')
            .catch(e => this.error = e);
    };

    loadComplete = this.$debounce(() => this.loadCompleted = true, 1000, false);


    notify = (title, message) => {
        if (!this.notifications) return;
        try {
            var options = {
                body: message,
            };

            var n = new Notification(title, options);
            n.onclick = () => {
                window.focus();
            }
        } catch (e) {
            console.error(e);
        }
    };

    notifyDebounce = this.$debounce(() => this.notify('Exception', this.lastException.message), 10000, true);
}
