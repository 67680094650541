import {SpinnerService} from '../modules/SpinnerModule';
import {StudentDto} from "../model/StudentDto";
import {DataHubService} from "../modules/DataHubModule";
import {ApiService} from '../modules/ApiModule';
import {ConfigService} from '../modules/ConfigModule';
import {Ng1Controller, HookResult, Transition} from "@uirouter/angularjs";

export default class ContractController implements Ng1Controller {
    private classId: number;

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
        this.classId = newValues.classId;
        this.refresh();
    }

    $onInit(): void {
    }

    uiCanExit(transition: Transition): HookResult {
        return true;
    }

    students: StudentDto[] = [];

    constructor(
        $scope: ng.IScope,
        private $api: ApiService,
        private $config: ConfigService,
        private $spinner: SpinnerService,
        private $dataHub: DataHubService,
        $stateParams: any,
    ) {
        this.$dataHub.client.bindAllStudent(() => this.students);

        this.classId = $stateParams.classId;
        $dataHub.connected(() => this.refresh());
        if ($dataHub.isConnected)
            this.refresh();
    }

    refresh = () => {
        this.students = [];

        if (!this.classId) return;
        if (!this.$dataHub.isConnected) return;


        this.$dataHub.server.contractQuery(this.classId)
            .catch(this.$dataHub.defaultErrorHandler);

    };


    showPrintUnprinted = () => {
        return this.students.some(x => !x.contractPrinted);
    };

    contractsPrinted = (studentIds: number[]) => {
        for (var studentId of studentIds) {
            this.$dataHub.server.contractPrinted(studentId, true);
        }
    };

    signed = (record: StudentDto) => {
        this.$dataHub.server.contractSigned(record.id, !record.contractSigned)
            .catch(this.$dataHub.defaultErrorHandler);
    };

    get studentIdListUnprintedContract() {
        return this.students.filter(x => !x.contractPrinted).map(s => s.id);
    };


};
