import * as angular from 'angular';
import {StateProvider} from '@uirouter/angularjs';

import ReleaseReadyController from "./ReleaseReadyController";
import ReleaseNotReadyController from "./ReleaseNotReadyController";
import LocationTokenController from "./LocationTokenController";
import PriceManagerController from "./PriceManagerController";
import PrinterSetupController from "./PrinterSetupController";
import ZoomLinkController from "./ZoomLinkController";
import VideoListController from "./VideoList";

class Routes {
    static $inject = ['$stateProvider'];

    constructor($stateProvider: StateProvider) {
        $stateProvider
            .state('admin', {
                abstract: true,
                url: '/admin'
            })

            .state('admin.release', {
                url: '/release',
                template: require('./ReleaseReady.html'),
                controller: ReleaseReadyController,
                controllerAs: 'vm',
                data: {title: 'Release-Ready'}
            })
            .state('admin.releaseNotReady', {
                url: '/release/notReady',
                template: require('./ReleaseNotReady.html'),
                controller: ReleaseNotReadyController,
                controllerAs: 'vm',
                data: {title: 'Release-Not Ready'}
            })
            .state('admin.priceManager', {
                url: '/priceManager',
                template: require('./PriceManager.html'),
                controller: PriceManagerController,
                controllerAs: 'vm',
                data: {title: 'Price Manager'}
            })


            .state('admin.document', {
                abstract: true,
                url: '/document'
            })

            .state('admin.locationToken', {
                url: '/locationToken',
                template: require('./LocationToken.html'),
                controller: LocationTokenController,
                controllerAs: 'vm',
                data: {title: 'Location Setup'}
            })

            .state('admin.printerSetup', {
                url: '/printerSetup',
                template: require('./PrinterSetup.html'),
                controller: PrinterSetupController,
                controllerAs: 'vm',
                data: {title: 'Printer Setup'}
            })

            .state('admin.zoomLink', {
                url: '/zoomLink',
                template: require('./ZoomLink.html'),
                controller: ZoomLinkController,
                controllerAs: 'vm',
                data: {title: 'Zoom Link'}
            })

            .state('admin.videoList', {
                url: '/videoList',
                template: require('./VideoList.html'),
                controller: VideoListController,
                controllerAs: 'vm',
                data: {title: 'Video List'}
            })


        ;
    }
}

export default angular.module('app.admin', [])
    .config(Routes)
    .controller({ReleaseController: ReleaseReadyController})
    .name;
