import * as angular from 'angular';

function tel() {
    return function (input) {
        if (!input) return '';

        var value = input.toString().trim().replace(/^\+/, '');

        if (value.match(/[^0-9]/)) return input;

        var numberArray = value.split("");
        if (numberArray.length == 11 && numberArray[0] == '1') numberArray.splice(0, 1);

        switch (numberArray.length) {
            case 10:
                numberArray.splice(0, 0, '(');
                numberArray.splice(3 + 1, 0, ')');
                numberArray.splice(3 + 2, 0, ' ');
                numberArray.splice(6 + 3, 0, '-');
                break;
        }
        return numberArray.join("");

    };
}

export default angular.module('filters.tel', [])
    .filter('tel', tel)
    .name;
