import {LogDto} from '../model/LogDto';
import {DataHubService} from "../modules/DataHubModule";
import {DebounceFactory} from "../modules/DebounceModule";

export default class ExceptionController {
    logs: LogDto[];

    constructor(private $dataHub: DataHubService) {
        $dataHub.client.bindAllLog(() => this.logs);
        $dataHub.connected(this.reload);
    }

    ack = (log: LogDto) => {
        this.$dataHub.server.logExceptionAcknowledge(log.id)
            .catch(this.$dataHub.defaultErrorHandler);
    };


    reload = () => {
        this.logs = [];
        if (!this.$dataHub.isConnected) return;
        this.$dataHub.server.logExceptions()
            .catch(this.$dataHub.defaultErrorHandler);
    };

}
