import {DataHubService} from "../modules/DataHubModule";
import {ITimeoutService} from "angular";
import {VirtualClassParticipantDetailDto} from "../model/VirtualClassParticipantDetailDto";

export class VirtualClassParticipantLogController {

    constructor(private $uibModalInstance,
                private $timeout: ITimeoutService,
                private $dataHub: DataHubService,
                private dto: VirtualClassParticipantDetailDto) {

    }

    done = () => {
        this.$uibModalInstance.close('1');
    };

}
