import {DataHubService} from "../modules/DataHubModule";
import {SpinnerService} from "../modules/SpinnerModule";
import {StudentStuckDto} from "../model/StudentStuckDto";
import {ngStorage} from "ngstorage";
import StorageService = ngStorage.StorageService;

export default class StuckController {
    students: StudentStuckDto[] = [];
    private loading: boolean;

    constructor(
        private $dataHub: DataHubService,
        private $spinner: SpinnerService,
        private $localStorage: StorageService,
        private $stateParams: any
    ) {

        $dataHub.client.onStudentStuckUpdated(e => this.students.merge(e, x => x.id));
        $dataHub.client.onStudentStuckRemoved(e => this.students.remove(e, x => x.id));

        $dataHub.connected(this.load);

        this.load();
    }

    load = () => {
        if (!this.$dataHub.isConnected) return;
        this.$spinner.show();
        this.loading = true;
        this.$dataHub.server.studentStuckReport()
            .finally(() => {
                this.loading = false;
                this.$spinner.hide();
            });
    };

    get sortBy() {
        return this.$localStorage.stuckSortBy;
    }

    set sortBy(value) {
        this.$localStorage.stuckSortBy = value;
    }

    get sortByAsc() {
        return this.$localStorage.stuckSortByAsc;
    }

    set sortByAsc(value) {
        this.$localStorage.stuckSortByAsc = value;
        this.$localStorage.stuckSortByAsc = value;
    }


};
