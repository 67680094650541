import * as angular from 'angular';
import {StateProvider} from '@uirouter/angularjs';
import AttendanceController from './AttendanceController';
import CalendarController from "./CalendarController";
import ClassroomController from "./ClassroomController";
import TestingController from "./TestingController";
import ReportController from "./ReportController";
import ManageClassesController from "./ManageClassesController";
import NoShowController from "./NoShowController";
import AddClassesController from "./AddClassesController";
import ContractController from "./ContractController";
import CertificateController from "./CertificateController";
import ExamController from "./ExamController";
import VirtualController from "./Virtual";
import VirtualParticipant from "./VirtualParticipant";
import VirtualParticipantLabels from "./VirtualParticipantLabels";


class Routes {
    static $inject = ['$stateProvider'];

    constructor($stateProvider: StateProvider) {
        $stateProvider
            .state('classroom', {
                abstract: true,
                template: require('./Classroom.html'),
                controller: ClassroomController,
                controllerAs: 'vm',
                url: '/classroom?{locationId:int}&{classDate}&{classId:int}',
                params: {
                    locationId: {dynamic: true},
                    classDate: {dynamic: true},
                    classId: {dynamic: true},
                },
                resolve: {classId: $stateParams => $stateParams.classId}
            })
            .state('manageClasses', {
                template: require('./ManageClasses.html'),
                controller: ManageClassesController,
                controllerAs: 'vm',
                url: '/classroom/manageClasses',
                data: {title: 'Manage Classes'}
            })
            .state('addClasses', {
                template: require('./AddClasses.html'),
                controller: AddClassesController,
                controllerAs: 'vm',
                url: '/classroom/addClasses',
                data: {title: 'Add Classes'}
            })
            .state('noShow', {
                template: require('./NoShow.html'),
                controller: NoShowController,
                controllerAs: 'vm',
                url: '/classroom/noShow',
                data: {title: 'No-Shows'}
            })
            .state('classroom.contract', {
                url: '/contract',
                template: require('./Contract.html'),
                controller: ContractController,
                controllerAs: 'vm',
                data: {title: 'Contracts'}
            })
            .state('classroom.attendance', {
                url: '/attendance',
                template: require('./Attendance.html'),
                controller: AttendanceController,
                controllerAs: 'vm',
                data: {title: 'Attendance'}
            })
            .state('classroom.testing', {
                url: '/testing',
                template: require('./Testing.html'),
                controller: TestingController,
                controllerAs: 'vm',
                data: {title: 'Testing'}
            })
            .state('classroom.exam', {
                url: '/exam',
                template: require('./Exam.html'),
                controller: ExamController,
                controllerAs: 'vm',
                data: {title: 'Exams'}
            })
            .state('classroom.certificate', {
                url: '/certificate',
                template: require('./Certificate.html'),
                controller: CertificateController,
                controllerAs: 'vm',
                data: {title: 'Certificates'}
            })
            .state('classroom.report', {
                url: '/report',
                template: require('./Report.html'),
                controller: ReportController,
                controllerAs: 'vm',
                data: {title: 'Class Report'}
            })
            .state('calendar', {
                url: '/classroom/calendar',
                template: require('./Calendar.html'),
                controller: CalendarController,
                controllerAs: 'vm',
                data: {title: 'Calendar'}
            })
            .state('classroomVirtual', {
                url: '/classroom/virtual?id&debug',
                params: {
                    id: {dynamic: true},
                    debug: {dynamic: true}
                },
                template: require('./Virtual.html'),
                controller: VirtualController,
                controllerAs: 'vm',
                data: {title: 'Virtual Classroom'}
            });
    }
}

export default angular.module('app.classroom', [VirtualParticipant, VirtualParticipantLabels])
    .config(Routes)
    .controller({AttendanceController})
    .name;
