import * as angular from 'angular';

export class LoggerService {

    public logHandler: (severity: string, messages: string[]) => void;

    constructor() {
        console.debug("[Logger] Initialized")
    }

    private send = (consoleMethod: ((message?: any, ...optionalParams: any[]) => void),
                    message?: any,
                    optionalParams?: any[]) => {
        // log to console
        let args: any[] = [];
        args.push('[Logger]');
        args.push(message);
        if (optionalParams && optionalParams.length)
            args = args.concat(optionalParams);
        consoleMethod.apply(window.console, args);
        
        if(!this.logHandler || !(this.logHandler instanceof Function))
            return;

        try {
            let msgs: string[] = [];
            if (typeof (message) == 'string')
                msgs.push(message);
            else
                msgs.push(JSON.stringify(message, null, 2));
            if (optionalParams)
                optionalParams.forEach(p => {
                    if (typeof (p) == 'string')
                        msgs.push(p);
                    else
                        msgs.push(JSON.stringify(p, null, 2));
                });
            this.logHandler(consoleMethod.name, msgs);
        } catch (e) {
            console.error(e);
        }
    };

    log = (message?: any, ...optionalParams: any[]) => {
        this.send.apply(this, [window.console.log, message, optionalParams]);
    };

    info(message?: any, ...optionalParams: any[]) {
        this.send(window.console.info, message, optionalParams);
    };

    debug(message?: any, ...optionalParams: any[]) {
        this.send(window.console.debug, message, optionalParams);
    };

    warn(message?: any, ...optionalParams: any[]) {
        this.send(window.console.warn, message, optionalParams);
    };

    error(message?: any, ...optionalParams: any[]) {
        this.send(window.console.error, message, optionalParams);
    };
}

export default angular.module('services.logger', [])
    .service('$logger', LoggerService)
    .name;
