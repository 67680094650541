import * as angular from 'angular';

// https://stackoverflow.com/a/39726751/2358953

export type DebounceFactory = (func: Function, wait: number, immediate: boolean) => () => ng.IPromise<{}>;

export default angular.module('factory.debounce', [])
    .factory('$debounce', ['$timeout', '$q', ($timeout: ng.ITimeoutService, $q: ng.IQService): DebounceFactory => {
        // The service is actually this function, which we call with the func
        // that should be debounced and how long to wait in between calls
        let factory: DebounceFactory = function debounce(func: Function, wait: number, immediate: boolean): () => ng.IPromise<{}> {
            var timeout;
            // Create a deferred object that will be resolved when we need to
            // actually call the func
            var deferred = $q.defer();
            return function (): ng.IPromise<{}> {
                var context = this, args = arguments;
                var later = function () {
                    timeout = null;
                    if (!immediate) {
                        deferred.resolve(func.apply(context, args));
                        deferred = $q.defer();
                    }
                };
                var callNow = immediate && !timeout;
                if (timeout) {
                    $timeout.cancel(timeout);
                }
                timeout = $timeout(later, wait);
                if (callNow) {
                    deferred.resolve(func.apply(context, args));
                    deferred = $q.defer();
                }
                return deferred.promise;
            };

        }
        return factory;

    }])
    .name;