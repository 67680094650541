// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html




// Myers.StudentManagementSystem.Data.Enum.AsteriskCallStatus
export enum AsteriskCallStatus {
    
    Unknown = 0,
    Ringing = 1,
    Answered = 2,
    Completed = 3
}
