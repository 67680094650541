// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html

import { GiftCertificateTemplateFieldType } from './GiftCertificateTemplateFieldType';
import { EntityBaseDto } from './EntityBaseDto';

// Myers.StudentManagementSystem.Data.Models.GiftCertificateTemplateFieldDto
export class GiftCertificateTemplateFieldDto extends EntityBaseDto {
    public fieldType: GiftCertificateTemplateFieldType = null;
    public top: number = 0;
    public left: number = 0;
    public width: number = 0;
    public height: number = 0;
    public fontName: string = null;
    public fontSize: number = 0;
    public prefix: string = null;
    public suffix: string = null;
    public placeholder: string = null;
}


    



