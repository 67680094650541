import * as angular from 'angular';

export class ApiService {
    apiBase: string;
    constructor(private $http: angular.IHttpService,
                private $window: angular.IWindowService,
                private toaster: any) {
        this.apiBase = $window.location.hostname == 'localhost' ?
            'http://localhost:8081/api/' : $window.apiBase;
    }

    get<T>(url: string, config?: angular.IRequestShortcutConfig): angular.IHttpPromise<T> {
        return this.$http.get<T>(this.apiBase + url, config)
    }

    post<T>(url: string, data: any, config?: angular.IRequestShortcutConfig): angular.IHttpPromise<T> {
        return this.$http.post<T>(this.apiBase + url, data, config);
    }

    defaultErrorHandler = (reason: any) => {
        console.error(reason);
        if (!reason) return;
        var msg = null;
        if (reason.data) {
            if (reason.data.message)
                msg = reason.data.message;
            if (reason.data.exceptionMessage)
                msg = msg + reason.data.exceptionMessage;
        } else if (reason.statusText && reason.statusText.length > 0) {
            msg = reason.statusText;
        } else {
            msg = `An error occurred: ${reason.status} ${reason.xhrStatus}`;
        }
        this.toaster.error(msg);
    }
}

export default angular.module('services.api', [])
    .service('$api', ApiService)
    .name;
