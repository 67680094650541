import {Ng1Controller, Transition, TargetState, StateService} from "@uirouter/angularjs";
import {AddressDto} from "../model/AddressDto";
import {DataHubService} from "../modules/DataHubModule";
import {ApiService} from "../modules/ApiModule";
import {SpinnerService} from "../modules/SpinnerModule";
import {GiftCertificateDetailDto} from "../model/GiftCertificateDetailDto";
import {GiftCertificateTemplateDto} from "../model/GiftCertificateTemplateDto";
import {GiftCertificateTypeDto} from "../model/GiftCertificateTypeDto";
import {DebounceFactory} from "../modules/DebounceModule";

export default class EditController implements Ng1Controller {

    private model = new GiftCertificateDetailDto();
    private templates: GiftCertificateTemplateDto[] = [];
    private types: GiftCertificateTypeDto[] = [];
    private blobUrl: string;

    $onInit(): void {
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(private $dataHub: DataHubService,
                private $api: ApiService,
                private toaster: any,
                private $spinner: SpinnerService,
                private $debounce: DebounceFactory,
                private $state: StateService,
                private $stateParams: any) {

        this.model.certificateDate = new Date();

        $dataHub.client.onGiftCertificateDetailUpdated(e => {
            this.model = e;
            this.preview();
        });
        $dataHub.bindAllGiftCertificateTemplate(() => this.templates);
        $dataHub.bindAllGiftCertificateType(() => this.types)

        $dataHub.connected(this.refresh);
        this.refresh();
    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;
        this.$dataHub.giftCertificateTemplateSubscribe();
        this.$dataHub.giftCertificateTypeSubscribe();

        if (!this.$stateParams.giftCertificateId) return;

        this.$spinner.show('Loading');
        this.$dataHub.server.giftCertificateSubscribeOne(this.$stateParams.giftCertificateId)
            .catch(this.$dataHub.defaultErrorHandler)
            .finally(() => this.$spinner.hide());
    };

    addressAutocomplete = val => {
        return this.$api.get('address/autocomplete', {
            params: {
                mode: 'address',
                input: val
            }
        })
            .then(response => response.data);
    };

    addressSelected = place => {
        this.$api.get<AddressDto>('address/place', {params: {placeId: place.place_id}})
            .then(r => {
                var addr = r.data;
                this.model.deliveryAddress = `${addr.addr1}
${addr.city}, ${addr.state} ${addr.postalCode}`;
            })
    };

    save = () => {
        this.$spinner.show();
        let promise = this.$dataHub.server.giftCertificateModify(this.model);
        promise.then(result => {
            this.model.id = result.id;
        })
            .catch(this.$dataHub.defaultErrorHandler)
            .finally(() => this.$spinner.hide());
        return promise;
    };

    saveClose = () => {
        this.save().then(() => window.close());
    };

    savePayment = () => {
        this.save().then(() => this.$state.go('transaction.add', {giftCertificateId: this.model.id}));
    };

    typeChanged = (dto: GiftCertificateTypeDto) => {
        this.model.messageNote = dto.description;
        this.model.faceValue = dto.faceValue;
        this.model.purchasePrice = dto.purchasePrice;
        if (dto.saleActive)
            this.model.purchasePrice = dto.salePrice;
    }

    formChanged = () => {
        this.previewDebounce();
    }


    preview = () => {
        if(!this.model.giftCertificateTemplate) return;
        this.$api.post('giftCertificate/preview', this.model, {responseType: 'blob'})
            .then(result => {
                // #view=fit causes mobile safari not to render the pdf
                this.blobUrl = window.URL.createObjectURL(result.data);
            })
    }

    email = () => {
        this.$dataHub.giftCertificateEmail(this.model.id)
            .then(() => this.toaster.success('Email sent'))
            .catch(this.$dataHub.defaultErrorHandler);
    }

    previewDebounce = this.$debounce(this.preview, 2000, false);
}
