import * as angular from 'angular';

function toUpper() {
    return {
        restrict: 'A',
        require: '^ngModel',
        link: function (scope, element, attr, ctrl: ng.INgModelController) {
            ctrl.$overrideModelOptions({updateOn: 'blur'});
            ctrl.$parsers.push(function (viewValue) {
                if (!viewValue) return viewValue;
                let formattedValue = viewValue.toUpperCase();
                if (ctrl.$viewValue !== formattedValue) {
                    ctrl.$setViewValue(formattedValue);
                    ctrl.$render();
                }
                return formattedValue;
            })

        }
    };
}

export default angular.module('directives.toUpper', [])
    .directive('toUpper', toUpper)
    .name;
