import {SpinnerService} from '../modules/SpinnerModule';
import {StudentDto} from "../model/StudentDto";
import {ClassDto} from "../model/ClassDto";
import {DataHubService} from "../modules/DataHubModule";
import {ApiService} from '../modules/ApiModule';
import {ConfigService} from '../modules/ConfigModule';
import {Ng1Controller, HookResult, Transition} from "@uirouter/angularjs";
import {StudentWithTestScoresDto} from "../model/StudentWithTestScoresDto";

export default class TestingController implements Ng1Controller {
    private classId: number;
    private selectedClass: ClassDto;
    private searchResults: StudentDto[];

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
        this.classId=newValues.classId;
        this.refresh();
    }

    $onInit(): void {
    }

    uiCanExit(transition: Transition): HookResult {
        return true;
    }

    students: StudentWithTestScoresDto[] = [];

    constructor(
        $scope: ng.IScope,
        private $api: ApiService,
        private $config: ConfigService,
        private $spinner: SpinnerService,
        private $dataHub: DataHubService,
        $stateParams: any,
    ) {
        this.$dataHub.client.onStudentWithTestScoreUpdated(e => this.students.merge(e, x => x.id));
        this.$dataHub.client.onStudentWithTestScoreRemoved(e => this.students.remove(e, x => x.id));

        this.classId=$stateParams.classId;
        $dataHub.connected(() => this.refresh());
        if ($dataHub.isConnected)
            this.refresh();
    }

    refresh = () => {
        this.students = [];
        this.selectedClass = null;
        if (!this.classId) return;
        if (!this.$dataHub.isConnected) return;

        this.$dataHub.server.classGet(this.classId)
            .then((cls) => {
                this.selectedClass = cls;
            })
            .catch(this.$dataHub.defaultErrorHandler);

        this.$dataHub.server.classTestScores(this.classId)
            .catch(this.$dataHub.defaultErrorHandler);

    };

    addStudent(student: StudentDto) {
        this.$dataHub.server.studentTestScores(student.id)
            .catch(this.$dataHub.defaultErrorHandler);
    };

    saveTest(record: StudentWithTestScoresDto) {
        record.newTest.location = this.selectedClass.location;

        this.$dataHub.server.studentTestScoreAdd(record)
            .catch(this.$dataHub.defaultErrorHandler);
    }

    saveTestAll() {
        for (var record of this.students) {
            if (!record.newTest.score || !record.newTest.testVersion || !record.newTest.testDate)
                continue;

            this.saveTest(record);
        }
    }


};
