import {Ng1Controller, Transition, TargetState, StateService} from "@uirouter/angularjs";
import {StudentContractDto} from "../model/StudentContractDto";
import {DataHubService} from "../modules/DataHubModule";

export default class ContractViewController implements Ng1Controller {
    private model: StudentContractDto;
    private html: string;
    private studentId: number;

    $onInit(): void {
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(
                private $dataHub: DataHubService,
                private $state: StateService,
                private $stateParams: any) {
        $dataHub.connected(this.refresh);
        $dataHub.onStudentContractUpdated(this.update);
    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;
        this.$dataHub.studentContractSubscribe(this.$stateParams.contractId);
        this.studentId = this.$stateParams.studentId;
    }

    update = (result: StudentContractDto) => {
        var html = result.contract.electronicHtml;
        result.fields.forEach((field, index) => {
            var key = '[' + field.field.name + ']';
            html = html.replaceAll(key, `<contract-field id="${field.id}" field="vm.model.fields[${index}]" field-changed="vm.fieldChanged($field)" contract="vm.model"></contract-field>`);
        });
        this.html = html;
        this.model = result;
    };


}
