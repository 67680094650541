import {DataHubService} from "../modules/DataHubModule";
import {StudentReleaseDto} from "../model/StudentReleaseDto";
import {SpinnerService} from "../modules/SpinnerModule";
import {StudentTypeDto} from "../model/StudentTypeDto";

export default class ReleaseNotReadyController {
    records = new Array<StudentReleaseDto>();
    private studentTypes: StudentTypeDto[] = [];
    private loading: boolean;
    private studentTypeSelected: number[];

    constructor(
        private $dataHub: DataHubService,
        private $spinner: SpinnerService
    ) {

        $dataHub.client.bindAllStudentType(() => this.studentTypes);
        $dataHub.client.bindAllStudentReleaseReady(() => this.records);

        $dataHub.connected(() => {
            this.records = [];
            this.$spinner.show();
            this.loading = true;
            this.$dataHub.server.studentTypeList();
            return $dataHub.server.studentReleaseNotReadyQuery()
                .finally(() => {
                    this.loading = false;
                    this.$spinner.hide();
                });
        });
    }


    studentTypeSelectAll = () => this.studentTypeSelected = this.studentTypes.map(x => x.id);

    studentTypeFilter = (value: StudentReleaseDto, index: number, array: StudentTypeDto[]): boolean => {
        if (!this.studentTypeSelected || !this.studentTypeSelected.length) return true;
        return this.studentTypeSelected.filter(x => x == value.studentTypeId).length > 0;
    }

};
