// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html



// Myers.StudentManagementSystem.Data.Models.StudentSearchDto
export class StudentSearchDto {
    public text: string = null;
    public classId: number = null;
    public includeArchived: boolean = false;
}


    



