import {DataHubService} from "../modules/DataHubModule";
import {SpinnerService} from "../modules/SpinnerModule";
import {StudentDto} from "../model/StudentDto";
import {TransactionDto} from "../model/TransactionDto";
import {AttendanceRecordDto} from "../model/AttendanceRecordDto";
import {DrivingRecordDto} from "../model/DrivingRecordDto";
import {StudentNoteDto} from "../model/StudentNoteDto";
import {CertificateDto} from "../model/CertificateDto";
import {StudentLogDto} from "../model/StudentLogDto";
import {ApiService} from "../modules/ApiModule";
import {AddressDto} from "../model/AddressDto";
import {StudentTypeDto} from "../model/StudentTypeDto";
import {CountyDto} from "../model/CountyDto";
import {HighSchoolDto} from "../model/HighSchoolDto";
import {LocationDto} from "../model/LocationDto";
import {PricingDto} from "../model/PricingDto";
import {ClassDto} from "../model/ClassDto";
import {ReferralHeardFromDto} from "../model/ReferralHeardFromDto";
import {ReferralWhyChoseDto} from "../model/ReferralWhyChoseDto";
import {Ng1Controller, StateService} from "@uirouter/angularjs";
import {IDeferred} from "angular";
import {DebounceFactory} from "../modules/DebounceModule";
import {
    addDays,
    addMonths,
    addYears,
    differenceInYears,
    endOfDay,
    endOfMonth,
    endOfYear,
    intervalToDuration,
    isSameDay,
    set,
    startOfDay,
    startOfMonth,
    startOfYear,
    subYears
} from 'date-fns';
import {ContractMode} from "../model/ContractMode";

export default class StudentEditController implements Ng1Controller {
    private dupes: StudentDto[];
    private contractVoidLocked: boolean;
    private saving: boolean;

    $onInit(): void {
    }

    uiOnParamsChanged(newValues: any, $transition$: import("@uirouter/angularjs").Transition): void {
    }

    uiCanExit(transition: import("@uirouter/angularjs").Transition): import("@uirouter/angularjs").HookResult {
        while (true) {
            var d = this.subscriptions.pop();
            if (!d) break;
            d.resolve();
        }
    }

    student: StudentDto;
    studentAge;
    transactions: TransactionDto[] = [];
    attendance: AttendanceRecordDto[] = [];
    drivingRecords: DrivingRecordDto[] = [];
    notes: StudentNoteDto[] = [];
    certificates: CertificateDto[] = [];
    log: StudentLogDto[] = [];
    studentTypes: StudentTypeDto[] = [];
    counties: CountyDto[] = [];
    highSchools: HighSchoolDto[] = [];
    locations: LocationDto[] = [];
    priceLevels: PricingDto[] = [];
    classes: ClassDto[] = [];
    referralHeardFromList: ReferralHeardFromDto[] = [];
    referralWhyChoseList: ReferralWhyChoseDto[] = [];

    selectedLocation: LocationDto;

    startDateOptions = {
        datepickerMode: 'month',
        minDate: () => this.classes.map(r => r.classDate).sort()[0],
        dateDisabled: (p) => {
            if (!this.classes || !this.classes.length) return true;
            var start = p.date;
            var end = p.date;
            switch (p.mode) {
                case "day":
                    start = startOfDay(start);
                    end = endOfDay(end);
                    break;
                case "month":
                    start = startOfMonth(start);
                    end = endOfMonth(end);
                    break;
                case "year":
                    start = startOfYear(start);
                    end = endOfYear(end);
                    break;
            }
            let result = this.classes.filter(x => x.classDate >= start && x.classDate <= end).length == 0;
            // console.log('dateDisabled', p, start, end, result);
            return result;

        }
    };

    subscriptions: IDeferred<void>[] = [];

    arCertBoxes = [
        {id: 1, name: '2 Point Credit'},
        {id: 2, name: '12 Point/Suspension'},
        {id: 3, name: 'DUI'},
        {id: 4, name: 'Court Ordered'},
        {id: 5, name: 'Pre-18 Violation'}
    ];

    constructor(
        private $api: ApiService,
        private $dataHub: DataHubService,
        private $spinner: SpinnerService,
        private $debounce: DebounceFactory,
        $scope: ng.IScope,
        private $state: StateService,
        private $stateParams: any
    ) {
        this.subscriptions.push(this.$dataHub.client.onStudentUpdated(this.studentUpdated));

        $dataHub.client.bindAllPriceLevel(() => this.priceLevels);
        $dataHub.client.bindAllClass(() => this.classes);
        $dataHub.client.bindAllStudentType(() => this.studentTypes);
        $dataHub.client.bindAllCounty(() => this.counties);
        $dataHub.client.bindAllHighSchool(() => this.highSchools);
        $dataHub.client.bindAllLocation(() => this.locations);
        $dataHub.client.bindAllReferralHeardFrom(() => this.referralHeardFromList);
        $dataHub.client.bindAllReferralWhyChose(() => this.referralWhyChoseList);

        $dataHub.connected(this.load);

        $scope.$watch(() => {
            if (!this.student) return null;
            return this.student.studentType;
        }, this.studentTypeChanged);

        $scope.$watch(() => this.selectedLocation, this.locationChanged);

        this.load();
    }

    load = () => {
        if (!this.$dataHub.isConnected) return;
        this.$dataHub.server.studentTypeList().catch(this.$dataHub.defaultErrorHandler);
        this.$dataHub.server.countyList().catch(this.$dataHub.defaultErrorHandler);
        this.$dataHub.server.highSchoolList().catch(this.$dataHub.defaultErrorHandler);
        this.$dataHub.server.referralSources().catch(this.$dataHub.defaultErrorHandler);

        let sid = this.$stateParams.studentId;
        if (!sid) return;
        this.$spinner.show('Loading Student');
        this.$dataHub.server.studentGet(sid).catch(this.$dataHub.defaultErrorHandler);
    };

    studentUpdated = s => {
        console.log('StudentUpdated', s);
        this.student = s;
        if (this.student.startingClass)
            this.selectedLocation = this.student.startingClass.location;

        this.$spinner.hide();

        this.dupeCheck();
    };

    addressAutocomplete = val => {
        return this.$api.get('address/autocomplete', {
            params: {
                mode: 'address',
                input: val
            }
        })
            .then(response => response.data);
    };

    addressSelected = place => {
        this.$api.get<AddressDto>('address/place', {params: {placeId: place.place_id}})
            .then(r => {
                var addr = r.data;
                this.student.addr1 = addr.addr1;
                this.student.city = addr.city;
                this.student.state = addr.state;
                this.student.zip = addr.postalCode;
                var cty = addr.county.replace('County', '').trim();
                this.student.county = this.counties.filter(x => x.name === cty)[0];
            })
    };

    studentTypeChanged = () => {
        this.locations = [];
        this.priceLevels = [];
        this.classes = [];
        if (!this.student || !this.student.studentType) return;
        if (this.student.price && this.student.price.studentTypeId != this.student.studentType.id)
            this.student.price = null;
        this.$dataHub.server.locationsForStudentType(this.student.studentType.id).catch(this.$dataHub.defaultErrorHandler);
        this.$dataHub.server.priceLevelsForStudentType(this.student.studentType.id).catch(this.$dataHub.defaultErrorHandler);

        if (!this.student.contractMode)
            this.student.contractMode = ContractMode.Electronic;
    };

    locationChanged = () => {
        this.classes = [];
        if (!this.student || !this.student.studentType) return;
        this.$dataHub.server.classesForStudentType(this.student.studentType.id, this.selectedLocation.id)
            .catch(this.$dataHub.defaultErrorHandler);
    };

    save = (formValid: boolean) => {
        console.debug('save');
        if (!formValid) return;

        if (this.saving) {
            alert('dont double click');
            return;
        }
        this.saving = true;
        this.$spinner.show('Saving Student...');
        this.$dataHub.server.studentUpdate(this.student)
            .then(r => {
                this.student = r;
                this.$state.go('^.view', {studentId: r.id}, {reload: true});
            })
            .catch(e => {
                this.$dataHub.defaultErrorHandler(e);
            })
            .finally(() => {
                this.$spinner.hide();
                this.saving = false;
            });
    };

    dupeCheck = this.$debounce(() => {
        if (!this.student.firstName || !this.student.lastName || !this.student.dob) return;
        this.$dataHub.server.studentDupeCheck(this.student)
            .then(dupes => this.dupes = dupes);
    }, 1000, false);

    get startingClasses() {
        if (!this.student.startDate) return;
        return this.classes.filter(c => isSameDay(c.classDate, this.student.startDate));
    }

    licenseNumberPattern = /^\w{2}\d{6}$/;

    get licenseNumberValid() {
        if (!this.student || !this.student.licenseNumber) return true;
        return this.licenseNumberPattern.test(this.student.licenseNumber);
    }

    get dobMin() {
        return startOfYear(subYears(new Date(), 150)).toISOString();
    }

    get dobPlus15y5m() {
        if (!this.student || !this.student.dob) return null;
        return addMonths(addYears(this.student.dob, 15), 5).toISOString();
    }

    get licenseIssuedMin() {
        if (!this.student || !this.student.dob) return null;
        return addMonths(addYears(this.student.dob, 15), 6).toISOString();
    }

    get today() {
        return endOfDay(new Date()).toISOString();
    }

    get licenseExpiresWarning() {
        if (!this.student || !this.student.licenseExpires) return;
        let expDate = this.student.licenseExpires;
        if (differenceInYears(expDate, new Date()) >= 5)
            return 'License expires 5+ years in the future. Ignore if non-Ohio license.'

        if (!this.student.licenseIssued) return;
        let issueDate = this.student.licenseIssued;
        if (expDate > addDays(addYears(issueDate, 4), 90))
            return 'Check Issue & Exp Date: expiry is more than 4y90d from issuance. Ignore if non-Ohio license.'

        if (!this.student.dob || !this.student.licenseIssued) return;
        let dob = this.student.dob;
        let expOnDob = isSameDay(set(expDate, {year: dob.getFullYear()}), dob);

        let validPeriod = intervalToDuration({start: issueDate, end: expDate});
        let validPermit = validPeriod.years == 1 && validPeriod.months == 0 && validPeriod.days == 0;
        if (!expOnDob && !validPermit)
            return 'Check Issue & Exp Date: Temp Permits expire after 1 year; Licenses expire on birthday. Ignore if non-Ohio license.'

        return null;
    }
};
