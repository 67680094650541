import * as angular from 'angular';
import {StateProvider} from '@uirouter/angularjs';
import StudentViewController from "./StudentViewController";
import StudentEditController from "./StudentEditController";
import StuckController from "./StuckController";
import ContractViewController from "./ContractViewController";
import MailMessagesComponent from "./MailMessagesComponent";
import ExamTranscriptController from "./ExamTranscriptController";
import WithoutPassingTest from "./WithoutPassingTest";
import ExamManagerController from "./ExamManager";
import WithoutSignedContract from "./WithoutSignedContract";
import SearchController from "./SearchController";
import ExamAdminController from "./ExamAdmin";

class Routes {
    constructor($stateProvider: StateProvider) {
        $stateProvider
            .state('student', {
                abstract: true,
                url: '/student'
            })
            .state('student.add', {
                url: '/add',
                template: require('./StudentEdit.html'),
                controller: StudentEditController,
                controllerAs: 'vm',
                data: {title: 'Add Student'}
            })

            .state('student.search', {
                url: '/search',
                template: require('./Search.html'),
                controller: SearchController,
                controllerAs: 'vm',
                data: {title: 'Student Search'}
            })

            .state('student.view', {
                url: '/:studentId/view?:action&tab',
                template: require('./StudentView.html'),
                params: {
                    action: {value: null},
                    tab: {
                        dynamic: true,
                        value: 'info'
                    }
                },
                controller: StudentViewController,
                controllerAs: 'vm',
                data: {title: 'Student'}
            })

            .state('student.edit', {
                url: '/:studentId/edit',
                template: require('./StudentEdit.html'),
                controller: StudentEditController,
                controllerAs: 'vm',
                data: {title: 'Edit Student'}
            })

            .state('student.exam', {
                abstract: true,
                url: '/:studentId/exam/:examId'
            })

            .state('student.exam.transcript', {
                url: '/transcript',
                template: require('./ExamTranscript.html'),
                controller: ExamTranscriptController,
                controllerAs: 'vm',
                data: {title: 'Exam Transcript'}
            })

            .state('student.stuck', {
                url: '/stuck',
                template: require('./Stuck.html'),
                controller: StuckController,
                controllerAs: 'vm',
                data: {title: 'Stuck Students'}
            })

            .state('student.contract', {
                url: '/:studentId/contract/:contractId',
                template: require('./ContractView.html'),
                controller: ContractViewController,
                controllerAs: 'vm',
                data: {title: 'View Contract'}
            })

            .state('student.withoutPassingTest', {
                url: '/withoutPassingTest',
                template: require('./WithoutPassingTest.html'),
                controller: WithoutPassingTest,
                controllerAs: 'vm',
                data: {title: 'Students w/o Passing Test'}
            })

            .state('student.withoutSignedContract', {
                url: '/withoutSignedContract',
                template: require('./WithoutSignedContract.html'),
                controller: WithoutSignedContract,
                controllerAs: 'vm',
                data: {title: 'Students w/o Signed Contract'}
            })

            .state('student.examManager', {
                url: '/examManager',
                template: require('./ExamManager.html'),
                controller: ExamManagerController,
                controllerAs: 'vm',
                data: {title: 'Exam Manager'}
            })
            .state('student.examAdmin', {
                url: '/examAdmin',
                template: require('./ExamAdmin.html'),
                controller: ExamAdminController,
                controllerAs: 'vm',
                data: {title: 'Exam Admin'}
            })

        ;
    }
}

export default angular.module('app.student', [
    MailMessagesComponent
])
    .config(Routes)
    .name;
