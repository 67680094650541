import {DataHubService} from "../modules/DataHubModule";
import {SpinnerService} from "../modules/SpinnerModule";
import {UserDto} from "../model/UserDto";

export default class ListController {
    users: UserDto[] = [];
    private roles: string[];

    constructor(
        private $dataHub: DataHubService,
        private $spinner: SpinnerService
    ) {

        $dataHub.bindAllUser(() => this.users);

        $dataHub.connected(() => {
            this.$spinner.show();

            return $dataHub.userList()
                .catch(this.$dataHub.defaultErrorHandler)
                .finally(() => this.$spinner.hide());
        });
    }


};
