import * as angular from 'angular';
import {StateProvider} from '@uirouter/angularjs';

import LogController from './LogController';
import DebugController from "./DebugController";
import UserActivityController from "./UserActivityController";
import CertificateRequestsController from "./CertificateRequestsController";
import ExceptionController from "./ExceptionController";
import ChartController from "./ChartController";

class Routes {
    static $inject = ['$stateProvider'];

    constructor($stateProvider: StateProvider) {
        $stateProvider
            .state('developer', {abstract: true})
            .state('developer.log', {
                url: '/developer/log',
                template: require('./log.html'),
                controller: LogController,
                controllerAs: 'vm',
                data: {title: 'Logs'}
            })
            .state('developer.exception', {
                url: '/developer/exception',
                template: require('./Exception.html'),
                controller: ExceptionController,
                controllerAs: 'vm',
                data: {title: 'Exceptions'}
            })
            .state('developer.debug', {
                url: '/developer/debug',
                template: require('./debug.html'),
                controller: DebugController,
                controllerAs: 'vm',
                data: {title: 'Debug'}
            })
            .state('developer.userActivity', {
                url: '/developer/useractivity',
                template: require('./UserActivityView.html'),
                controller: UserActivityController,
                controllerAs: 'vm',
                data: {title: 'User Activity'}
            })
            .state('developer.certificateRequests', {
                url: '/developer/certificateRequests',
                template: require('./CertificateRequests.html'),
                controller: CertificateRequestsController,
                controllerAs: 'vm',
                data: {title: 'Certificate Requests'}
            })

            .state('developer.chart', {
                url: '/developer/chart',
                template: require('./chart.html'),
                controller: ChartController,
                controllerAs: 'vm',

            })
    }
}

export default angular.module('app.developer', [])
    .config(Routes)
    .controller({LogController, DebugController})
    .name;
