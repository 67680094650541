import * as angular from 'angular';
import {StateProvider} from '@uirouter/angularjs';
import AddController from "./AddController";
import ViewController from "./ViewController";
import RefundController from "./RefundController";

class Routes {
    constructor($stateProvider: StateProvider) {
        $stateProvider

            .state('transaction', {
                abstract: true,
                url: '/transaction'
            })

            .state('transaction.add', {
                url: '/add?studentId&giftCertificateId',
                template: require('./Add.html'),
                controller: AddController,
                controllerAs: 'vm',
                data: {title: 'Add Transaction'}
            })

            .state('transaction.view', {
                url: '/:txnId',
                params: {
                    txnId: {dynamic: true}
                },
                template: require('./View.html'),
                controller: ViewController,
                controllerAs: 'vm',
                data: {title: 'View Transaction'}
            })

            .state('transaction.refund', {
                url: '/:txnId/refund',
                params: {
                    txnId: {dynamic: true}
                },
                template: require('./Refund.html'),
                controller: RefundController,
                controllerAs: 'vm',
                data: {title: 'Refund Transaction'}
            })


        ;
    }
}

export default angular.module('app.transaction', [])
    .config(Routes)
    .name;
