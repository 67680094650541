import * as angular from 'angular';

function modal() {
    return {
        template:
            '<div class="modal fade" tabindex="-1" role="dialog"><div class="modal-dialog"><div class="modal-content" ng-transclude></div></div></div>',
        restrict: 'E',
        transclude: true,
        replace: true,
        scope: { title: '@', visible: '=', onShown: '&', onHide: '&' },
        link: function postLink(scope: any, element, attrs) {

            jQuery(element).modal({
                show: false,
                keyboard: attrs.keyboard,
                backdrop: attrs.backdrop
            });

            scope.$watch(function () { return scope.visible; },
                function (value) {

                    if (value) {
                        $(element).modal('show');
                    } else {
                        $(element).modal('hide');
                    }
                });

            $(element).on('shown.bs.modal',
                function () {
                    scope.$apply(function () {
                        scope.$parent[attrs.visible] = true;
                    });
                });

            $(element).on('shown.bs.modal',
                function () {
                    scope.$apply(function () {
                        scope.onShown({});
                    });
                });

            $(element).on('hidden.bs.modal',
                function () {
                    scope.$apply(function () {
                        scope.$parent[attrs.visible] = false;
                    });
                });

            $(element).on('hidden.bs.modal',
                function () {
                    scope.$apply(function () {
                        scope.onHide({});
                    });
                });
        }
    };
};

function modalHeader() {
    return {
        template:
            '<div class="modal-header"><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button><h4 class="modal-title">{{title}}</h4></div>',
        replace: true,
        restrict: 'E',
        scope: { title: '@' }
    };
};

function modalBody() {
    return {
        template: '<div class="modal-body" ng-transclude></div>',
        replace: true,
        restrict: 'E',
        transclude: true
    };
};

function modalFooter() {
    return {
        template: '<div class="modal-footer" ng-transclude></div>',
        replace: true,
        restrict: 'E',
        transclude: true
    };
};

export default angular.module('directives.modal', [])
    .directive('modal', modal)
    .directive('modalHeader', modalHeader)
    .directive('modalBody', modalBody)
    .directive('modalFooter', modalFooter)
    .name;