import {Ng1Controller, StateService, TargetState, Transition} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {UserDto} from "../model/UserDto";
import {DrivingRecordDto} from "../model/DrivingRecordDto";
import {ConfigService} from "../modules/ConfigModule";
import {format} from "date-fns";

export default class LogController implements Ng1Controller {
    private instructors: UserDto[] = [];
    private drivingRecords: DrivingRecordDto[] = [];
    private dates = {};
    private model = { month: new Date(), userId: 0 };

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
        if (newValues.month)
            this.model.month = newValues.month;
        if (newValues.userId)
            this.model.userId = parseInt(newValues.userId);
        this.refresh();
    }

    constructor(private $dataHub: DataHubService,
                private toaster: any,
                private $config: ConfigService,
                private $state: StateService,
                private $stateParams: any) {
        $dataHub.client.bindAllUser(() => this.instructors);
        $dataHub.client.bindAllDrivingRecord(() => this.drivingRecords);
        $dataHub.connected(this.load);

        for (var year = new Date().getFullYear(); year > 2000; year--)
            for (var month = 12; month > 0; month--) {
                var m = new Date(year, month - 1, 1);
                var key = format(m, "yyyy-MM-dd");
                this.dates[key] = format(m, "MMMM yyyy");
            }

        this.model.month = $stateParams.month;
        this.model.userId = parseInt($stateParams.userId) || 0;
    }

    load = () => {
        if (!this.$dataHub.isConnected) return;
        this.$dataHub.server.userDrivingInstructorsSubscribe()
            .catch(this.$dataHub.defaultErrorHandler);
        this.refresh();
    };

    refresh = () => {
        this.drivingRecords = [];
        if (!this.$dataHub.isConnected) return;
        if (!this.model.month || !this.model.userId) return;
        this.$dataHub.server.instructorDrivingRecordSubscribe(this.model.userId, this.model.month);
    };

    instructorChanged = () => {
        this.$state.go('.', {userId: this.model.userId});
    };

    monthChanged = () => {
        this.$state.go('.', {month: this.model.month});
    };

}
