// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html



// Myers.StudentManagementSystem.Data.Models.StudentRestartDto
export class StudentRestartDto {
    public studentId: number = 0;
    public restartDate: Date = new Date(0);
}


    



