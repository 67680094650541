import * as angular from 'angular';

import LoginController from './login.controller';
import {StateProvider} from "@uirouter/angularjs";

class Routes {
    constructor($stateProvider: StateProvider) {
        $stateProvider
            .state('login', {
                url: '/login',
                template: require('./login.html'),
                controller: LoginController,
                controllerAs: '$ctrl',
                data: {title: 'Login'}
            })
        ;
    }
}

export default angular.module('app.login', [])
    .config(Routes)
    .name;
