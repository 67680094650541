// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html



// Myers.StudentManagementSystem.Data.Models.UserDisplayDto
export class UserDisplayDto {
    public id: number = 0;
    public firstName: string = null;
    public lastName: string = null;
    public fullName: string = null;
    public username: string = null;
}


    



