// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html

import { GiftCertificateDisplayDto } from './GiftCertificateDisplayDto';
import { StudentDisplayDto } from './StudentDisplayDto';
import { UserDisplayDto } from './UserDisplayDto';
import { LocationDto } from './LocationDto';
import { TransactionTypeDto } from './TransactionTypeDto';

// Myers.StudentManagementSystem.Data.Models.TransactionDto
export class TransactionDto {
    public id: number = 0;
    public timeCreated: Date = new Date(0);
    public txnDate: Date = new Date(0);
    public txnDateOnly: Date = new Date(0);
    public amount: number = 0;
    public authAmount: number = 0;
    public amountRefunded: number = 0;
    public swipeData: string = null;
    public cardName: string = null;
    public cardNum: string = null;
    public expDate: string = null;
    public expMonth: string = null;
    public expYear: string = null;
    public cardCode: string = null;
    public billAddr: string = null;
    public billZip: string = null;
    public checkNum: string = null;
    public nameOnCheck: string = null;
    public certNum: string = null;
    public balance: number = 0;
    public receiptNum: string = null;
    public other: string = null;
    public desc: string = null;
    public resultAccepted: boolean = false;
    public resultTransId: string = null;
    public resultAuthCode: string = null;
    public resultErrorCode: number = 0;
    public lastError: string = null;
    public receiptPrinted: boolean = false;
    public receiptSigned: boolean = false;
    public canVoid: boolean = false;
    public canRefund: boolean = false;
    public giftCertificate: GiftCertificateDisplayDto = null;
    public student: StudentDisplayDto = null;
    public user: UserDisplayDto = null;
    public location: LocationDto = null;
    public transactionType: TransactionTypeDto = null;
    public sequence: number = 0;
    public btwHours: number = null;
    public txnDateShow: boolean = false;
    public txnDateCount: number = 0;
    public txnDateAmount: number = 0;
    public locationShow: boolean = false;
    public locationCount: number = 0;
    public locationAmount: number = 0;
    public userShow: boolean = false;
    public userCount: number = 0;
    public userAmount: number = 0;
    public txnTypeShow: boolean = false;
    public txnTypeCount: number = 0;
    public txnTypeAmount: number = 0;
    public remoteIp: string = null;
    public userAgent: string = null;
    public signature: boolean = false;
}


    



