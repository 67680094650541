import * as angular from 'angular';

import Age from "./Age";
import BootstrapFormValidation from "./BootstrapFormValidation";
import BottomNav from "./BottomNav";
import ClickToDial from "./ClickToDial";
import Chart from "./Chart";
import CurrencyFilter from "./CurrencyFilter";
import DatePicker from "./DatePicker";
import DateToText from "./DateValidation";
import Debug from "./Debug";
import DurationFilter from "./DurationFilter"
import EmailDirective from "./EmailDirective";
import Filename from "./Filename";
import FormGroupInput from "./FormGroupInput";
import Highlight from "./Highlight";
import Hint from "./Hint";
import Modal from "./Modal";
import NgEnter from "./NgEnter";
import Panel from "./Panel";
import Popout from "./Popout";
import Sortable from "./Sortable";
import StringToCurrency from "./StringToCurrency";
import StringToDate from "./StringToDate";
import StringToTime from "./StringToTime";
import StudentSearch from "./StudentSearch";
import StudentSelect from "./StudentSelect";
import TelDirective from "./TelDirective";
import TelFilter from "./TelFilter";
import DateDurationDirective from "./DateDurationDirective";
import PrintReportDirective from "./PrintReportDirective";
import BindHtmlCompile from "./BindHtmlCompile";
import ContractField from "./ContractField";
import ToUpper from "./ToUpper";
import AutoCase from "./AutoCase";
import JanusSubscribe from "./JanusSubscribe";
import VUMeter from "./VUMeter";
import NgMuted from "./NgMuted";
import TrustedUrlFilter from "./TrustedUrlFilter";
import NgSrcObject from "./NgSrcObject";
import SignaturePad from "./SignaturePad";
import NgAutoFocus from "./NgAutoFocus";
import VirtualClassType from "./VirtualClassType";
import NgSinkId from "./NgSinkId";
import NgVolume from "./NgVolume";


export default angular.module('app.directives', [
    Age,
    AutoCase,
    BindHtmlCompile,
    BootstrapFormValidation,
    BottomNav,
    ClickToDial,
    Chart,
    ContractField,
    CurrencyFilter,
    DateDurationDirective,
    DatePicker,
    DateToText,
    Debug,
    DurationFilter,
    EmailDirective,
    Filename,
    FormGroupInput,
    Highlight,
    Hint,
    JanusSubscribe,
    Modal,
    NgAutoFocus,
    NgEnter,
    NgMuted,
    NgSinkId,
    NgSrcObject,
    NgVolume,
    Panel,
    Popout,
    PrintReportDirective,
    SignaturePad,
    Sortable,
    StringToCurrency,
    StringToDate,
    StringToTime,
    StudentSearch,
    StudentSelect,
    TelDirective,
    TelFilter,
    ToUpper,
    TrustedUrlFilter,
    VirtualClassType,
    VUMeter,
])
    .name;
