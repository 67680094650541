import {Ng1Controller, Transition, TargetState, StateService} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {UserDto} from "../model/UserDto";
import {ConfigService} from "../modules/ConfigModule";
import {DocumentDto} from "../model/DocumentDto";
import {DocumentTypeDto} from "../model/DocumentTypeDto";
import {IScope} from "angular";
import {DebounceFactory} from "../modules/DebounceModule";
import {RoleName} from "../model/RoleName";

export default class InstructorListController implements Ng1Controller {
    private documents: DocumentDto[] = [];
    private documentTypes: DocumentTypeDto[] = [];
    private users: UserDto[] = [];

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(private $dataHub: DataHubService,
                private toaster: any,
                private $config: ConfigService,
                private $debounce: DebounceFactory,
                private $filter: ng.IFilterService,
                private $scope: IScope,
                private $state: StateService,
                private $stateParams: any) {
        $dataHub.connected(this.refresh);

        $dataHub.client.bindAllDocument(() => this.documents);

        $dataHub.client.onDocumentTypeUpdated(dt => {
            if (!dt.user) return;
            this.documentTypes.merge(dt, x => x.id);
            this.typeSort();
        });

        $dataHub.client.onDocumentTypeRemoved(dt => this.documentTypes.remove(dt, x => x.id));

        $dataHub.client.onUserUpdated(user => {
            if (!user.active) return;
            if (user.roles.indexOf(RoleName.InCarInstructor) < 0 && user.roles.indexOf(RoleName.ClassroomInstructor) < 0) return;
            if (!(user.instructorLicense > 1)) return;
            this.users.merge(user, x => x.id);
            this.userSort();
        });

        $dataHub.client.onUserRemoved(user => this.users.remove(user, x => x.id));
    }

    userSort = this.$debounce(() => {
        this.users = this.$filter('orderBy')(this.users, ['fullName'], false);
    }, 1000, false);

    typeSort = this.$debounce(() => {
        this.documentTypes = this.$filter('orderBy')(this.documentTypes, ['name'], false);
    }, 1000, false);


    refresh = () => {
        if (!this.$dataHub.isConnected) return;

        this.$dataHub.server.documentUserAllSubscribe();
        this.$dataHub.server.documentTypeSubscribe();
        this.$dataHub.server.userList();
    };

    archive = (doc: DocumentDto) => {
        this.$dataHub.documentArchive(doc.id)
            .catch(this.$dataHub.defaultErrorHandler);
    }

    getUserPdfUrl = (user:UserDto) => {
        if(!user) return null;
        return this.$config.apiBase + 'document/user/' + user.id;
    }

}
