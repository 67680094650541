// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html




// Myers.StudentManagementSystem.Data.Enum.AsteriskQueueStatus
export enum AsteriskQueueStatus {
    
    Unknown = 0,
    Joined = 1,
    Answered = 2,
    Abandoned = 3
}
