// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html

import { StudentTypeDto } from './StudentTypeDto';
import { CountyDto } from './CountyDto';
import { HighSchoolDto } from './HighSchoolDto';
import { ClassDto } from './ClassDto';
import { UserDisplayDto } from './UserDisplayDto';
import { PricingDto } from './PricingDto';
import { ReferralHeardFromDto } from './ReferralHeardFromDto';
import { ReferralWhyChoseDto } from './ReferralWhyChoseDto';
import { ContractMode } from './ContractMode';
import { EntityBaseDto } from './EntityBaseDto';

// Myers.StudentManagementSystem.Data.Models.StudentDto
export class StudentDto extends EntityBaseDto {
    public archived: boolean = false;
    public studentType: StudentTypeDto = null;
    public firstName: string = null;
    public middleName: string = null;
    public lastName: string = null;
    public dob: Date = null;
    public addr1: string = null;
    public city: string = null;
    public state: string = null;
    public zip: string = null;
    public county: CountyDto = null;
    public highSchool: HighSchoolDto = null;
    public phone: string = null;
    public email: string = null;
    public parentName: string = null;
    public parentPhone: string = null;
    public parentEmail: string = null;
    public licenseNumber: string = null;
    public licenseIssued: Date = null;
    public licenseExpires: Date = null;
    public licenseExpiresEffective: Date = null;
    public contractId: number = 0;
    public contractPrinted: boolean = false;
    public contractSigned: boolean = false;
    public startingClass: ClassDto = null;
    public lastClass: ClassDto = null;
    public instructor: UserDisplayDto = null;
    public startDate: Date = null;
    public classDoneDate: Date = null;
    public testPassed: boolean = false;
    public testDate: Date = null;
    public releaseReadyDate: Date = null;
    public driveReadyDate: Date = null;
    public signOutDate: Date = null;
    public driveDoneDate: Date = null;
    public completionDate: Date = null;
    public completeClassByDate: Date = null;
    public completeClassByOverride: boolean = false;
    public completeByDate: Date = null;
    public completeByOverride: boolean = false;
    public certificateIssueDate: Date = null;
    public btwHoursPurchased: number = 0;
    public btwHoursEntered: number = 0;
    public btwHoursBalance: number = 0;
    public certificateNumber: string = null;
    public testScore: number = null;
    public testCount: number = 0;
    public instructorAssigned: boolean = false;
    public balance: number = 0;
    public price: PricingDto = null;
    public incarRequests: string = null;
    public odCertCompletionNumber: string = null;
    public odCertCompletionDate: Date = null;
    public adultRemedialCertificateType: number = null;
    public paymentStatus: string = null;
    public status: string = null;
    public referralHeardFrom: ReferralHeardFromDto = null;
    public referralHeardFromOther: string = null;
    public referralWhyChose: ReferralWhyChoseDto = null;
    public referralWhyChoseOther: string = null;
    public fullName: string = null;
    public btwListDate: Date = null;
    public preRegId: number = null;
    public gender: boolean = false;
    public btwRemarks: string = null;
    public contractVoid: boolean = false;
    public contractSend: boolean = false;
    public iep: boolean = null;
    public contractMode: ContractMode = null;
    public contractModeString: string = null;
    public idFormatted: string = null;
}


    



