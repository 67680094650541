// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html



// Myers.StudentManagementSystem.Data.Models.ClassQueryDto
export class ClassQueryDto {
    public locationId: number = null;
    public classDate: Date = null;
}


    



