// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html




// Myers.StudentManagementSystem.Data.Enum.VirtualClassType
export enum VirtualClassType {
    
    None = 0,
    Myers = 1,
    Zoom = 2
}
