import {Ng1Controller, Transition, TargetState, StateService} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {StudentExamDto} from "../model/StudentExamDto";
import {ngStorage} from "ngstorage";
import StorageService = ngStorage.StorageService;
import {Janus} from 'janus-gateway';
import {IScope, ITimeoutService} from "angular";
import {StudentExamStatus} from "../model/StudentExamStatus";
import {ExamManagerMessageController} from "./ExamManagerMessageController";
import {ExamManagerDebugController} from "./ExamManagerDebugController";
import * as Bowser from 'bowser';
import {ApiService} from "../modules/ApiModule";
import {SpinnerService} from "../modules/SpinnerModule";

export default class ExamManagerController implements Ng1Controller {
    private waiting: StudentExamDto[] = [];
    private assigned: StudentExamDto[] = [];
    private janusConnected: boolean;
    private janus: any;
    private pluginHandles = {};
    private endpoints: string[];

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(private $dataHub: DataHubService,
                private toaster: any,
                private $scope: IScope,
                private $timeout: ITimeoutService,
                private $localStorage: StorageService,
                private $api: ApiService,
                private $spinner: SpinnerService,
                private $uibModal,
                private $state: StateService,
                private $stateParams: any) {
        this.$dataHub.bindAllStudentExamWaiting(() => this.waiting);

        let merge = (e: StudentExamDto) => {
            e["bowser"] = Bowser.parse(e.userAgent);
            this.assigned.merge(e, x => x.id);
        };
        this.$dataHub.onStudentExamAssignedUpdated(merge);
        this.$dataHub.onStudentExamConnectedUpdated(merge);
        this.$dataHub.bindStudentExamAssignedRemoved(() => this.assigned);
        this.$dataHub.bindStudentExamConnectedRemoved(() => this.assigned);

        $dataHub.connected(this.refresh);
        this.janusInit();
    }

    refresh = () => {
        this.waiting = [];
        this.assigned = [];
        if (!this.$dataHub.isConnected) return;

        this.$dataHub.studentExamWaitingSubscribe().catch(this.$dataHub.defaultErrorHandler);
        this.$dataHub.studentExamAssignedSubscribe().catch(this.$dataHub.defaultErrorHandler);
    };

    assignToMe = (row: StudentExamDto) => {
        this.$dataHub.studentExamInstructorAssign(row.id, null)
            .catch(this.$dataHub.defaultErrorHandler);
    };

    setExamStatus = (exam: StudentExamDto, status: StudentExamStatus) => {
        this.$dataHub.studentExamStatusUpdate(exam.id, status)
            .catch(this.$dataHub.defaultErrorHandler);
    };

    remove = (row: StudentExamDto) => {
        this.$dataHub.studentExamInstructorRemove(row.id)
            .catch(this.$dataHub.defaultErrorHandler);
    };

    subscribeAll = () => {
        this.$dataHub.studentExamConnectedSubscribe().catch(this.$dataHub.defaultErrorHandler);
    };

    get columns() {
        return this.$localStorage['classroom_exam_columns'];
    }

    set columns(value) {
        this.$localStorage['classroom_exam_columns'] = value;
    }

    chat = (exam: StudentExamDto) => {
        var modalInstance = this.$uibModal.open({
            template: require('./ExamManagerMessageModal.html'),
            controller: ExamManagerMessageController,
            controllerAs: '$ctrl',
            bindToController: true,
            resolve: {
                exam: () => exam,
            }
        });
    };
    debug = (exam: StudentExamDto) => {
        var modalInstance = this.$uibModal.open({
            template: require('./ExamManagerDebugModal.html'),
            controller: ExamManagerDebugController,
            size: 'lg',
            controllerAs: '$ctrl',
            bindToController: true,
            resolve: {
                exam: () => exam,
            }
        });
    };

    /// attach a newly created video element to an existing stream (if it exists)
    videoInit = (id) => {
        if (!id) return;
        this.janusAttachFeed(id);
    };

    janusInit = () => {
        this.$api.get<string[]>('janus/endpoint')
            .then(r => this.endpoints = r.data);
        Janus.init({
            debug: false,
            dependencies: Janus.useDefaultDependencies(),
            callback: () => {
            }
        });
    };

    janusConnect = () => {
        if (this.janusConnected) return;
        this.janus = new Janus({
                server: this.endpoints,
                success: () => {
                    console.log('janus connected');
                    this.janusJoinRoom();
                    this.$scope.$apply(() => this.janusConnected = true);
                },
                error: (cause) => {
                    console.error('janus error', cause);
                    this.$scope.$apply(() => this.janusConnected = false);
                },
                destroyed: function () {
                }
            }
        );
    };

    /// join the videoroom to get publisher events
    janusJoinRoom = () => {
        var plugin;
        this.janus.attach(
            {
                plugin: "janus.plugin.videoroom",
                success: (pluginHandle) => {
                    plugin = pluginHandle;

                    var register = {
                        "request": "join",
                        "room": 1,
                        "ptype": "publisher",
                    };
                    plugin.send({"message": register});

                },
                error: function (error) {
                    Janus.error("  -- Error attaching plugin...", error);
                },
                consentDialog: function (on) {
                    Janus.debug("Consent dialog should be " + (on ? "on" : "off") + " now");
                },
                webrtcState: function (on) {
                    Janus.log("Janus says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
                },
                onmessage: (msg, jsep) => {
                    Janus.debug(" ::: Got a message (publisher) :::");
                    Janus.debug(msg);
                    var event = msg["videoroom"];
                    Janus.debug("Event: " + event);
                    switch (event) {
                        case "joined":
                            let myid = msg["id"];
                            let private_id = msg["private_id"]
                            console.log("Successfully joined room " + msg["room"] + " with ID " + myid + ", privateid:" + private_id);

                            if (msg["publishers"] !== undefined && msg["publishers"] !== null) {
                                this.janusProcessPublishers(msg["publishers"]);
                            }
                            break;
                        case "event": // Any feed to attach to?
                            if (msg["publishers"] !== undefined && msg["publishers"] !== null) {
                                this.janusProcessPublishers(msg["publishers"]);
                            } else if (msg["leaving"] !== undefined && msg["leaving"] !== null) {
                                // One of the publishers has gone away?
                                var leaving = msg["leaving"];
                                console.log("Publisher left: " + leaving);

                            } else if (msg["error"] !== undefined && msg["error"] !== null) {
                                console.error(msg["error"]);
                            }
                            break;
                    }
                    if (jsep !== undefined && jsep !== null) {
                        Janus.debug("Handling SDP as well...");
                        Janus.debug(jsep);
                        plugin.handleRemoteJsep({jsep: jsep});
                    }
                },
                onlocalstream: function (stream) {
                    Janus.debug(" ::: Got a local stream :::");
                    Janus.debug(stream);
                },
                onremotestream: function (stream) {
                    // The publisher stream is sendonly, we don't expect anything here
                },
                oncleanup: function () {
                    Janus.log(" ::: Got a cleanup notification :::");
                }
            });
    };

    /// attach a given feed to the appropriate video element
    janusAttachFeed = (id, count = 0) => {
        if (!this.janus) return;

        let exam = this.assigned.filter(x => x.rtcInfoCamera && x.rtcInfoCamera.handleId == id || x.rtcInfoScreen && x.rtcInfoScreen.handleId == id)[0];
        if (!exam) {
            if (count > 5) {
                console.debug('feed id', id, 'not found after 5 retries. giving up.');
                return;
            }
            console.debug('feed id', id, 'not found yet. retrying');
            this.$timeout(() => this.janusAttachFeed(id, count + 1), 2500);
            return;
        }

        let mode = exam.rtcInfoCamera && exam.rtcInfoCamera.handleId == id ? "camera" : "screen";
        let rtcInfo = exam.rtcInfoCamera && exam.rtcInfoCamera.handleId == id ?
            exam.rtcInfoCamera : exam.rtcInfoScreen;
        console.debug(`[${exam.student.fullName}] [${mode}] attaching videoroom`);

        if (this.pluginHandles[id]) {
            try {
                console.warn(id, 'detaching old handle');
                this.pluginHandles[id].detach();
            } catch (e) {
                console.error(e);
            }
        }

        var remoteFeed = null;
        this.janus.attach(
            {
                plugin: "janus.plugin.videoroom",
                success: (pluginHandle) => {
                    remoteFeed = pluginHandle;
                    this.pluginHandles[id] = pluginHandle;
                    Janus.log("Plugin attached! (" + remoteFeed.getPlugin() + ", id=" + remoteFeed.getId() + ")");
                    Janus.log("  -- This is a subscriber");
                    // We wait for the plugin to send us an offer
                    var listen = {
                        "request": "join",
                        "room": rtcInfo.roomId,
                        "ptype": "subscriber",
                        "feed": rtcInfo.handleId,
                        "private_id": rtcInfo.privateId
                    };
                    remoteFeed.send({"message": listen});
                },
                error: function (error) {
                    Janus.error("  -- Error attaching plugin...", error);
                },
                onmessage: function (msg, jsep) {
                    Janus.debug(" ::: Got a message (listener) :::");
                    Janus.debug(msg);
                    var event = msg["videoroom"];
                    Janus.debug("Event: " + event);
                    if (event != undefined) {
                        if (event === "attached") {
                            Janus.log("Successfully attached to " + rtcInfo);
                        } else {
                            Janus.warn("Unexpected event:" + event)
                        }
                    }
                    if (jsep !== undefined && jsep !== null) {
                        Janus.debug("Handling SDP as well...");
                        Janus.debug(jsep);
                        // Answer and attach
                        remoteFeed.createAnswer(
                            {
                                jsep: jsep,
                                media: {audioSend: false, videoSend: false},	// We want recvonly audio/video
                                success: function (jsep) {
                                    Janus.debug("Got SDP!");
                                    Janus.debug(jsep);
                                    var body = {"request": "start", "room": rtcInfo.roomId};
                                    remoteFeed.send({"message": body, "jsep": jsep});
                                },
                                error: function (error) {
                                    Janus.error("WebRTC error:", error);
                                }
                            });
                    }
                },
                onlocalstream: function (stream) {
                    // The subscriber stream is recvonly, we don't expect anything here
                    Janus.warn("Received a local stream??");
                },
                onremotestream: function (stream) {
                    let video = mode == "screen" ? exam["videoScreen"] : exam["videoCamera"];
                    if (!video) {
                        console.warn(`[${exam.student.fullName}] [${mode}] no video element`);
                        return;
                    }
                    var e: HTMLVideoElement = video[0];
                    console.debug(`[${exam.student.fullName}] [${mode}] attaching remote stream`);
                    Janus.attachMediaStream(e, stream);
                },
                oncleanup: function () {
                    Janus.log(" ::: Got a cleanup notification (remote feed " + rtcInfo + ") :::");

                },
                detached: () => {
                    console.debug(`[${exam.student.fullName}] [${mode}] detached`);
                }
            });
    };


    private janusProcessPublishers(list: any) {
        if (!list) return;
        for (var publisher of list) {
            this.janusAttachFeed(publisher.id)
        }
    }

    void = (exam: StudentExamDto) => {
        var reason = prompt('Enter reason for voiding exam:');
        if (!reason) return;
        var countTowardsAttempts = confirm('Should this exam be counted towards the students total test attempts?');
        var final = confirm(`Please confirm you want to void this exam:

Student: ${exam.student.fullName}
Reason:  ${reason}
This exam ${countTowardsAttempts ? 'WILL' : 'WILL NOT'} count towards total test attempts
`);
        if (!final) return;

        this.$spinner.show('Voiding Exam');
        this.$dataHub.studentExamVoid(exam.id, reason, countTowardsAttempts)
            .catch(this.$dataHub.defaultErrorHandler)
            .finally(() => this.$spinner.hide());
    }
}
