import {Ng1Controller, Transition, TargetState, StateService} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {ngStorage} from "ngstorage";
import StorageService = ngStorage.StorageService;
import * as angular from "angular";
import {IScope} from "angular";
import {MailMessageDto} from "../model/MailMessageDto";
import {MailMessageEventDto} from "../model/MailMessageEventDto";

class MailMessagesComponentController implements Ng1Controller {
    private records: MailMessageDto[] = [];
    private studentId: number;

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(private $dataHub: DataHubService,
                private $localStorage: StorageService,
                private $scope: IScope,
                private $uibModal: any,
                private $state: StateService,
                private $stateParams: any) {
        $dataHub.client.bindAllMailMessage(() => this.records);
        $dataHub.connected(this.refresh);
        $scope.$watch(() => this.studentId, this.refresh);
    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;
        if (!this.studentId) return;
        this.$dataHub.server.mailMessageSubscribe(this.studentId)
            .catch(this.$dataHub.defaultErrorHandler);
    };

    detail = (row: MailMessageDto) => {
        var modalInstance = this.$uibModal.open({
            template: require('./MailMessageDetail.html'),
            controller: MailMessageDetailController,
            controllerAs: '$ctrl',
            bindToController: true,
            resolve: {
                mailMessage: () => row
            }
        });
    }
}

class MailMessageDetailController {
    private records: MailMessageEventDto[] = [];

    constructor(private $dataHub: DataHubService,
                private mailMessage: MailMessageDto) {
        $dataHub.client.onMailMessageEventUpdated(e => {
            if (e.mailMessageId != this.mailMessage.id) return;
            this.records.merge(e, e => e.id);
        });
        $dataHub.server.mailMessageEventSubscribe(this.mailMessage.id);
    }
}

export default angular.module('app.student.mailMessagesComponent', [])
    .component('mailMessages', {
        template: require('./MailMessagesComponent.html'),
        controller: MailMessagesComponentController,
        controllerAs: '$ctrl',
        bindings: {
            studentId: '=',
        }
    }).name;
