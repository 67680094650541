import * as angular from 'angular';
import {IAttributes, IDirectiveLinkFn} from "angular";

function getNames(form, input: string) {
    let validNames: string[] = [];
    for (var name of input.split(' ')) {
        name = name.trim();
        if (!form[name]) {
            console.warn(form.$name + '.' + name + ' not found - did you forget the name attribute on the input element?');
        } else {
            validNames.push(name);
        }
    }
    return validNames;
}


function bootstrapFormValidation($timeout: ng.ITimeoutService): ng.IDirective {
    let validClass = 'has-success';
    let blankClass = 'has-warning';
    let invalidClass = 'has-error';
    return {
        restrict: 'A',
        require: '^form',

        link: function (scope: ng.IScope, element: JQLite, attrs: IAttributes, form: ng.IFormController): IDirectiveLinkFn {
            if (!form) {
                console.warn('did not get a ngFormController');
                return;
            }

            let names = attrs.formValidation;
            if (!names) {
                console.warn('no name value supplied to form-validation');
                return;
            }

            // delay execution until form has fully loaded
            $timeout(() => {
                let validNames = getNames(form, names);
                for (var modelName of validNames) {
                    let modelController: ng.INgModelController = form[modelName];
                    modelController.$viewChangeListeners.push(() => {
                        console.debug('viewChangeLister called');
                        let anyInvalid = validNames.some(name => form[name].$invalid && (form[name].$touched || form.$submitted));
                        if(anyInvalid){
                            $(element).removeClass(validClass);
                            $(element).removeClass(blankClass);
                            $(element).addClass(invalidClass);
                            return;
                        }

                        let anyBlank = validNames.some(name => !form[name].$modelValue && form[name].$touched);
                        if(anyBlank){
                            $(element).removeClass(invalidClass);
                            $(element).removeClass(validClass);
                            $(element).addClass(blankClass);
                            return;
                        }

                        let allValid = validNames.every(inputName => form[inputName].$valid);
                        if(allValid){
                            $(element).removeClass(invalidClass);
                            $(element).removeClass(blankClass);
                            $(element).addClass(validClass);
                            return;
                        }

                        console.debug('wat');
                    });
                }
            });


        },
    };
}

export default angular.module('directives.bootstrapFormValidation', [])
    .directive('formValidation', bootstrapFormValidation)
    .name;
