import * as angular from 'angular';
import {format, isValid, parse} from "date-fns";

let validFormats = ['H', 'HH', 'ha', 'hha', 'Hmm', 'HHmm', 'hmma', 'hhmma', 'hh:mm a', 'hh:mm aa', 'HH:mm', 'HH.mm', 'HH:mm:ss'];
let modelFormat = 'HH:mm:ss';
let displayFormat = 'hh:mm a';

function stringToTime() {
    return {
        restrict: 'A',
        require: '^ngModel',
        link: function (scope, element, attrs, ngModel: ng.INgModelController) {
            ngModel.$formatters.unshift(function (value) {
                if (!value) return null;
                return formatForDisplay();
            });

            ngModel.$parsers.push(function (value) {
                if (!value) return null;
                var result: Date;
                for (const fmt of validFormats) {
                    result = parse(value, fmt, new Date());
                    console.debug(value, fmt, result);
                    if (isValid(result))
                        break;
                }
                if (isValid(result))
                    return format(result, modelFormat);
                return undefined;
            });

            element.on('blur', () => {
                if (!ngModel.$modelValue) return;
                ngModel.$viewValue = formatForDisplay();
                ngModel.$render();
            });

            function formatForDisplay() {
                if (!ngModel.$modelValue) return;
                for (const fmt of validFormats) {
                    var result = parse(ngModel.$modelValue, fmt, new Date());
                    if (isValid(result))
                        return format(result, displayFormat);
                }
            }
        }
    };
}

export default angular.module('directives.stringToTime', [])
    .directive('stringToTime', stringToTime)
    .name;
