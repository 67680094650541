// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html

import { UserEmergencyContact } from './UserEmergencyContact';

// Myers.StudentManagementSystem.Data.Models.UserDto
export class UserDto {
    public id: number = null;
    public active: boolean = false;
    public firstName: string = null;
    public lastName: string = null;
    public fullName: string = null;
    public username: string = null;
    public addr1: string = null;
    public city: string = null;
    public state: string = null;
    public zip: number = null;
    public email: string = null;
    public phone: string = null;
    public mobile: string = null;
    public instructorLicense: number = null;
    public driverLicense: string = null;
    public driverAllowedStudentTypes: string = null;
    public driverAllowedStudentTypesArray: number[] = [];
    public newPassword: string = null;
    public emergencyContact1: UserEmergencyContact = null;
    public emergencyContact2: UserEmergencyContact = null;
    public vehiclePlateNumber: string = null;
    public roles: string[] = [];
}


    



