import * as angular from "angular";
import ChartJs, {Chart} from "chart.js";

// Chart.defaults.global.multiTooltipTemplate = '<%if (datasetLabel){%><%=datasetLabel%>: <%}%><%= value %>';
Chart.defaults.global.tooltips.mode = 'label';
Chart.defaults.global.elements.line.borderWidth = 2;
Chart.defaults.global.elements.rectangle.borderWidth = 2;
// Chart.defaults.global.legend.display = false;
Chart.defaults.global.colors = [
    '#97BBCD', // blue
    '#DCDCDC', // light grey
    '#F7464A', // red
    '#46BFBD', // green
    '#FDB45C', // yellow
    '#949FB1', // grey
    '#4D5360'  // dark grey
];


function chart($rootScope) {
    let chartColors;

    function getColors(type, dataLength: number) {
        var colors = angular.copy(chartColors || Chart.defaults.global.colors);
        var notEnoughColors = colors.length < dataLength;
        while (colors.length < dataLength) {
            colors.push(getRandomColor());
        }
        // mutate colors in this case as we don't want
        // the colors to change on each refresh
        if (notEnoughColors) chartColors = colors;
        return colors.map(convertColor);
    }

    function convertColor(color) {
        // Allows RGB and RGBA colors to be input as a string: e.g.: "rgb(159,204,0)", "rgba(159,204,0, 0.5)"
        if (typeof color === 'string' && color[0] === 'r') return getColor(rgbStringToRgb(color));
        // Allows hex colors to be input as a string.
        if (typeof color === 'string' && color[0] === '#') return getColor(hexToRgb(color.substr(1)));
        // Allows colors to be input as an object, bypassing getColor() entirely
        if (typeof color === 'object' && color !== null) return color;
        return getRandomColor();
    }

    function getRandomColor() {
        var color = [getRandomInt(0, 255), getRandomInt(0, 255), getRandomInt(0, 255)];
        return getColor(color);
    }

    function getColor(color) {
        var alpha = color[3] || 1;
        color = color.slice(0, 3);
        return {
            backgroundColor: rgba(color, 0.2),
            pointBackgroundColor: rgba(color, alpha),
            pointHoverBackgroundColor: rgba(color, 0.8),
            borderColor: rgba(color, alpha),
            pointBorderColor: '#fff',
            pointHoverBorderColor: rgba(color, alpha)
        };
    }

    function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function rgba(color, alpha) {
        return 'rgba(' + color.concat(alpha).join(',') + ')';
    }

// Credit: http://stackoverflow.com/a/11508164/1190235
    function hexToRgb(hex) {
        var bigint = parseInt(hex, 16),
            r = (bigint >> 16) & 255,
            g = (bigint >> 8) & 255,
            b = bigint & 255;

        return [r, g, b];
    }

    function rgbStringToRgb(color) {
        var match = color.match(/^rgba?\(([\d,.]+)\)$/);
        if (!match) throw new Error('Cannot parse rgb value');
        color = match[1].split(',');
        return color.map(Number);
    }

    return {
        restrict: 'A',
        scope: {
            chartConfig: '=chart'
        },
        link: function (scope, element, attr) {
            let myChart;

            function destroy() {
                if (!myChart) return;
                myChart.destroy();
                console.debug('chart destroyed');
            }

            function create(element, config) {
                destroy();
                if (!element || !config) return;
                var ctx = element[0].getContext('2d');
                console.debug('chart creating', config);
                myChart = new ChartJs.Chart(ctx, angular.copy(config));
                console.debug('chart created', myChart);
                scope.chartConfig.update = true;
                update();
            }

            function update() {
                if (!myChart) return;
                if (!scope.chartConfig || !scope.chartConfig.update || !scope.chartConfig.data) return;
                let data = scope.chartConfig.data;
                var colors = getColors(scope.chartConfig.type, data.datasets.length);
                console.debug('chart updating', data);
                myChart.data = {
                    labels: data.labels,
                    datasets: data.datasets.map((item, index) => {
                        return angular.extend(item, colors[item.colorIndex || index], item);
                    })
                };
                myChart.update();
                console.debug('chart updated', myChart.data);
                scope.chartConfig.update = false;
            }

            scope.$watch(() => scope.chartConfig, () => {
                create(element, scope.chartConfig);
            });

            scope.$watch(() => scope.chartConfig ? scope.chartConfig.update : null, update);

            scope.$on('$destroy', function () {
                destroy();
            });

            scope.$on('$resize', function () {
                if (myChart) myChart.resize();
            });
        }
    };
}

export default angular.module('directives.chart', [])
    .directive('chart', chart)
    .name;
