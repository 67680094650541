import {DataHubService} from "../modules/DataHubModule";
import {SpinnerService} from "../modules/SpinnerModule";
import {StudentDto} from "../model/StudentDto";
import {PrintService} from "../modules/PrintModule";
import {ConfigService} from "../modules/ConfigModule";

export default class SignOutStudentsController {
    records: StudentDto[] = [];
    today: Date;

    constructor(
        private $dataHub: DataHubService,
        private $print: PrintService,
        private $config: ConfigService,
        private $spinner: SpinnerService
    ) {
        this.today = new Date();
        $dataHub.client.bindAllStudentInCarAvailable(() => this.records);
        $dataHub.connected(this.load);
        this.load();
    }

    load = () => {
        if (!this.$dataHub.isConnected) return;
        this.$spinner.show();
        this.$dataHub.server.inCarGetAvailable()
            .finally(() => this.$spinner.hide());
    };
    signOut = (student: StudentDto) => {
        this.$dataHub.server.studentSignOut(student.id)
            .then(() => {
                this.$print.printPdf(`${this.$config.apiBase}report/inCarRecordReport?studentId=${student.id}`)
                    .then(r => console.log('print:', r))
                    .catch(e => {
                        console.log('print error:', e);
                        alert(e);
                    });
            })
            .catch((e) => {
                console.warn(e);
                alert(e);
            });
    };

};
