import {DataHubService} from "../modules/DataHubModule";
import {SpinnerService} from "../modules/SpinnerModule";
import {StudentStuckDto} from "../model/StudentStuckDto";

export default class NoShowController {
    date: Date = new Date();
    records: StudentStuckDto[] = [];

    constructor(private $dataHub: DataHubService,
                private $spinner: SpinnerService) {
        $dataHub.connected(this.dateChanged);
        if ($dataHub.isConnected) this.dateChanged();
    }

    dateChanged = () => {
        this.$spinner.show("Loading Students...");
        this.records.length = 0;
        this.$dataHub.server.classGetNoShows(this.date).then((studentDtos: StudentStuckDto[]) => {
            this.records = studentDtos;
        }).catch(e => {
            alert(e);
            console.warn(e);
        }).finally(() => this.$spinner.hide());
    };
};
