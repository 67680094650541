import * as angular from 'angular';
import uirouter, {StateProvider} from '@uirouter/angularjs';

import HomeController from './home.controller';

class HomeRoutes {
    static $inject = ['$stateProvider'];

    constructor($stateProvider: StateProvider) {
        $stateProvider
            .state('home', {
                url: '/',
                template: require('./home.html'),
                controller: 'HomeController',
                controllerAs: 'vm',
                data: {title: 'Main'}
            });
    }
}

export default angular.module('app.home', [uirouter])
    .config(HomeRoutes)
    .controller('HomeController', HomeController)
    .name;
