import * as angular from 'angular';
import {StateProvider} from '@uirouter/angularjs';
import DebugController from "./DebugController";
import QueueMemberReportController from "./QueueMemberReportController";
import CallController from "./CallController";
import CallDetailController from "./CallDetailController";

class Routes {
    static $inject = ['$stateProvider'];

    constructor($stateProvider: StateProvider) {
        $stateProvider
            .state('asterisk', {
                abstract: true,
                url: '/asterisk'
            })

            .state('asterisk.debug', {
                url: '/debug',
                template: require('./Debug.html'),
                controller: DebugController,
                controllerAs: 'vm',
                data: {title: 'Debug'}
            })

            .state('asterisk.call', {
                url: '/calls?date',
                params: {
                    date: {dynamic: true, isOptional: true}
                },
                template: require('./Call.html'),
                controller: CallController,
                controllerAs: 'vm',
                data: {title: 'Call Manager'}
            })

            .state('asterisk.callDetail', {
                url: '/call/:callId',
                template: require('./CallDetail.html'),
                controller: CallDetailController,
                controllerAs: 'vm',
                data: {title: 'Call Detail'}
            })

            .state('asterisk.queueMemberReport', {
                url: '/queueMemberReport',
                template: require('./QueueMemberReport.html'),
                controller: QueueMemberReportController,
                controllerAs: 'vm',
                data: {title: 'Queue Member Report'}
            })

    }
}

export default angular.module('app.asterisk', [])
    .config(Routes)
    .name;
