// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html

import { StudentNoteDto } from './StudentNoteDto';
import { AsteriskCallNoteDto } from './AsteriskCallNoteDto';

// Myers.StudentManagementSystem.Data.Models.StudentReleaseDto
export class StudentReleaseDto {
    public id: number = 0;
    public firstName: string = null;
    public lastName: string = null;
    public testScore: number = 0;
    public licenseExpires: Date = null;
    public classDoneDate: Date = null;
    public classDoneDateOnly: Date = null;
    public releaseReadyDate: Date = null;
    public balance: number = 0;
    public incarRequests: string = null;
    public completeByDate: Date = null;
    public status: string = null;
    public paymentStatus: string = null;
    public location: string = null;
    public studentTypeId: number = null;
    public studentType: string = null;
    public city: string = null;
    public county: string = null;
    public zip: string = null;
    public locationOrCity: string = null;
    public highSchool: string = null;
    public fullName: string = null;
    public lastNote: StudentNoteDto = null;
    public lastAsteriskCallNote: AsteriskCallNoteDto = null;
    public lastNoteDate: Date = null;
}


    



