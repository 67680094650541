import {DebounceFactory} from '../modules/DebounceModule';
import {SpinnerService} from '../modules/SpinnerModule';
import {LocationDto} from '../model/LocationDto';
import {ConfigService} from '../modules/ConfigModule';
import {DataHubService} from "../modules/DataHubModule";
import {ClassTypeDto} from "../model/ClassTypeDto";
import {RosterReportQueryDto} from "../model/RosterReportQueryDto";
import {RosterReportDto} from "../model/RosterReportDto";
import {UserStorageService} from "../modules/UserStorageModule";

export default class RosterReportController {
    locations: LocationDto[] = [];
    classTypes: ClassTypeDto[] = [];
    private data: RosterReportDto[];

    constructor(private $debounce: DebounceFactory,
                private $spinner: SpinnerService,
                private $dataHub: DataHubService,
                private $userStorage: UserStorageService,
                private $config: ConfigService) {

        this.$dataHub.bindAllLocation(() => this.locations);
        this.$dataHub.bindAllClassType(() => this.classTypes);

        this.$dataHub.onConnected(() => {
            this.$dataHub.location();
            this.$dataHub.classTypeSubscribe();
        })
    }

    refresh = this.$debounce(() => {
        this.$spinner.show();

        this.$dataHub.rosterReportQuery(this.options)
            .then(r => this.data = r)
            .catch(this.$dataHub.defaultErrorHandler)
            .finally(() => this.$spinner.hide());

    }, 500, false);


    locationsSelectAll = () => {
        this.options.locationIds = this.locations.map(i => i.id);
    };
    classTypesSelectAll = () => {
        this.options.classTypeIds = this.classTypes.map(i => i.id);
    };

    get options() {
        return this.$userStorage.$get<RosterReportQueryDto>('rosterReportOptions', new RosterReportQueryDto());
    }

    get reportTotal() {
        if(!this.data) return null;
        return this.data.map(i=>i.students.length).reduce((a,b) => a+b);
    }

}
