import {DataHubService} from "../modules/DataHubModule";
import {SpinnerService} from "../modules/SpinnerModule";
import {ConfigService} from "../modules/ConfigModule";
import {StudentInCarDto} from "../model/StudentInCarDto";
import {UserDto} from "../model/UserDto";
import {StateService, Transition} from "@uirouter/core";

export default class MyStudentsController {
    records: StudentInCarDto[] = [];
    releaseDialogVisible: boolean = false;
    releaseReason: string;
    selectedStudent: StudentInCarDto;
    private instructors: UserDto[] = [];

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
        this.$state = newValues;
        this.loadRecords();
    }

    constructor(
        private $dataHub: DataHubService,
        private $config: ConfigService,
        private $spinner: SpinnerService,
        private $state: StateService,
        private $stateParams: any
    ) {
        $dataHub.client.bindAllStudentInCar(() => this.records);
        $dataHub.client.bindAllUser(() => this.instructors);

        $dataHub.connected(this.load);
        this.load();
    }

    load = () => {
        if (!this.$dataHub.isConnected) return;

        this.$dataHub.server.userDrivingInstructorsSubscribe()
            .catch(this.$dataHub.defaultErrorHandler);

        this.loadRecords();
    };

    loadRecords = () => {
        if (!this.$dataHub.isConnected) return;
        this.records = [];
        this.$spinner.show();
        this.$dataHub.server.inCarAssignedStudentSubscribe(this.$stateParams.userId || 0)
            .catch(this.$dataHub.defaultErrorHandler)
            .finally(() => this.$spinner.hide());
    };

    markCalled(student: StudentInCarDto) {
        return this.$dataHub.server.studentCalled(student.id, true).catch(e => {
            console.warn(e);
            alert(e);
        });
    }

    beginRelease(student: StudentInCarDto) {
        this.selectedStudent = student;
        this.releaseDialogVisible = true;
        this.releaseReason = "";
    }

    finishRelease() {
        this.releaseDialogVisible = false;
        return this.$dataHub.server.studentSignIn(this.selectedStudent.id, this.releaseReason).catch(e => {
            console.warn(e);
            alert(e);
        });
    }

    instructorChanged = (instructor) => {
        this.$state.go('.', {userId: instructor.id});
    }
};
