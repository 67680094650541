import {StateProvider} from "@uirouter/angularjs";

export default class routing {
    static $inject = ['$urlRouterProvider', '$locationProvider', '$stateProvider'];

    constructor($urlRouterProvider, $locationProvider: ng.ILocationProvider,
                $stateProvider: StateProvider) {
        $locationProvider.html5Mode(true);
        $urlRouterProvider.otherwise('/');

    }
}
