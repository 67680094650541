import {Ng1Controller, Transition, TargetState, StateService} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {StudentExamDto} from "../model/StudentExamDto";
import {ngStorage} from "ngstorage";
import StorageService = ngStorage.StorageService;
import {IScope, ITimeoutService} from "angular";
import {ExamManagerMessageController} from "./ExamManagerMessageController";
import {ExamManagerDebugController} from "./ExamManagerDebugController";
import * as Bowser from 'bowser';

export default class ExamAdminController implements Ng1Controller {
    private students: StudentExamDto[] = [];

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(private $dataHub: DataHubService,
                private toaster: any,
                private $scope: IScope,
                private $timeout: ITimeoutService,
                private $localStorage: StorageService,
                private $uibModal,
                private $state: StateService,
                private $stateParams: any) {

        let merge = (e: StudentExamDto) => {
            e["bowser"] = Bowser.parse(e.userAgent);
            this.students.merge(e, x => x.id);
        };
        this.$dataHub.onStudentExamConnectedUpdated(merge);
        this.$dataHub.bindStudentExamConnectedRemoved(() => this.students);

        $dataHub.connected(this.refresh);
    }

    refresh = () => {
        this.students = [];
        if (!this.$dataHub.isConnected) return;

        this.$dataHub.studentExamConnectedSubscribe().catch(this.$dataHub.defaultErrorHandler);
    };

    instructorRemove = (row: StudentExamDto) => {
        this.$dataHub.studentExamInstructorRemove(row.id)
            .catch(this.$dataHub.defaultErrorHandler);
    };

    chat = (exam: StudentExamDto) => {
        var modalInstance = this.$uibModal.open({
            template: require('./ExamManagerMessageModal.html'),
            controller: ExamManagerMessageController,
            controllerAs: '$ctrl',
            bindToController: true,
            resolve: {
                exam: () => exam,
            }
        });
    };
    debug = (exam: StudentExamDto) => {
        var modalInstance = this.$uibModal.open({
            template: require('./ExamManagerDebugModal.html'),
            controller: ExamManagerDebugController,
            size: 'lg',
            controllerAs: '$ctrl',
            bindToController: true,
            resolve: {
                exam: () => exam,
            }
        });
    };


}
