import * as angular from 'angular';

function email() {
    return {
        template: '<a ng-if="addr" title="Email {{addr|tel}}" class="btn btn-default btn-xs"><i class="fad fa-envelope-square"></i> {{addr}}</a>',
        restrict: 'E',
        scope: {addr: '<'},
    };
}
export default angular.module('directives.email', [])
    .directive('email', email)
    .name;
