import {Ng1Controller, StateService, TargetState, Transition} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {AsteriskCallDto} from "../model/AsteriskCallDto";
import {UserStorageService} from "../modules/UserStorageModule";
import {SpinnerService} from "../modules/SpinnerModule";
import * as angular from "angular";
import {AsteriskCallBackStatus} from "../model/AsteriskCallBackStatus";
import {AsteriskCallLinkDto} from "../model/AsteriskCallLinkDto";
import {AsteriskEventDto} from "../model/AsteriskEventDto";
import {ApiService} from "../modules/ApiModule";

export default class CallDetailController implements Ng1Controller {
    private error: any;
    private call: AsteriskCallDto;
    private related: AsteriskCallDto[] = [];
    private events: AsteriskEventDto[] = [];
    private availableTags: string[] = [];
    private recordings: any;

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
        this.$stateParams = newValues;
        this.refresh();
    }

    constructor(private $dataHub: DataHubService,
                private $api: ApiService,
                private $userStorage: UserStorageService,
                private $spinner: SpinnerService,
                private $state: StateService, private $stateParams: any) {
        $dataHub.connected(this.refresh);

        $dataHub.client.onAsteriskCallUpdated(e => this.call = e);
        $dataHub.client.bindAllAsteriskRelatedCall(() => this.related);
        $dataHub.client.bindAllAsteriskEvent(() => this.events);

    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;
        this.$dataHub.server.asteriskCallDetailSubscribe(this.$stateParams.callId);
    };


    dial = (number) => {
        this.$spinner.show('Dialing');
        this.$dataHub.server.asteriskCallOriginate(this.$userStorage.myExtension, number)
            .finally(() => this.$spinner.hide());
    };

    linkStudent = (student) => {
        this.$dataHub.server.asteriskCallLinkStudent(this.call.id, student.id);
    };

    unlink = (dto:AsteriskCallLinkDto) => {
        this.$dataHub.server.asteriskCallUnlink(this.call.id, dto.id);
    };

    noteChanged = (note) => {
        this.$dataHub.server.asteriskCallNoteUpdate(this.call.id, note);
    };

    tagSearch = (input) => {
        this.$dataHub.server.asteriskCallTagSearch(input)
            .then(r => {
                angular.forEach(r, (tag) => {
                    this.availableTags.merge(tag, x => x)
                })

            });
    };

    tagAdded = (tag) => {
        this.$dataHub.server.asteriskCallTagAdd(this.call.id, tag);
    };

    tagRemoved = (tag) => {
        this.$dataHub.server.asteriskCallTagRemove(this.call.id, tag);
    };

    callBackSet = (status: AsteriskCallBackStatus) => {
        this.$dataHub.server.asteriskCallBackStatusUpdate(this.call.id, status);
    };

    recordingCheck = () => {
        this.$api.get<any>(`asterisk/call/${this.call.id}/recordings`)
            .then(result => this.recordings = result.data.recordings);
    }
}
