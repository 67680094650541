import {Ng1Controller, Transition, TargetState, StateService} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {UserDto} from "../model/UserDto";
import {ConfigService} from "../modules/ConfigModule";
import {DocumentDto} from "../model/DocumentDto";
import {DocumentTypeDto} from "../model/DocumentTypeDto";
import {IScope} from "angular";
import {DocumentSearchDto} from "../model/DocumentSearchDto";
import {UserDisplayDto} from "../model/UserDisplayDto";

export default class LinkController implements Ng1Controller {
    private documents: DocumentDto[] = [];
    private documentTypes: DocumentTypeDto[] = [];
    private users: UserDto[] = [];
    private selected: DocumentDto;
    private searchOptions: DocumentSearchDto;
    private mode: string;

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
        this.select(newValues.id);
    }

    constructor(private $dataHub: DataHubService,
                private toaster: any,
                private $config: ConfigService,
                private $scope: IScope,
                private $state: StateService,
                private $stateParams: any) {
        $dataHub.connected(this.refresh);

        $dataHub.client.bindAllDocument(() => this.documents);
        $dataHub.client.bindAllDocumentType(() => this.documentTypes);
        $dataHub.client.bindAllUser(() => this.users);

        $dataHub.client.onDocumentUpdated(doc => {
            if (doc.id == this.$stateParams.id)
                this.selected = doc;
        });

        $dataHub.client.onDocumentRemoved(() => {
            if (this.documents.length == 0)
                this.$dataHub.server.documentInvalidSubscribe();
        })
    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;

        this.$dataHub.server.documentTypeSubscribe();
        this.$dataHub.server.userList();
        if(this.$stateParams.userId) {
            this.mode = 'search';
            this.searchOptions = new DocumentSearchDto();
            this.searchOptions.user = new UserDisplayDto();
            this.searchOptions.user.id = this.$stateParams.userId;
            this.search();
        } else {
            this.$dataHub.server.documentInvalidSubscribe();

        }
    };

    select = (id) => {
        this.selected = this.documents.filter(x => x.id == id)[0];
    };

    get pdfUrl() {
        if (!this.selected) return;
        return this.$config.apiBase + 'document/' + this.selected.documentGuid;
    }

    save = (document) => {
        this.$dataHub.server.documentModify(document)
            .then(() => this.selectNext(document))
            .catch(this.$dataHub.defaultErrorHandler);
    };

    selectNext = (current) => {
        // select "next"
        var next = this.documents.filter(x => x.id > current.id)[0];
        this.$state.go('.', {id: next != null ? next.id : null});
        this.$scope.$broadcast('docTypeFocus');
    };

    delete = (document) => {
        if (!confirm('Delete ' + document.fileName + '?')) return;

        this.$dataHub.server.documentDelete(document)
            .then(() => this.selectNext(document))
            .catch(this.$dataHub.defaultErrorHandler);
    };

    search = () => {
        this.documents = [];
        this.$dataHub.server.documentSearch(this.searchOptions)
            .catch(this.$dataHub.defaultErrorHandler);
    };
}
