import {ConfigService} from '../modules/ConfigModule';
import {SpinnerService} from '../modules/SpinnerModule';
import {ngStorage} from 'ngStorage';

export default class LoginController {
    model = {
        username: null,
        password: null,
        newPassword: null,
        newPassword2: null,
        rememberMe: false,
    };
    error = null;

    constructor(
        $scope: ng.IScope,
        private $http: ng.IHttpService,
        private $localStorage: ngStorage.StorageService,
        private $config: ConfigService,
        private $spinner: SpinnerService) {

        $spinner.hide();
    }


    login = () => {
        this.$spinner.show('Checking login...');
        this.$config.login(this.model.username, this.model.password, this.model.newPassword, this.model.rememberMe)
            .then(() => this.error = null)
            .catch(e => {
                this.error = e;
                if (e.newPasswordPwned) {
                    this.model.newPassword = null;
                    this.model.newPassword2 = null;
                }
                if (e.passwordChanged) {
                    this.model = {newPassword: null, newPassword2: null, password: null, username: this.model.username, rememberMe: false};
                }
            })
            .finally(() => this.$spinner.hide());
    };


}
