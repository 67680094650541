import * as angular from 'angular';

function popoutDirective() {
    return {
        link(scope, element, attrs) {
            element.bind('click', event => {
                popWindow(attrs.href, attrs.id);
                event.preventDefault();
            });
        }
    };
}

export function popWindow(href: string, id: string) {
    let w = window.open(href, id,
        'width=995, height=600, toolbar=no, location=no, directories=no, status=no, menubar=no, copyhistory=no');
    if (screen.width < 1200) {
        w.moveTo(0, 0);
        w.resizeTo(screen.availWidth, screen.availHeight);
    }
    return w;
}

export default angular.module('directives.popout', [])
    .directive('popout', popoutDirective)
    .name;
