// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html

import { TransactionDto } from './TransactionDto';
import { GiftCertificateLogDto } from './GiftCertificateLogDto';
import { GiftCertificateDto } from './GiftCertificateDto';

// Myers.StudentManagementSystem.Data.Models.GiftCertificateDetailDto
export class GiftCertificateDetailDto extends GiftCertificateDto {
    public transactions: TransactionDto[] = [];
    public logs: GiftCertificateLogDto[] = [];
}


    



