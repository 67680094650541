import * as angular from 'angular';
import {DataHubService} from "./DataHubModule";
import {UserStorageDto} from "../model/UserStorageDto";
import {ConfigService} from "./ConfigModule";
import {IPromise} from "angular";

export class UserStorageService {
    initialized: boolean;

    [index: string]: any;

    $debounce: IPromise<any>;
    $lastValues = {};

    constructor(private $dataHub: DataHubService,
                $config: ConfigService,
                $rootScope: ng.IRootScopeService,
                $timeout: ng.ITimeoutService) {
        $dataHub.client.onUserStorageUpdated(this.$userStorageUpdated);
        $dataHub.client.onUserStorageRemoved(this.$userStorageRemoved);

        $dataHub.connected(this.$reload);

        $config.onLogin(this.$reload);

        $rootScope.$watch(() => {
            this.$debounce || (this.$debounce = $timeout(() => this.$applyChanges(), 500, false));
        })
    }

    $applyChanges = () => {
        this.$debounce = null;
        angular.forEach(this, (value, key) => {
            if (key[0] === '$') return;
            if (angular.equals(this.$lastValues[key], value)) return;
            this.$set(key, value);
        })
    };

    $set = <T>(key: string, value: T): void => {
        if (!this.$dataHub.isConnected) return;
        this.$dataHub.server.userStorageSet(key, JSON.stringify(value));
    };
    $get = <T>(key: string, defaultValue: T): T => {
        if (!this.$dataHub.isConnected) return undefined;
        if (!this[key])
            this[key] = defaultValue;
        return this[key];
    };

    $remove = (key: string): void => {
        this.$dataHub.server.userStorageRemove(key);
    };

    $userStorageUpdated = (entity: UserStorageDto) => {
        this[entity.key] = JSON.parse(entity.value);
        this.$lastValues[entity.key] = JSON.parse(entity.value);
    };

    $userStorageRemoved = (entity: UserStorageDto) => {
        delete this[entity.key];
        delete this.$lastValues[entity.key];
    };

    $reload = () => {
        this.initialized = false;
        if (!this.$dataHub.isConnected) return;
        this.$dataHub.server.userStorageQuery()
            .then(() => this.initialized = true);
    }

}

export default angular.module('services.userStorage', [])
    .service('$userStorage', UserStorageService)
    .name;
