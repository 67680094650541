import * as angular from 'angular';
import SignaturePad from "signature_pad";
import {IScope, ITimeoutService} from "angular";
import {StudentContractFieldDto} from "../model/StudentContractFieldDto";
import trimCanvas from 'trim-canvas'
import {StudentContractPublicDto} from "../model/StudentContractPublicDto";

class contractFieldController {
    private field: StudentContractFieldDto;
    private fieldForSig: StudentContractFieldDto;
    private contract: StudentContractPublicDto;
    private fieldChanged: ({$field: StudentContractFieldDto}) => {};

    constructor(private $uibModal) {
    }

    sign = () => {
        this.showSignatureDialog();
    };

    copyFieldForSig() {
        this.fieldForSig = angular.copy(this.field);
        this.fieldForSig.userValue=null;
        this.fieldForSig.dataValue=null;
    }

    showSignatureDialog = () => {
        this.copyFieldForSig();

        var modalInstance = this.$uibModal.open({
            template: require('./ContractFieldSignature.html'),
            backdrop: 'static',
            controller: signatureController,
            controllerAs: '$ctrl',
            bindToController: true,
            resolve: {
                field: () => this.fieldForSig,
            }
        });

        modalInstance.result.then(() => {
            if (this.fieldChanged && typeof this.fieldChanged === "function") {
                this.fieldChanged({$field: this.fieldForSig});
            }
        }, function () {
            console.debug('Modal dismissed at: ' + new Date());
        });
    };

    initial = () => {
        this.copyFieldForSig();
        var modalInstance = this.$uibModal.open({
            template: require('./ContractFieldInitial.html'),
            backdrop: 'static',
            controller: signatureController,
            controllerAs: '$ctrl',
            bindToController: true,
            resolve: {
                field: () => this.fieldForSig,
            }
        });

        modalInstance.result.then(() => {
            if (this.fieldChanged && typeof this.fieldChanged === "function") {
                this.fieldChanged({$field: this.fieldForSig});
            }
        }, function () {
            console.debug('Modal dismissed at: ' + new Date());
        });
    };

}

class signatureController {
    private signaturePad: SignaturePad;
    private canvas: HTMLCanvasElement;
    private signatureLength: number = 0;

    constructor(private $uibModalInstance,
                private $timeout: ITimeoutService,
                private $scope: IScope,
                private field: StudentContractFieldDto,
    ) {

        this.$timeout(() => {
            this.signaturePad = new SignaturePad(this.canvas[0], {
                onEnd: this.signatureOnEnd,
                minWidth: 2,
                maxWidth: 4,
            });
            this.resizeCanvas();
            window.addEventListener("resize", this.resizeCanvas.bind(this));
        }, 0);

    }

    private resizeCanvas() {
        var ratio = Math.max(window.devicePixelRatio || 1, 1);
        var canvas = this.canvas[0];
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);
        this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
        this.$scope.$applyAsync(() => {
            this.signatureLength = 0;
        });
    }


    private cancel() {
        this.$uibModalInstance.dismiss('cancel');
    }

    private clear() {
        this.signaturePad.clear();
        this.signatureLength = 0;
    }

    private accept() {
        trimCanvas(this.canvas[0]);
        this.field.dataValue = this.signaturePad.toDataURL();
        this.$uibModalInstance.close();
    }

    signatureOnEnd = () => {
        this.$scope.$apply(() => {
            this.signatureLength = this.signaturePad.toDataURL().length;
        })
    }
}


function contractFieldDirective() {
    return {
        template: require('./ContractField.html'),
        restrict: 'E',
        transclude: true,
        scope: {
            field: '<',
            fieldChanged: '&',
            contract: '<'
        },
        controller: contractFieldController,
        controllerAs: '$ctrl',
        bindToController: true,
        link: (scope, element, attrs) => {
            element.addClass('inline-flex');
        }
    };
};


export default angular.module('directives.contractField', [])
    .directive('contractField', contractFieldDirective)
    .name;
