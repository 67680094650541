// Generated by DataHubGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html



import {SpinnerService} from './SpinnerModule';
import {HubProxy} from './HubProxyModule';
import {IQService, IRootScopeService, ITimeoutService, IWindowService} from "angular";
import { StudentExamStatusDto } from '../model/StudentExamStatusDto';
import { StudentExamQuestionPublicDto } from '../model/StudentExamQuestionPublicDto';
import { StudentExamMessageDto } from '../model/StudentExamMessageDto';
import { RtcInfo } from '../model/RtcInfo';

export class ExamHub extends HubProxy {

    constructor($q: IQService,
                $rootScope: IRootScopeService,
                $timeout: ITimeoutService,
                $window: IWindowService,
                $spinner: SpinnerService) {
        super($q, $rootScope, $timeout, $window, $spinner, 'ExamHub', false);
    }

    // server methods

    
    join = (studentId: string) => {
        return this.invoke<StudentExamStatusDto>('Join', studentId);
    }
    
    messageSubmit = (message: StudentExamMessageDto) => {
        return this.invoke<void>('MessageSubmit', message);
    }
    
    next = () => {
        return this.invoke<StudentExamQuestionPublicDto>('Next', );
    }
    
    skip = (dto: StudentExamQuestionPublicDto) => {
        return this.invoke<StudentExamQuestionPublicDto>('Skip', dto);
    }
    
    status = () => {
        return this.invoke<StudentExamStatusDto>('Status', );
    }
    
    submitAnswer = (dto: StudentExamQuestionPublicDto) => {
        return this.invoke<StudentExamQuestionPublicDto>('SubmitAnswer', dto);
    }
    
    updateFullscreen = (fullscreen: boolean) => {
        return this.invoke<void>('UpdateFullscreen', fullscreen);
    }
    
    updateRtcInfo = (rtc: RtcInfo) => {
        return this.invoke<void>('UpdateRtcInfo', rtc);
    }
    
    consoleLog = (severity: string, messages: string[]) => {
        return this.invoke<void>('ConsoleLog', severity, messages);
    }
    


    // client methods
    
    /**
    * Wires up a callback to be invoked when MessageRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onMessageRemoved = (callback: (arg: StudentExamMessageDto) => void) => {
        return this.on('MessageRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when MessageUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onMessageUpdated = (callback: (arg: StudentExamMessageDto) => void) => {
        return this.on('MessageUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when Reset is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onReset = (callback: () => void) => {
        return this.on('Reset', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StatusChanged is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStatusChanged = (callback: (status: StudentExamStatusDto) => void) => {
        return this.on('StatusChanged', callback);
    }
    

    // convenience methods to bind updates/removes to an array
    
    /**
    * Bind MessageUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of StudentExamMessageDto to update
    */
    bindMessageUpdated = (arrayFunc: () => Array<StudentExamMessageDto>) => {
        return this.on('MessageUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    

    
    /**
    * Bind MessageRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of StudentExamMessageDto to update
    */
    bindMessageRemoved = (arrayFunc: () => Array<StudentExamMessageDto>) => {
        return this.on('MessageRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    

    
    bindAllMessage = (arrayFunc: () => Array<StudentExamMessageDto>) => {
        this.bindMessageRemoved(arrayFunc);
        this.bindMessageUpdated(arrayFunc);
        
    }
    



}
