import * as angular from 'angular';
import {StateProvider} from '@uirouter/angularjs';
import ListController from "./ListController";
import ViewController from "./ViewController";

class Routes {
    constructor($stateProvider: StateProvider) {
        $stateProvider
            .state('prereg', {abstract: true})
            .state('prereg.list', {
                url: '/prereg',
                template: require('./List.html'),
                controller: ListController,
                controllerAs: 'vm',
                data: {title: 'Pre Registrations'}
            })
            .state('prereg.view', {
                url: '/prereg/:id',
                template: require('./View.html'),
                controller: ViewController,
                controllerAs: 'vm',
                data: {title: 'View Pre-Registration'}
            })
        ;
    }
}

export default angular.module('app.prereg', [])
    .config(Routes)
    .name;
