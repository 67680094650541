// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html




// Myers.StudentManagementSystem.Data.Enum.VirtualClassMode
export enum VirtualClassMode {
    
    None = 0,
    AttendanceAndCertificate = 1,
    Form = 2,
    FinalExamCourseEvalAttendanceCertificate = 3,
    AttendanceSignature = 4
}
