import * as angular from 'angular';
import {StateProvider} from '@uirouter/angularjs';

import PaymentReportController from './PaymentReportController';
import SignupController from "./SignupController";
import RosterReportController from "./RosterReportController";

class Routes {
    static $inject = ['$stateProvider'];

    constructor($stateProvider: StateProvider) {
        $stateProvider
            .state('report', {
                abstract: true,
                url: '/report'
            })
            .state('report.payment', {
                url: '/payment',
                template: require('./payment.html'),
                controller: PaymentReportController,
                controllerAs: 'vm',
                data: {
                    title: 'Payment Summary'
                }
            })
            .state('report.signup', {
                url: '/signup',
                template: require('./Signup.html'),
                controller: SignupController,
                controllerAs: 'vm',
                data: {
                    title: 'Student Signup Report'
                }
            })
            .state('report.roster', {
                url: '/roster',
                template: require('./Roster.html'),
                controller: RosterReportController,
                controllerAs: '$ctrl',
                data: {
                    title: 'Student Roster Report'
                }
            })

        ;
    }
}

export default angular.module('app.report', [])
    .config(Routes)
    .controller({PaymentReportController})
    .name;
