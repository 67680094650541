import {SpinnerService} from '../modules/SpinnerModule';
import {StudentDto} from "../model/StudentDto";
import {ClassDto} from "../model/ClassDto";
import {DataHubService} from "../modules/DataHubModule";
import {ApiService} from '../modules/ApiModule';
import {ConfigService} from '../modules/ConfigModule';
import {Ng1Controller, HookResult, Transition} from "@uirouter/angularjs";
import {StudentWithTestScoresDto} from "../model/StudentWithTestScoresDto";
import {Janus} from 'janus-gateway';
import {ITimeoutService} from "angular";
import {StudentExamDto} from "../model/StudentExamDto";
import {StudentExamStatus} from "../model/StudentExamStatus";
import {ngStorage} from "ngstorage";
import StorageService = ngStorage.StorageService;

export default class ExamController implements Ng1Controller {
    private classId: number;
    private selectedClass: ClassDto;
    private searchResults: StudentDto[];
    private exams: StudentExamDto[] = [];
    private students: StudentWithTestScoresDto[];
    private janus: any;
    private screentest: any;
    private janusConnected: boolean;

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
        this.classId = newValues.classId;
        this.refresh();
    }

    $onInit(): void {
    }

    uiCanExit(transition: Transition): HookResult {
        return true;
    }

    constructor(
        private $scope: ng.IScope,
        private toaster: any,
        private $timeout: ITimeoutService,
        private $api: ApiService,
        private $config: ConfigService,
        private $spinner: SpinnerService,
        private $dataHub: DataHubService,
        private $localStorage: StorageService,
        $stateParams: any,
    ) {
        this.$dataHub.client.bindAllStudentWithTestScore(() => this.students);
        this.$dataHub.client.bindAllStudentExam(() => this.exams);

        this.classId = $stateParams.classId;
        $dataHub.connected(() => this.refresh());
        if ($dataHub.isConnected)
            this.refresh();

        this.janusInit();



    }

    get columns() {
        return this.$localStorage['classroom_exam_columns'];
    }

    set columns(value) {
        this.$localStorage['classroom_exam_columns'] = value;
    }

    refresh = () => {
        this.students = [];
        this.exams = [];
        this.selectedClass = null;
        if (!this.classId) return;
        if (!this.$dataHub.isConnected) return;

        this.$dataHub.server.classGet(this.classId)
            .then((cls) => {
                this.selectedClass = cls;
            })
            .catch(this.$dataHub.defaultErrorHandler);

        this.$dataHub.server.classTestScores(this.classId)
            .catch(this.$dataHub.defaultErrorHandler);

        this.$dataHub.server.classStudentTestSubscribe(this.classId)
            .catch(this.$dataHub.defaultErrorHandler);


    };

    addStudent(student: StudentDto) {
        this.$dataHub.server.studentTestScores(student.id)
            .catch(this.$dataHub.defaultErrorHandler);
        this.$dataHub.server.studentExamSubscribe(student.id)
            .catch(this.$dataHub.defaultErrorHandler);
    };

    generateExam = (student: StudentDto) => {
        this.$dataHub.server.studentExamLinkSend(student.id)
            .then(() => this.toaster.success('Exam link sent'))
            .catch(this.$dataHub.defaultErrorHandler);
    };

    setExamStatus = (exam: StudentExamDto, status: StudentExamStatus) => {
        this.$dataHub.studentExamStatusUpdate(exam.id, status)
            .catch(this.$dataHub.defaultErrorHandler);
    };

    janusInit = () => {
        Janus.init({
            debug: true,
            dependencies: Janus.useDefaultDependencies(), // or: Janus.useOldDependencies() to get the behaviour of previous Janus versions
            callback: () => {
            }
        });
    };

    janusConnect = () => {
        if (this.janusConnected) return;
        this.janus = new Janus({
                server: ['wss://srvmds02.myersdrivingschool.net:8989/', 'https://srvmds02.myersdrivingschool.net:8089/janus'],
                success: () => {
                    console.log('success');
                    this.janusAttach(this.janus);
                    this.$scope.$apply(() => this.janusConnected = true);
                },
                error: (cause) => {
                    console.error(cause);
                    this.$scope.$apply(() => this.janusConnected = false);
                },
                destroyed: function () {
                    // I should get rid of this
                }
            }
        );
    };

    janusAttach = (janus) => {
        janus.attach(
            {
                plugin: "janus.plugin.videoroom",
                success: (pluginHandle) => {
                    this.screentest = pluginHandle;
                    Janus.log("Plugin attached! (" + this.screentest.getPlugin() + ", id=" + this.screentest.getId() + ")");

                    var register = {
                        "request": "join",
                        "room": 1,
                        "ptype": "publisher",
                    };
                    this.screentest.send({"message": register});

                },
                error: function (error) {
                    Janus.error("  -- Error attaching plugin...", error);
                },
                consentDialog: function (on) {
                    Janus.debug("Consent dialog should be " + (on ? "on" : "off") + " now");
                },
                webrtcState: function (on) {
                    Janus.log("Janus says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
                },
                onmessage: (msg, jsep) => {
                    Janus.debug(" ::: Got a message (publisher) :::");
                    Janus.debug(msg);
                    var event = msg["videoroom"];
                    Janus.debug("Event: " + event);
                    if (event != undefined) {
                        if (event === "joined") {
                            let myid = msg["id"];
                            let private_id = msg["private_id"]
                            Janus.log("Successfully joined room " + msg["room"] + " with ID " + myid + ", privateid:" + private_id);

                            if (msg["publishers"] !== undefined && msg["publishers"] !== null) {
                                this.janusProcessPublishers(msg["publishers"]);
                            }
                        } else if (event === "event") {
                            // Any feed to attach to?
                            if (msg["publishers"] !== undefined && msg["publishers"] !== null) {
                                this.janusProcessPublishers(msg["publishers"]);
                            } else if (msg["leaving"] !== undefined && msg["leaving"] !== null) {
                                // One of the publishers has gone away?
                                var leaving = msg["leaving"];
                                Janus.log("Publisher left: " + leaving);

                            } else if (msg["error"] !== undefined && msg["error"] !== null) {
                                console.error(msg["error"]);
                            }
                        }
                    }
                    if (jsep !== undefined && jsep !== null) {
                        Janus.debug("Handling SDP as well...");
                        Janus.debug(jsep);
                        this.screentest.handleRemoteJsep({jsep: jsep});
                    }
                },
                onlocalstream: function (stream) {
                    Janus.debug(" ::: Got a local stream :::");
                    Janus.debug(stream);
                },
                onremotestream: function (stream) {
                    // The publisher stream is sendonly, we don't expect anything here
                },
                oncleanup: function () {
                    Janus.log(" ::: Got a cleanup notification :::");
                }
            });
    };


    janusAttachFeed = (id, count = 0) => {
        let exam = this.exams.filter(x => x.rtcInfoCamera && x.rtcInfoCamera.handleId == id || x.rtcInfoScreen && x.rtcInfoScreen.handleId == id)[0];
        if (!exam) {
            if (count > 5) {
                console.debug('feed id not found after 5 retries. giving up.');
                return;
            }
            console.debug('feed id', id, 'not found yet. retrying in 5000ms');
            this.$timeout(() => this.janusAttachFeed(id, count + 1), 2500);
            return;
        }
        console.debug('attaching for testentity', exam);

        let mode = exam.rtcInfoCamera && exam.rtcInfoCamera.handleId == id ? "camera" : "screen";
        let rtcInfo = exam.rtcInfoCamera && exam.rtcInfoCamera.handleId == id ?
            exam.rtcInfoCamera : exam.rtcInfoScreen;
        // A new feed has been published, create a new plugin handle and attach to it as a listener
        var remoteFeed = null;
        this.janus.attach(
            {
                plugin: "janus.plugin.videoroom",
                // opaqueId: opaqueId,
                success: function (pluginHandle) {
                    remoteFeed = pluginHandle;
                    Janus.log("Plugin attached! (" + remoteFeed.getPlugin() + ", id=" + remoteFeed.getId() + ")");
                    Janus.log("  -- This is a subscriber");
                    // We wait for the plugin to send us an offer
                    var listen = {
                        "request": "join",
                        "room": rtcInfo.roomId,
                        "ptype": "subscriber",
                        "feed": rtcInfo.handleId,
                        "private_id": rtcInfo.privateId
                    };
                    remoteFeed.send({"message": listen});
                },
                error: function (error) {
                    Janus.error("  -- Error attaching plugin...", error);
                },
                onmessage: function (msg, jsep) {
                    Janus.debug(" ::: Got a message (listener) :::");
                    Janus.debug(msg);
                    var event = msg["videoroom"];
                    Janus.debug("Event: " + event);
                    if (event != undefined) {
                        if (event === "attached") {
                            Janus.log("Successfully attached to " + rtcInfo);
                        } else {
                            // What has just happened?
                        }
                    }
                    if (jsep !== undefined && jsep !== null) {
                        Janus.debug("Handling SDP as well...");
                        Janus.debug(jsep);
                        // Answer and attach
                        remoteFeed.createAnswer(
                            {
                                jsep: jsep,
                                media: {audioSend: false, videoSend: false},	// We want recvonly audio/video
                                success: function (jsep) {
                                    Janus.debug("Got SDP!");
                                    Janus.debug(jsep);
                                    var body = {"request": "start", "room": rtcInfo.roomId};
                                    remoteFeed.send({"message": body, "jsep": jsep});
                                },
                                error: function (error) {
                                    Janus.error("WebRTC error:", error);
                                }
                            });
                    }
                },
                onlocalstream: function (stream) {
                    // The subscriber stream is recvonly, we don't expect anything here
                },
                onremotestream: function (stream) {
                    console.debug("attaching stream");
                    let video = mode == "screen" ? exam["videoScreen"] : exam["videoCamera"];
                    video.removeClass('bg-info');
                    video.addClass('bg-success');
                    Janus.attachMediaStream(video[0], stream);
                },
                oncleanup: function () {
                    Janus.log(" ::: Got a cleanup notification (remote feed " + rtcInfo + ") :::");

                }
            });
    };


    private janusProcessPublishers(list: any) {
        console.debug("publishers:", list);
        if (!list) return;
        for (var publisher of list) {
            this.janusAttachFeed(publisher.id)
        }
    }
};
