import {Ng1Controller, Transition, TargetState, StateService} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {ZoomMeetingParticipantDto} from "../model/ZoomMeetingParticipantDto";
import {StudentDisplayDto} from "../model/StudentDisplayDto";
import {AttendanceRecordDto} from "../model/AttendanceRecordDto";
import {SpinnerService} from "../modules/SpinnerModule";
import {addDays} from "date-fns";

export default class ZoomLinkController implements Ng1Controller {

    private date: Date;
    private records: ZoomMeetingParticipantDto[];
    private students: StudentDisplayDto[];
    private attendance: AttendanceRecordDto[];
    private dirty: boolean;

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(private $dataHub: DataHubService,
                private toaster: any,
                private $spinner: SpinnerService,
                private $state: StateService,
                private $stateParams: any) {
        $dataHub.connected(this.refresh);
        $dataHub.bindAllZoomMeetingParticipant(() => this.records);

        this.date = addDays(new Date(), -1);
    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;
        this.records = [];
        this.students = [];
        this.dirty = false;
        this.$dataHub.zoomParticipantUnreconciled(this.date)
            .then(r => this.records = r)
            .catch(this.$dataHub.defaultErrorHandler);

        this.$dataHub.zoomParticipantAttendanceQuery(this.date)
            .then(r => this.updateAttendance(r))
            .catch(this.$dataHub.defaultErrorHandler);
    }

    updateAttendance = (attendance: AttendanceRecordDto[]) => {
        this.attendance = attendance;
        this.students = [];
        this.attendance.forEach(record => {
            let student = this.students.filter(s => s.id == record.student.id)[0];
            if (!student) {
                this.students.push(record.student);
                student = record.student;
            }
            if (!student["attendance"])
                student["attendance"] = [];
            student["attendance"].push(record);
            record.student = null;
        })
    }

    linkSelected = (student: StudentDisplayDto) => {
        if (!student["drops"])
            student["drops"] = [];
        this.records.filter(i => i["selected"]).forEach(record => {
            student["drops"].push(record);
            record["linked"] = true;
            record["selected"] = false;
        })
    }

    unlink = (row: ZoomMeetingParticipantDto, student: StudentDisplayDto) => {
        student["drops"].remove(row, x => x);
        row["linked"] = false;
    }

    submitChanges = async () => {
        this.$spinner.show();
        for (const student of this.students) {
            if (!student["drops"]) continue;
            for (const drop of student["drops"]) {
                await this.$dataHub.zoomParticipantLink(drop["rowId"], student.id)
                    .catch(this.$dataHub.defaultErrorHandler);
            }
        }
        this.$spinner.hide();
        this.refresh();
    }

    getRelated = (row: ZoomMeetingParticipantDto) => {
        this.$dataHub.zoomParticipantRelatedQuery(row.rowId)
            .then(r => {
                row["related"] = r;
            })
    }

    getTitle = (row: ZoomMeetingParticipantDto) => {
        return `Device: ${row.device}
IP: ${row.ipAddress}
Location: ${row.location}
Leave Reason: ${row.leaveReason}
Reconcile Msg: ${row.message}`;
    }
}
