import * as angular from 'angular';

export class SpinnerService {
    static $inject = ['$window'];
    static count = 0;
    spinner = $('#spinner');
    caption = $('#spinnerCaption');

    show(message: string = null, incrementCounter = true): void {
        this.caption.text(message);
        this.spinner.show();
        if (incrementCounter || SpinnerService.count === 0)
            SpinnerService.count++;
    }

    hide(): void {
        SpinnerService.count--;
        if (SpinnerService.count <= 0) {
            this.spinner.hide();
            SpinnerService.count = 0;
        }
    }

    reset(): void {
        SpinnerService.count = 0;
        this.spinner.hide();
    }
}

export default angular.module('services.spinner', [])
    .service('$spinner', SpinnerService)
    .name;
