// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html




// Myers.StudentManagementSystem.Data.Enum.AttendanceStatus
export enum AttendanceStatus {
    
    Unknown = 0,
    Present = 1,
    Absent = 2,
    Dismissed = 3,
    LeftEarly = 4,
    Void = 5,
    Scheduled = 6,
    Expected = 7,
    CheckedIn = 8
}
