import * as moment from 'moment';

import {DebounceFactory} from '../modules/DebounceModule';
import {SpinnerService} from '../modules/SpinnerModule';
import {HubProxyFactory, HubProxy} from '../modules/HubProxyModule';
import {TransactionDto} from '../model/TransactionDto';
import {PaymentReportOptionsDto} from '../model/PaymentReportOptionsDto';
import {LocationDto} from '../model/LocationDto';
import {ConfigService} from '../modules/ConfigModule';
import {TransactionTypeDto} from "../model/TransactionTypeDto";

export default class PaymentReportController {
    static $inject = ['$debounce', '$spinner', 'hubProxyFactory', '$filter', '$location', '$config', '$scope'];
    data: TransactionDto[];
    dataDisplay: TransactionDto[];
    locations: LocationDto[] = [];
    locationsSelected: LocationDto[] = [];
    transactionTypes: TransactionTypeDto[] = [];
    transactionTypesSelected: TransactionTypeDto[] = [];
    options = new PaymentReportOptionsDto();
    hub: HubProxy;

    constructor(private $debounce: DebounceFactory,
                private $spinner: SpinnerService,
                hubProxyFactory: HubProxyFactory,
                private $filter: ng.IFilterService,
                private $location: ng.ILocationService,
                private $config: ConfigService,
                $scope: ng.IScope) {

        this.locationChangeSuccess();
        $scope.$on('$locationChangeSuccess', this.locationChangeSuccess);

        this.hub = hubProxyFactory.create('DataHub', false);
        this.hub.on('locationUpdated', this.locationUpdated);
        this.hub.on('transactionUpdated', this.transactionUpdated);
        this.hub.onStateChanged(this.stateChanged);
        this.hub.start();

    }

    locationChangeSuccess = () => {
        var search = this.$location.search();
        console.debug('locationChangeSuccess', search);
        this.options.fromDate = moment(search.fromDate).local().toDate() || this.options.fromDate;
        this.options.toDate = moment(search.toDate).local().toDate() || this.options.toDate;
        this.options.locationId = search.locationId || this.options.locationId;

        this.refresh();
    };

    optionsChanged = () => {
        console.debug('optionsChanged', this.options);
        if (this.options.fromDate)
            this.$location.search('fromDate', this.options.fromDate.toJSON());
        if (this.options.toDate)
            this.$location.search('toDate', this.options.toDate.toJSON());
        this.$location.search('locationId', this.options.locationId);

        this.refresh();
    };

    refresh = this.$debounce(() => {
        console.debug('refresh', this.options);
        if (this.hub.connection.state !== SignalR.ConnectionState.Connected)
            return;
        this.$spinner.show();
        this.data = new Array<TransactionDto>();

        this.hub.invoke('ReportPayment', this.options)
            .catch((e) => console.warn(e))
            .finally(() => this.$spinner.hide());
    }, 500, false);

    locationUpdated = (location: LocationDto) => {
        this.locations.merge(location, x => x.id);
    };

    transactionUpdated = (payment: TransactionDto) => {
        this.data.merge(payment, x => x.id);
        this.transactionTypes.merge(payment.transactionType, x => x.id);
        this.transactionSort();
    };

    transactionSortInner = () => {
        console.log('transactionSort');
        var data = this.$filter('orderBy')(this.data, ['txnDateOnly', 'location.name', 'user.username', 'transactionType.name'], true)
            .filter(d => this.transactionTypesSelected.length == 0 || this.transactionTypesSelected.some(t => t.id == d.transactionType.id))
            .filter(d => this.locationsSelected.length == 0 || this.locationsSelected.some(t => t.id == d.location.id))
        ;

        var txnDateCount = 0;
        var txnDateAmount = 0;
        var locationCount = 0;
        var locationAmount = 0;
        var userCount = 0;
        var userAmount = 0;
        var txnTypeCount = 0;
        var txnTypeAmount = 0;

        data.forEach((current: TransactionDto, index: number, array: TransactionDto[]) => {
            var next = array[index + 1];
            console.debug(current, next);

            txnDateCount++;
            txnDateAmount += current.amount;
            current.txnDateShow = !next || next.txnDateOnly.getTime() !== current.txnDateOnly.getTime();
            if (current.txnDateShow) {
                current.txnDateCount = txnDateCount;
                current.txnDateAmount = txnDateAmount;
                txnDateCount = 0;
                txnDateAmount = 0;
            }

            locationCount++;
            locationAmount += current.amount;
            current.locationShow = !next || next.location.id !== current.location.id;
            if (current.txnDateShow || current.locationShow) {
                current.locationShow = true;
                current.locationCount = locationCount;
                current.locationAmount = locationAmount;
                locationCount = 0;
                locationAmount = 0;
            }

            userCount++;
            userAmount += current.amount;
            current.userShow = !next || next.user.id !== current.user.id;
            if (current.txnDateShow || current.locationShow || current.userShow) {
                current.userShow = true;
                current.userCount = userCount;
                current.userAmount = userAmount;
                userCount = 0;
                userAmount = 0;
            }

            txnTypeCount++;
            txnTypeAmount += current.amount;
            current.txnTypeShow = !next || next.transactionType.id !== current.transactionType.id;
            if (current.txnDateShow || current.locationShow || current.userShow || current.txnTypeShow) {
                current.txnTypeShow = true;
                current.txnTypeCount = txnTypeCount;
                current.txnTypeAmount = txnTypeAmount;
                txnTypeCount = 0;
                txnTypeAmount = 0;
            }

        });
        data.reverse();
        this.dataDisplay = data;
    };

    transactionSort = this.$debounce(this.transactionSortInner, 1000, false);

    locationsSelectAll = () => {
        this.locationsSelected = this.locations.slice();
    };

    transactionTypesSelectAll = () => {
        this.transactionTypesSelected = this.transactionTypes.slice();
    };

    stateChanged = (change: SignalR.StateChanged) => {
        switch (change.newState) {
            case SignalR.ConnectionState.Connected:
                this.hub.invoke('Location');
                this.refresh();
                break;
        }
    }
}
