import * as angular from 'angular';
import {StateProvider} from '@uirouter/angularjs';
import SignOutStudentsController from "./SignOutStudentsController";
import MyStudentsController from "./MyStudentsController";
import RecordsController from "./RecordsController";
import AssignController from "./AssignController";
import LogController from "./LogController";

class Routes {
    constructor($stateProvider: StateProvider) {
        $stateProvider
            .state('inCar', {abstract: true})
            .state('inCar.signOut', {
                url: '/inCar/signOut',
                template: require('./SignOutStudents.html'),
                controller: SignOutStudentsController,
                controllerAs: 'vm',
                data: {title: 'Sign Out Students'}
            })
            .state('inCar.records', {
                url: '/inCar/records?:studentId',
                template: require('./Records.html'),
                params: {studentId: {dynamic: false}},
                controller: RecordsController,
                controllerAs: 'vm',
                data: {title: 'In-Car Records'}
            })
            .state('inCar.myStudents', {
                url: '/inCar/myStudents?userId',
                params: {studentId: {dynamic: true}},
                template: require('./MyStudents.html'),
                controller: MyStudentsController,
                controllerAs: 'vm',
                data: {title: 'My Students'}
            })
            .state('inCar.assign', {
                url: '/inCar/assign/:studentId',
                template: require('./Assign.html'),
                controller: AssignController,
                controllerAs: 'vm',
                data: {title: 'Assign Instructor'}
            })

            .state('inCar.log', {
                url: '/inCar/log?userId&month',
                params: {userId: {dynamic: true}, month: {dynamic: true}},
                template: require('./Log.html'),
                controller: LogController,
                controllerAs: 'vm',
                data: {title: 'Instructor Logs'}
            })
        ;
    }
}

export default angular.module('app.inCar', [])
    .config(Routes)
    .name;
