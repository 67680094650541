// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html




// Myers.StudentManagementSystem.Data.Enum.AsteriskCallBackStatus
export enum AsteriskCallBackStatus {
    
    NotRequired = 0,
    Waiting = 1,
    Completed = 2,
    CustomerCalledBack = 4,
    CallingNow = 5,
    NoAnswerLeftMsg = 6
}
