import * as angular from 'angular';
import {DataHubService} from "../modules/DataHubModule";
import {SpinnerService} from "../modules/SpinnerModule";
import {UserDto} from "../model/UserDto";
import {StudentTypeDto} from "../model/StudentTypeDto";
import {Ng1Controller, TargetState, Transition, StateService} from "@uirouter/angularjs";

export default class EditController implements Ng1Controller {
    user: UserDto;
    studentTypes: StudentTypeDto[] = [];
    error;
    private roles: string[];
    private d1: angular.IDeferred<void>;
    private d2: angular.IDeferred<void>;
    private d3: () => void;

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        this.d1.resolve();
        this.d2.resolve();
        this.d3();
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(
        private $dataHub: DataHubService,
        private $spinner: SpinnerService,
        private $state: StateService,
        private $stateParams,
    ) {

        this.d1 = $dataHub.onUserUpdated(e => {
            this.user = e;
        });

        this.d2 = $dataHub.bindStudentTypeUpdated(() => this.studentTypes);

        this.d3 = $dataHub.onConnected(this.refresh);
    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;
        this.$dataHub.roleList()
            .then(roles => this.roles = roles);
        this.$dataHub.studentTypeList();
        let userId = this.$stateParams.id;
        if (userId) {
            this.$spinner.show();
            this.$dataHub.userGet(this.$stateParams.id)
                .finally(() => this.$spinner.hide());
        } else if (!this.user) {
            this.user = new UserDto();
            this.user.active = true;
        }
    }


    save = () => {
        this.$spinner.show('Saving');
        this.$dataHub.userUpdate(this.user)
            .then(result => {
                this.$state.go('^.view', {id: result.id});
            })
            .catch(e => this.error = e)
            .finally(() => this.$spinner.hide());
    };


};
