import {Ng1Controller, StateService, TargetState, Transition} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {GiftCertificateTemplateDto} from "../model/GiftCertificateTemplateDto";
import {ngStorage} from "ngstorage";

export default class TemplateListController implements Ng1Controller {

    private templates: GiftCertificateTemplateDto[] = [];

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(private $dataHub: DataHubService,
                private $state: StateService,
                private $stateParams: any) {
        $dataHub.connected(this.refresh);
        $dataHub.bindAllGiftCertificateTemplate(() => this.templates)
    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;
        this.$dataHub.giftCertificateTemplateSubscribe();
    }

}
