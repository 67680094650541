import * as angular from 'angular';
import {ngStorage} from "ngstorage";
import {DataHub} from "./DataHub";
import {IQService, IRootScopeService, ITimeoutService, IWindowService} from "angular";
import {SpinnerService} from "./SpinnerModule";

export class DataHubService extends DataHub {
    private serverVersion: string;

    constructor($timeout: ITimeoutService,
                $rootScope: IRootScopeService,
                $q: IQService,
                $window: IWindowService,
                $spinner: SpinnerService,
                private $localStorage: ngStorage.StorageService,
                private toaster: any) {
        super($q, $rootScope, $timeout, $window, $spinner);
        this.onServerVersion(this.versionCheck);

        this.onStateChanged(() => {
            this.browserGuidCheck();
        });

        $rootScope.$watch(() => this.$localStorage.loggedIn, (loggedIn) => {
            console.log('DataHub loggedIn:', loggedIn);
            if(loggedIn)
                this.start().then(() => console.debug('started'));
            else
                this.stop();
        });
    }

    // if the server version has changed, the client version has also
    // warn user to reload
    private versionCheck = newVersion => {
        console.debug('Server Version:', newVersion);
        if (this.serverVersion && this.serverVersion != newVersion) {
            this.toaster.info('System Updated',
                'Click here to reload - save any work first!',
                0, null,
                () => window.location.reload(),
                null,
                true);

        }
        this.serverVersion = newVersion;
    };

    private browserGuidCheck = () => {
        if (this.$localStorage.browserGuid) return;
        this.newGuid().then(guid => this.$localStorage.browserGuid = guid);
    };


    defaultErrorHandler = (e: Error) => {
        console.error(e);
        this.toaster.error(e.message);
    };


    // convenience fields for legacy code
    get client() {return this;}
    get server() {return this;}
    connected = this.onConnected;

}

export default angular.module('services.dataHub', [])
    .service('$dataHub', DataHubService)
    .name;
