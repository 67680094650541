import * as angular from 'angular';

function bottomNav() {
    return {
        template: require('./bottomNav.html'),
        restrict: 'E',
        transclude: true,
        replace: true,
    };
};

function bottomNavLeft() {
    return {
        template: require('./bottomNavLeft.html'),
        replace: true,
        transclude: true,
        restrict: 'E',
    };
};


function bottomNavRight() {
    return {
        template: require('./bottomNavRight.html'),
        replace: true,
        transclude: true,
        restrict: 'E',
    };
};

export default angular.module('directives.bottomNav', [])
    .directive('bottomNav', bottomNav)
    .directive('bottomNavLeft', bottomNavLeft)
    .directive('bottomNavRight', bottomNavRight)
    .name;
