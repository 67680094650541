import * as angular from 'angular';
import {ConfigService} from "../modules/ConfigModule";
import {StateService} from '@uirouter/angularjs';
import {UserInfoDto} from "../model/UserInfoDto";
import {RoleName} from "../model/RoleName";

class MenuItem {
    constructor(public id: string, public name: string, public faIcon: string, public url: string, public visible: Boolean = true) {
    }
}

class MenuGroup {
    constructor(public name: string, public  items: MenuItem[], public  panelContext: string = "primary", public  visible: Boolean = true) {
    }
}

export class MenuService {
    groups: MenuGroup[];

    constructor(private $config: ConfigService,
                $rootScope: ng.IRootScopeService,
                private $state: StateService) {
        $rootScope.$watch(() => $config.userInfo, () => this.refreshMenu($config.userInfo))
    }

    refreshMenu = (userInfo: UserInfoDto) => {
        if (!userInfo) {
            this.groups = [];
            return;
        }

        let href = this.$state.href;

        let isAdmin = userInfo.isAdministrator;
        let isOffice = userInfo.isOffice || isAdmin;
        let isClassroom = userInfo.isClassroomInstructor || isAdmin;
        let isInCar = userInfo.isInCarInstructor || isAdmin;
        let isDeveloper = userInfo.isDeveloper;

        this.groups = [
            new MenuGroup('Students', [
                new MenuItem('studentAdd', 'Add Student', 'fa fa-user-plus', href('student.add'), isClassroom || isOffice),
                new MenuItem('studentSearch', 'Search', 'fa fa-search', href('student.search'), isClassroom || isOffice),
                new MenuItem('preregList', 'Pre-Registrations', 'fa fa-clipboard', href('prereg.list'), isClassroom || isOffice),
                new MenuItem('giftCertificateList', 'Gift Certs', 'fad fa-gift-card', href('giftCertificate.list'), isClassroom || isOffice),
            ]),

            new MenuGroup('Classroom', [
                new MenuItem('virtual', 'Virtual', 'fas fa-camera', href('classroomVirtual'), isClassroom || isOffice),
                new MenuItem('contract', 'Contracts', 'fas fa-file-signature', href('classroom.contract'), isClassroom || isOffice),
                new MenuItem('attendance', 'Attendance', 'fas fa-users-class', href('classroom.attendance'), isClassroom || isOffice),
                new MenuItem('testing', 'Testing', 'fas fa-star', href('classroom.testing'), isClassroom || isOffice),
                new MenuItem('calendar', 'Calendar', 'fas fa-calendar', href('calendar')),
                new MenuItem('noShow', 'No-Shows', 'fas fa-snooze', href('noShow'), isOffice),
                new MenuItem('studentStuck', 'Stuck', 'fas fa-user-clock', href('student.stuck'), isOffice),
                new MenuItem('addClasses', 'Add Classes', 'fas fa-calendar-plus', href('addClasses'), userInfo.isClassroomAdmin),
                new MenuItem('manageClasses', 'Manage Classes', 'fas fa-watch', href('manageClasses'), userInfo.isClassroomAdmin),
                new MenuItem('paymentReport', 'Payment Summary', 'fas fa-file-invoice-dollar', href('report.payment'), isClassroom),
                new MenuItem('examManager', 'Exam Proctor', 'fas fa-users-class', href('student.examManager'), userInfo.isExamProctor),
                new MenuItem('examAdmin', 'Exam Admin', 'fas fa-users-class', href('student.examAdmin'), userInfo.isExamAdmin),
            ], 'primary'),

            new MenuGroup('In Car', [
                new MenuItem('myStudents', 'My Students', 'fas fa-directions', href('inCar.myStudents'), isInCar),
                new MenuItem('inCarAvailableList', 'Sign Out Students', 'fas fa-clipboard-user', href('inCar.signOut'), isInCar || isOffice),
                new MenuItem('inCarLog', 'Instructor Log', 'fas fa-clipboard-list', href('inCar.log'), isInCar || isOffice),
                new MenuItem('studentRelease', 'Release-Ready', 'far fa-traffic-light-go text-success', href('admin.release'), userInfo.isStudentRelease),
                new MenuItem('studentReleaseNotReady', 'Release-Not Ready', 'far fa-traffic-light-stop text-danger', href('admin.releaseNotReady'), isOffice),
            ], 'primary'),

            new MenuGroup('Office', [
                new MenuItem('callManager', 'Call Manager', 'fad fa-phone-office', href('asterisk.call')),
                new MenuItem('queueMemberReport', 'Queue Report', 'fad fa-chart-pie', href('asterisk.queueMemberReport')),
                new MenuItem('paymentReport', 'Payment Summary', 'fas fa-file-invoice-dollar', href('report.payment')),
                new MenuItem('signupReport', 'Student Signups', 'fas fa-analytics', href('report.signup')),
                new MenuItem('rosterReport', 'Roster', 'fas fa-clipboard-user', href('report.roster')),
                new MenuItem('studentsWithoutContract', 'Needs Contract', 'fas fa-file-signature', href('student.withoutSignedContract')),
                new MenuItem('studentsWithoutTest', 'Needs Test', 'fas fa-ballot-check', href('student.withoutPassingTest')),
                new MenuItem('certificateList', 'Print Certs', 'fas fa-file-certificate', href('certificate.list')),
            ], 'primary', isOffice),

            new MenuGroup('Admin', [
                new MenuItem('userList', 'User List', 'fas fa-users', href('user.list')),
                new MenuItem('instructorDocumentList', 'Instructor Docs', 'fad fa-file-pdf', href('document.instructorList')),
                new MenuItem('locationSetup', 'Location Set', 'fas fa-school', href('admin.locationToken')),
                new MenuItem('printerSetup', 'Printer Setup', 'fas fa-print', href('admin.printerSetup')),
                new MenuItem('priceManager', 'Price Manager', 'glyphicon glyphicon-tags', href('admin.priceManager')),
                new MenuItem('documentUpload', 'Doc Upload', 'fad fa-file-upload', href('document.upload')),
                new MenuItem('documentLink', 'Doc Link', 'fad fa-link', href('document.link', {id: null})),
                new MenuItem('zoomLink', 'Zoom Link', 'fas fa-link', href('admin.zoomLink')),
                new MenuItem('videoList', 'Video List', 'fas fa-video', href('admin.videoList')),
            ], 'warning', isAdmin),


            new MenuGroup('Developer', [
                new MenuItem('logs', 'Logs', 'fas fa-file-exclamation', href('developer.log')),
                new MenuItem('exceptions', 'Exceptions', 'fas fa-exclamation-triangle', href('developer.exception')),
                new MenuItem('changelogs', 'Change Logs', 'fas fa-exclamation-triangle', href('changeLog.list')),
                new MenuItem('debug', 'Debug', 'fas fa-bug', href('developer.debug')),
                new MenuItem('userActivity', 'User Activity', 'fas fa-users', href('developer.userActivity')),
                new MenuItem('certificateRequests', 'Cert Reqs', 'fad fa-file-certificate', href('developer.certificateRequests')),
                new MenuItem('contractList', 'Contracts', 'fas fa-file-contract', href('contract.list')),
                new MenuItem('hangfire', 'Hangfire', 'fas fa-fire', this.$config.apiBase + '../hangfire'),
            ], 'danger', isDeveloper)
        ];
    }
}

export default angular.module('services.menu', [])
    .service('$menu', MenuService)
    .name;
