import {UserInfoDto} from '../model/UserInfoDto';
import {ConfigService} from '../modules/ConfigModule';
import {ngStorage} from 'ngStorage';
import {MenuService} from "../modules/MenuModule";
import {DataHubService} from "../modules/DataHubModule";
import {popWindow} from "../directive/Popout";
import {ClassDto} from "../model/ClassDto";
import {UserActivityDto} from "../model/UserActivityDto";
import {CertificateTypeDto} from "../model/CertificateTypeDto";
import {Ng1Controller, TargetState, Transition} from "@uirouter/angularjs";
import {PrinterDto} from "../model/PrinterDto";
import {ChangeLogDto} from "../model/ChangeLogDto";

export default class HomeController implements Ng1Controller {
    userInfo: UserInfoDto;
    serverVersion: string;
    clientVersion: string;
    private scheduleUser: ClassDto[] = [];
    private scheduleToday: ClassDto[] = [];
    private userActivity: UserActivityDto[] = [];
    private certificateTypes: CertificateTypeDto[] = [];
    private printers: PrinterDto[] = [];
    private changeLogs: ChangeLogDto[];

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(private $dataHub: DataHubService,
                private $config: ConfigService,
                private $menu: MenuService,
                private $state,
                private $http: ng.IHttpService,
                private $sessionStorage: ngStorage.StorageService,
                private $window: ng.IWindowService) {

        this.clientVersion = $window.version;
        $dataHub.client.onServerVersion(v => this.serverVersion = v);
        $config.checkLogin().then(r => this.userInfo = r);

        $dataHub.client.bindAllClassScheduleToday(() => this.scheduleToday);
        $dataHub.client.bindAllClassScheduleUser(() => this.scheduleUser);
        $dataHub.client.bindAllUserActivity(() => this.userActivity);
        $dataHub.client.bindAllCertificateType(() => this.certificateTypes);
        $dataHub.client.bindAllPrinter(() => this.printers);

        $dataHub.connected(this.refresh)
    }

    logout = () => this.$config.logout();

    studentSelected = (student) => {
        console.debug('selected', student);
        var url = this.$state.href('student.view', {studentId: student.id});
        popWindow(url, student.id);
    };

    refresh = () => {
        if (!this.$dataHub.isConnected) return;
        this.$dataHub.server.classScheduleTodaySubscribe().catch(this.$dataHub.defaultErrorHandler);
        this.$dataHub.server.classScheduleUserSubscribe(0).catch(this.$dataHub.defaultErrorHandler);
        this.$dataHub.server.userActivitySubscribe();
        this.$dataHub.server.certificateTypeSubscribe();
        if (this.$config.userInfo.isAdministrator)
            this.$dataHub.server.printerSubscribe();

        this.changeLogRefresh();
    }

    changeLogRefresh = () => {
        this.$dataHub.changeLogGetNew()
            .then(r => this.changeLogs = r);
    }
    changeLogAcknowledge = (dto: ChangeLogDto) => {
        this.$dataHub.changeLogAcknowledge(dto.id)
            .then(() => {
                this.changeLogs.remove(dto, x => x.id);
                if (this.changeLogs.length == 0)
                    this.changeLogRefresh();
            });
    }

}
