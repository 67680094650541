import {Ng1Controller, Transition, TargetState, StateService} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {UserStorageService} from "../modules/UserStorageModule";

export default class QueueMemberReportController implements Ng1Controller {
    private report: any;

    $onInit(): void {
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(private $dataHub: DataHubService,
                private $userStorage: UserStorageService,
                private $state: StateService,
                private $stateParams: any) {
        $dataHub.connected(this.refresh);
    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;
        if (!this.options.fromDate || !this.options.toDate) return;

        this.$dataHub.asteriskQueueMemberReport(this.options.fromDate, this.options.toDate)
            .then(x => this.report = x);
    };

    get options() {
        return this.$userStorage.queueMemberReportSettings || (this.$userStorage.queueMemberReportSettings = {});
    }
}
