// Generated by DataHubGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html



import {SpinnerService} from './SpinnerModule';
import {HubProxy} from './HubProxyModule';
import {IQService, IRootScopeService, ITimeoutService, IWindowService} from "angular";
import { StudentPortalCertificateRequestDto } from '../model/StudentPortalCertificateRequestDto';
import { FormDto } from '../model/FormDto';
import { StudentPortalSessionDto } from '../model/StudentPortalSessionDto';
import { StudentPortalStudentDto } from '../model/StudentPortalStudentDto';
import { StudentPhotoUploadDto } from '../model/StudentPhotoUploadDto';
import { StudentPortalClassInfoDto } from '../model/StudentPortalClassInfoDto';
import { UserAgentDto } from '../model/UserAgentDto';
import { VirtualClassParticipantMessageDto } from '../model/VirtualClassParticipantMessageDto';
import { VirtualClassParticipantDto } from '../model/VirtualClassParticipantDto';
import { VirtualClassDto } from '../model/VirtualClassDto';
import { VirtualClassParticipantPublicDto } from '../model/VirtualClassParticipantPublicDto';

export class VirtualClassHub extends HubProxy {

    constructor($q: IQService,
                $rootScope: IRootScopeService,
                $timeout: ITimeoutService,
                $window: IWindowService,
                $spinner: SpinnerService) {
        super($q, $rootScope, $timeout, $window, $spinner, 'VirtualClassHub', false);
    }

    // server methods

    
    attendanceSignatureRequired = () => {
        return this.invoke<boolean>('AttendanceSignatureRequired', );
    }
    
    attendanceSignatureSubmit = (signature: string) => {
        return this.invoke<void>('AttendanceSignatureSubmit', signature);
    }
    
    certificateRequest = () => {
        return this.invoke<StudentPortalCertificateRequestDto>('CertificateRequest', );
    }
    
    certificateRequestSubmit = (certificateRequestDto: StudentPortalCertificateRequestDto) => {
        return this.invoke<void>('CertificateRequestSubmit', certificateRequestDto);
    }
    
    formGet = () => {
        return this.invoke<FormDto>('FormGet', );
    }
    
    formSubmit = (formDto: FormDto) => {
        return this.invoke<FormDto>('FormSubmit', formDto);
    }
    
    getDemoSession = () => {
        return this.invoke<StudentPortalSessionDto>('GetDemoSession', );
    }
    
    getDemoStudent = (dto: StudentPortalSessionDto) => {
        return this.invoke<StudentPortalStudentDto>('GetDemoStudent', dto);
    }
    
    join = (session: StudentPortalSessionDto, student: StudentPortalStudentDto, classDto: StudentPortalClassInfoDto) => {
        return this.invoke<void>('Join', session, student, classDto);
    }
    
    leave = (reason: string) => {
        return this.invoke<void>('Leave', reason);
    }
    
    log = (severity: string, messages: string[]) => {
        return this.invoke<void>('Log', severity, messages);
    }
    
    messageDelivered = (messageId: number) => {
        return this.invoke<void>('MessageDelivered', messageId);
    }
    
    messageRead = (messageId: number) => {
        return this.invoke<void>('MessageRead', messageId);
    }
    
    messageSubmit = (dto: VirtualClassParticipantMessageDto) => {
        return this.invoke<void>('MessageSubmit', dto);
    }
    
    muteToggle = () => {
        return this.invoke<void>('MuteToggle', );
    }
    
    photoRequired = () => {
        return this.invoke<StudentPhotoUploadDto[]>('PhotoRequired', );
    }
    
    photoUpload = (photoDto: StudentPhotoUploadDto) => {
        return this.invoke<void>('PhotoUpload', photoDto);
    }
    
    schedule = (session: StudentPortalSessionDto, student: StudentPortalStudentDto) => {
        return this.invoke<StudentPortalClassInfoDto[]>('Schedule', session, student);
    }
    
    update = (participant: VirtualClassParticipantDto) => {
        return this.invoke<void>('Update', participant);
    }
    
    userAgentCheck = (originalUserAgent: string) => {
        return this.invoke<UserAgentDto>('UserAgentCheck', originalUserAgent);
    }
    


    // client methods
    
    /**
    * Wires up a callback to be invoked when ClassRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onClassRemoved = (callback: (arg: VirtualClassDto) => void) => {
        return this.on('ClassRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when ClassUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onClassUpdated = (callback: (arg: VirtualClassDto) => void) => {
        return this.on('ClassUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when MessageRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onMessageRemoved = (callback: (arg: VirtualClassParticipantMessageDto) => void) => {
        return this.on('MessageRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when MessageUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onMessageUpdated = (callback: (arg: VirtualClassParticipantMessageDto) => void) => {
        return this.on('MessageUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when ParticipantRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onParticipantRemoved = (callback: (arg: VirtualClassParticipantPublicDto) => void) => {
        return this.on('ParticipantRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when ParticipantSelfRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onParticipantSelfRemoved = (callback: (virtualClassParticipantDto: VirtualClassParticipantDto) => void) => {
        return this.on('ParticipantSelfRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when ParticipantSelfUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onParticipantSelfUpdated = (callback: (virtualClassParticipantDto: VirtualClassParticipantDto) => void) => {
        return this.on('ParticipantSelfUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when ParticipantUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onParticipantUpdated = (callback: (arg: VirtualClassParticipantPublicDto) => void) => {
        return this.on('ParticipantUpdated', callback);
    }
    

    // convenience methods to bind updates/removes to an array
    
    /**
    * Bind ClassUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of VirtualClassDto to update
    */
    bindClassUpdated = (arrayFunc: () => Array<VirtualClassDto>) => {
        return this.on('ClassUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind MessageUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of VirtualClassParticipantMessageDto to update
    */
    bindMessageUpdated = (arrayFunc: () => Array<VirtualClassParticipantMessageDto>) => {
        return this.on('MessageUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind ParticipantSelfUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of VirtualClassParticipantDto to update
    */
    bindParticipantSelfUpdated = (arrayFunc: () => Array<VirtualClassParticipantDto>) => {
        return this.on('ParticipantSelfUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind ParticipantUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of VirtualClassParticipantPublicDto to update
    */
    bindParticipantUpdated = (arrayFunc: () => Array<VirtualClassParticipantPublicDto>) => {
        return this.on('ParticipantUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    

    
    /**
    * Bind ClassRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of VirtualClassDto to update
    */
    bindClassRemoved = (arrayFunc: () => Array<VirtualClassDto>) => {
        return this.on('ClassRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind MessageRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of VirtualClassParticipantMessageDto to update
    */
    bindMessageRemoved = (arrayFunc: () => Array<VirtualClassParticipantMessageDto>) => {
        return this.on('MessageRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind ParticipantRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of VirtualClassParticipantPublicDto to update
    */
    bindParticipantRemoved = (arrayFunc: () => Array<VirtualClassParticipantPublicDto>) => {
        return this.on('ParticipantRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind ParticipantSelfRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of VirtualClassParticipantDto to update
    */
    bindParticipantSelfRemoved = (arrayFunc: () => Array<VirtualClassParticipantDto>) => {
        return this.on('ParticipantSelfRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    

    
    bindAllClass = (arrayFunc: () => Array<VirtualClassDto>) => {
        this.bindClassRemoved(arrayFunc);
        this.bindClassUpdated(arrayFunc);
        
    }
    
    bindAllMessage = (arrayFunc: () => Array<VirtualClassParticipantMessageDto>) => {
        this.bindMessageRemoved(arrayFunc);
        this.bindMessageUpdated(arrayFunc);
        
    }
    
    bindAllParticipant = (arrayFunc: () => Array<VirtualClassParticipantPublicDto>) => {
        this.bindParticipantRemoved(arrayFunc);
        this.bindParticipantUpdated(arrayFunc);
        
    }
    
    bindAllParticipantSelf = (arrayFunc: () => Array<VirtualClassParticipantDto>) => {
        this.bindParticipantSelfRemoved(arrayFunc);
        this.bindParticipantSelfUpdated(arrayFunc);
        
    }
    



}
