import * as angular from "angular";

function virtualClassType() {
    function getFieldModelName(attrs) {
        var objectAndField = attrs.ngModel;
        var names = objectAndField.split('.');
        return names[names.length - 1];
    }

    return {
        template: require('./VirtualClassType.html'),
        restrict: 'E',
        require: ['ngModel'],
        scope: {
            title: '@',
            placeholder:'@',
            ngModel: '=',
            ngDisabled: '=',
        },
        link: (scope, element, attrs) => {
            scope.name = getFieldModelName(attrs);
        },
    };
}

export default angular.module('directives.virtualClassType', [])
    .directive('virtualClassType', virtualClassType)
    .name;
