// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html

import { MessageSender } from './MessageSender';
import { UserDisplayDto } from './UserDisplayDto';

// Myers.StudentManagementSystem.Data.Models.VirtualClassParticipantMessageDto
export class VirtualClassParticipantMessageDto {
    public id: number = 0;
    public participantId: number = 0;
    public timestamp: Date = new Date(0);
    public from: string = null;
    public sender: MessageSender = null;
    public userId: number = 0;
    public read: boolean = false;
    public readTime: Date = null;
    public readByUser: UserDisplayDto = null;
    public delivered: boolean = false;
    public deliveredTime: Date = null;
    public message: string = null;
}


    



