// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html

import { StudentAttendanceDto } from './StudentAttendanceDto';
import { ClassDto } from './ClassDto';
import { UserDisplayDto } from './UserDisplayDto';
import { AttendanceStatus } from './AttendanceStatus';
import { AttendanceVirtualLogDto } from './AttendanceVirtualLogDto';

// Myers.StudentManagementSystem.Data.Models.AttendanceRecordDto
export class AttendanceRecordDto {
    public id: number = 0;
    public postDate: Date = null;
    public postTime: string = null;
    public student: StudentAttendanceDto = null;
    public class: ClassDto = null;
    public user: UserDisplayDto = null;
    public attendanceStatus: AttendanceStatus = null;
    public attendanceStatusString: string = null;
    public virtualLogs: AttendanceVirtualLogDto[] = [];
}


    



