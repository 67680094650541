import {Ng1Controller, Transition, TargetState, StateService} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {SpinnerService} from "../modules/SpinnerModule";
import {TransactionDto} from "../model/TransactionDto";
import {IFormController} from "angular";
import {TransactionRefundRequestDto} from "../model/TransactionRefundRequestDto";

export default class RefundController implements Ng1Controller {
    private txnId: number;
    private transaction: TransactionDto;
    private model = new TransactionRefundRequestDto();
    private dispose1: angular.IDeferred<void>;
    private result: TransactionDto;

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        this.dispose1.resolve();
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
        this.parseValues(newValues);
    }

    constructor(private $dataHub: DataHubService,
                private $spinner: SpinnerService,
                private toaster: any,
                private $state: StateService,
                private $stateParams: any) {

        this.model["refundAmount"] = null;

        this.dispose1 = this.$dataHub.onTransactionUpdated(r => {
            if (r.id != this.txnId) return;
            this.transaction = r;
            this.model.transactionId = this.transaction.id;
        })

        $dataHub.connected(this.refresh);
        this.parseValues($stateParams);
    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;

        if (!this.txnId) return;

        this.$spinner.show('Loading Transaction');
        this.$dataHub.transactionSubscribe(this.txnId, false)
            .finally(() => this.$spinner.hide());

    }


    parseValues(paramValues) {
        if (paramValues.txnId) this.txnId = paramValues.txnId;
    }

    submit = (form: IFormController) => {
        if (form.$invalid) return;
        this.$spinner.show('Processing');
        this.$dataHub.transactionRefund(this.model)
            .then(result => {
                this.result = result;
                if (this.result.resultAccepted)
                    this.$state.go('^.view', {txnId: this.result.id});
            })
            .finally(() => this.$spinner.hide());
    }
}
