import * as angular from 'angular';
import {StateProvider} from '@uirouter/angularjs';
import ListController from "./ListController";

class Routes {
    static $inject = ['$stateProvider'];

    constructor($stateProvider: StateProvider) {
        $stateProvider
            .state('contract', {
                abstract: true,
                url:'/contract'
            })

            .state('contract.list', {
                url: '/',
                template: require('./List.html'),
                controller: ListController,
                controllerAs: 'vm',
                data: {title: 'Contract List'}
            })



    }
}

export default angular.module('app.contract', [])
    .config(Routes)
    .name;
