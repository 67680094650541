import {Ng1Controller, Transition, TargetState, StateService} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {HubProxy, HubProxyFactory} from "../modules/HubProxyModule";
import {UserStorageService} from "../modules/UserStorageModule";
import * as angular from "angular";

export default class DebugController implements Ng1Controller {
    private error: any;
    private asteriskProxy: HubProxy;
    private events = [];
    private eventColumns = [];
    private peers = [];
    private originateResponse: any;
    private calls = [];

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(private $dataHub: DataHubService,
                private hubProxyFactory: HubProxyFactory,
                private $userStorage: UserStorageService,
                private $state: StateService, private $stateParams: any) {
        $dataHub.connected(this.refresh);

        this.asteriskProxy = hubProxyFactory.create('AsteriskHub', true);
        this.asteriskProxy.on('peerStatus', e => this.peers.merge(e, x => x.Peer));
        this.asteriskProxy.on('asteriskEvent', e => {
            var keys = Object.keys(e);
            for(var key of keys) {
                if(this.eventColumns.some(x => x === key)) continue;
                this.eventColumns.push(key);
            }
            this.events.unshift(e);
            this.parseEvent(e);
        });

        this.asteriskProxy.onStateChanged(this.stateChanged);
        this.asteriskProxy.start();


    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;
        // load data or something
    }

    private stateChanged() {

    }


    originate = (exten, number) => {
        this.asteriskProxy.invoke('originate', exten, number)
            .then(r => this.originateResponse = r);
    }


    eventsToIgnore = ["PeerStatus","Registry","NewExten"];

    parseEvent = (event) => {

        if(this.eventsToIgnore.some(x=>x==event.Event))return;
        if(!event.Channel && !event.Channel1 && !event.Channel2) return;
        var call = this.calls.filter(x=>x.channel == event.Channel)[0];
        if(!call && event.Channel) {
            call = {
                channel: event.Channel,
            }
            this.calls.push(call);
        }
        switch (event.Event) {
            case "Newchannel":
                call.state = event.State;
                break;
            case "Status":
                call.state = event.State;
                call.callerId = event.CallerID;
                break;
        }
    }
}
