// Generated by DataHubGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html



import {SpinnerService} from './SpinnerModule';
import {HubProxy} from './HubProxyModule';
import {IQService, IRootScopeService, ITimeoutService, IWindowService} from "angular";
import { AsteriskQueueMemberReportDto } from '../model/AsteriskQueueMemberReportDto';
import { AttendanceOptionsDto } from '../model/AttendanceOptionsDto';
import { CertificateRequestDto } from '../model/CertificateRequestDto';
import { ChangeLogDto } from '../model/ChangeLogDto';
import { ClassAddResultDto } from '../model/ClassAddResultDto';
import { ClassDto } from '../model/ClassDto';
import { StudentStuckDto } from '../model/StudentStuckDto';
import { ClassWarningsDto } from '../model/ClassWarningsDto';
import { ClassInfoDto } from '../model/ClassInfoDto';
import { MyClassesDto } from '../model/MyClassesDto';
import { ClassTypeDto } from '../model/ClassTypeDto';
import { FeedbackStatusDto } from '../model/FeedbackStatusDto';
import { FeedbackTypeDto } from '../model/FeedbackTypeDto';
import { GiftCertificateDto } from '../model/GiftCertificateDto';
import { TransactionDto } from '../model/TransactionDto';
import { GiftCertificateTemplateDto } from '../model/GiftCertificateTemplateDto';
import { LocationTokenDto } from '../model/LocationTokenDto';
import { PrintJobResultDto } from '../model/PrintJobResultDto';
import { PaymentReportOptionsDto } from '../model/PaymentReportOptionsDto';
import { RosterReportDto } from '../model/RosterReportDto';
import { StudentDto } from '../model/StudentDto';
import { StudentDisplayDto } from '../model/StudentDisplayDto';
import { UserDto } from '../model/UserDto';
import { VideoDto } from '../model/VideoDto';
import { FormDisplayDto } from '../model/FormDisplayDto';
import { AttendanceRecordDto } from '../model/AttendanceRecordDto';
import { ZoomMeetingParticipantDto } from '../model/ZoomMeetingParticipantDto';
import { AsteriskCallBackStatus } from '../model/AsteriskCallBackStatus';
import { AsteriskTransferType } from '../model/AsteriskTransferType';
import { AttendanceQueryDto } from '../model/AttendanceQueryDto';
import { CertificateDto } from '../model/CertificateDto';
import { ClassCalendarOptionsDto } from '../model/ClassCalendarOptionsDto';
import { ClassManageQueryDto } from '../model/ClassManageQueryDto';
import { ClassQueryDto } from '../model/ClassQueryDto';
import { ContractFullDto } from '../model/ContractFullDto';
import { DocumentDto } from '../model/DocumentDto';
import { DocumentSearchDto } from '../model/DocumentSearchDto';
import { FeedbackMessageDto } from '../model/FeedbackMessageDto';
import { FeedbackSubmitDto } from '../model/FeedbackSubmitDto';
import { FeedbackDto } from '../model/FeedbackDto';
import { PricingDto } from '../model/PricingDto';
import { PrinterDto } from '../model/PrinterDto';
import { PrintJobRequestDto } from '../model/PrintJobRequestDto';
import { RosterReportQueryDto } from '../model/RosterReportQueryDto';
import { DrivingRecordDto } from '../model/DrivingRecordDto';
import { StudentExamMessageDto } from '../model/StudentExamMessageDto';
import { StudentExamStatus } from '../model/StudentExamStatus';
import { StudentReleaseDto } from '../model/StudentReleaseDto';
import { StudentRestartDto } from '../model/StudentRestartDto';
import { StudentSearchDto } from '../model/StudentSearchDto';
import { StudentWithTestScoresDto } from '../model/StudentWithTestScoresDto';
import { TransactionRefundRequestDto } from '../model/TransactionRefundRequestDto';
import { UserActivityDto } from '../model/UserActivityDto';
import { VirtualClassMessageDto } from '../model/VirtualClassMessageDto';
import { VirtualClassMode } from '../model/VirtualClassMode';
import { VirtualClassParticipantMessageDto } from '../model/VirtualClassParticipantMessageDto';
import { VirtualClassParticipantDto } from '../model/VirtualClassParticipantDto';
import { VirtualClassDto } from '../model/VirtualClassDto';
import { AsteriskCallDto } from '../model/AsteriskCallDto';
import { AsteriskChannelDto } from '../model/AsteriskChannelDto';
import { AsteriskEventDto } from '../model/AsteriskEventDto';
import { AsteriskExtensionDto } from '../model/AsteriskExtensionDto';
import { AsteriskQueueMemberSessionDto } from '../model/AsteriskQueueMemberSessionDto';
import { CertificateTypeDto } from '../model/CertificateTypeDto';
import { ContractDto } from '../model/ContractDto';
import { CountyDto } from '../model/CountyDto';
import { DocumentTypeDto } from '../model/DocumentTypeDto';
import { DrivingRecordTypeDto } from '../model/DrivingRecordTypeDto';
import { GiftCertificateDetailDto } from '../model/GiftCertificateDetailDto';
import { GiftCertificateTypeDto } from '../model/GiftCertificateTypeDto';
import { HighSchoolDto } from '../model/HighSchoolDto';
import { LocationDto } from '../model/LocationDto';
import { LogDto } from '../model/LogDto';
import { MailMessageEventDto } from '../model/MailMessageEventDto';
import { MailMessageDto } from '../model/MailMessageDto';
import { PreRegDto } from '../model/PreRegDto';
import { ReferralHeardFromDto } from '../model/ReferralHeardFromDto';
import { ReferralWhyChoseDto } from '../model/ReferralWhyChoseDto';
import { SignoutRecordDto } from '../model/SignoutRecordDto';
import { StudentContractDto } from '../model/StudentContractDto';
import { StudentContractSummaryDto } from '../model/StudentContractSummaryDto';
import { StudentExamDto } from '../model/StudentExamDto';
import { StudentExamLogDto } from '../model/StudentExamLogDto';
import { StudentExamTranscriptDto } from '../model/StudentExamTranscriptDto';
import { StudentInCarDto } from '../model/StudentInCarDto';
import { StudentLogDto } from '../model/StudentLogDto';
import { StudentNoteDto } from '../model/StudentNoteDto';
import { StudentTypeDto } from '../model/StudentTypeDto';
import { TransactionTypeDto } from '../model/TransactionTypeDto';
import { UserDisplayDto } from '../model/UserDisplayDto';
import { UserStorageDto } from '../model/UserStorageDto';
import { VirtualClassParticipantDetailDto } from '../model/VirtualClassParticipantDetailDto';
import { StudentFormDto } from '../model/StudentFormDto';
import { VirtualClassDetailDto } from '../model/VirtualClassDetailDto';

export class DataHub extends HubProxy {

    constructor($q: IQService,
                $rootScope: IRootScopeService,
                $timeout: ITimeoutService,
                $window: IWindowService,
                $spinner: SpinnerService) {
        super($q, $rootScope, $timeout, $window, $spinner, 'DataHub', false);
    }

    // server methods

    
    asteriskCallBackStatusUpdate = (callId: number, status: AsteriskCallBackStatus) => {
        return this.invoke<void>('AsteriskCallBackStatusUpdate', callId, status);
    }
    
    asteriskCallDetailSubscribe = (callId: number) => {
        return this.invoke<void>('AsteriskCallDetailSubscribe', callId);
    }
    
    asteriskCallHangup = (callId: number) => {
        return this.invoke<void>('AsteriskCallHangup', callId);
    }
    
    asteriskCallLinkStudent = (callId: number, studentId: number) => {
        return this.invoke<void>('AsteriskCallLinkStudent', callId, studentId);
    }
    
    asteriskCallNoteUpdate = (callId: number, note: string) => {
        return this.invoke<void>('AsteriskCallNoteUpdate', callId, note);
    }
    
    asteriskCallOriginate = (ext: string, number: string) => {
        return this.invoke<void>('AsteriskCallOriginate', ext, number);
    }
    
    asteriskCallPark = (callId: number) => {
        return this.invoke<string>('AsteriskCallPark', callId);
    }
    
    asteriskCallSubscribe = (callDate: Date) => {
        return this.invoke<void>('AsteriskCallSubscribe', callDate);
    }
    
    asteriskCallTagAdd = (callId: number, tag: string) => {
        return this.invoke<void>('AsteriskCallTagAdd', callId, tag);
    }
    
    asteriskCallTagRemove = (callId: number, tag: string) => {
        return this.invoke<void>('AsteriskCallTagRemove', callId, tag);
    }
    
    asteriskCallTagSearch = (input: string) => {
        return this.invoke<string[]>('AsteriskCallTagSearch', input);
    }
    
    asteriskCallTransfer = (callId: number, extensionNumber: string, mode: AsteriskTransferType) => {
        return this.invoke<void>('AsteriskCallTransfer', callId, extensionNumber, mode);
    }
    
    asteriskCallUnlink = (callId: number, linkId: number) => {
        return this.invoke<void>('AsteriskCallUnlink', callId, linkId);
    }
    
    asteriskChannelSubscribe = () => {
        return this.invoke<void>('AsteriskChannelSubscribe', );
    }
    
    asteriskChanSpy = (callId: number) => {
        return this.invoke<void>('AsteriskChanSpy', callId);
    }
    
    asteriskExtensionSubscribe = () => {
        return this.invoke<void>('AsteriskExtensionSubscribe', );
    }
    
    asteriskQueueMemberAdd = (ext: string, queue: string) => {
        return this.invoke<void>('AsteriskQueueMemberAdd', ext, queue);
    }
    
    asteriskQueueMemberPause = (ext: string, queue: string, paused: boolean, reason: string) => {
        return this.invoke<void>('AsteriskQueueMemberPause', ext, queue, paused, reason);
    }
    
    asteriskQueueMemberRemove = (ext: string, queue: string) => {
        return this.invoke<void>('AsteriskQueueMemberRemove', ext, queue);
    }
    
    asteriskQueueMemberReport = (start: Date, end: Date) => {
        return this.invoke<AsteriskQueueMemberReportDto[]>('AsteriskQueueMemberReport', start, end);
    }
    
    asteriskQueueMemberSubscribe = () => {
        return this.invoke<void>('AsteriskQueueMemberSubscribe', );
    }
    
    attendanceOptions = () => {
        return this.invoke<AttendanceOptionsDto>('AttendanceOptions', );
    }
    
    attendanceQuery = (query: AttendanceQueryDto) => {
        return this.invoke<void>('AttendanceQuery', query);
    }
    
    attendanceUpdate = (record: AttendanceRecordDto) => {
        return this.invoke<void>('AttendanceUpdate', record);
    }
    
    certificateDuplicate = (certificate: CertificateDto) => {
        return this.invoke<CertificateRequestDto>('CertificateDuplicate', certificate);
    }
    
    certificatePrinted = (requestId: number) => {
        return this.invoke<void>('CertificatePrinted', requestId);
    }
    
    certificateRequestAdd = (dto: CertificateRequestDto) => {
        return this.invoke<CertificateRequestDto>('CertificateRequestAdd', dto);
    }
    
    certificateRequestApprove = (dto: CertificateRequestDto) => {
        return this.invoke<void>('CertificateRequestApprove', dto);
    }
    
    certificateRequestDebugSubscribe = () => {
        return this.invoke<void>('CertificateRequestDebugSubscribe', );
    }
    
    certificateRequestDelete = (dto: CertificateRequestDto) => {
        return this.invoke<void>('CertificateRequestDelete', dto);
    }
    
    certificateRequestGet = (studentId: number) => {
        return this.invoke<CertificateRequestDto>('CertificateRequestGet', studentId);
    }
    
    certificateRequestReset = (dto: CertificateRequestDto) => {
        return this.invoke<void>('CertificateRequestReset', dto);
    }
    
    certificateRequestSubscribe = () => {
        return this.invoke<void>('CertificateRequestSubscribe', );
    }
    
    certificateRequestSubscribeOne = (requestId: number) => {
        return this.invoke<void>('CertificateRequestSubscribeOne', requestId);
    }
    
    certificateTypeSubscribe = () => {
        return this.invoke<void>('CertificateTypeSubscribe', );
    }
    
    certificateVoid = (certificate: CertificateDto) => {
        return this.invoke<CertificateRequestDto>('CertificateVoid', certificate);
    }
    
    changeLogAcknowledge = (changeLogId: number) => {
        return this.invoke<void>('ChangeLogAcknowledge', changeLogId);
    }
    
    changeLogGet = (changeLogId: number) => {
        return this.invoke<ChangeLogDto>('ChangeLogGet', changeLogId);
    }
    
    changeLogGetNew = () => {
        return this.invoke<ChangeLogDto[]>('ChangeLogGetNew', );
    }
    
    changeLogGetUnreviewed = () => {
        return this.invoke<ChangeLogDto[]>('ChangeLogGetUnreviewed', );
    }
    
    changeLogUpdate = (dto: ChangeLogDto) => {
        return this.invoke<void>('ChangeLogUpdate', dto);
    }
    
    classAdd = (classDto: ClassDto[], validationOnly: boolean) => {
        return this.invoke<ClassAddResultDto>('ClassAdd', classDto, validationOnly);
    }
    
    classCalendar = (dto: ClassCalendarOptionsDto) => {
        return this.invoke<void>('ClassCalendar', dto);
    }
    
    classCertificateRequestCreate = (studentId: number) => {
        return this.invoke<void>('ClassCertificateRequestCreate', studentId);
    }
    
    classCertificates = (classId: number) => {
        return this.invoke<void>('ClassCertificates', classId);
    }
    
    classDelete = (id: number) => {
        return this.invoke<void>('ClassDelete', id);
    }
    
    classesForStudentType = (studentTypeId: number, locationId: number) => {
        return this.invoke<void>('ClassesForStudentType', studentTypeId, locationId);
    }
    
    classGet = (classId: number) => {
        return this.invoke<ClassDto>('ClassGet', classId);
    }
    
    classGetNoShows = (date: Date) => {
        return this.invoke<StudentStuckDto[]>('ClassGetNoShows', date);
    }
    
    classGetStudentTrainingReportList = (classId: number) => {
        return this.invoke<void>('ClassGetStudentTrainingReportList', classId);
    }
    
    classGetWarnings = (id: number[]) => {
        return this.invoke<ClassWarningsDto[]>('ClassGetWarnings', id);
    }
    
    classInfoList = () => {
        return this.invoke<ClassInfoDto[]>('ClassInfoList', );
    }
    
    classInfoSubscribe = () => {
        return this.invoke<void>('ClassInfoSubscribe', );
    }
    
    classManageQuerySubscribe = (query: ClassManageQueryDto) => {
        return this.invoke<void>('ClassManageQuerySubscribe', query);
    }
    
    classMyClasses = () => {
        return this.invoke<MyClassesDto>('ClassMyClasses', );
    }
    
    classQuery = (query: ClassQueryDto) => {
        return this.invoke<void>('ClassQuery', query);
    }
    
    classScheduleTodaySubscribe = () => {
        return this.invoke<void>('ClassScheduleTodaySubscribe', );
    }
    
    classScheduleUserSubscribe = (userId: number) => {
        return this.invoke<void>('ClassScheduleUserSubscribe', userId);
    }
    
    classStudentTestSubscribe = (classId: number) => {
        return this.invoke<void>('ClassStudentTestSubscribe', classId);
    }
    
    classTestScores = (classId: number) => {
        return this.invoke<void>('ClassTestScores', classId);
    }
    
    classTypeList = () => {
        return this.invoke<ClassTypeDto[]>('ClassTypeList', );
    }
    
    classTypeSubscribe = () => {
        return this.invoke<void>('ClassTypeSubscribe', );
    }
    
    classUpdate = (classDto: ClassDto) => {
        return this.invoke<void>('ClassUpdate', classDto);
    }
    
    contractFullSubscribe = () => {
        return this.invoke<void>('ContractFullSubscribe', );
    }
    
    contractPrinted = (studentId: number, value: boolean) => {
        return this.invoke<void>('ContractPrinted', studentId, value);
    }
    
    contractQuery = (classId: number) => {
        return this.invoke<void>('ContractQuery', classId);
    }
    
    contractSave = (dto: ContractFullDto) => {
        return this.invoke<void>('ContractSave', dto);
    }
    
    contractSigned = (studentId: number, value: boolean) => {
        return this.invoke<void>('ContractSigned', studentId, value);
    }
    
    contractSubscribe = (studentTypeId: number) => {
        return this.invoke<void>('ContractSubscribe', studentTypeId);
    }
    
    countyList = () => {
        return this.invoke<void>('CountyList', );
    }
    
    devGcCollect = () => {
        return this.invoke<void>('DevGcCollect', );
    }
    
    devValidateAllStudents = () => {
        return this.invoke<void>('DevValidateAllStudents', );
    }
    
    documentArchive = (documentId: number) => {
        return this.invoke<void>('DocumentArchive', documentId);
    }
    
    documentDelete = (dto: DocumentDto) => {
        return this.invoke<void>('DocumentDelete', dto);
    }
    
    documentInvalidSubscribe = () => {
        return this.invoke<void>('DocumentInvalidSubscribe', );
    }
    
    documentModify = (dto: DocumentDto) => {
        return this.invoke<void>('DocumentModify', dto);
    }
    
    documentSearch = (dto: DocumentSearchDto) => {
        return this.invoke<void>('DocumentSearch', dto);
    }
    
    documentTypeSubscribe = () => {
        return this.invoke<void>('DocumentTypeSubscribe', );
    }
    
    documentUserAllSubscribe = () => {
        return this.invoke<void>('DocumentUserAllSubscribe', );
    }
    
    documentUserMostRecentSubscribe = () => {
        return this.invoke<void>('DocumentUserMostRecentSubscribe', );
    }
    
    documentUserSubscribe = (userId: number) => {
        return this.invoke<void>('DocumentUserSubscribe', userId);
    }
    
    drivingRecordTypeSubscribe = () => {
        return this.invoke<void>('DrivingRecordTypeSubscribe', );
    }
    
    feedbackMessageSubmit = (feedbackId: number, message: FeedbackMessageDto) => {
        return this.invoke<void>('FeedbackMessageSubmit', feedbackId, message);
    }
    
    feedbackStatusGet = () => {
        return this.invoke<FeedbackStatusDto[]>('FeedbackStatusGet', );
    }
    
    feedbackSubmit = (dto: FeedbackSubmitDto) => {
        return this.invoke<number>('FeedbackSubmit', dto);
    }
    
    feedbackSubscribeAll = () => {
        return this.invoke<void>('FeedbackSubscribeAll', );
    }
    
    feedbackSubscribeOne = (feedbackId: number) => {
        return this.invoke<void>('FeedbackSubscribeOne', feedbackId);
    }
    
    feedbackTypeQuery = () => {
        return this.invoke<FeedbackTypeDto[]>('FeedbackTypeQuery', );
    }
    
    feedbackUpdate = (dto: FeedbackDto) => {
        return this.invoke<void>('FeedbackUpdate', dto);
    }
    
    feedbackVote = (feedbackId: number, vote: number) => {
        return this.invoke<void>('FeedbackVote', feedbackId, vote);
    }
    
    giftCertificateEmail = (giftCertificateId: number) => {
        return this.invoke<void>('GiftCertificateEmail', giftCertificateId);
    }
    
    giftCertificateFonts = () => {
        return this.invoke<string[]>('GiftCertificateFonts', );
    }
    
    giftCertificateModify = (dto: GiftCertificateDto) => {
        return this.invoke<GiftCertificateDto>('GiftCertificateModify', dto);
    }
    
    giftCertificatePaymentMethodSubscribe = () => {
        return this.invoke<void>('GiftCertificatePaymentMethodSubscribe', );
    }
    
    giftCertificatePaymentProcess = (dto: TransactionDto) => {
        return this.invoke<TransactionDto>('GiftCertificatePaymentProcess', dto);
    }
    
    giftCertificateSubscribe = () => {
        return this.invoke<void>('GiftCertificateSubscribe', );
    }
    
    giftCertificateSubscribeOne = (giftCertificateId: number) => {
        return this.invoke<void>('GiftCertificateSubscribeOne', giftCertificateId);
    }
    
    giftCertificateSubscribeSearch = (query: string) => {
        return this.invoke<void>('GiftCertificateSubscribeSearch', query);
    }
    
    giftCertificateTemplateSubscribe = () => {
        return this.invoke<void>('GiftCertificateTemplateSubscribe', );
    }
    
    giftCertificateTemplateSubscribeOne = (id: number) => {
        return this.invoke<void>('GiftCertificateTemplateSubscribeOne', id);
    }
    
    giftCertificateTemplateUpdate = (dto: GiftCertificateTemplateDto) => {
        return this.invoke<GiftCertificateTemplateDto>('GiftCertificateTemplateUpdate', dto);
    }
    
    giftCertificateTypeSubscribe = () => {
        return this.invoke<void>('GiftCertificateTypeSubscribe', );
    }
    
    highSchoolList = () => {
        return this.invoke<void>('HighSchoolList', );
    }
    
    inCarAssignedStudentSubscribe = (userId: number) => {
        return this.invoke<void>('InCarAssignedStudentSubscribe', userId);
    }
    
    inCarGetAvailable = () => {
        return this.invoke<void>('InCarGetAvailable', );
    }
    
    inCarTransactionTypeSubscribe = (studentId: number) => {
        return this.invoke<void>('InCarTransactionTypeSubscribe', studentId);
    }
    
    instructorDrivingRecordSubscribe = (instructorId: number, month: Date) => {
        return this.invoke<void>('InstructorDrivingRecordSubscribe', instructorId, month);
    }
    
    location = () => {
        return this.invoke<void>('Location', );
    }
    
    locationsForStudentType = (studentTypeId: number) => {
        return this.invoke<void>('LocationsForStudentType', studentTypeId);
    }
    
    locationTokenCreate = (locationId: number, description: string, privateComputer: boolean) => {
        return this.invoke<LocationTokenDto>('LocationTokenCreate', locationId, description, privateComputer);
    }
    
    locationTokenValidate = (tokenGuid: string) => {
        return this.invoke<LocationTokenDto>('LocationTokenValidate', tokenGuid);
    }
    
    logExceptionAcknowledge = (logId: number) => {
        return this.invoke<void>('LogExceptionAcknowledge', logId);
    }
    
    logExceptions = () => {
        return this.invoke<void>('LogExceptions', );
    }
    
    logs = () => {
        return this.invoke<void>('Logs', );
    }
    
    mailMessageEventSubscribe = (mailMessageId: number) => {
        return this.invoke<void>('MailMessageEventSubscribe', mailMessageId);
    }
    
    mailMessageSubscribe = (studentId: number) => {
        return this.invoke<void>('MailMessageSubscribe', studentId);
    }
    
    newGuid = () => {
        return this.invoke<string>('NewGuid', );
    }
    
    preRegAddNote = (preRegId: number, note: string) => {
        return this.invoke<void>('PreRegAddNote', preRegId, note);
    }
    
    preRegArchive = (preRegId: number) => {
        return this.invoke<void>('PreRegArchive', preRegId);
    }
    
    preRegConvert = (preRegId: number) => {
        return this.invoke<number>('PreRegConvert', preRegId);
    }
    
    preRegGet = (preRegId: number) => {
        return this.invoke<void>('PreRegGet', preRegId);
    }
    
    preRegSubscribe = () => {
        return this.invoke<void>('PreRegSubscribe', );
    }
    
    priceLevelAddUpdate = (dto: PricingDto) => {
        return this.invoke<void>('PriceLevelAddUpdate', dto);
    }
    
    priceLevelEnabledToggle = (priceLevelId: number) => {
        return this.invoke<void>('PriceLevelEnabledToggle', priceLevelId);
    }
    
    priceLevelMoveDown = (priceLevelId: number) => {
        return this.invoke<void>('PriceLevelMoveDown', priceLevelId);
    }
    
    priceLevelMoveUp = (priceLevelId: number) => {
        return this.invoke<void>('PriceLevelMoveUp', priceLevelId);
    }
    
    priceLevelPermanentlyDisable = (priceLevelId: number) => {
        return this.invoke<void>('PriceLevelPermanentlyDisable', priceLevelId);
    }
    
    priceLevelsForStudentType = (studentTypeId: number) => {
        return this.invoke<void>('PriceLevelsForStudentType', studentTypeId);
    }
    
    priceLevelsForStudentTypeFull = (studentTypeId: number) => {
        return this.invoke<void>('PriceLevelsForStudentTypeFull', studentTypeId);
    }
    
    printerDelete = (dto: PrinterDto) => {
        return this.invoke<void>('PrinterDelete', dto);
    }
    
    printerPrintTestPage = (dto: PrinterDto) => {
        return this.invoke<void>('PrinterPrintTestPage', dto);
    }
    
    printerPurgeJobs = (dto: PrinterDto) => {
        return this.invoke<void>('PrinterPurgeJobs', dto);
    }
    
    printerRefresh = () => {
        return this.invoke<void>('PrinterRefresh', );
    }
    
    printerSubscribe = () => {
        return this.invoke<void>('PrinterSubscribe', );
    }
    
    printerUpdate = (dto: PrinterDto) => {
        return this.invoke<void>('PrinterUpdate', dto);
    }
    
    printJobSubmit = (dto: PrintJobRequestDto) => {
        return this.invoke<PrintJobResultDto>('PrintJobSubmit', dto);
    }
    
    referralSources = () => {
        return this.invoke<void>('ReferralSources', );
    }
    
    reportPayment = (model: PaymentReportOptionsDto) => {
        return this.invoke<PaymentReportOptionsDto>('ReportPayment', model);
    }
    
    roleList = () => {
        return this.invoke<string[]>('RoleList', );
    }
    
    rosterReportQuery = (dto: RosterReportQueryDto) => {
        return this.invoke<RosterReportDto[]>('RosterReportQuery', dto);
    }
    
    studentArchive = (studentId: number) => {
        return this.invoke<void>('StudentArchive', studentId);
    }
    
    studentCalled = (studentId: number, value: boolean) => {
        return this.invoke<void>('StudentCalled', studentId, value);
    }
    
    studentCertificateRequestSubscribe = (studentId: number) => {
        return this.invoke<void>('StudentCertificateRequestSubscribe', studentId);
    }
    
    studentContractSend = (studentId: number) => {
        return this.invoke<void>('StudentContractSend', studentId);
    }
    
    studentContractSubscribe = (studentContractId: number) => {
        return this.invoke<void>('StudentContractSubscribe', studentContractId);
    }
    
    studentContractVoid = (studentContractId: number, reason: string) => {
        return this.invoke<void>('StudentContractVoid', studentContractId, reason);
    }
    
    studentDrivingRecordDelete = (id: number) => {
        return this.invoke<void>('StudentDrivingRecordDelete', id);
    }
    
    studentDrivingRecords = (studentId: number) => {
        return this.invoke<void>('StudentDrivingRecords', studentId);
    }
    
    studentDrivingRecordUpdate = (dto: DrivingRecordDto) => {
        return this.invoke<void>('StudentDrivingRecordUpdate', dto);
    }
    
    studentDupeCheck = (dto: StudentDto) => {
        return this.invoke<StudentDto[]>('StudentDupeCheck', dto);
    }
    
    studentExamAssignedSubscribe = () => {
        return this.invoke<void>('StudentExamAssignedSubscribe', );
    }
    
    studentExamConnectedSubscribe = () => {
        return this.invoke<void>('StudentExamConnectedSubscribe', );
    }
    
    studentExamInstructorAssign = (examId: number, instructorId: number) => {
        return this.invoke<void>('StudentExamInstructorAssign', examId, instructorId);
    }
    
    studentExamInstructorRemove = (examId: number) => {
        return this.invoke<void>('StudentExamInstructorRemove', examId);
    }
    
    studentExamLinkGet = (studentId: number) => {
        return this.invoke<string>('StudentExamLinkGet', studentId);
    }
    
    studentExamLinkSend = (studentId: number) => {
        return this.invoke<void>('StudentExamLinkSend', studentId);
    }
    
    studentExamLogSubscribe = (examId: number) => {
        return this.invoke<void>('StudentExamLogSubscribe', examId);
    }
    
    studentExamMessageRead = (messageId: number) => {
        return this.invoke<void>('StudentExamMessageRead', messageId);
    }
    
    studentExamMessageSubmit = (message: StudentExamMessageDto) => {
        return this.invoke<void>('StudentExamMessageSubmit', message);
    }
    
    studentExamMessageSubscribe = (examId: number) => {
        return this.invoke<void>('StudentExamMessageSubscribe', examId);
    }
    
    studentExamStatusUpdate = (examId: number, status: StudentExamStatus) => {
        return this.invoke<void>('StudentExamStatusUpdate', examId, status);
    }
    
    studentExamSubscribe = (studentId: number) => {
        return this.invoke<void>('StudentExamSubscribe', studentId);
    }
    
    studentExamTranscriptSubscribe = (examId: number) => {
        return this.invoke<void>('StudentExamTranscriptSubscribe', examId);
    }
    
    studentExamVoid = (examId: number, reason: string, countTowardsAttempts: boolean) => {
        return this.invoke<void>('StudentExamVoid', examId, reason, countTowardsAttempts);
    }
    
    studentExamWaitingSubscribe = () => {
        return this.invoke<void>('StudentExamWaitingSubscribe', );
    }
    
    studentGet = (id: number) => {
        return this.invoke<void>('StudentGet', id);
    }
    
    studentInCarGet = (id: number) => {
        return this.invoke<void>('StudentInCarGet', id);
    }
    
    studentInstructorAssign = (studentId: number, instructorId: number) => {
        return this.invoke<void>('StudentInstructorAssign', studentId, instructorId);
    }
    
    studentNoteAdd = (id: number, text: string) => {
        return this.invoke<void>('StudentNoteAdd', id, text);
    }
    
    studentPasswordGet = (studentId: number) => {
        return this.invoke<string>('StudentPasswordGet', studentId);
    }
    
    studentPasswordReset = (studentId: number) => {
        return this.invoke<void>('StudentPasswordReset', studentId);
    }
    
    studentRelease = (dto: StudentReleaseDto) => {
        return this.invoke<void>('StudentRelease', dto);
    }
    
    studentReleaseNotReadyQuery = () => {
        return this.invoke<void>('StudentReleaseNotReadyQuery', );
    }
    
    studentReleaseReadyDateUpdate = (studentId: number, newDate: Date) => {
        return this.invoke<void>('StudentReleaseReadyDateUpdate', studentId, newDate);
    }
    
    studentReleaseReadyQuery = () => {
        return this.invoke<void>('StudentReleaseReadyQuery', );
    }
    
    studentRemarksUpdate = (studentId: number, remarks: string) => {
        return this.invoke<void>('StudentRemarksUpdate', studentId, remarks);
    }
    
    studentRestart = (dto: StudentRestartDto) => {
        return this.invoke<void>('StudentRestart', dto);
    }
    
    studentSearch = (dto: StudentSearchDto) => {
        return this.invoke<StudentDisplayDto[]>('StudentSearch', dto);
    }
    
    studentSignIn = (studentId: number, reason: string) => {
        return this.invoke<void>('StudentSignIn', studentId, reason);
    }
    
    studentSignOut = (studentId: number) => {
        return this.invoke<void>('StudentSignOut', studentId);
    }
    
    studentSignupList = (start: Date, end: Date) => {
        return this.invoke<StudentDto[]>('StudentSignupList', start, end);
    }
    
    studentStuckReport = () => {
        return this.invoke<void>('StudentStuckReport', );
    }
    
    studentSubscribeAll = (id: number) => {
        return this.invoke<void>('StudentSubscribeAll', id);
    }
    
    studentTestScoreAdd = (dto: StudentWithTestScoresDto) => {
        return this.invoke<void>('StudentTestScoreAdd', dto);
    }
    
    studentTestScores = (studentId: number) => {
        return this.invoke<void>('StudentTestScores', studentId);
    }
    
    studentTestScoreVoid = (testScoreId: number, reason: string) => {
        return this.invoke<void>('StudentTestScoreVoid', testScoreId, reason);
    }
    
    studentTypeList = () => {
        return this.invoke<void>('StudentTypeList', );
    }
    
    studentUnRelease = (dto: StudentReleaseDto) => {
        return this.invoke<void>('StudentUnRelease', dto);
    }
    
    studentUpdate = (student: StudentDto) => {
        return this.invoke<StudentDto>('StudentUpdate', student);
    }
    
    studentVirtualClassParticipantSubscribe = (studentId: number) => {
        return this.invoke<void>('StudentVirtualClassParticipantSubscribe', studentId);
    }
    
    studentWithoutPassingTestSubscribe = (studentTypeIds: number[]) => {
        return this.invoke<void>('StudentWithoutPassingTestSubscribe', studentTypeIds);
    }
    
    studentWithoutSignedContractSubscribe = (studentTypeIds: number[]) => {
        return this.invoke<void>('StudentWithoutSignedContractSubscribe', studentTypeIds);
    }
    
    transactionAdd = (dto: TransactionDto) => {
        return this.invoke<TransactionDto>('TransactionAdd', dto);
    }
    
    transactionGet = (studentId: number) => {
        return this.invoke<void>('TransactionGet', studentId);
    }
    
    transactionRefund = (dto: TransactionRefundRequestDto) => {
        return this.invoke<TransactionDto>('TransactionRefund', dto);
    }
    
    transactionSubscribe = (transactionId: number, includeRelated: boolean) => {
        return this.invoke<void>('TransactionSubscribe', transactionId, includeRelated);
    }
    
    transactionTypeList = (studentId: number, txnDate: Date) => {
        return this.invoke<void>('TransactionTypeList', studentId, txnDate);
    }
    
    transactionVoid = (dto: TransactionDto) => {
        return this.invoke<void>('TransactionVoid', dto);
    }
    
    userActivityForceClose = (dto: UserActivityDto) => {
        return this.invoke<void>('UserActivityForceClose', dto);
    }
    
    userActivityForceRefresh = (dto: UserActivityDto) => {
        return this.invoke<void>('UserActivityForceRefresh', dto);
    }
    
    userActivitySubscribe = () => {
        return this.invoke<void>('UserActivitySubscribe', );
    }
    
    userActivityUpdate = (dto: UserActivityDto) => {
        return this.invoke<void>('UserActivityUpdate', dto);
    }
    
    userDrivingInstructorsSubscribe = () => {
        return this.invoke<void>('UserDrivingInstructorsSubscribe', );
    }
    
    userGet = (id: number) => {
        return this.invoke<void>('UserGet', id);
    }
    
    userGetActive = () => {
        return this.invoke<UserDto[]>('UserGetActive', );
    }
    
    userList = () => {
        return this.invoke<void>('UserList', );
    }
    
    userPasswordReset = (userId: number) => {
        return this.invoke<string>('UserPasswordReset', userId);
    }
    
    userSignatureGet = (userId: number) => {
        return this.invoke<string>('UserSignatureGet', userId);
    }
    
    userSignatureSelfGet = () => {
        return this.invoke<string>('UserSignatureSelfGet', );
    }
    
    userSignatureSelfUpdate = (dataUrl: string) => {
        return this.invoke<void>('UserSignatureSelfUpdate', dataUrl);
    }
    
    userStorageQuery = () => {
        return this.invoke<void>('UserStorageQuery', );
    }
    
    userStorageRemove = (key: string) => {
        return this.invoke<void>('UserStorageRemove', key);
    }
    
    userStorageSet = (key: string, value: string) => {
        return this.invoke<void>('UserStorageSet', key, value);
    }
    
    userTeacherSubscribe = () => {
        return this.invoke<void>('UserTeacherSubscribe', );
    }
    
    userUpdate = (user: UserDto) => {
        return this.invoke<UserDto>('UserUpdate', user);
    }
    
    videoList = () => {
        return this.invoke<VideoDto[]>('VideoList', );
    }
    
    videoUpdate = (dto: VideoDto) => {
        return this.invoke<VideoDto>('VideoUpdate', dto);
    }
    
    virtualClassActiveSubscribe = () => {
        return this.invoke<void>('VirtualClassActiveSubscribe', );
    }
    
    virtualClassFormGet = (virtualClassId: number) => {
        return this.invoke<FormDisplayDto[]>('VirtualClassFormGet', virtualClassId);
    }
    
    virtualClassFormSet = (virtualClassId: number, formId: number) => {
        return this.invoke<void>('VirtualClassFormSet', virtualClassId, formId);
    }
    
    virtualClassJoin = (virtualClassId: number) => {
        return this.invoke<void>('VirtualClassJoin', virtualClassId);
    }
    
    virtualClassMessageSubmit = (dto: VirtualClassMessageDto) => {
        return this.invoke<void>('VirtualClassMessageSubmit', dto);
    }
    
    virtualClassMessageSubscribe = (virtualClassId: number) => {
        return this.invoke<void>('VirtualClassMessageSubscribe', virtualClassId);
    }
    
    virtualClassModeSet = (virtualClassId: number, mode: VirtualClassMode) => {
        return this.invoke<void>('VirtualClassModeSet', virtualClassId, mode);
    }
    
    virtualClassParticipantLog = (severity: string, messages: string[]) => {
        return this.invoke<void>('VirtualClassParticipantLog', severity, messages);
    }
    
    virtualClassParticipantMessageRead = (messageId: number) => {
        return this.invoke<void>('VirtualClassParticipantMessageRead', messageId);
    }
    
    virtualClassParticipantMessageSubmit = (message: VirtualClassParticipantMessageDto) => {
        return this.invoke<void>('VirtualClassParticipantMessageSubmit', message);
    }
    
    virtualClassParticipantMessageSubscribe = (participantId: number) => {
        return this.invoke<void>('VirtualClassParticipantMessageSubscribe', participantId);
    }
    
    virtualClassParticipantPing = (virtualClassId: number) => {
        return this.invoke<void>('VirtualClassParticipantPing', virtualClassId);
    }
    
    virtualClassParticipantRemove = (dto: VirtualClassParticipantDto) => {
        return this.invoke<void>('VirtualClassParticipantRemove', dto);
    }
    
    virtualClassParticipantRemoveAllDisconnected = (dto: VirtualClassDto) => {
        return this.invoke<void>('VirtualClassParticipantRemoveAllDisconnected', dto);
    }
    
    virtualClassParticipantSubscribe = (virtualClassId: number) => {
        return this.invoke<void>('VirtualClassParticipantSubscribe', virtualClassId);
    }
    
    virtualClassParticipantUpdate = (dto: VirtualClassParticipantDto) => {
        return this.invoke<void>('VirtualClassParticipantUpdate', dto);
    }
    
    virtualClassStudentFormSubscribe = (virtualClassId: number) => {
        return this.invoke<void>('VirtualClassStudentFormSubscribe', virtualClassId);
    }
    
    virtualClassUpdate = (dto: VirtualClassDto) => {
        return this.invoke<void>('VirtualClassUpdate', dto);
    }
    
    zoomParticipantAttendanceQuery = (date: Date) => {
        return this.invoke<AttendanceRecordDto[]>('ZoomParticipantAttendanceQuery', date);
    }
    
    zoomParticipantLink = (rowId: number, studentId: number) => {
        return this.invoke<void>('ZoomParticipantLink', rowId, studentId);
    }
    
    zoomParticipantReconcile = (rowId: number) => {
        return this.invoke<void>('ZoomParticipantReconcile', rowId);
    }
    
    zoomParticipantRelatedQuery = (rowId: number) => {
        return this.invoke<ZoomMeetingParticipantDto[]>('ZoomParticipantRelatedQuery', rowId);
    }
    
    zoomParticipantUnreconciled = (date: Date) => {
        return this.invoke<ZoomMeetingParticipantDto[]>('ZoomParticipantUnreconciled', date);
    }
    


    // client methods
    
    /**
    * Wires up a callback to be invoked when AsteriskCallRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onAsteriskCallRemoved = (callback: (arg: AsteriskCallDto) => void) => {
        return this.on('AsteriskCallRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when AsteriskCallUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onAsteriskCallUpdated = (callback: (arg: AsteriskCallDto) => void) => {
        return this.on('AsteriskCallUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when AsteriskChannelRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onAsteriskChannelRemoved = (callback: (arg: AsteriskChannelDto) => void) => {
        return this.on('AsteriskChannelRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when AsteriskChannelUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onAsteriskChannelUpdated = (callback: (arg: AsteriskChannelDto) => void) => {
        return this.on('AsteriskChannelUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when AsteriskEventUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onAsteriskEventUpdated = (callback: (arg: AsteriskEventDto) => void) => {
        return this.on('AsteriskEventUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when AsteriskExtensionRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onAsteriskExtensionRemoved = (callback: (arg: AsteriskExtensionDto) => void) => {
        return this.on('AsteriskExtensionRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when AsteriskExtensionUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onAsteriskExtensionUpdated = (callback: (arg: AsteriskExtensionDto) => void) => {
        return this.on('AsteriskExtensionUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when AsteriskQueueMemberRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onAsteriskQueueMemberRemoved = (callback: (arg: AsteriskQueueMemberSessionDto) => void) => {
        return this.on('AsteriskQueueMemberRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when AsteriskQueueMemberUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onAsteriskQueueMemberUpdated = (callback: (arg: AsteriskQueueMemberSessionDto) => void) => {
        return this.on('AsteriskQueueMemberUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when AsteriskRelatedCallUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onAsteriskRelatedCallUpdated = (callback: (arg: AsteriskCallDto) => void) => {
        return this.on('AsteriskRelatedCallUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when AttendanceRecordRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onAttendanceRecordRemoved = (callback: (arg: AttendanceRecordDto) => void) => {
        return this.on('AttendanceRecordRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when AttendanceRecordUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onAttendanceRecordUpdated = (callback: (arg: AttendanceRecordDto) => void) => {
        return this.on('AttendanceRecordUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when CertificateRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onCertificateRemoved = (callback: (arg: CertificateDto) => void) => {
        return this.on('CertificateRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when CertificateRequestRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onCertificateRequestRemoved = (callback: (arg: CertificateRequestDto) => void) => {
        return this.on('CertificateRequestRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when CertificateRequestUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onCertificateRequestUpdated = (callback: (arg: CertificateRequestDto) => void) => {
        return this.on('CertificateRequestUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when CertificateTypeRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onCertificateTypeRemoved = (callback: (arg: CertificateTypeDto) => void) => {
        return this.on('CertificateTypeRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when CertificateTypeUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onCertificateTypeUpdated = (callback: (arg: CertificateTypeDto) => void) => {
        return this.on('CertificateTypeUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when CertificateUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onCertificateUpdated = (callback: (arg: CertificateDto) => void) => {
        return this.on('CertificateUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when ClassInfoRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onClassInfoRemoved = (callback: (arg: ClassInfoDto) => void) => {
        return this.on('ClassInfoRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when ClassInfoUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onClassInfoUpdated = (callback: (arg: ClassInfoDto) => void) => {
        return this.on('ClassInfoUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when ClassRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onClassRemoved = (callback: (arg: ClassDto) => void) => {
        return this.on('ClassRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when ClassScheduleTodayRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onClassScheduleTodayRemoved = (callback: (arg: ClassDto) => void) => {
        return this.on('ClassScheduleTodayRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when ClassScheduleTodayUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onClassScheduleTodayUpdated = (callback: (arg: ClassDto) => void) => {
        return this.on('ClassScheduleTodayUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when ClassScheduleUserRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onClassScheduleUserRemoved = (callback: (arg: ClassDto) => void) => {
        return this.on('ClassScheduleUserRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when ClassScheduleUserUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onClassScheduleUserUpdated = (callback: (arg: ClassDto) => void) => {
        return this.on('ClassScheduleUserUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when ClassTypeRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onClassTypeRemoved = (callback: (arg: ClassTypeDto) => void) => {
        return this.on('ClassTypeRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when ClassTypeUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onClassTypeUpdated = (callback: (arg: ClassTypeDto) => void) => {
        return this.on('ClassTypeUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when ClassUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onClassUpdated = (callback: (arg: ClassDto) => void) => {
        return this.on('ClassUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when ContractFullRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onContractFullRemoved = (callback: (arg: ContractFullDto) => void) => {
        return this.on('ContractFullRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when ContractFullUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onContractFullUpdated = (callback: (arg: ContractFullDto) => void) => {
        return this.on('ContractFullUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when ContractRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onContractRemoved = (callback: (arg: ContractDto) => void) => {
        return this.on('ContractRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when ContractUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onContractUpdated = (callback: (arg: ContractDto) => void) => {
        return this.on('ContractUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when CountyUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onCountyUpdated = (callback: (arg: CountyDto) => void) => {
        return this.on('CountyUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when DocumentRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onDocumentRemoved = (callback: (arg: DocumentDto) => void) => {
        return this.on('DocumentRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when DocumentTypeRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onDocumentTypeRemoved = (callback: (arg: DocumentTypeDto) => void) => {
        return this.on('DocumentTypeRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when DocumentTypeUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onDocumentTypeUpdated = (callback: (arg: DocumentTypeDto) => void) => {
        return this.on('DocumentTypeUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when DocumentUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onDocumentUpdated = (callback: (arg: DocumentDto) => void) => {
        return this.on('DocumentUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when DrivingRecordRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onDrivingRecordRemoved = (callback: (arg: DrivingRecordDto) => void) => {
        return this.on('DrivingRecordRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when DrivingRecordTypeUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onDrivingRecordTypeUpdated = (callback: (arg: DrivingRecordTypeDto) => void) => {
        return this.on('DrivingRecordTypeUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when DrivingRecordUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onDrivingRecordUpdated = (callback: (arg: DrivingRecordDto) => void) => {
        return this.on('DrivingRecordUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when FeedbackMessageRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onFeedbackMessageRemoved = (callback: (arg: FeedbackMessageDto) => void) => {
        return this.on('FeedbackMessageRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when FeedbackMessageUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onFeedbackMessageUpdated = (callback: (arg: FeedbackMessageDto) => void) => {
        return this.on('FeedbackMessageUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when FeedbackRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onFeedbackRemoved = (callback: (arg: FeedbackDto) => void) => {
        return this.on('FeedbackRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when FeedbackUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onFeedbackUpdated = (callback: (arg: FeedbackDto) => void) => {
        return this.on('FeedbackUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when GiftCertificateDetailUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onGiftCertificateDetailUpdated = (callback: (arg: GiftCertificateDetailDto) => void) => {
        return this.on('GiftCertificateDetailUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when GiftCertificateRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onGiftCertificateRemoved = (callback: (arg: GiftCertificateDto) => void) => {
        return this.on('GiftCertificateRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when GiftCertificateTemplateRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onGiftCertificateTemplateRemoved = (callback: (giftCertificateTemplateDto: GiftCertificateTemplateDto) => void) => {
        return this.on('GiftCertificateTemplateRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when GiftCertificateTemplateUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onGiftCertificateTemplateUpdated = (callback: (giftCertificateTemplateDto: GiftCertificateTemplateDto) => void) => {
        return this.on('GiftCertificateTemplateUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when GiftCertificateTypeRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onGiftCertificateTypeRemoved = (callback: (arg: GiftCertificateTypeDto) => void) => {
        return this.on('GiftCertificateTypeRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when GiftCertificateTypeUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onGiftCertificateTypeUpdated = (callback: (arg: GiftCertificateTypeDto) => void) => {
        return this.on('GiftCertificateTypeUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when GiftCertificateUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onGiftCertificateUpdated = (callback: (arg: GiftCertificateDto) => void) => {
        return this.on('GiftCertificateUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when HighSchoolUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onHighSchoolUpdated = (callback: (arg: HighSchoolDto) => void) => {
        return this.on('HighSchoolUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when JanusParticipantRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onJanusParticipantRemoved = (callback: (arg: VirtualClassParticipantDto) => void) => {
        return this.on('JanusParticipantRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when JanusParticipantUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onJanusParticipantUpdated = (callback: (arg: VirtualClassParticipantDto) => void) => {
        return this.on('JanusParticipantUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when LocationUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onLocationUpdated = (callback: (arg: LocationDto) => void) => {
        return this.on('LocationUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when LogRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onLogRemoved = (callback: (arg: LogDto) => void) => {
        return this.on('LogRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when LogUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onLogUpdated = (callback: (logDto: LogDto) => void) => {
        return this.on('LogUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when MailMessageEventRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onMailMessageEventRemoved = (callback: (arg: MailMessageEventDto) => void) => {
        return this.on('MailMessageEventRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when MailMessageEventUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onMailMessageEventUpdated = (callback: (arg: MailMessageEventDto) => void) => {
        return this.on('MailMessageEventUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when MailMessageRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onMailMessageRemoved = (callback: (arg: MailMessageDto) => void) => {
        return this.on('MailMessageRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when MailMessageUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onMailMessageUpdated = (callback: (arg: MailMessageDto) => void) => {
        return this.on('MailMessageUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when PreRegRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onPreRegRemoved = (callback: (arg: PreRegDto) => void) => {
        return this.on('PreRegRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when PreRegUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onPreRegUpdated = (callback: (arg: PreRegDto) => void) => {
        return this.on('PreRegUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when PriceLevelRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onPriceLevelRemoved = (callback: (arg: PricingDto) => void) => {
        return this.on('PriceLevelRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when PriceLevelUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onPriceLevelUpdated = (callback: (arg: PricingDto) => void) => {
        return this.on('PriceLevelUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when PrinterRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onPrinterRemoved = (callback: (arg: PrinterDto) => void) => {
        return this.on('PrinterRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when PrinterUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onPrinterUpdated = (callback: (arg: PrinterDto) => void) => {
        return this.on('PrinterUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when ReferralHeardFromUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onReferralHeardFromUpdated = (callback: (arg: ReferralHeardFromDto) => void) => {
        return this.on('ReferralHeardFromUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when ReferralWhyChoseUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onReferralWhyChoseUpdated = (callback: (arg: ReferralWhyChoseDto) => void) => {
        return this.on('ReferralWhyChoseUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when ServerVersion is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onServerVersion = (callback: (version: string) => void) => {
        return this.on('ServerVersion', callback);
    }
    
    /**
    * Wires up a callback to be invoked when SignoutRecordRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onSignoutRecordRemoved = (callback: (arg: SignoutRecordDto) => void) => {
        return this.on('SignoutRecordRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when SignoutRecordUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onSignoutRecordUpdated = (callback: (arg: SignoutRecordDto) => void) => {
        return this.on('SignoutRecordUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentContractRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentContractRemoved = (callback: (arg: StudentContractDto) => void) => {
        return this.on('StudentContractRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentContractSummaryRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentContractSummaryRemoved = (callback: (arg: StudentContractSummaryDto) => void) => {
        return this.on('StudentContractSummaryRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentContractSummaryUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentContractSummaryUpdated = (callback: (arg: StudentContractSummaryDto) => void) => {
        return this.on('StudentContractSummaryUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentContractUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentContractUpdated = (callback: (arg: StudentContractDto) => void) => {
        return this.on('StudentContractUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentExamAssignedRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentExamAssignedRemoved = (callback: (arg: StudentExamDto) => void) => {
        return this.on('StudentExamAssignedRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentExamAssignedUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentExamAssignedUpdated = (callback: (arg: StudentExamDto) => void) => {
        return this.on('StudentExamAssignedUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentExamConnectedRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentExamConnectedRemoved = (callback: (arg: StudentExamDto) => void) => {
        return this.on('StudentExamConnectedRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentExamConnectedUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentExamConnectedUpdated = (callback: (arg: StudentExamDto) => void) => {
        return this.on('StudentExamConnectedUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentExamLogRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentExamLogRemoved = (callback: (arg: StudentExamLogDto) => void) => {
        return this.on('StudentExamLogRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentExamLogUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentExamLogUpdated = (callback: (arg: StudentExamLogDto) => void) => {
        return this.on('StudentExamLogUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentExamMessageRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentExamMessageRemoved = (callback: (arg: StudentExamMessageDto) => void) => {
        return this.on('StudentExamMessageRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentExamMessageUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentExamMessageUpdated = (callback: (arg: StudentExamMessageDto) => void) => {
        return this.on('StudentExamMessageUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentExamRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentExamRemoved = (callback: (studentExamDto: StudentExamDto) => void) => {
        return this.on('StudentExamRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentExamTranscriptUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentExamTranscriptUpdated = (callback: (arg: StudentExamTranscriptDto) => void) => {
        return this.on('StudentExamTranscriptUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentExamUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentExamUpdated = (callback: (studentExamDto: StudentExamDto) => void) => {
        return this.on('StudentExamUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentExamWaitingRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentExamWaitingRemoved = (callback: (arg: StudentExamDto) => void) => {
        return this.on('StudentExamWaitingRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentExamWaitingUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentExamWaitingUpdated = (callback: (arg: StudentExamDto) => void) => {
        return this.on('StudentExamWaitingUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentInCarAvailableRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentInCarAvailableRemoved = (callback: (arg: StudentDto) => void) => {
        return this.on('StudentInCarAvailableRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentInCarAvailableUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentInCarAvailableUpdated = (callback: (arg: StudentDto) => void) => {
        return this.on('StudentInCarAvailableUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentInCarRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentInCarRemoved = (callback: (arg: StudentInCarDto) => void) => {
        return this.on('StudentInCarRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentInCarUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentInCarUpdated = (callback: (arg: StudentInCarDto) => void) => {
        return this.on('StudentInCarUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentLogRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentLogRemoved = (callback: (arg: StudentLogDto) => void) => {
        return this.on('StudentLogRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentLogUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentLogUpdated = (callback: (arg: StudentLogDto) => void) => {
        return this.on('StudentLogUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentNoteRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentNoteRemoved = (callback: (arg: StudentNoteDto) => void) => {
        return this.on('StudentNoteRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentNoteUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentNoteUpdated = (callback: (arg: StudentNoteDto) => void) => {
        return this.on('StudentNoteUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentReleaseReadyRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentReleaseReadyRemoved = (callback: (arg: StudentReleaseDto) => void) => {
        return this.on('StudentReleaseReadyRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentReleaseReadyUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentReleaseReadyUpdated = (callback: (arg: StudentReleaseDto) => void) => {
        return this.on('StudentReleaseReadyUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentRemoved = (callback: (arg: StudentDto) => void) => {
        return this.on('StudentRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentStuckRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentStuckRemoved = (callback: (studentStuckDto: StudentStuckDto) => void) => {
        return this.on('StudentStuckRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentStuckUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentStuckUpdated = (callback: (studentStuckDto: StudentStuckDto) => void) => {
        return this.on('StudentStuckUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentTrainingReportUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentTrainingReportUpdated = (callback: (studentWithTestScoresDto: StudentWithTestScoresDto) => void) => {
        return this.on('StudentTrainingReportUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentTypeUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentTypeUpdated = (callback: (arg: StudentTypeDto) => void) => {
        return this.on('StudentTypeUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentUpdated = (callback: (arg: StudentDto) => void) => {
        return this.on('StudentUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentWithoutPassingTestRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentWithoutPassingTestRemoved = (callback: (arg: StudentDto) => void) => {
        return this.on('StudentWithoutPassingTestRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentWithoutPassingTestUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentWithoutPassingTestUpdated = (callback: (arg: StudentDto) => void) => {
        return this.on('StudentWithoutPassingTestUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentWithoutSignedContractRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentWithoutSignedContractRemoved = (callback: (arg: StudentDto) => void) => {
        return this.on('StudentWithoutSignedContractRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentWithoutSignedContractUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentWithoutSignedContractUpdated = (callback: (arg: StudentDto) => void) => {
        return this.on('StudentWithoutSignedContractUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentWithTestScoreRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentWithTestScoreRemoved = (callback: (arg: StudentWithTestScoresDto) => void) => {
        return this.on('StudentWithTestScoreRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when StudentWithTestScoreUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onStudentWithTestScoreUpdated = (callback: (arg: StudentWithTestScoresDto) => void) => {
        return this.on('StudentWithTestScoreUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when TransactionRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onTransactionRemoved = (callback: (arg: TransactionDto) => void) => {
        return this.on('TransactionRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when TransactionTypeRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onTransactionTypeRemoved = (callback: (arg: TransactionTypeDto) => void) => {
        return this.on('TransactionTypeRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when TransactionTypeUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onTransactionTypeUpdated = (callback: (arg: TransactionTypeDto) => void) => {
        return this.on('TransactionTypeUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when TransactionUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onTransactionUpdated = (callback: (arg: TransactionDto) => void) => {
        return this.on('TransactionUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when UserActivityForceClose is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onUserActivityForceClose = (callback: () => void) => {
        return this.on('UserActivityForceClose', callback);
    }
    
    /**
    * Wires up a callback to be invoked when UserActivityForceRefresh is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onUserActivityForceRefresh = (callback: () => void) => {
        return this.on('UserActivityForceRefresh', callback);
    }
    
    /**
    * Wires up a callback to be invoked when UserActivityRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onUserActivityRemoved = (callback: (uc: UserActivityDto) => void) => {
        return this.on('UserActivityRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when UserActivityUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onUserActivityUpdated = (callback: (ua: UserActivityDto) => void) => {
        return this.on('UserActivityUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when UserDisplayRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onUserDisplayRemoved = (callback: (arg: UserDisplayDto) => void) => {
        return this.on('UserDisplayRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when UserDisplayUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onUserDisplayUpdated = (callback: (arg: UserDisplayDto) => void) => {
        return this.on('UserDisplayUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when UserRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onUserRemoved = (callback: (arg: UserDto) => void) => {
        return this.on('UserRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when UserStorageRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onUserStorageRemoved = (callback: (arg: UserStorageDto) => void) => {
        return this.on('UserStorageRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when UserStorageUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onUserStorageUpdated = (callback: (arg: UserStorageDto) => void) => {
        return this.on('UserStorageUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when UserUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onUserUpdated = (callback: (arg: UserDto) => void) => {
        return this.on('UserUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when VirtualClassMessageRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onVirtualClassMessageRemoved = (callback: (arg: VirtualClassMessageDto) => void) => {
        return this.on('VirtualClassMessageRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when VirtualClassMessageUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onVirtualClassMessageUpdated = (callback: (arg: VirtualClassMessageDto) => void) => {
        return this.on('VirtualClassMessageUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when VirtualClassParticipantDetailRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onVirtualClassParticipantDetailRemoved = (callback: (arg: VirtualClassParticipantDetailDto) => void) => {
        return this.on('VirtualClassParticipantDetailRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when VirtualClassParticipantDetailUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onVirtualClassParticipantDetailUpdated = (callback: (arg: VirtualClassParticipantDetailDto) => void) => {
        return this.on('VirtualClassParticipantDetailUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when VirtualClassParticipantMessageRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onVirtualClassParticipantMessageRemoved = (callback: (arg: VirtualClassParticipantMessageDto) => void) => {
        return this.on('VirtualClassParticipantMessageRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when VirtualClassParticipantMessageUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onVirtualClassParticipantMessageUpdated = (callback: (arg: VirtualClassParticipantMessageDto) => void) => {
        return this.on('VirtualClassParticipantMessageUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when VirtualClassRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onVirtualClassRemoved = (callback: (arg: VirtualClassDto) => void) => {
        return this.on('VirtualClassRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when VirtualClassStudentFormRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onVirtualClassStudentFormRemoved = (callback: (arg: StudentFormDto) => void) => {
        return this.on('VirtualClassStudentFormRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when VirtualClassStudentFormUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onVirtualClassStudentFormUpdated = (callback: (arg: StudentFormDto) => void) => {
        return this.on('VirtualClassStudentFormUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when VirtualClassUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onVirtualClassUpdated = (callback: (arg: VirtualClassDto) => void) => {
        return this.on('VirtualClassUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when ZoomMeetingParticipantRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onZoomMeetingParticipantRemoved = (callback: (arg: ZoomMeetingParticipantDto) => void) => {
        return this.on('ZoomMeetingParticipantRemoved', callback);
    }
    
    /**
    * Wires up a callback to be invoked when ZoomMeetingParticipantUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onZoomMeetingParticipantUpdated = (callback: (arg: ZoomMeetingParticipantDto) => void) => {
        return this.on('ZoomMeetingParticipantUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when VirtualClassActiveUpdated is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onVirtualClassActiveUpdated = (callback: (arg: VirtualClassDetailDto) => void) => {
        return this.on('VirtualClassActiveUpdated', callback);
    }
    
    /**
    * Wires up a callback to be invoked when VirtualClassActiveRemoved is triggered by DataHub
    *
    * @param callback The callback to be invoked.
    */
    onVirtualClassActiveRemoved = (callback: (arg: VirtualClassDetailDto) => void) => {
        return this.on('VirtualClassActiveRemoved', callback);
    }
    

    // convenience methods to bind updates/removes to an array
    
    /**
    * Bind AsteriskCallUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of AsteriskCallDto to update
    */
    bindAsteriskCallUpdated = (arrayFunc: () => Array<AsteriskCallDto>) => {
        return this.on('AsteriskCallUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind AsteriskChannelUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of AsteriskChannelDto to update
    */
    bindAsteriskChannelUpdated = (arrayFunc: () => Array<AsteriskChannelDto>) => {
        return this.on('AsteriskChannelUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind AsteriskEventUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of AsteriskEventDto to update
    */
    bindAsteriskEventUpdated = (arrayFunc: () => Array<AsteriskEventDto>) => {
        return this.on('AsteriskEventUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind AsteriskExtensionUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of AsteriskExtensionDto to update
    */
    bindAsteriskExtensionUpdated = (arrayFunc: () => Array<AsteriskExtensionDto>) => {
        return this.on('AsteriskExtensionUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind AsteriskQueueMemberUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of AsteriskQueueMemberSessionDto to update
    */
    bindAsteriskQueueMemberUpdated = (arrayFunc: () => Array<AsteriskQueueMemberSessionDto>) => {
        return this.on('AsteriskQueueMemberUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind AsteriskRelatedCallUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of AsteriskCallDto to update
    */
    bindAsteriskRelatedCallUpdated = (arrayFunc: () => Array<AsteriskCallDto>) => {
        return this.on('AsteriskRelatedCallUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind AttendanceRecordUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of AttendanceRecordDto to update
    */
    bindAttendanceRecordUpdated = (arrayFunc: () => Array<AttendanceRecordDto>) => {
        return this.on('AttendanceRecordUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind CertificateRequestUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of CertificateRequestDto to update
    */
    bindCertificateRequestUpdated = (arrayFunc: () => Array<CertificateRequestDto>) => {
        return this.on('CertificateRequestUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind CertificateTypeUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of CertificateTypeDto to update
    */
    bindCertificateTypeUpdated = (arrayFunc: () => Array<CertificateTypeDto>) => {
        return this.on('CertificateTypeUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind CertificateUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of CertificateDto to update
    */
    bindCertificateUpdated = (arrayFunc: () => Array<CertificateDto>) => {
        return this.on('CertificateUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind ClassInfoUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of ClassInfoDto to update
    */
    bindClassInfoUpdated = (arrayFunc: () => Array<ClassInfoDto>) => {
        return this.on('ClassInfoUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.classInfoId);
        });
    }
    
    /**
    * Bind ClassScheduleTodayUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of ClassDto to update
    */
    bindClassScheduleTodayUpdated = (arrayFunc: () => Array<ClassDto>) => {
        return this.on('ClassScheduleTodayUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind ClassScheduleUserUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of ClassDto to update
    */
    bindClassScheduleUserUpdated = (arrayFunc: () => Array<ClassDto>) => {
        return this.on('ClassScheduleUserUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind ClassTypeUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of ClassTypeDto to update
    */
    bindClassTypeUpdated = (arrayFunc: () => Array<ClassTypeDto>) => {
        return this.on('ClassTypeUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind ClassUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of ClassDto to update
    */
    bindClassUpdated = (arrayFunc: () => Array<ClassDto>) => {
        return this.on('ClassUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind ContractFullUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of ContractFullDto to update
    */
    bindContractFullUpdated = (arrayFunc: () => Array<ContractFullDto>) => {
        return this.on('ContractFullUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind ContractUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of ContractDto to update
    */
    bindContractUpdated = (arrayFunc: () => Array<ContractDto>) => {
        return this.on('ContractUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind CountyUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of CountyDto to update
    */
    bindCountyUpdated = (arrayFunc: () => Array<CountyDto>) => {
        return this.on('CountyUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind DocumentTypeUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of DocumentTypeDto to update
    */
    bindDocumentTypeUpdated = (arrayFunc: () => Array<DocumentTypeDto>) => {
        return this.on('DocumentTypeUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind DocumentUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of DocumentDto to update
    */
    bindDocumentUpdated = (arrayFunc: () => Array<DocumentDto>) => {
        return this.on('DocumentUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind DrivingRecordTypeUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of DrivingRecordTypeDto to update
    */
    bindDrivingRecordTypeUpdated = (arrayFunc: () => Array<DrivingRecordTypeDto>) => {
        return this.on('DrivingRecordTypeUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind DrivingRecordUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of DrivingRecordDto to update
    */
    bindDrivingRecordUpdated = (arrayFunc: () => Array<DrivingRecordDto>) => {
        return this.on('DrivingRecordUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind FeedbackMessageUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of FeedbackMessageDto to update
    */
    bindFeedbackMessageUpdated = (arrayFunc: () => Array<FeedbackMessageDto>) => {
        return this.on('FeedbackMessageUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind FeedbackUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of FeedbackDto to update
    */
    bindFeedbackUpdated = (arrayFunc: () => Array<FeedbackDto>) => {
        return this.on('FeedbackUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind GiftCertificateDetailUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of GiftCertificateDetailDto to update
    */
    bindGiftCertificateDetailUpdated = (arrayFunc: () => Array<GiftCertificateDetailDto>) => {
        return this.on('GiftCertificateDetailUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind GiftCertificateTemplateUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of GiftCertificateTemplateDto to update
    */
    bindGiftCertificateTemplateUpdated = (arrayFunc: () => Array<GiftCertificateTemplateDto>) => {
        return this.on('GiftCertificateTemplateUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind GiftCertificateTypeUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of GiftCertificateTypeDto to update
    */
    bindGiftCertificateTypeUpdated = (arrayFunc: () => Array<GiftCertificateTypeDto>) => {
        return this.on('GiftCertificateTypeUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind GiftCertificateUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of GiftCertificateDto to update
    */
    bindGiftCertificateUpdated = (arrayFunc: () => Array<GiftCertificateDto>) => {
        return this.on('GiftCertificateUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind HighSchoolUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of HighSchoolDto to update
    */
    bindHighSchoolUpdated = (arrayFunc: () => Array<HighSchoolDto>) => {
        return this.on('HighSchoolUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind JanusParticipantUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of VirtualClassParticipantDto to update
    */
    bindJanusParticipantUpdated = (arrayFunc: () => Array<VirtualClassParticipantDto>) => {
        return this.on('JanusParticipantUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind LocationUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of LocationDto to update
    */
    bindLocationUpdated = (arrayFunc: () => Array<LocationDto>) => {
        return this.on('LocationUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind LogUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of LogDto to update
    */
    bindLogUpdated = (arrayFunc: () => Array<LogDto>) => {
        return this.on('LogUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind MailMessageEventUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of MailMessageEventDto to update
    */
    bindMailMessageEventUpdated = (arrayFunc: () => Array<MailMessageEventDto>) => {
        return this.on('MailMessageEventUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind MailMessageUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of MailMessageDto to update
    */
    bindMailMessageUpdated = (arrayFunc: () => Array<MailMessageDto>) => {
        return this.on('MailMessageUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind PreRegUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of PreRegDto to update
    */
    bindPreRegUpdated = (arrayFunc: () => Array<PreRegDto>) => {
        return this.on('PreRegUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind PriceLevelUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of PricingDto to update
    */
    bindPriceLevelUpdated = (arrayFunc: () => Array<PricingDto>) => {
        return this.on('PriceLevelUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind PrinterUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of PrinterDto to update
    */
    bindPrinterUpdated = (arrayFunc: () => Array<PrinterDto>) => {
        return this.on('PrinterUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind ReferralHeardFromUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of ReferralHeardFromDto to update
    */
    bindReferralHeardFromUpdated = (arrayFunc: () => Array<ReferralHeardFromDto>) => {
        return this.on('ReferralHeardFromUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind ReferralWhyChoseUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of ReferralWhyChoseDto to update
    */
    bindReferralWhyChoseUpdated = (arrayFunc: () => Array<ReferralWhyChoseDto>) => {
        return this.on('ReferralWhyChoseUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind SignoutRecordUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of SignoutRecordDto to update
    */
    bindSignoutRecordUpdated = (arrayFunc: () => Array<SignoutRecordDto>) => {
        return this.on('SignoutRecordUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentContractSummaryUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of StudentContractSummaryDto to update
    */
    bindStudentContractSummaryUpdated = (arrayFunc: () => Array<StudentContractSummaryDto>) => {
        return this.on('StudentContractSummaryUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentContractUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of StudentContractDto to update
    */
    bindStudentContractUpdated = (arrayFunc: () => Array<StudentContractDto>) => {
        return this.on('StudentContractUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentExamAssignedUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of StudentExamDto to update
    */
    bindStudentExamAssignedUpdated = (arrayFunc: () => Array<StudentExamDto>) => {
        return this.on('StudentExamAssignedUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentExamConnectedUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of StudentExamDto to update
    */
    bindStudentExamConnectedUpdated = (arrayFunc: () => Array<StudentExamDto>) => {
        return this.on('StudentExamConnectedUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentExamLogUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of StudentExamLogDto to update
    */
    bindStudentExamLogUpdated = (arrayFunc: () => Array<StudentExamLogDto>) => {
        return this.on('StudentExamLogUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentExamMessageUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of StudentExamMessageDto to update
    */
    bindStudentExamMessageUpdated = (arrayFunc: () => Array<StudentExamMessageDto>) => {
        return this.on('StudentExamMessageUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentExamTranscriptUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of StudentExamTranscriptDto to update
    */
    bindStudentExamTranscriptUpdated = (arrayFunc: () => Array<StudentExamTranscriptDto>) => {
        return this.on('StudentExamTranscriptUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentExamUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of StudentExamDto to update
    */
    bindStudentExamUpdated = (arrayFunc: () => Array<StudentExamDto>) => {
        return this.on('StudentExamUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentExamWaitingUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of StudentExamDto to update
    */
    bindStudentExamWaitingUpdated = (arrayFunc: () => Array<StudentExamDto>) => {
        return this.on('StudentExamWaitingUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentInCarAvailableUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of StudentDto to update
    */
    bindStudentInCarAvailableUpdated = (arrayFunc: () => Array<StudentDto>) => {
        return this.on('StudentInCarAvailableUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentInCarUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of StudentInCarDto to update
    */
    bindStudentInCarUpdated = (arrayFunc: () => Array<StudentInCarDto>) => {
        return this.on('StudentInCarUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentLogUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of StudentLogDto to update
    */
    bindStudentLogUpdated = (arrayFunc: () => Array<StudentLogDto>) => {
        return this.on('StudentLogUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentNoteUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of StudentNoteDto to update
    */
    bindStudentNoteUpdated = (arrayFunc: () => Array<StudentNoteDto>) => {
        return this.on('StudentNoteUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentReleaseReadyUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of StudentReleaseDto to update
    */
    bindStudentReleaseReadyUpdated = (arrayFunc: () => Array<StudentReleaseDto>) => {
        return this.on('StudentReleaseReadyUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentStuckUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of StudentStuckDto to update
    */
    bindStudentStuckUpdated = (arrayFunc: () => Array<StudentStuckDto>) => {
        return this.on('StudentStuckUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentTrainingReportUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of StudentWithTestScoresDto to update
    */
    bindStudentTrainingReportUpdated = (arrayFunc: () => Array<StudentWithTestScoresDto>) => {
        return this.on('StudentTrainingReportUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentTypeUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of StudentTypeDto to update
    */
    bindStudentTypeUpdated = (arrayFunc: () => Array<StudentTypeDto>) => {
        return this.on('StudentTypeUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of StudentDto to update
    */
    bindStudentUpdated = (arrayFunc: () => Array<StudentDto>) => {
        return this.on('StudentUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentWithoutPassingTestUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of StudentDto to update
    */
    bindStudentWithoutPassingTestUpdated = (arrayFunc: () => Array<StudentDto>) => {
        return this.on('StudentWithoutPassingTestUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentWithoutSignedContractUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of StudentDto to update
    */
    bindStudentWithoutSignedContractUpdated = (arrayFunc: () => Array<StudentDto>) => {
        return this.on('StudentWithoutSignedContractUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentWithTestScoreUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of StudentWithTestScoresDto to update
    */
    bindStudentWithTestScoreUpdated = (arrayFunc: () => Array<StudentWithTestScoresDto>) => {
        return this.on('StudentWithTestScoreUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind TransactionTypeUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of TransactionTypeDto to update
    */
    bindTransactionTypeUpdated = (arrayFunc: () => Array<TransactionTypeDto>) => {
        return this.on('TransactionTypeUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind TransactionUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of TransactionDto to update
    */
    bindTransactionUpdated = (arrayFunc: () => Array<TransactionDto>) => {
        return this.on('TransactionUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind UserActivityUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of UserActivityDto to update
    */
    bindUserActivityUpdated = (arrayFunc: () => Array<UserActivityDto>) => {
        return this.on('UserActivityUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.connectionId);
        });
    }
    
    /**
    * Bind UserDisplayUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of UserDisplayDto to update
    */
    bindUserDisplayUpdated = (arrayFunc: () => Array<UserDisplayDto>) => {
        return this.on('UserDisplayUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind UserStorageUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of UserStorageDto to update
    */
    bindUserStorageUpdated = (arrayFunc: () => Array<UserStorageDto>) => {
        return this.on('UserStorageUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.userId);
        });
    }
    
    /**
    * Bind UserUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of UserDto to update
    */
    bindUserUpdated = (arrayFunc: () => Array<UserDto>) => {
        return this.on('UserUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind VirtualClassMessageUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of VirtualClassMessageDto to update
    */
    bindVirtualClassMessageUpdated = (arrayFunc: () => Array<VirtualClassMessageDto>) => {
        return this.on('VirtualClassMessageUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind VirtualClassParticipantDetailUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of VirtualClassParticipantDetailDto to update
    */
    bindVirtualClassParticipantDetailUpdated = (arrayFunc: () => Array<VirtualClassParticipantDetailDto>) => {
        return this.on('VirtualClassParticipantDetailUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind VirtualClassParticipantMessageUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of VirtualClassParticipantMessageDto to update
    */
    bindVirtualClassParticipantMessageUpdated = (arrayFunc: () => Array<VirtualClassParticipantMessageDto>) => {
        return this.on('VirtualClassParticipantMessageUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind VirtualClassStudentFormUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of StudentFormDto to update
    */
    bindVirtualClassStudentFormUpdated = (arrayFunc: () => Array<StudentFormDto>) => {
        return this.on('VirtualClassStudentFormUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind VirtualClassUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of VirtualClassDto to update
    */
    bindVirtualClassUpdated = (arrayFunc: () => Array<VirtualClassDto>) => {
        return this.on('VirtualClassUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    
    /**
    * Bind ZoomMeetingParticipantUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of ZoomMeetingParticipantDto to update
    */
    bindZoomMeetingParticipantUpdated = (arrayFunc: () => Array<ZoomMeetingParticipantDto>) => {
        return this.on('ZoomMeetingParticipantUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.rowId);
        });
    }
    
    /**
    * Bind VirtualClassActiveUpdated to a local array
    *
    * @param arrayFunc A function that returns the array of VirtualClassDetailDto to update
    */
    bindVirtualClassActiveUpdated = (arrayFunc: () => Array<VirtualClassDetailDto>) => {
        return this.on('VirtualClassActiveUpdated', entity => {
            let array = arrayFunc();
            array.merge(entity, x => x.id);
        });
    }
    

    
    /**
    * Bind AsteriskCallRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of AsteriskCallDto to update
    */
    bindAsteriskCallRemoved = (arrayFunc: () => Array<AsteriskCallDto>) => {
        return this.on('AsteriskCallRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind AsteriskChannelRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of AsteriskChannelDto to update
    */
    bindAsteriskChannelRemoved = (arrayFunc: () => Array<AsteriskChannelDto>) => {
        return this.on('AsteriskChannelRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind AsteriskExtensionRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of AsteriskExtensionDto to update
    */
    bindAsteriskExtensionRemoved = (arrayFunc: () => Array<AsteriskExtensionDto>) => {
        return this.on('AsteriskExtensionRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind AsteriskQueueMemberRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of AsteriskQueueMemberSessionDto to update
    */
    bindAsteriskQueueMemberRemoved = (arrayFunc: () => Array<AsteriskQueueMemberSessionDto>) => {
        return this.on('AsteriskQueueMemberRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind AttendanceRecordRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of AttendanceRecordDto to update
    */
    bindAttendanceRecordRemoved = (arrayFunc: () => Array<AttendanceRecordDto>) => {
        return this.on('AttendanceRecordRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind CertificateRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of CertificateDto to update
    */
    bindCertificateRemoved = (arrayFunc: () => Array<CertificateDto>) => {
        return this.on('CertificateRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind CertificateRequestRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of CertificateRequestDto to update
    */
    bindCertificateRequestRemoved = (arrayFunc: () => Array<CertificateRequestDto>) => {
        return this.on('CertificateRequestRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind CertificateTypeRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of CertificateTypeDto to update
    */
    bindCertificateTypeRemoved = (arrayFunc: () => Array<CertificateTypeDto>) => {
        return this.on('CertificateTypeRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind ClassInfoRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of ClassInfoDto to update
    */
    bindClassInfoRemoved = (arrayFunc: () => Array<ClassInfoDto>) => {
        return this.on('ClassInfoRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.classInfoId);
        });
    }
    
    /**
    * Bind ClassRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of ClassDto to update
    */
    bindClassRemoved = (arrayFunc: () => Array<ClassDto>) => {
        return this.on('ClassRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind ClassScheduleTodayRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of ClassDto to update
    */
    bindClassScheduleTodayRemoved = (arrayFunc: () => Array<ClassDto>) => {
        return this.on('ClassScheduleTodayRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind ClassScheduleUserRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of ClassDto to update
    */
    bindClassScheduleUserRemoved = (arrayFunc: () => Array<ClassDto>) => {
        return this.on('ClassScheduleUserRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind ClassTypeRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of ClassTypeDto to update
    */
    bindClassTypeRemoved = (arrayFunc: () => Array<ClassTypeDto>) => {
        return this.on('ClassTypeRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind ContractFullRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of ContractFullDto to update
    */
    bindContractFullRemoved = (arrayFunc: () => Array<ContractFullDto>) => {
        return this.on('ContractFullRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind ContractRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of ContractDto to update
    */
    bindContractRemoved = (arrayFunc: () => Array<ContractDto>) => {
        return this.on('ContractRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind DocumentRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of DocumentDto to update
    */
    bindDocumentRemoved = (arrayFunc: () => Array<DocumentDto>) => {
        return this.on('DocumentRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind DocumentTypeRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of DocumentTypeDto to update
    */
    bindDocumentTypeRemoved = (arrayFunc: () => Array<DocumentTypeDto>) => {
        return this.on('DocumentTypeRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind DrivingRecordRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of DrivingRecordDto to update
    */
    bindDrivingRecordRemoved = (arrayFunc: () => Array<DrivingRecordDto>) => {
        return this.on('DrivingRecordRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind FeedbackMessageRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of FeedbackMessageDto to update
    */
    bindFeedbackMessageRemoved = (arrayFunc: () => Array<FeedbackMessageDto>) => {
        return this.on('FeedbackMessageRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind FeedbackRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of FeedbackDto to update
    */
    bindFeedbackRemoved = (arrayFunc: () => Array<FeedbackDto>) => {
        return this.on('FeedbackRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind GiftCertificateRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of GiftCertificateDto to update
    */
    bindGiftCertificateRemoved = (arrayFunc: () => Array<GiftCertificateDto>) => {
        return this.on('GiftCertificateRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind GiftCertificateTemplateRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of GiftCertificateTemplateDto to update
    */
    bindGiftCertificateTemplateRemoved = (arrayFunc: () => Array<GiftCertificateTemplateDto>) => {
        return this.on('GiftCertificateTemplateRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind GiftCertificateTypeRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of GiftCertificateTypeDto to update
    */
    bindGiftCertificateTypeRemoved = (arrayFunc: () => Array<GiftCertificateTypeDto>) => {
        return this.on('GiftCertificateTypeRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind JanusParticipantRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of VirtualClassParticipantDto to update
    */
    bindJanusParticipantRemoved = (arrayFunc: () => Array<VirtualClassParticipantDto>) => {
        return this.on('JanusParticipantRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind LogRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of LogDto to update
    */
    bindLogRemoved = (arrayFunc: () => Array<LogDto>) => {
        return this.on('LogRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind MailMessageEventRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of MailMessageEventDto to update
    */
    bindMailMessageEventRemoved = (arrayFunc: () => Array<MailMessageEventDto>) => {
        return this.on('MailMessageEventRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind MailMessageRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of MailMessageDto to update
    */
    bindMailMessageRemoved = (arrayFunc: () => Array<MailMessageDto>) => {
        return this.on('MailMessageRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind PreRegRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of PreRegDto to update
    */
    bindPreRegRemoved = (arrayFunc: () => Array<PreRegDto>) => {
        return this.on('PreRegRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind PriceLevelRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of PricingDto to update
    */
    bindPriceLevelRemoved = (arrayFunc: () => Array<PricingDto>) => {
        return this.on('PriceLevelRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind PrinterRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of PrinterDto to update
    */
    bindPrinterRemoved = (arrayFunc: () => Array<PrinterDto>) => {
        return this.on('PrinterRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind SignoutRecordRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of SignoutRecordDto to update
    */
    bindSignoutRecordRemoved = (arrayFunc: () => Array<SignoutRecordDto>) => {
        return this.on('SignoutRecordRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentContractRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of StudentContractDto to update
    */
    bindStudentContractRemoved = (arrayFunc: () => Array<StudentContractDto>) => {
        return this.on('StudentContractRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentContractSummaryRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of StudentContractSummaryDto to update
    */
    bindStudentContractSummaryRemoved = (arrayFunc: () => Array<StudentContractSummaryDto>) => {
        return this.on('StudentContractSummaryRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentExamAssignedRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of StudentExamDto to update
    */
    bindStudentExamAssignedRemoved = (arrayFunc: () => Array<StudentExamDto>) => {
        return this.on('StudentExamAssignedRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentExamConnectedRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of StudentExamDto to update
    */
    bindStudentExamConnectedRemoved = (arrayFunc: () => Array<StudentExamDto>) => {
        return this.on('StudentExamConnectedRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentExamLogRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of StudentExamLogDto to update
    */
    bindStudentExamLogRemoved = (arrayFunc: () => Array<StudentExamLogDto>) => {
        return this.on('StudentExamLogRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentExamMessageRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of StudentExamMessageDto to update
    */
    bindStudentExamMessageRemoved = (arrayFunc: () => Array<StudentExamMessageDto>) => {
        return this.on('StudentExamMessageRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentExamRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of StudentExamDto to update
    */
    bindStudentExamRemoved = (arrayFunc: () => Array<StudentExamDto>) => {
        return this.on('StudentExamRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentExamWaitingRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of StudentExamDto to update
    */
    bindStudentExamWaitingRemoved = (arrayFunc: () => Array<StudentExamDto>) => {
        return this.on('StudentExamWaitingRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentInCarAvailableRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of StudentDto to update
    */
    bindStudentInCarAvailableRemoved = (arrayFunc: () => Array<StudentDto>) => {
        return this.on('StudentInCarAvailableRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentInCarRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of StudentInCarDto to update
    */
    bindStudentInCarRemoved = (arrayFunc: () => Array<StudentInCarDto>) => {
        return this.on('StudentInCarRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentLogRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of StudentLogDto to update
    */
    bindStudentLogRemoved = (arrayFunc: () => Array<StudentLogDto>) => {
        return this.on('StudentLogRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentNoteRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of StudentNoteDto to update
    */
    bindStudentNoteRemoved = (arrayFunc: () => Array<StudentNoteDto>) => {
        return this.on('StudentNoteRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentReleaseReadyRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of StudentReleaseDto to update
    */
    bindStudentReleaseReadyRemoved = (arrayFunc: () => Array<StudentReleaseDto>) => {
        return this.on('StudentReleaseReadyRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of StudentDto to update
    */
    bindStudentRemoved = (arrayFunc: () => Array<StudentDto>) => {
        return this.on('StudentRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentStuckRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of StudentStuckDto to update
    */
    bindStudentStuckRemoved = (arrayFunc: () => Array<StudentStuckDto>) => {
        return this.on('StudentStuckRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentWithoutPassingTestRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of StudentDto to update
    */
    bindStudentWithoutPassingTestRemoved = (arrayFunc: () => Array<StudentDto>) => {
        return this.on('StudentWithoutPassingTestRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentWithoutSignedContractRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of StudentDto to update
    */
    bindStudentWithoutSignedContractRemoved = (arrayFunc: () => Array<StudentDto>) => {
        return this.on('StudentWithoutSignedContractRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind StudentWithTestScoreRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of StudentWithTestScoresDto to update
    */
    bindStudentWithTestScoreRemoved = (arrayFunc: () => Array<StudentWithTestScoresDto>) => {
        return this.on('StudentWithTestScoreRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind TransactionRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of TransactionDto to update
    */
    bindTransactionRemoved = (arrayFunc: () => Array<TransactionDto>) => {
        return this.on('TransactionRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind TransactionTypeRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of TransactionTypeDto to update
    */
    bindTransactionTypeRemoved = (arrayFunc: () => Array<TransactionTypeDto>) => {
        return this.on('TransactionTypeRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind UserActivityRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of UserActivityDto to update
    */
    bindUserActivityRemoved = (arrayFunc: () => Array<UserActivityDto>) => {
        return this.on('UserActivityRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.connectionId);
        });
    }
    
    /**
    * Bind UserDisplayRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of UserDisplayDto to update
    */
    bindUserDisplayRemoved = (arrayFunc: () => Array<UserDisplayDto>) => {
        return this.on('UserDisplayRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind UserRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of UserDto to update
    */
    bindUserRemoved = (arrayFunc: () => Array<UserDto>) => {
        return this.on('UserRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind UserStorageRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of UserStorageDto to update
    */
    bindUserStorageRemoved = (arrayFunc: () => Array<UserStorageDto>) => {
        return this.on('UserStorageRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.userId);
        });
    }
    
    /**
    * Bind VirtualClassMessageRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of VirtualClassMessageDto to update
    */
    bindVirtualClassMessageRemoved = (arrayFunc: () => Array<VirtualClassMessageDto>) => {
        return this.on('VirtualClassMessageRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind VirtualClassParticipantDetailRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of VirtualClassParticipantDetailDto to update
    */
    bindVirtualClassParticipantDetailRemoved = (arrayFunc: () => Array<VirtualClassParticipantDetailDto>) => {
        return this.on('VirtualClassParticipantDetailRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind VirtualClassParticipantMessageRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of VirtualClassParticipantMessageDto to update
    */
    bindVirtualClassParticipantMessageRemoved = (arrayFunc: () => Array<VirtualClassParticipantMessageDto>) => {
        return this.on('VirtualClassParticipantMessageRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind VirtualClassRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of VirtualClassDto to update
    */
    bindVirtualClassRemoved = (arrayFunc: () => Array<VirtualClassDto>) => {
        return this.on('VirtualClassRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind VirtualClassStudentFormRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of StudentFormDto to update
    */
    bindVirtualClassStudentFormRemoved = (arrayFunc: () => Array<StudentFormDto>) => {
        return this.on('VirtualClassStudentFormRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    
    /**
    * Bind ZoomMeetingParticipantRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of ZoomMeetingParticipantDto to update
    */
    bindZoomMeetingParticipantRemoved = (arrayFunc: () => Array<ZoomMeetingParticipantDto>) => {
        return this.on('ZoomMeetingParticipantRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.rowId);
        });
    }
    
    /**
    * Bind VirtualClassActiveRemoved to a local array
    *
    * @param arrayFunc A function that returns the array of VirtualClassDetailDto to update
    */
    bindVirtualClassActiveRemoved = (arrayFunc: () => Array<VirtualClassDetailDto>) => {
        return this.on('VirtualClassActiveRemoved', entity => {
            let array = arrayFunc();
            array.remove(entity, x => x.id);
        });
    }
    

    
    bindAllAsteriskCall = (arrayFunc: () => Array<AsteriskCallDto>) => {
        this.bindAsteriskCallRemoved(arrayFunc);
        this.bindAsteriskCallUpdated(arrayFunc);
        
    }
    
    bindAllAsteriskChannel = (arrayFunc: () => Array<AsteriskChannelDto>) => {
        this.bindAsteriskChannelRemoved(arrayFunc);
        this.bindAsteriskChannelUpdated(arrayFunc);
        
    }
    
    bindAllAsteriskEvent = (arrayFunc: () => Array<AsteriskEventDto>) => {
        this.bindAsteriskEventUpdated(arrayFunc);
        
    }
    
    bindAllAsteriskExtension = (arrayFunc: () => Array<AsteriskExtensionDto>) => {
        this.bindAsteriskExtensionRemoved(arrayFunc);
        this.bindAsteriskExtensionUpdated(arrayFunc);
        
    }
    
    bindAllAsteriskQueueMember = (arrayFunc: () => Array<AsteriskQueueMemberSessionDto>) => {
        this.bindAsteriskQueueMemberRemoved(arrayFunc);
        this.bindAsteriskQueueMemberUpdated(arrayFunc);
        
    }
    
    bindAllAsteriskRelatedCall = (arrayFunc: () => Array<AsteriskCallDto>) => {
        this.bindAsteriskRelatedCallUpdated(arrayFunc);
        
    }
    
    bindAllAttendanceRecord = (arrayFunc: () => Array<AttendanceRecordDto>) => {
        this.bindAttendanceRecordRemoved(arrayFunc);
        this.bindAttendanceRecordUpdated(arrayFunc);
        
    }
    
    bindAllCertificate = (arrayFunc: () => Array<CertificateDto>) => {
        this.bindCertificateRemoved(arrayFunc);
        this.bindCertificateUpdated(arrayFunc);
        
    }
    
    bindAllCertificateRequest = (arrayFunc: () => Array<CertificateRequestDto>) => {
        this.bindCertificateRequestRemoved(arrayFunc);
        this.bindCertificateRequestUpdated(arrayFunc);
        
    }
    
    bindAllCertificateType = (arrayFunc: () => Array<CertificateTypeDto>) => {
        this.bindCertificateTypeRemoved(arrayFunc);
        this.bindCertificateTypeUpdated(arrayFunc);
        
    }
    
    bindAllClassInfo = (arrayFunc: () => Array<ClassInfoDto>) => {
        this.bindClassInfoRemoved(arrayFunc);
        this.bindClassInfoUpdated(arrayFunc);
        
    }
    
    bindAllClass = (arrayFunc: () => Array<ClassDto>) => {
        this.bindClassRemoved(arrayFunc);
        this.bindClassUpdated(arrayFunc);
        
    }
    
    bindAllClassScheduleToday = (arrayFunc: () => Array<ClassDto>) => {
        this.bindClassScheduleTodayRemoved(arrayFunc);
        this.bindClassScheduleTodayUpdated(arrayFunc);
        
    }
    
    bindAllClassScheduleUser = (arrayFunc: () => Array<ClassDto>) => {
        this.bindClassScheduleUserRemoved(arrayFunc);
        this.bindClassScheduleUserUpdated(arrayFunc);
        
    }
    
    bindAllClassType = (arrayFunc: () => Array<ClassTypeDto>) => {
        this.bindClassTypeRemoved(arrayFunc);
        this.bindClassTypeUpdated(arrayFunc);
        
    }
    
    bindAllContractFull = (arrayFunc: () => Array<ContractFullDto>) => {
        this.bindContractFullRemoved(arrayFunc);
        this.bindContractFullUpdated(arrayFunc);
        
    }
    
    bindAllContract = (arrayFunc: () => Array<ContractDto>) => {
        this.bindContractRemoved(arrayFunc);
        this.bindContractUpdated(arrayFunc);
        
    }
    
    bindAllCounty = (arrayFunc: () => Array<CountyDto>) => {
        this.bindCountyUpdated(arrayFunc);
        
    }
    
    bindAllDocument = (arrayFunc: () => Array<DocumentDto>) => {
        this.bindDocumentRemoved(arrayFunc);
        this.bindDocumentUpdated(arrayFunc);
        
    }
    
    bindAllDocumentType = (arrayFunc: () => Array<DocumentTypeDto>) => {
        this.bindDocumentTypeRemoved(arrayFunc);
        this.bindDocumentTypeUpdated(arrayFunc);
        
    }
    
    bindAllDrivingRecord = (arrayFunc: () => Array<DrivingRecordDto>) => {
        this.bindDrivingRecordRemoved(arrayFunc);
        this.bindDrivingRecordUpdated(arrayFunc);
        
    }
    
    bindAllDrivingRecordType = (arrayFunc: () => Array<DrivingRecordTypeDto>) => {
        this.bindDrivingRecordTypeUpdated(arrayFunc);
        
    }
    
    bindAllFeedbackMessage = (arrayFunc: () => Array<FeedbackMessageDto>) => {
        this.bindFeedbackMessageRemoved(arrayFunc);
        this.bindFeedbackMessageUpdated(arrayFunc);
        
    }
    
    bindAllFeedback = (arrayFunc: () => Array<FeedbackDto>) => {
        this.bindFeedbackRemoved(arrayFunc);
        this.bindFeedbackUpdated(arrayFunc);
        
    }
    
    bindAllGiftCertificateDetail = (arrayFunc: () => Array<GiftCertificateDetailDto>) => {
        this.bindGiftCertificateDetailUpdated(arrayFunc);
        
    }
    
    bindAllGiftCertificate = (arrayFunc: () => Array<GiftCertificateDto>) => {
        this.bindGiftCertificateRemoved(arrayFunc);
        this.bindGiftCertificateUpdated(arrayFunc);
        
    }
    
    bindAllGiftCertificateTemplate = (arrayFunc: () => Array<GiftCertificateTemplateDto>) => {
        this.bindGiftCertificateTemplateRemoved(arrayFunc);
        this.bindGiftCertificateTemplateUpdated(arrayFunc);
        
    }
    
    bindAllGiftCertificateType = (arrayFunc: () => Array<GiftCertificateTypeDto>) => {
        this.bindGiftCertificateTypeRemoved(arrayFunc);
        this.bindGiftCertificateTypeUpdated(arrayFunc);
        
    }
    
    bindAllHighSchool = (arrayFunc: () => Array<HighSchoolDto>) => {
        this.bindHighSchoolUpdated(arrayFunc);
        
    }
    
    bindAllJanusParticipant = (arrayFunc: () => Array<VirtualClassParticipantDto>) => {
        this.bindJanusParticipantRemoved(arrayFunc);
        this.bindJanusParticipantUpdated(arrayFunc);
        
    }
    
    bindAllLocation = (arrayFunc: () => Array<LocationDto>) => {
        this.bindLocationUpdated(arrayFunc);
        
    }
    
    bindAllLog = (arrayFunc: () => Array<LogDto>) => {
        this.bindLogRemoved(arrayFunc);
        this.bindLogUpdated(arrayFunc);
        
    }
    
    bindAllMailMessageEvent = (arrayFunc: () => Array<MailMessageEventDto>) => {
        this.bindMailMessageEventRemoved(arrayFunc);
        this.bindMailMessageEventUpdated(arrayFunc);
        
    }
    
    bindAllMailMessage = (arrayFunc: () => Array<MailMessageDto>) => {
        this.bindMailMessageRemoved(arrayFunc);
        this.bindMailMessageUpdated(arrayFunc);
        
    }
    
    bindAllPreReg = (arrayFunc: () => Array<PreRegDto>) => {
        this.bindPreRegRemoved(arrayFunc);
        this.bindPreRegUpdated(arrayFunc);
        
    }
    
    bindAllPriceLevel = (arrayFunc: () => Array<PricingDto>) => {
        this.bindPriceLevelRemoved(arrayFunc);
        this.bindPriceLevelUpdated(arrayFunc);
        
    }
    
    bindAllPrinter = (arrayFunc: () => Array<PrinterDto>) => {
        this.bindPrinterRemoved(arrayFunc);
        this.bindPrinterUpdated(arrayFunc);
        
    }
    
    bindAllReferralHeardFrom = (arrayFunc: () => Array<ReferralHeardFromDto>) => {
        this.bindReferralHeardFromUpdated(arrayFunc);
        
    }
    
    bindAllReferralWhyChose = (arrayFunc: () => Array<ReferralWhyChoseDto>) => {
        this.bindReferralWhyChoseUpdated(arrayFunc);
        
    }
    
    bindAllSignoutRecord = (arrayFunc: () => Array<SignoutRecordDto>) => {
        this.bindSignoutRecordRemoved(arrayFunc);
        this.bindSignoutRecordUpdated(arrayFunc);
        
    }
    
    bindAllStudentContract = (arrayFunc: () => Array<StudentContractDto>) => {
        this.bindStudentContractRemoved(arrayFunc);
        this.bindStudentContractUpdated(arrayFunc);
        
    }
    
    bindAllStudentContractSummary = (arrayFunc: () => Array<StudentContractSummaryDto>) => {
        this.bindStudentContractSummaryRemoved(arrayFunc);
        this.bindStudentContractSummaryUpdated(arrayFunc);
        
    }
    
    bindAllStudentExamAssigned = (arrayFunc: () => Array<StudentExamDto>) => {
        this.bindStudentExamAssignedRemoved(arrayFunc);
        this.bindStudentExamAssignedUpdated(arrayFunc);
        
    }
    
    bindAllStudentExamConnected = (arrayFunc: () => Array<StudentExamDto>) => {
        this.bindStudentExamConnectedRemoved(arrayFunc);
        this.bindStudentExamConnectedUpdated(arrayFunc);
        
    }
    
    bindAllStudentExamLog = (arrayFunc: () => Array<StudentExamLogDto>) => {
        this.bindStudentExamLogRemoved(arrayFunc);
        this.bindStudentExamLogUpdated(arrayFunc);
        
    }
    
    bindAllStudentExamMessage = (arrayFunc: () => Array<StudentExamMessageDto>) => {
        this.bindStudentExamMessageRemoved(arrayFunc);
        this.bindStudentExamMessageUpdated(arrayFunc);
        
    }
    
    bindAllStudentExam = (arrayFunc: () => Array<StudentExamDto>) => {
        this.bindStudentExamRemoved(arrayFunc);
        this.bindStudentExamUpdated(arrayFunc);
        
    }
    
    bindAllStudentExamTranscript = (arrayFunc: () => Array<StudentExamTranscriptDto>) => {
        this.bindStudentExamTranscriptUpdated(arrayFunc);
        
    }
    
    bindAllStudentExamWaiting = (arrayFunc: () => Array<StudentExamDto>) => {
        this.bindStudentExamWaitingRemoved(arrayFunc);
        this.bindStudentExamWaitingUpdated(arrayFunc);
        
    }
    
    bindAllStudentInCarAvailable = (arrayFunc: () => Array<StudentDto>) => {
        this.bindStudentInCarAvailableRemoved(arrayFunc);
        this.bindStudentInCarAvailableUpdated(arrayFunc);
        
    }
    
    bindAllStudentInCar = (arrayFunc: () => Array<StudentInCarDto>) => {
        this.bindStudentInCarRemoved(arrayFunc);
        this.bindStudentInCarUpdated(arrayFunc);
        
    }
    
    bindAllStudentLog = (arrayFunc: () => Array<StudentLogDto>) => {
        this.bindStudentLogRemoved(arrayFunc);
        this.bindStudentLogUpdated(arrayFunc);
        
    }
    
    bindAllStudentNote = (arrayFunc: () => Array<StudentNoteDto>) => {
        this.bindStudentNoteRemoved(arrayFunc);
        this.bindStudentNoteUpdated(arrayFunc);
        
    }
    
    bindAllStudentReleaseReady = (arrayFunc: () => Array<StudentReleaseDto>) => {
        this.bindStudentReleaseReadyRemoved(arrayFunc);
        this.bindStudentReleaseReadyUpdated(arrayFunc);
        
    }
    
    bindAllStudent = (arrayFunc: () => Array<StudentDto>) => {
        this.bindStudentRemoved(arrayFunc);
        this.bindStudentUpdated(arrayFunc);
        
    }
    
    bindAllStudentStuck = (arrayFunc: () => Array<StudentStuckDto>) => {
        this.bindStudentStuckRemoved(arrayFunc);
        this.bindStudentStuckUpdated(arrayFunc);
        
    }
    
    bindAllStudentTrainingReport = (arrayFunc: () => Array<StudentWithTestScoresDto>) => {
        this.bindStudentTrainingReportUpdated(arrayFunc);
        
    }
    
    bindAllStudentType = (arrayFunc: () => Array<StudentTypeDto>) => {
        this.bindStudentTypeUpdated(arrayFunc);
        
    }
    
    bindAllStudentWithoutPassingTest = (arrayFunc: () => Array<StudentDto>) => {
        this.bindStudentWithoutPassingTestRemoved(arrayFunc);
        this.bindStudentWithoutPassingTestUpdated(arrayFunc);
        
    }
    
    bindAllStudentWithoutSignedContract = (arrayFunc: () => Array<StudentDto>) => {
        this.bindStudentWithoutSignedContractRemoved(arrayFunc);
        this.bindStudentWithoutSignedContractUpdated(arrayFunc);
        
    }
    
    bindAllStudentWithTestScore = (arrayFunc: () => Array<StudentWithTestScoresDto>) => {
        this.bindStudentWithTestScoreRemoved(arrayFunc);
        this.bindStudentWithTestScoreUpdated(arrayFunc);
        
    }
    
    bindAllTransaction = (arrayFunc: () => Array<TransactionDto>) => {
        this.bindTransactionRemoved(arrayFunc);
        this.bindTransactionUpdated(arrayFunc);
        
    }
    
    bindAllTransactionType = (arrayFunc: () => Array<TransactionTypeDto>) => {
        this.bindTransactionTypeRemoved(arrayFunc);
        this.bindTransactionTypeUpdated(arrayFunc);
        
    }
    
    bindAllUserActivity = (arrayFunc: () => Array<UserActivityDto>) => {
        this.bindUserActivityRemoved(arrayFunc);
        this.bindUserActivityUpdated(arrayFunc);
        
    }
    
    bindAllUserDisplay = (arrayFunc: () => Array<UserDisplayDto>) => {
        this.bindUserDisplayRemoved(arrayFunc);
        this.bindUserDisplayUpdated(arrayFunc);
        
    }
    
    bindAllUser = (arrayFunc: () => Array<UserDto>) => {
        this.bindUserRemoved(arrayFunc);
        this.bindUserUpdated(arrayFunc);
        
    }
    
    bindAllUserStorage = (arrayFunc: () => Array<UserStorageDto>) => {
        this.bindUserStorageRemoved(arrayFunc);
        this.bindUserStorageUpdated(arrayFunc);
        
    }
    
    bindAllVirtualClassMessage = (arrayFunc: () => Array<VirtualClassMessageDto>) => {
        this.bindVirtualClassMessageRemoved(arrayFunc);
        this.bindVirtualClassMessageUpdated(arrayFunc);
        
    }
    
    bindAllVirtualClassParticipantDetail = (arrayFunc: () => Array<VirtualClassParticipantDetailDto>) => {
        this.bindVirtualClassParticipantDetailRemoved(arrayFunc);
        this.bindVirtualClassParticipantDetailUpdated(arrayFunc);
        
    }
    
    bindAllVirtualClassParticipantMessage = (arrayFunc: () => Array<VirtualClassParticipantMessageDto>) => {
        this.bindVirtualClassParticipantMessageRemoved(arrayFunc);
        this.bindVirtualClassParticipantMessageUpdated(arrayFunc);
        
    }
    
    bindAllVirtualClass = (arrayFunc: () => Array<VirtualClassDto>) => {
        this.bindVirtualClassRemoved(arrayFunc);
        this.bindVirtualClassUpdated(arrayFunc);
        
    }
    
    bindAllVirtualClassStudentForm = (arrayFunc: () => Array<StudentFormDto>) => {
        this.bindVirtualClassStudentFormRemoved(arrayFunc);
        this.bindVirtualClassStudentFormUpdated(arrayFunc);
        
    }
    
    bindAllZoomMeetingParticipant = (arrayFunc: () => Array<ZoomMeetingParticipantDto>) => {
        this.bindZoomMeetingParticipantRemoved(arrayFunc);
        this.bindZoomMeetingParticipantUpdated(arrayFunc);
        
    }
    
    bindAllVirtualClassActive = (arrayFunc: () => Array<VirtualClassDetailDto>) => {
        this.bindVirtualClassActiveUpdated(arrayFunc);
        this.bindVirtualClassActiveRemoved(arrayFunc);
        
    }
    



}
