import * as angular from 'angular';
import {UserStorageService} from "../modules/UserStorageModule";
import {ngStorage} from "ngstorage";
import StorageService = ngStorage.StorageService;

function hint($sessionStorage: StorageService, $userStorage: UserStorageService) {
    return {
        restrict: 'E',
        transclude: true,
        scope: {
            id: '@id',
            title: '@title',
            class: '@class'
        },
        link: (scope: any, element, attrs) => {
            scope.$sessionStorage = $sessionStorage;
            scope.$userStorage = $userStorage;
            scope.key = `hint_${scope.id}_closed`;
            scope.class = scope.class || 'alert-info';
            if(!scope.class.startsWith('alert-'))
                scope.class = 'alert-' + scope.class;
        },
        template: require('./Hint.html')
    };
}

export default angular.module('directives.hint', [])
    .directive('hint', hint)
    .name;
