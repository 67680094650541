import * as angular from "angular";
import {ConfigService} from "../modules/ConfigModule";
import {RoleName} from "../model/RoleName";

function debug($config: ConfigService) {
    return {
        restrict: 'E',
        scope: {
            title: '@model',
            model: '<model'
        },
        link: (scope, element, attrs) => {
            scope.$watch(() => $config.userInfo, () => {
                scope.developer = $config.userInfo && $config.userInfo.roles.indexOf(RoleName.Developer)>=0;
            })
        },
        template: require('./Debug.html')
    };
}

function debug2() {
    return {
        restrict: 'E',
        scope: {
            title: '@model',
            model: '<model'
        },
        link: (scope, element, attrs) => {
            scope.developer = true;
        },
        template: require('./Debug.html')
    };
}

export default angular.module('directives.debug', [])
    .directive('debug', debug)
    .directive('debug2', debug2)
    .name;
