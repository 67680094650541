import {ClassDto} from "../model/ClassDto";
import {DataHubService} from "../modules/DataHubModule";
import {LocationDto} from "../model/LocationDto";
import {ClassInfoDto} from "../model/ClassInfoDto";
import {UserDisplayDto} from "../model/UserDisplayDto";
import {SpinnerService} from "../modules/SpinnerModule";
import {ClassAddResultDto} from "../model/ClassAddResultDto";
import {ClassWarningsDto} from "../model/ClassWarningsDto";
import {ClassTypeDto} from "../model/ClassTypeDto";

import * as angular from 'angular';
import {addDays, addHours, addMinutes, addWeeks, format, parse, startOfWeek} from "date-fns";
import {VirtualClassType} from "../model/VirtualClassType";

class NamedClassSequence {
    fullName: string;

    constructor(public name: string, public order: number[]) {
        this.fullName = `${name} (${order.slice(0, 4).join(",")}...)`;
    }
}

class WeekSchedule {
    Sunday: boolean = false;
    Monday: boolean = false;
    Tuesday: boolean = false;
    Wednesday: boolean = false;
    Thursday: boolean = false;
    Friday: boolean = false;
    Saturday: boolean = false;
}

class ClassSetupOptions {
    classType: ClassTypeDto;
    breakTime: number;
    startTime: string;
    week: Date;
    location: LocationDto;
    teacher: UserDisplayDto;
    order: NamedClassSequence;
    startWith: ClassInfoDto;
    generate: number;
    schedule: WeekSchedule = new WeekSchedule();
    virtualClassType: VirtualClassType = VirtualClassType.None;
}

export default class AddClassesController {
    setupOptions: ClassSetupOptions = new ClassSetupOptions();
    classes: ClassDto[] = [];
    classInfos: ClassInfoDto[] = [];
    classTypes: ClassTypeDto[] = [];
    drivingInstructors: UserDisplayDto[] = [];
    locations = new Array<LocationDto>();
    upcomingWeeks: Date[] = [];
    warnings: string[];
    namedClassSequences: NamedClassSequence[];
    startWithOptions: ClassInfoDto[] = [];
    generateOptions: number[] = [...Array(4).keys()].map(x => (x + 1) * 12)
        .concat([...Array(11).keys()].map(x => x + 1));

    constructor(private $dataHub: DataHubService,
                private $spinner: SpinnerService,
                private toaster: any) {
        $dataHub.client.bindAllLocation(() => this.locations);
        $dataHub.client.bindAllClassInfo(() => this.classInfos);
        $dataHub.client.bindAllClassType(() => this.classTypes);
        $dataHub.client.bindAllUserDisplay(() => this.drivingInstructors);
        $dataHub.connected(this.load);
        let startOfThisWeek = startOfWeek(new Date());
        this.upcomingWeeks = [...Array(52).keys()].map(x => addWeeks(startOfThisWeek, x));
        let normal = [...Array(12).keys()].map(x => x + 1);
        let flipped = [...Array(12).keys()].map(x => x - x % 2 * 2 + 2);
        this.namedClassSequences = [
            new NamedClassSequence("Normal", normal.slice()),
            new NamedClassSequence("Flipped", flipped.slice()),
            new NamedClassSequence("Inversed", normal.slice().reverse()),
            new NamedClassSequence("Inverse Flipped", flipped.slice().reverse())
        ];
        this.setupOptions.order = this.namedClassSequences[0];

        if ($dataHub.isConnected) this.load();
    }

    load = () => {
        this.$dataHub.server.location();
        this.$dataHub.server.classInfoSubscribe();
        this.$dataHub.server.classTypeSubscribe();
        this.$dataHub.server.userTeacherSubscribe();
    };
    random = () => Math.random();

    appendClass() {
        let classDto = new ClassDto();
        classDto.classDate = new Date();
        this.classes.push(classDto);
    }

    delete(classDto) {
        this.classes = this.classes.filter(value => value != classDto);
    }

    addOrValidateClasses(validationOnly) {
        const toAdd = this.classes.length;
        this.$spinner.show(validationOnly
            ? `Validating ${toAdd} class(es)`
            : `Attempting to add ${toAdd} class(es)`);
        this.$dataHub.server.classAdd(this.classes, validationOnly)
            .then((value: ClassAddResultDto) => {
                    console.log(value);
                    if (angular.isUndefined(value.errors) || value.errors == null || value.errors.length == 0) {
                        if (!validationOnly) {
                            this.classes.length = 0;
                            this.toaster.success(`${toAdd} class(es) added!`)
                            this.$dataHub.server.classGetWarnings(value.newIds)
                                .then((value1: ClassWarningsDto[]) => this.warnings = value1
                                    .map(value2 => value2.warnings)
                                    .reduce((a, b) => a.concat(b))
                                    .reduce((distinct, item) => !distinct.includes(item)
                                        ? [...distinct, item]
                                        : distinct, []));
                            return;
                        }
                    }
                    if (!validationOnly) this.toaster.error("Classes not added");
                    for (let i = 0; i < value.errors.length; i++) {
                        let classDtos = <any>this.classes;
                        classDtos[i].error = value.errors[i];
                    }

                }
            )
            .catch(e => {
                console.error(e);
                alert(e);
            })
            .finally(() => this.$spinner.hide());
    }

    dismissWarnings = () => this.warnings.length = 0;

    classTypeChanged() {
        this.setupOptions.breakTime = this.setupOptions.classType.breakTime;
        this.startWithOptions = this.classInfos.filter(value => value.classType.id == this.setupOptions.classType.id);
        if (this.startWithOptions.length > 0) this.setupOptions.startWith = this.startWithOptions[0];
        else this.setupOptions.startWith = null;
    }

    someSelected = (schedule: WeekSchedule) => schedule.Sunday || schedule.Monday || schedule.Tuesday ||
        schedule.Wednesday || schedule.Thursday || schedule.Friday || schedule.Saturday;

    quickFill() {
        this.setupOptions.classType = this.classTypes.filter(value => value.name == "Student")[0];
        this.setupOptions.week = this.upcomingWeeks[0];
        this.setupOptions.location = this.locations.filter(value => value.name == "Alliance")[0];
        this.setupOptions.teacher = this.drivingInstructors.filter(value => value.lastName == "4")[0];
        this.setupOptions.generate = 12;
        this.setupOptions.schedule.Monday = true;
        this.setupOptions.schedule.Tuesday = true;
        this.setupOptions.startTime = "13:23:00";
        this.classTypeChanged();
    }

    generateClasses() {
        let so = this.setupOptions;
        if (so.classType.perDay <= 0) return;
        this.classes.length = 0;
        const week = [];
        if (so.schedule.Sunday) week.push(0);
        if (so.schedule.Monday) week.push(1);
        if (so.schedule.Tuesday) week.push(2);
        if (so.schedule.Wednesday) week.push(3);
        if (so.schedule.Thursday) week.push(4);
        if (so.schedule.Friday) week.push(5);
        if (so.schedule.Saturday) week.push(6);

        let weekIndex = 0;
        let currentWeek = new Date(so.week.valueOf());

        const curriculum = this.classInfos.filter(value => value.classType.id == so.classType.id)
            .sort((a, b) => a.classInfoId - b.classInfoId);

        let curriculumStartIndex = curriculum
            .indexOf(curriculum.filter(value => value.classInfoId == so.startWith.classInfoId)[0]);
        let orderIndex = so.order.order.indexOf(curriculumStartIndex + 1);
        let dayClassIndex = 0;

        for (let i = 0; i < so.generate; i++) {
            let classDto = new ClassDto();
            classDto.location = so.location;
            classDto.classDate = addDays(currentWeek, week[weekIndex]);


            const curriculumIndex = (so.order.order[orderIndex] - 1) % curriculum.length;
            classDto.classInfo = curriculum[curriculumIndex];

            var start = parse(so.startTime, 'HH:mm:ss', new Date());
            start = addHours(start, dayClassIndex * so.classType.length);
            start = addMinutes(start, dayClassIndex * so.breakTime);
            classDto.cTimeStart = format(start, "HH:mm");

            var end = addHours(start, so.classType.length);
            classDto.cTimeEnd = format(end, "HH:mm");
            classDto.teacher = so.teacher;
            classDto.virtualClassType = so.virtualClassType;

            this.classes.push(classDto);
            orderIndex++;
            orderIndex %= so.order.order.length;
            dayClassIndex++;
            if (dayClassIndex >= so.classType.perDay) {
                dayClassIndex = 0;
                weekIndex++;
                if (weekIndex >= week.length) {
                    weekIndex = 0;
                    currentWeek = addWeeks(currentWeek, 1);
                }
            }
        }

        this.addOrValidateClasses(true);
    }
};
