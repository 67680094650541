// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html



// Myers.StudentManagementSystem.Data.Models.PaymentReportOptionsDto
export class PaymentReportOptionsDto {
    public fromDate: Date = new Date(0);
    public toDate: Date = new Date(0);
    public locationId: number = null;
    public userId: number = null;
}


    



