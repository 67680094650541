import {Ng1Controller, Transition, TargetState, StateService} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {StudentRestartDto} from "../model/StudentRestartDto";

export default class RestartController implements Ng1Controller {
    private model: StudentRestartDto;
    private submitting: boolean;

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(private $dataHub: DataHubService,
                private toaster: any,
                private $state: StateService,
                private $stateParams: any,
                private $uibModalInstance: any) {
        $dataHub.connected(this.refresh);
        this.model = new StudentRestartDto();
        this.model.studentId = this.$stateParams.studentId;
        this.model.restartDate = new Date();
    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;

        // this.$dataHub.server.subscribeToSomething().catch(this.$dataHub.defaultErrorHandler);
    }

    submit = () => {
        if (this.submitting) return;
        this.submitting = true;
        this.$dataHub.studentRestart(this.model)
            .then(() => this.$uibModalInstance.close())
            .catch(this.$dataHub.defaultErrorHandler)
            .finally(() => this.submitting = false);
    }
}
