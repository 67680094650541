import * as angular from 'angular';
import * as moment from "moment";

// apply ng-min and ng-max to non-date input elements
// code based on https://github.com/angular/angular.js/blob/65f800e19ec669ab7d5abbd2f6b82bf60110651a/src/ng/directive/input.js
function dateValidation($parse) {
    return {
        restrict: 'A',
        require: '^ngModel',
        link: function (scope, element, attr, ctrl: ng.INgModelController) {

            function isRequired() {
                return angular.isDefined(attr.required) || (attr.ngRequired && $parse(attr.ngRequired)(scope));
            }

            if (angular.isDefined(attr.min) || attr.ngMin) {
                ctrl.$validators.min = function (value) {
                    var minVal = attr.min || $parse(attr.ngMin)(scope);
                    if(!minVal) return true;
                    var parsedMinVal = parseDate(minVal);
                    return (!value && !isRequired()) || (parseDate(value) >= parsedMinVal);
                };
            }

            if (angular.isDefined(attr.max) || attr.ngMax) {
                ctrl.$validators.max = function (value) {
                    var maxVal = attr.max || $parse(attr.ngMax)(scope);
                    if(!maxVal) return true;
                    var parsedMaxVal = parseDate(maxVal);
                    return (!value && !isRequired()) || (parseDate(value) <= parsedMaxVal);
                };
            }

            ctrl.$parsers.push(function(viewValue) {
                if(!viewValue) return viewValue;
                let m = moment(viewValue);
                let formattedValue = m.format('MM/DD/YYYY');
                if(ctrl.$viewValue !== formattedValue){
                    ctrl.$setViewValue(formattedValue);
                    ctrl.$render();
                }
                return m.toDate();
            })

        }
    };
}


function parseDate(input) {
    return moment(input).toDate();
}


export default angular.module('directives.inputDate', [])
    .directive('dateValidation', dateValidation)
    .name;
