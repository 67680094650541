import {UserActivityDto} from "../model/UserActivityDto";
import {DataHubService} from "../modules/DataHubModule";

export default class UserActivityController {
    models: UserActivityDto[] = [];
    thisVersion = window["version"];

    constructor(private $dataHub: DataHubService) {
        $dataHub.bindAllUserActivity(() => this.models);
        this.reload();
        $dataHub.connected(this.reload);
    }

    private reload = () => {
        this.models = [];
        if (!this.$dataHub.isConnected) return;
        this.$dataHub.invoke('UserActivitySubscribe');
    };


    windowRefresh = (dto: UserActivityDto) => this.$dataHub.invoke('UserActivityForceRefresh', dto);
    windowClose = (dto: UserActivityDto) => this.$dataHub.invoke('UserActivityForceClose', dto);

}
