import {DataHubService} from "../modules/DataHubModule";
import {StudentExamMessageDto} from "../model/StudentExamMessageDto";
import {StudentExamDto} from "../model/StudentExamDto";
import {ITimeoutService} from "angular";

export class ExamManagerMessageController {
    private messages: StudentExamMessageDto[] = [];
    private chatContainer: JQuery<HTMLDivElement>;

    constructor(private $uibModalInstance,
                private $timeout: ITimeoutService,
                private $dataHub: DataHubService,
                private exam: StudentExamDto) {

        this.$dataHub.onStudentExamMessageUpdated(msg => {
            if (msg.examId != exam.id) return;
            this.messages.merge(msg, x => x.id);
            $timeout(() => {
                let e = this.chatContainer[0];
                if (!e) return;
                e.scrollTop = e.scrollHeight;
            });
        });
        this.$dataHub.onConnected(this.refresh);
        this.refresh()
    }

    refresh = () => {
        this.$dataHub.studentExamMessageSubscribe(this.exam.id);
    };

    done = () => {
        this.$uibModalInstance.close('1');
    };

    send = (msg) => {
        let dto = new StudentExamMessageDto();
        dto.examId = this.exam.id;
        dto.message = msg;
        this.$dataHub.studentExamMessageSubmit(dto)
            .catch(this.$dataHub.defaultErrorHandler);
        this.markRead();
    };

    markRead = () => {
        this.messages.filter(x => !x.read).forEach(msg => {
            this.$dataHub.studentExamMessageRead(msg.id);
        })
    };


}
