import {SpinnerService} from '../modules/SpinnerModule';
import {ClassDto} from "../model/ClassDto";
import {DataHubService} from "../modules/DataHubModule";
import {ApiService} from '../modules/ApiModule';
import {ConfigService} from '../modules/ConfigModule';
import {Ng1Controller, HookResult, Transition} from "@uirouter/angularjs";
import {StudentWithTestScoresDto} from "../model/StudentWithTestScoresDto";

export default class ReportController implements Ng1Controller {
    private classId: number;
    private selectedClass: ClassDto;

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
        this.classId = newValues.classId;
        this.refresh();
    }

    $onInit(): void {
    }

    uiCanExit(transition: Transition): HookResult {
        return true;
    }

    students: StudentWithTestScoresDto[] = [];

    constructor(
        $scope: ng.IScope,
        private $api: ApiService,
        private $config: ConfigService,
        private $spinner: SpinnerService,
        private $dataHub: DataHubService,
        $stateParams: any,
    ) {
        this.$dataHub.client.onStudentTrainingReportUpdated(e => this.students.merge(e, x => x.id));

        this.classId = $stateParams.classId;
        $dataHub.connected(() => this.refresh());
        if ($dataHub.isConnected)
            this.refresh();
    }

    refresh = () => {
        this.students = [];
        this.selectedClass = null;

        if (!this.classId) return;
        if (!this.$dataHub.isConnected) return;

        this.$dataHub.server.classGet(this.classId)
            .then((cls) => {
                this.selectedClass = cls;
            })
            .catch(this.$dataHub.defaultErrorHandler);

        this.$dataHub.server.classGetStudentTrainingReportList(this.classId)
            .catch(this.$dataHub.defaultErrorHandler);
    };

    get studentIdList() {
        return this.students.map(s => s.id);
    }

};
