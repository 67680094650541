import {Ng1Controller, Transition, TargetState, StateService} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {ChangeLogDto} from "../model/ChangeLogDto";

export default class ListController implements Ng1Controller {
    private records: ChangeLogDto[];

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(private $dataHub: DataHubService,
                private toaster: any,
                private $state: StateService,
                private $stateParams: any) {
        $dataHub.connected(this.refresh)
    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;

        this.$dataHub.changeLogGetUnreviewed()
            .then(r => this.records = r)
            .catch(this.$dataHub.defaultErrorHandler);
    }

    ignore = (row: ChangeLogDto) => {
        row.reviewed = true;
        this.$dataHub.changeLogUpdate(row)
            .then(() => {
                this.records.remove(row, x => x.id);
            });
    }
}
