import * as angular from 'angular';
import {IScope, ITimeoutService} from "angular";

// @ts-ignore
import processor from "!!raw-loader!./vumeter-processor.js";

// based on the newer webworker way of doing things

class vuMeterController {
    private volume: number;
    private mic: MediaStreamAudioSourceNode;
    private node: AudioWorkletNode;
    private context: AudioContext;
    private contextLoaded: boolean;

    constructor(private $scope: IScope, private $timeout: ITimeoutService) {
        this.context = new AudioContext();
        var blob = new Blob([processor], {type: "application/javascript"});
        var url = URL.createObjectURL(blob);
        console.debug(processor, blob, url);
        this.context.audioWorklet.addModule(url)
            .then(() => {
                console.log('context loaded');
                this.contextLoaded = true;
                this.connect($scope["stream"]);
            });
        this.volume = 0.0;

        $scope.$watch('stream', (newValue: MediaStream) => this.connect(newValue));
    }


    connect = (stream: MediaStream) => {
        if (!stream || !this.contextLoaded) return;
        console.log('SoundMeter connecting', stream);

        try {
            this.mic = this.context.createMediaStreamSource(stream);
            this.node = new AudioWorkletNode(this.context, 'vumeter');
            this.node.port.onmessage = event => {
                if (event.data.volume)
                    this.$timeout(() => this.volume = event.data.volume);
            }

            this.mic.connect(this.node).connect(this.context.destination);
        } catch (e) {
            console.error(e);
        }
    }
}


function VuMeter() {
    return {
        template: '{{$ctrl.volume}}',
        restrict: 'E',
        scope: {stream: '<'},
        controller: vuMeterController,
        controllerAs: '$ctrl'
    };
}

export default angular.module('directives.vuMeter', [])
    .directive('vuMeter', VuMeter)
    .name;
