// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html




// Myers.StudentManagementSystem.Data.Enum.ContractMode
export enum ContractMode {
    
    Unknown = 0,
    Paper = 1,
    Electronic = 2
}
