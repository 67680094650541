// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html



// Myers.StudentManagementSystem.Data.Models.PricingDto
export class PricingDto {
    public id: number = 0;
    public name: string = null;
    public studentTypeId: number = 0;
    public contractId: number = 0;
    public price: number = 0;
    public priceSpecial: number = null;
    public discountPayInFull: number = 0;
    public discountCash: number = 0;
    public inUse: boolean = false;
    public enabled: boolean = false;
    public terms: number = 0;
    public grace: number = 0;
    public default: boolean = false;
    public adminOnly: boolean = false;
    public restartFee: number = 0;
    public extraInCar: number = 0;
    public useCarForTest: number = 0;
    public noShow: number = 0;
    public cancelIncar: number = 0;
    public voidCert: number = 0;
    public book: number = 0;
    public chair: number = 0;
    public table: number = 0;
    public displayOrder: number = 0;
    public btwHours: number = null;
}


    



