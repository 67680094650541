import {Ng1Controller, Transition, TargetState, StateService} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {PrinterDto} from "../model/PrinterDto";
import {ngStorage} from "ngstorage";
import StorageService = ngStorage.StorageService;
import {LocationDto} from "../model/LocationDto";

export default class PrinterSetupController implements Ng1Controller {
    private printers: PrinterDto[] = [];
    private locations: LocationDto[] = [];

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(private $dataHub: DataHubService,
                private $localStorage: StorageService,
                private $state: StateService,
                private $stateParams: any) {

        $dataHub.client.bindAllPrinter(() => this.printers);
        $dataHub.client.bindAllLocation(() => this.locations)

        $dataHub.connected(this.refresh)
    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;
        this.$dataHub.printerSubscribe();
        this.$dataHub.location();
    }

    deletePrinter = (printer: PrinterDto) => {
        this.$dataHub.printerDelete(printer)
            .catch(this.$dataHub.defaultErrorHandler);
    }

    clearPrintJobs = (printer: PrinterDto) => {
        this.$dataHub.printerPurgeJobs(printer)
            .catch(this.$dataHub.defaultErrorHandler);
    }

    printTestPage = (printer: PrinterDto) => {
        this.$dataHub.printerPrintTestPage(printer)
            .catch(this.$dataHub.defaultErrorHandler);
    }

    updatePrinter = (printer: PrinterDto) => {
        this.$dataHub.printerUpdate(printer)
            .catch(this.$dataHub.defaultErrorHandler);
    }
}
