import * as angular from 'angular';
import {StudentDisplayDto} from "../model/StudentDisplayDto";
import {DataHubService} from "../modules/DataHubModule";
import {StudentSearchDto} from "../model/StudentSearchDto";

class studentSearchController {
    students: StudentDisplayDto[] = [];

    constructor(private $dataHub: DataHubService, private $scope) {
    }

    refresh(search) {
        this.students = [];
        if (!search) return;
        let dto = new StudentSearchDto();
        dto.text = search;
        dto.classId = this.$scope.classId;
        this.$dataHub.studentSearch(dto)
            .then((response) => {
                this.students = response;
            });
    };

    onSelect(student) {
        this.$scope.onSelect({$student: student});
    }
}


function studentSearch() {
    return {
        template: require('./StudentSearch.html'),
        restrict: 'E',
        transclude: true,
        scope: {onSelect: '&', placeholder: '@', classId: '='},
        controller: studentSearchController,
        controllerAs: '$ctrl'
    };
};


export default angular.module('directives.studentSearch', [])
    .directive('studentSearch', studentSearch)
    .name;
