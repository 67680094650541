import * as angular from 'angular';

import {SpinnerService} from '../modules/SpinnerModule';
import {StudentDto} from "../model/StudentDto";
import {ClassDto} from "../model/ClassDto";
import {DataHubService} from "../modules/DataHubModule";
import {ApiService} from '../modules/ApiModule';
import {ConfigService} from '../modules/ConfigModule';
import {Ng1Controller, HookResult, Transition} from "@uirouter/angularjs";
import {CertificateRequestDto} from "../model/CertificateRequestDto";

export default class CertificateController implements Ng1Controller {
    private classId: number;
    private selectedClass: ClassDto;
    private certificateRequests: CertificateRequestDto[] = [];
    students: StudentDto[] = [];

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
        this.classId = newValues.classId;
        this.refresh();
    }

    $onInit(): void {
    }

    uiCanExit(transition: Transition): HookResult {
        return true;
    }

    constructor(
        $scope: ng.IScope,
        private $api: ApiService,
        private $config: ConfigService,
        private $spinner: SpinnerService,
        private $dataHub: DataHubService,
        private $stateParams: any,
    ) {
        this.$dataHub.client.bindAllStudent(() => this.students);
        this.$dataHub.client.onStudentUpdated(student => this.$dataHub.server.studentCertificateRequestSubscribe(student.id));

        // use custom key selector to ensure there is only 1 req per student id (ideally the "newest" one)
        this.$dataHub.client.onCertificateRequestUpdated(e => this.certificateRequests.merge(e, x => x.student.id));
        this.$dataHub.client.onCertificateRequestRemoved(e => this.certificateRequests.remove(e, x => x.id));

        this.classId = $stateParams.classId;
        $dataHub.connected(() => this.refresh());
        if ($dataHub.isConnected)
            this.refresh();
    }

    refresh = () => {
        this.students = [];
        if (!this.classId) return;
        if (!this.$dataHub.isConnected) return;


        this.$dataHub.server.classGet(this.classId)
            .then((cls) => {
                this.selectedClass = cls;
            })
            .catch(this.$dataHub.defaultErrorHandler);

        this.$dataHub.server.classCertificates(this.classId)
            .catch(this.$dataHub.defaultErrorHandler);


    };

    request = (student: StudentDto) => {
        this.$dataHub.server.classCertificateRequestCreate(student.id)
            .catch(this.$dataHub.defaultErrorHandler);
    };

    requestAll = () => {
        var eligible = this.students.filter(x => x.completionDate && !x.certificateNumber);
        eligible.forEach(this.request);
    };

    requestAllCanExecute = () => {
        return this.students.filter(x => x.completionDate && !x.certificateNumber).length > 0;
    };

    printAllCanExecute = () => {
        return this.certificateRequests.filter(x => x.status == -1 && x.printDate == null).length > 0;
    };

    get unprintedRequestIds() {
        return this.certificateRequests.filter(x => x.status == -1 && x.printDate == null).map(x => x.id);
    };

};
