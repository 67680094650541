import {Ng1Controller, Transition, TargetState, StateService} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {StudentSearchDto} from "../model/StudentSearchDto";
import {StudentDisplayDto} from "../model/StudentDisplayDto";

export default class ViewOneController implements Ng1Controller {
    private model = new StudentSearchDto();
    private results: StudentDisplayDto[];
    private i = new Date();

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(private $dataHub: DataHubService,
                private toaster: any,
                private $state: StateService,
                private $stateParams: any) {
        $dataHub.connected(this.refresh)
    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;

        // this.$dataHub.server.subscribeToSomething().catch(this.$dataHub.defaultErrorHandler);
    };

    search = () => {
        let i = new Date();
        this.i = i;
        this.$dataHub.studentSearch(this.model)
            .then(r => {
                if (i != this.i) {
                    return;
                }
                this.results = r;
            })
            .catch(this.$dataHub.defaultErrorHandler);
    }
}
