import * as angular from 'angular';
import {addMonths, addYears, intervalToDuration} from "date-fns";

function factory() {
    return {
        template: require('./VirtualParticipantLabels.html'),
    };
}

export default angular.module('classroom.virtualParticipantLabels', [])
    .directive('virtualParticipantLabels', factory)
    .name;
