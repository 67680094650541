// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html

import { MessageSender } from './MessageSender';

// Myers.StudentManagementSystem.Data.Models.StudentExamMessageDto
export class StudentExamMessageDto {
    public id: number = 0;
    public examId: number = 0;
    public timestamp: Date = new Date(0);
    public from: string = null;
    public initials: string = null;
    public sender: MessageSender = null;
    public userId: number = 0;
    public read: boolean = false;
    public message: string = null;
}


    



