import {Ng1Controller, StateService, TargetState, Transition} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {IScope} from "angular";
import {ConfigService} from "../modules/ConfigModule";
import {GiftCertificateTemplateDto} from "../model/GiftCertificateTemplateDto";
import {ApiService} from "../modules/ApiModule";
import {ngStorage} from "ngstorage";
import {GiftCertificateTemplateFieldDto} from "../model/GiftCertificateTemplateFieldDto";
import {DebounceFactory} from "../modules/DebounceModule";
import StorageService = ngStorage.StorageService;

export default class TemplateEditController implements Ng1Controller {

    private model: GiftCertificateTemplateDto;
    private previewUrl: string;
    private fonts: string[];

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(private $dataHub: DataHubService,
                private $config: ConfigService,
                private $localStorage: StorageService,
                private $debounce: DebounceFactory,
                private $api: ApiService,
                private Upload: any,
                private toaster: any,
                private $scope: IScope,
                private $state: StateService,
                private $stateParams: any) {
        $dataHub.connected(this.refresh);

        $dataHub.onGiftCertificateTemplateUpdated(e => {
            if (e.id == this.$stateParams.id)
                this.model = e;
        })

        $scope.$watch(() => this.model, () => this.previewDebounce(), true);
    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;
        if (this.$stateParams.id)
            this.$dataHub.giftCertificateTemplateSubscribeOne(this.$stateParams.id);
        this.$dataHub.giftCertificateFonts().then(result => this.fonts = result);
        // this.$dataHub.server.subscribeToSomething().catch(this.$dataHub.defaultErrorHandler);
    }

    uploadPdf = ($event: JQueryEventObject) => {
        console.debug('uploadPdf', $event);
        let input = <HTMLInputElement>$event.target
        let files = input.files;
        if (!files.length) return;
        let file = files[0];

        let url = this.$config.apiBase + 'giftCertificate/template'
        this.Upload.upload({
            url: url,
            data: {file: file}
        }).then(result => {
            this.model.storageKey = result.data;
            this.preview();
        })
            .catch(error => console.error(error));
    }


    preview = () => {
        this.$api.post('giftCertificate/template/preview', this.model, {responseType: 'blob'})
            .then(result => {
                this.previewUrl = window.URL.createObjectURL(result.data) + '#view=fit';
            })
    }

    previewDebounce = this.$debounce(this.preview, 1000, false);

    addField = () => {
        if (!this.model.fields)
            this.model.fields = [];
        let dto = new GiftCertificateTemplateFieldDto();
        dto.left = 10;
        dto.top = 10;
        dto.width = 100;
        dto.height = 50;
        dto.fontName = 'Arial';
        dto.fontSize = 18;

        this.model.fields.push(dto);
    }

    removeField = (field: GiftCertificateTemplateFieldDto) => {
        this.model.fields.remove(field, x => x);
    }

    save = () => {
        this.$dataHub.giftCertificateTemplateUpdate(this.model)
            .then(result => {
                this.model = result;
                this.toaster.success('Template Saved');
            })
            .catch(this.$dataHub.defaultErrorHandler);
    }
}
