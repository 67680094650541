import * as angular from 'angular';
import {ngStorage} from 'ngStorage';
import {UserInfoDto} from '../model/UserInfoDto';
import {LocationDto} from "../model/LocationDto";
import {LocationTokenDto} from "../model/LocationTokenDto";
import {DataHubService} from "./DataHubModule";
import {StateService, Transition} from "@uirouter/angularjs";
import {IPromise} from "angular";

export class ConfigService {
    public apiBase: string;
    public userInfo: UserInfoDto;
    public loggedIn: boolean = false;
    public loginTimestamp: Date;
    private onLoginCallbacks: { (): void; }[] = [];

    constructor(
        private $http: ng.IHttpService,
        private $location: ng.ILocationService,
        private $q: ng.IQService,
        private $rootScope: ng.IRootScopeService,
        private $window: ng.IWindowService,
        private $timeout: ng.ITimeoutService,
        private $interval: ng.IIntervalService,
        private $state: StateService,
        private $transitions: Transition,
        private $localStorage: ngStorage.StorageService,
        private $dataHub: DataHubService,
        private toaster: any) {

        var timeAppLoaded = new Date();

        this.apiBase = $window.location.hostname == 'localhost' ?
            'http://localhost:8081/api/' : $window.apiBase;
        console.debug('apiBase', this.apiBase);

        $transitions.onBefore({}, transition => {

            // dont check login status for public states
            if (transition.to().name.startsWith('public'))
                return;

            if (this.loggedIn && transition.to().name == 'login') {
                return transition.router.stateService.target('home');
            }
            if (!this.loggedIn && transition.to().name != 'login') {
                return transition.router.stateService.target('login');
            }
            this.checkLogin();
        });

        // watch localStorage so all windows share the same login status
        // and so each window does not need to hit the server simultaneously
        $rootScope.$watch(() => $localStorage.loginTimestamp,
            () => {
                this.loggedIn = $localStorage.loggedIn || false;
                this.loginTimestamp = new Date($localStorage.loginTimestamp);

                if ($state.includes('public'))
                    return;

                if (this.loggedIn && $state.is('login')) {
                    console.debug('go to home');
                    $state.go('home')
                } else if (!this.loggedIn && this.loginTimestamp > timeAppLoaded) {
                    console.error('logout triggered via local storage');
                    // close window if possible
                    if (window.opener && window.opener !== window)
                        window.close();
                    // otherwise go to login
                    if (!$state.is('login')) {
                        console.debug('go to login');
                        $state.go('login');
                    }
                }
            });

        // keep login alive by pinging server every 30s
        $interval(() => {
            if (!$localStorage.loggedIn) return;
            this.checkLogin().catch();
        }, 60000);

        $dataHub.connected(() => {
            this.locationTokenValidate();
            this.checkLogin().catch();
        });
    }

    // define common API endpoints

    get accountLogin() {
        return this.apiBase + 'account/login';
    }

    get accountUserInfo() {
        return this.apiBase + 'account/userinfo';
    }

    get accountLogout() {
        return this.apiBase + 'account/logout';
    }

    get environment() {
        return this.$window['environment'];
    }

    public checkLogin = (force: boolean = false): IPromise<UserInfoDto> => {
        var q = this.$q.defer<UserInfoDto>();

        if (this.$state.includes('public')) {
            console.debug('public, not checking login');
            q.resolve(null);
            return q.promise;
        }

        if (!force) {
            var now = new Date().valueOf();
            var lastTs = new Date(this.$localStorage.loginTimestamp).valueOf();
            var diff = now - lastTs;
            if (diff < 10000 && this.userInfo) {
                q.resolve(this.userInfo);
                return q.promise;
            }
        }


        this.$http.get<UserInfoDto>(this.accountUserInfo)
            .then(r => {
                this.userInfo = r.data;
                var wasLoggedIn = this.$localStorage.loggedIn;
                this.$localStorage.loggedIn = true;
                this.$localStorage.loginTimestamp = new Date();
                if (!wasLoggedIn)
                    this.onLoginInvoke();
                q.resolve(r.data);
            })
            .catch(e => {
                console.error('checkLogin', e);
                if (e.status == 401) {
                    this.userInfo = null;
                    this.$localStorage.loggedIn = false;
                    this.$localStorage.loginTimestamp = new Date();
                }
                q.reject(e.data ? e.data.message : e.xhrStatus);
            });
        return q.promise;
    };

    login = (username: string, password: string, newPassword: string, rememberMe: boolean) => {
        var q = this.$q.defer<UserInfoDto>();
        this.$http.post(this.accountLogin, {
            username: username,
            password: password,
            newPassword: newPassword,
            rememberMe: rememberMe,
            locationToken: this.locationTokenCurrent ? this.locationTokenCurrent.token : null,
        })
            .then(r => {
                console.log('login result', r);
                // restart dataHub if it stopped
                this.$timeout(() => this.$dataHub.start(), 0);
                this.checkLogin(true)
                    .then(q.resolve)
                    .catch(e => q.reject({error: [e]}));
            })
            .catch(e => {
                console.log('login error', e);
                if (e.data && e.data.modelState) {
                    q.reject(e.data.modelState);
                } else if (e.xhrStatus) {
                    q.reject({error: [`Error communicating with server (${e.xhrStatus})`]});
                } else {
                    q.reject({error: e});
                }
            });
        return q.promise;
    };

    logout = () => {
        var user = this.userInfo;
        var q = this.$q.defer();
        var loggedOut = (r) => {
            console.log('logout complete', r);
            this.toaster.info(user.name + ' Logged Out');
            this.$localStorage.loggedIn = false;
            this.$localStorage.loginTimestamp = new Date();
            q.resolve(r);
        };
        this.$http.post(this.accountLogout, null)
            .then(r => loggedOut(r))
            .catch(e => {
                // 401 - already logged out.
                // error - success!
                if (e.status == 401) {
                    loggedOut(e);
                } else {
                    console.log('logout error', e);
                    q.reject(e);
                }
            });
        return q.promise;
    };

    public onLogin(callback: () => void) {
        this.onLoginCallbacks.push(callback);
    }

    private onLoginInvoke = () => {
        for (var f of this.onLoginCallbacks) {
            try {
                f.apply(this);
            } catch (e) {
                console.error(e);
            }
        }
    };

    locationTokenValidate = () => {
        var tokenGuid;
        if (this.$localStorage.locationToken) tokenGuid = this.$localStorage.locationToken.token;
        this.$dataHub.server.locationTokenValidate(tokenGuid)
            .then(locationTokenDto => {
                this.$localStorage.locationToken = locationTokenDto;
            })
            .catch(e => {
                console.error(e);
            });
    };

    locationTokenCreate = (location: LocationDto, description: string, privateComputer: boolean) => {
        this.$dataHub.server.locationTokenCreate(location.id, description, privateComputer)
            .then(locationTokenDto => {
                this.$localStorage.locationToken = locationTokenDto
            })
            .catch(this.$dataHub.defaultErrorHandler)
    };

    get locationTokenCurrent(): LocationTokenDto {
        return this.$localStorage.locationToken;
    };
}

export default angular.module('services.config', [])
    .service('$config', ConfigService)
    .name;
