// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html



// Myers.StudentManagementSystem.Data.Entity.VirtualClassVideoPlayback
export class VirtualClassVideoPlayback {
    public id: number = null;
    public url: string = null;
    public mode: string = null;
    public lastUpdate: Date = null;
    public lastTimestamp: number = null;
    public now: Date = new Date(0);
    public currentTimestamp: number = null;
    public owner: string = null;
}


    



