import {debounce} from "lodash";

declare global {
    interface Number {
        thousandsSeparator(): string;
    }

    interface Array<T> {
        merge(entity: T, keySelectorFunc: (value: T) => any): T;

        replace(entity: T, keySelectorFunc: (value: T) => any): void;

        remove(entity: T, keySelectorFunc: (value: T) => any): void;
    }

    interface String {
        replaceAll(strReplace: string, strWith: string): string;
    }

    interface HTMLElement {
        webkitRequestFullscreen(options?: FullscreenOptions): Promise<void>;
    }

    interface MediaDevices {
        getDisplayMedia(constraints: MediaStreamConstraints): Promise<MediaStream>;
    }

    interface Navigator {
        getDisplayMedia(constraints: MediaStreamConstraints): Promise<MediaStream>;
    }

    interface HTMLMediaElement {
        captureStream(): MediaStream;
    }

    interface Window {
        maximize(): void;

        enterFullScreen(): void;

        exitFullScreen(): void;

        onFullScreenChange(callback: (isFullScreen: boolean) => void): void;
    }

}
Number.prototype.thousandsSeparator = function (): string {
    return Number(this).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

Array.prototype.merge = function <T>(entity: T, keySelectorFunc: (value: T) => any): T {
    const index = this.map(keySelectorFunc).indexOf(keySelectorFunc(entity));
    if (index >= 0) {
        return Object.assign(this[index], entity);
    } else {
        this.push(entity);
        return entity;
    }
};

Array.prototype.replace = function <T>(entity: T, keySelectorFunc: (value: T) => any): void {
    const index = this.map(keySelectorFunc).indexOf(keySelectorFunc(entity));
    if (index >= 0)
        this[index] = entity;
    else
        this.push(entity);
};

Array.prototype.remove = function <T>(entity: T, keySelectorFunc: (value: T) => any): void {
    const index = this.map(keySelectorFunc).indexOf(keySelectorFunc(entity));
    if (index >= 0)
        this.splice(index, 1);
};

String.prototype.replaceAll = function (strReplace, strWith) {
    // See http://stackoverflow.com/a/3561711/556609
    var esc = strReplace.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    var reg = new RegExp(esc, 'ig');
    return this.replace(reg, strWith);
};

Window.prototype.maximize = function () {
    if (window.moveTo)
        window.moveTo(0, 0);
    if (window.resizeTo) {
        console.debug('resizeTo', screen.width, screen.height);
        window.resizeTo(screen.width, screen.height);
    }
}

Window.prototype.enterFullScreen = function () {

    try {
        var e = window.document.documentElement;
        let options: FullscreenOptions = {navigationUI: "hide"};
        if (e.requestFullscreen)
            e.requestFullscreen(options);
        else {
            if (e.webkitRequestFullscreen) {
                e.webkitRequestFullscreen(options);
            }
        }
    }catch(e) {
        console.error('error while trying to fullScreen', e);
    }
}

Window.prototype.exitFullScreen = function () {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document["mozCancelFullScreen"]) { /* Firefox */
        document["mozCancelFullScreen"]();
    } else if (document["webkitExitFullscreen"]) { /* Chrome, Safari and Opera */
        document["webkitExitFullscreen"]();
    } else if (document["msExitFullscreen"]) { /* IE/Edge */
        document["msExitFullscreen"]();
    }
}

Window.prototype.onFullScreenChange = function (callback: (isFullScreen: boolean) => void) {
    let fullScreenChanged = (e: Event) => {
        let doc = window.document;
        let element = doc.fullscreenElement || doc["webkitFullscreenElement"] || doc.fullscreen;
        callback(!!element);
    };

    window.addEventListener('fullscreenchange', fullScreenChanged);
    window.addEventListener('webkitfullscreenchange', fullScreenChanged);
}

let resizeHandler = debounce(() => {
    document.documentElement.style.setProperty('--innerHeight', `${window.innerHeight}px`);
}, 400);

window.addEventListener('resize', resizeHandler);
resizeHandler();

export {}
