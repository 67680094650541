import * as angular from 'angular';
import {StateProvider} from '@uirouter/angularjs';
import ListController from "./ListController";
import EditController from "./EditController";
import TemplateEditController from "./TemplateEditController";
import TemplateListController from "./TemplateListController";

class Routes {
    static $inject = ['$stateProvider'];

    constructor($stateProvider: StateProvider) {
        $stateProvider
            .state('giftCertificate', {
                abstract: true,
                url:'/giftCertificate'
            })

            .state('giftCertificate.list', {
                url: '/list',
                template: require('./List.html'),
                controller: ListController,
                controllerAs: 'vm',
                data: {title: 'Gift Certificate List'}
            })

            .state('giftCertificate.create', {
                url: '/create',
                template: require('./Edit.html'),
                controller: EditController,
                controllerAs: 'vm',
                data: {title: 'Issue Gift Certificate'}
            })

            .state('giftCertificate.edit', {
                url: '/:giftCertificateId',
                template: require('./Edit.html'),
                controller: EditController,
                controllerAs: 'vm',
                data: {title: 'Edit Gift Certificate'}
            })

            .state('giftCertificate.template', {
                url:'/template',
                abstract:true
            })

            .state('giftCertificate.template.add', {
                url: '/add',
                template: require('./TemplateEdit.html'),
                controller: TemplateEditController,
                controllerAs: '$ctrl',
                data:{title:'Gift Cert Template Add'}
            })
            .state('giftCertificate.template.edit', {
                url: '/:id',
                template: require('./TemplateEdit.html'),
                controller: TemplateEditController,
                controllerAs: '$ctrl',
                data:{title:'Gift Cert Template Edit'}
            })
            .state('giftCertificate.template.list', {
                url: '',
                template: require('./TemplateList.html'),
                controller: TemplateListController,
                controllerAs: '$ctrl',
                data:{title:'Gift Cert Template List'}
            })

    }
}

export default angular.module('app.giftCertificate', [])
    .config(Routes)
    .name;
