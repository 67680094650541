import {Ng1Controller, Transition, TargetState, StateService} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {ISCEService, IScope} from "angular";
import {ChangeLogDto} from "../model/ChangeLogDto";
import {UserDto} from "../model/UserDto";

export default class ViewController implements Ng1Controller {
    private body: JQuery<HTMLDivElement>;
    private model: ChangeLogDto;
    private users: UserDto[] = [];
    private roles: string[];

    $onInit(): void {
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(private $dataHub: DataHubService,
                private $sce: ISCEService,
                private toaster: any,
                private $state: StateService,
                private $stateParams: any,
                private changeLogId: number,
                private $uibModalInstance,
                private $scope: IScope) {


        $dataHub.onConnected(() => {
            if (!this.model)
                this.reload();
        });

        this.reload();
    }

    reload = () => {
        if (!this.$dataHub.isConnected) return;
        this.$dataHub.changeLogGet(this.changeLogId)
            .then(r => {
                this.model = r;
                this.body[0].innerHTML=this.model.message;
            })
            .catch(this.$dataHub.defaultErrorHandler);

        this.$dataHub.userGetActive()
            .then(r => {
                this.users = r;
                this.roles = [];
                this.users.forEach(user => user.roles.forEach(role => {
                    if (this.roles.indexOf(role) >= 0) return;
                    this.roles.push(role);
                }))
            });

    }

    submit = () => {
        this.model.message = this.body[0].innerHTML;
        this.$dataHub.changeLogUpdate(this.model)
            .then(() => {
                this.$uibModalInstance.close();
            })
    };

    selectRole = (role: string) => {
        this.users.filter(user => user.roles.indexOf(role) >= 0)
            .filter(user => this.model.userIds.indexOf(user.id) < 0)
            .forEach(user => this.model.userIds.push(user.id));
    }

}
