import * as angular from 'angular';
import {SpinnerService} from "./SpinnerModule";

export class PrintService {
    private static template: string;

    constructor(
        private $http: ng.IHttpService,
        private $timeout: ng.ITimeoutService,
        private $q: ng.IQService,
        private $spinner: SpinnerService) {

    }

    printPdf(url: string) {
        var q = this.$q.defer<Boolean>();
        this.$spinner.show('Printing');
        q.promise.finally(() => this.$spinner.hide());

        if (window.navigator.userAgent.indexOf('iPad') > 0 || window.navigator.userAgent.indexOf('iPhone') > 0) {
            // mobile safari doesn't render the auto-print correctly
            // popout the pdf so the user can print manually
            window.open(url, '_printTarget');
            this.confirm(q);
        } else {
            this.loadPdf(url, q);
        }
        return q.promise;
    }

    private loadPdf(url: string, q: angular.IDeferred<Boolean>) {
        this.$http.get(url, {responseType: 'blob'})
            .then(r => {
                console.debug(r);
                const file = window.URL.createObjectURL(r.data);
                q.promise.finally(() => window.URL.revokeObjectURL(file));
                this.createFrame(file, q);
            })
            .catch(e => {
                console.debug('error in loadPdf.get:', e);
                return q.reject(e);
            });
    }

    private createFrame(file, q: angular.IDeferred<Boolean>) {
        var printFrame = document.createElement('iframe');
        printFrame.src = file;
        printFrame.onload = () => {

            // neither of these work with pdf content, presumably because the pdf viewer doesn't fire them
            // printFrame.contentWindow.onafterprint  = function(event) {
            //     console.log('onafterprint');
            // };
            //
            // var mediaQueryList = printFrame.contentWindow.matchMedia('print');
            // mediaQueryList.addListener(function(mql) {
            //     if(mql.matches) {
            //         console.log('webkit equivalent of onbeforeprint');
            //     }
            // });

            setTimeout(() => {
                printFrame.contentWindow.print();
                setTimeout(() => this.confirm(q), 1000);
            }, 100);
        };

        document.body.appendChild(printFrame);
        q.promise.finally(() => document.body.removeChild(printFrame));
    }

    private confirm(q: angular.IDeferred<Boolean>) {
        var dialog = document.createElement('div');
        dialog.innerHTML = require('./printConfirm.html');
        document.body.appendChild(dialog);


        const $modal = $(dialog)
            .find('.modal')
            .modal({show: true, keyboard: false, backdrop: 'static'});

        $modal.find('button[data-yes]').on('click', () => {
            q.resolve(true);
        });

        $modal.find('button[data-no]').on('click', () => {
            q.resolve(false);
        });

        $modal.on('hidden.bs.modal', function (e) {
            document.body.removeChild(dialog);
        });

        q.promise.finally(() => {
            $modal.modal('hide');
        });

        this.$spinner.hide();
    }
}

export default angular.module('services.print', [])
    .service('$print', PrintService)
    .name;
