import {DataHubService} from "../modules/DataHubModule";
import {StudentExamDto} from "../model/StudentExamDto";
import {ITimeoutService} from "angular";
import {StudentExamLogDto} from "../model/StudentExamLogDto";

export class ExamManagerDebugController {
    private logs: StudentExamLogDto[] = [];

    constructor(private $uibModalInstance,
                private $timeout: ITimeoutService,
                private $dataHub: DataHubService,
                private exam: StudentExamDto) {

        this.$dataHub.bindAllStudentExamLog(() => this.logs);
        this.$dataHub.onConnected(this.refresh);
        this.refresh()
    }

    refresh = () => {
        this.$dataHub.studentExamLogSubscribe(this.exam.id);
    };

    done = () => {
        this.$uibModalInstance.close('1');
    };

}
