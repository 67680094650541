import {DataHubService} from "../modules/DataHubModule";
import {CertificateRequestDto} from "../model/CertificateRequestDto";

export default class ListController {
    requests: CertificateRequestDto[] = [];

    constructor(private $dataHub: DataHubService) {
        $dataHub.bindAllCertificateRequest(() => this.requests);
        $dataHub.connected(this.reload);
    }

    reload = () => {
        this.requests = [];
        if (!this.$dataHub.isConnected) return;
        this.$dataHub.certificateRequestSubscribe();
    };

    reset = (row: CertificateRequestDto) => {
        this.$dataHub.certificateRequestReset(row).catch(this.$dataHub.defaultErrorHandler);
    };

    approve = (row: CertificateRequestDto) => {
        this.$dataHub.certificateRequestApprove(row).catch(this.$dataHub.defaultErrorHandler);
    };

    delete = (row: CertificateRequestDto) => {
        this.$dataHub.certificateRequestDelete(row).catch(this.$dataHub.defaultErrorHandler);
    };

    printed = (row: CertificateRequestDto) => {
        this.$dataHub.certificatePrinted(row.id).catch(this.$dataHub.defaultErrorHandler);
    };


}
