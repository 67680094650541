import * as angular from 'angular';
import {StateProvider} from '@uirouter/angularjs';
import RequestController from "./RequestController";
import ListController from "./ListController";

class Routes {
    static $inject = ['$stateProvider'];

    constructor($stateProvider: StateProvider) {
        $stateProvider
            .state('certificate', {
                abstract: true,
                url:'/certificate'
            })

            .state('certificate.request', {
                url: '/request?studentId&requestId',
                params: {
                    requestId: {dynamic: true}
                },
                template: require('./Request.html'),
                controller: RequestController,
                controllerAs: 'vm',
                data: {title: 'Request Certificate'}
            })
            .state('certificate.list', {
                url: '/list',
                template: require('./List.html'),
                controller: ListController,
                controllerAs: 'vm',
                data: {title: 'Request List'}
            })

    }
}

export default angular.module('app.certificate', [])
    .config(Routes)
    .name;
