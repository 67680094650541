// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html



// Myers.StudentManagementSystem.Data.Models.StudentDisplayDto
export class StudentDisplayDto {
    public id: number = 0;
    public archived: boolean = false;
    public studentTypeId: number = 0;
    public firstName: string = null;
    public middleName: string = null;
    public lastName: string = null;
    public fullName: string = null;
    public status: string = null;
}


    



