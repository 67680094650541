import * as angular from 'angular';
import CurrencyFilter from "./CurrencyFilter";
import {IFilterService} from "angular";


function stringToCurrency($filter: IFilterService) {
    return {
        restrict: 'A',
        require: '^ngModel',
        link: function (scope, element, attrs, ngModel: ng.INgModelController) {
            ngModel.$formatters.unshift(function (value) {
                console.debug('format', value);
                if (!value) return undefined;
                return formatForDisplay();
            });

            ngModel.$parsers.push(function (value) {
                console.debug('parse', value);
                if (!value) return null;
                let result = parseFloat(value.replace(/[^\\.\d\-]/g,''));
                if (result > -9999999999999999)
                    return Math.round(result * 100) / 100;
                return undefined;
            });

            element.on('blur', () => {
                ngModel.$viewValue = formatForDisplay();
                ngModel.$render();
            });

            function formatForDisplay() {
                let result = parseFloat(ngModel.$modelValue);
                if (result > -9999999999999999)
                    return $filter('currency')(result);
                return undefined;
            }
        }
    };
}

export default angular.module('directives.stringToCurrency', [])
    .directive('stringToCurrency', stringToCurrency)
    .name;
