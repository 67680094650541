import * as angular from 'angular';
import SignaturePad from "signature_pad";
import trimCanvas from 'trim-canvas'
import {INgModelController, IScope, ITimeoutService, IWindowService} from "angular";

function signaturePad($timeout: ITimeoutService, $window: IWindowService) {
    return {
        template: require('./SignaturePad.html'),
        restrict: 'E',
        require: 'ngModel',
        link: function (scope: IScope, element: JQuery<HTMLElement>, attrs: any, ngModel: INgModelController) {
            let canvas = <HTMLCanvasElement>element.find('canvas')[0];
            let resizeCanvas = () => {
                var ratio = Math.max(window.devicePixelRatio || 1, 1);
                canvas.width = canvas.offsetWidth * ratio;
                canvas.height = canvas.offsetHeight * ratio;
                canvas.getContext("2d").scale(ratio, ratio);
                signaturePad.clear(); // otherwise isEmpty() might return incorrect value
                ngModel.$setViewValue(null);
            }
            let signaturePad = new SignaturePad(canvas, {
                onEnd: () => {
                    scope.$evalAsync(() => {
                        let clone = cloneCanvas(canvas);
                        trimCanvas(clone);
                        ngModel.$setViewValue(clone.toDataURL());
                    })
                },
                minWidth: 2,
                maxWidth: 4
            });

            $timeout(() => resizeCanvas(), 1000);

            $window.addEventListener('resize', resizeCanvas.bind(this));

            element.find('.btn-reset').on('click', () => {
                signaturePad.clear()
                ngModel.$setViewValue(null);
            });

            scope.$watch(() => attrs.description, (newValue: string) => {
                element.find('.description').text(newValue || 'Sign above');
            })

            scope.$on('$destroy', () => {
                signaturePad.clear();
                $window.removeEventListener('resize', resizeCanvas.bind(this));
                console.debug('$destroy signaturePad');
            })

        }
    };
}

function cloneCanvas(oldCanvas) {

    //create a new canvas
    var newCanvas = document.createElement('canvas');
    var context = newCanvas.getContext('2d');

    //set dimensions
    newCanvas.width = oldCanvas.width;
    newCanvas.height = oldCanvas.height;

    //apply the old canvas to the new one
    context.drawImage(oldCanvas, 0, 0);

    //return the new canvas
    return newCanvas;
}

export default angular.module('directives.signaturePad', [])
    .directive('signaturePad', signaturePad)
    .name;
