import {DataHubService} from "../modules/DataHubModule";
import {StudentTypeDto} from "../model/StudentTypeDto";
import {PricingDto} from "../model/PricingDto";
import {ContractDto} from "../model/ContractDto";

import * as angular from 'angular';

export default class PriceManagerController {
    studentTypes: StudentTypeDto[] = [];
    studentType: StudentTypeDto;
    pricings: PricingDto[] = [];
    pricing: PricingDto = new PricingDto();
    addEditVisible: boolean = false;
    contracts: ContractDto[] = [];
    modalError: any;

    constructor(private $dataHub: DataHubService) {

        $dataHub.bindAllPriceLevel(() => this.pricings);
        $dataHub.bindAllContract(() => this.contracts);

        $dataHub.onStudentTypeUpdated(entity => {
            var containsDefault = this.studentTypes.filter(this.isDefaultStudentType).length > 0;
            this.studentTypes.merge(entity, x => x.id);
            if (!containsDefault) {
                var defaults = this.studentTypes.filter(this.isDefaultStudentType);
                if (defaults.length > 0) {
                    this.studentType = defaults[0];
                    this.studentTypeChanged();
                }
            }
        });

        $dataHub.onConnected(this.load);

        this.load();
    }

    load = () => {
        if (!this.$dataHub.isConnected) return;
        this.$dataHub.studentTypeList();
    };

    studentTypeChanged() {
        this.pricings.length = 0;
        this.$dataHub.priceLevelsForStudentTypeFull(this.studentType.id);
        this.contracts.length = 0;
        this.$dataHub.contractSubscribe(this.studentType.id);
    }

    toggleEnabled = (thing: any) => this.$dataHub.priceLevelEnabledToggle(thing.id).catch(e => thing.error = e);

    editClicked(editMe: PricingDto) {
        this.pricing = angular.copy(editMe);
        (<any>this.pricing).hasSpecialPrice = this.pricing.priceSpecial > 0;
        this.modalError = null;
        this.addEditVisible = true;
    }

    downClicked = (editMe: PricingDto) => this.$dataHub.priceLevelMoveUp(editMe.id);

    upClicked = (editMe: PricingDto) => this.$dataHub.priceLevelMoveDown(editMe.id);

    addClicked() {
        if (this.pricing.id > 0)
            this.resetPricingDto();
        else
            this.minimalResetPricingDto();
        this.addEditVisible = true;
    }

    closeModalClicked() {
        this.addEditVisible = false;
        this.modalError = null;
    }

    minimalResetPricingDto() {
        this.pricing.studentTypeId = this.studentType.id;
        this.modalError = null;
    }

    resetPricingDto() {
        this.pricing = new PricingDto();
        this.pricing.contractId = null;
        this.pricing.enabled = true;
        this.minimalResetPricingDto();
    }

    saveModalClicked() {
        this.$dataHub.priceLevelAddUpdate(this.pricing)
            .then(() => {
                this.addEditVisible = false;
                this.resetPricingDto();
            })
            .catch(e => this.modalError = e);
    }

    deleteModalClicked() {
        this.$dataHub.priceLevelPermanentlyDisable(this.pricing.id)
            .then(() => {
                this.addEditVisible = false;
                this.resetPricingDto();
            })
            .catch(e => this.modalError = e);
    }

    dismissModalError = () => this.modalError = null;

    private isDefaultStudentType = (value: StudentTypeDto) => value.name == "Student";
}
