import * as angular from 'angular';
import {Janus} from 'janus-gateway';
import {DebounceFactory} from "../modules/DebounceModule";
import {IIntervalService, IScope, ITimeoutService, IWindowService} from "angular";
import {LoggerService} from "../modules/LoggerModule";

function janusSubscribeDirective($debounce: DebounceFactory,
                                 $interval: IIntervalService,
                                 $timeout: ITimeoutService,
                                 $window: IWindowService,
                                 $logger: LoggerService) {
    return {
        link(scope: IScope, element: JQuery<HTMLVideoElement>, attrs) {
            var instance: Janus;
            var publisherId: number;
            var roomId: number;
            var pin: string;
            var opaqueId: string;
            let handle: any;
            let stats: any;
            let statsEnabled: boolean;
            let bitrateTimer: any;
            let audio: boolean;
            let video: boolean;
            let debugValue: any;
            let substream: number = null;
            let destroy = false;
            let visibleInViewport = false;
            let streamRef: MediaStream = null;
            let observer: IntersectionObserver;

            scope.$watch(attrs.janusInstance, (newValue) => {
                instance = newValue;
            });
            scope.$watch(() => instance && instance.isConnected(), newValue => {
                if (newValue)
                    subscribe();
            });
            scope.$watch(attrs.janusSubscribe, (newValue: number) => {
                if (statsEnabled && publisherId && newValue)
                    log(`publisherId changed: old:${publisherId} new:${newValue}`);
                publisherId = newValue;
                subscribe();
            });
            scope.$watch(attrs.janusOpaqueId, (newValue) => {
                if (angular.isObject(newValue))
                    opaqueId = JSON.stringify(newValue);
                else
                    opaqueId = newValue;
            });
            scope.$watch(attrs.janusRoomId, (newValue: number) => {
                roomId = newValue;
            });
            scope.$watch(attrs.janusPin, (newValue: string) => {
                pin = newValue;
            });
            scope.$watch(attrs.janusStatsEnabled, newValue => {
                statsEnabled = !!newValue;
            })
            if (attrs.janusStats)
                scope.$watch(attrs.janusStats, (newValue) => {
                    if (!newValue)
                        scope.$eval(attrs.janusStats + ' = {}');
                    else {
                        stats = newValue;
                        stats.changeSubstream = (x) => {
                            substream = x;
                            reconfigure();
                        }
                    }
                });
            scope.$watch(attrs.janusAudio, newValue => {
                let x = !!newValue;
                // log(`audio changed from ${audio} to ${x} (${newValue})`);
                if (x != audio) {
                    audio = x;
                    reconfigure();
                }
            })
            scope.$watch(attrs.janusVideo, newValue => {
                let x = !!newValue;
                if (x != video) {
                    video = x;
                    reconfigure();
                }
            })
            scope.$watch(attrs.janusDebug, newValue => {
                debugValue = newValue;
            })

            if (IntersectionObserver) {
                observer = new IntersectionObserver(entries => {
                    // assuming the latest entry is always last...
                    var entry = entries[entries.length - 1];
                    visibleInViewport = entry.isIntersecting;
                    if (statsEnabled) {
                        // console.debug(debugValue, entries);
                        log(`isIntersecting:${entry.isIntersecting} ${entry.intersectionRatio} ${entry.time}`)
                    }
                    if (stats) {
                        stats.ir = entry.intersectionRatio.toFixed(2);
                        stats.vis = visibleInViewport;
                    }
                    reconfigure();
                }, {
                    threshold: 0
                });
                observer.observe(element[0]);
            }

            scope.$on('$destroy', () => {
                log('$destroy');
                destroy = true;
                try {
                    handle?.detach();
                } catch (e) {
                    logError(e);
                }
                try {
                    if (observer)
                        observer.disconnect();
                } catch (e) {
                    logError(e);
                }
            });


            let log = (message?: any, ...optionalParams: any[]) => {
                $logger.debug(`[${debugValue} ${publisherId}] ${message}`, ...optionalParams);
            }
            let logError = (message?: any, ...optionalParams: any[]) => {
                $logger.error(`[${debugValue} ${publisherId}] ${message}`, ...optionalParams);
            }

            let reconfigure = () => {
                if (!handle) return;
                let body = {
                    "request": "configure",
                    "audio": audio,
                    "video": video && visibleInViewport,
                };
                if (stats) {
                    stats.audio = body.audio;
                    stats.video = body.video;
                }
                if (substream > 0 || substream === 0)
                    body["substream"] = substream;
                if (statsEnabled)
                    log(`reconfigure stream: video:${body.video} audio:${body.audio}`);

                handle.send({"message": body});
            }

            let subscribe = $debounce(() => {
                if (handle) {
                    try {
                        log('detaching old handle');
                        handle.detach();
                    } catch (e) {
                        logError(e);
                    }
                }

                if (!instance || !publisherId || destroy) {
                    return;
                }

                log('joining stream');

                var remoteFeed; // captured reference
                instance.attach({
                    plugin: "janus.plugin.videoroom",
                    opaqueId: opaqueId,
                    success: (pluginHandle) => {
                        handle = pluginHandle;
                        remoteFeed = pluginHandle;
                        // log("Subscriber attached! (" + remoteFeed.getPlugin() + ", id=" + remoteFeed.getId() + ")");
                        var listen = {
                            "request": "join",
                            "room": roomId,
                            "pin": pin,
                            "ptype": "subscriber",
                            "feed": publisherId,
                            // safari won't play audio from a <video> element if there isn't a video stream initially
                            "audio": true,
                            "video": true,
                        };
                        remoteFeed.send({"message": listen});
                    },
                    error: function (error) {
                        logError("  -- Error attaching plugin...", error);
                    },
                    onmessage: (msg, jsep) => {
                        if (statsEnabled) {
                            log("onMessage", msg);
                            // if (stats) {
                            //     if (!stats.msg) stats.msg = [];
                            //     stats.msg.push(msg);
                            //     while (stats.msg.length > 100)
                            //         stats.msg.shift();
                            // }
                        }
                        // console.debug('onmessage', msg);
                        if (msg["error"])
                            logError(msg["error_code"], msg["error"]);
                        if (msg["started"] == "ok")
                            setTimeout(() => reconfigure(), 1000);
                        var event = msg["videoroom"];
                        switch (event) {
                            case "attached":
                                // log("Successfully attached");
                                break;
                        }
                        if (stats && (msg["substream"] != undefined || msg["temporal"] != undefined)) {
                            scope.$applyAsync(() => {
                                if (msg["substream"] != undefined) stats.s = msg["substream"];
                                if (msg["temporal"] != undefined) stats.t = msg["temporal"];
                            });

                        }
                        if (jsep !== undefined && jsep !== null) {
                            // $logger.debug("Handling SDP as well...", jsep);
                            // Answer and attach
                            remoteFeed.createAnswer(
                                {
                                    jsep: jsep,
                                    media: {audioSend: false, videoSend: false},	// We want recvonly audio/video
                                    success: (jsep) => {
                                        // $logger.debug("Got SDP!", jsep);
                                        var body = {"request": "start"};
                                        remoteFeed.send({"message": body, "jsep": jsep});
                                    },
                                    error: function (error) {
                                        logError("WebRTC error:", error);
                                    }
                                });
                        }
                    },
                    webrtcState: (on) => {
                        if (stats && stats.webrtcState != on) {
                            scope.$applyAsync(() => stats.webrtcState = on);
                        }
                    },
                    mediaState: (type, on) => {
                        log(`mediaState ${type} ${on}`);
                    },
                    slowLink: (uplink) => {
                        log(`slowLink`);
                        if (attrs.janusSlowLink) {
                            scope.$eval(attrs.janusSlowLink, {source: debugValue});
                        }
                    },
                    onremotestream: function (stream: MediaStream) {
                        if (destroy) {
                            handle.detach();
                            try {
                                element[0].srcObject = null;
                            } catch {

                            }
                            return;
                        }
                        let isNewStream = stream != streamRef;
                        streamRef = stream;

                        if (statsEnabled)
                            log(`received ${isNewStream ? 'new' : 'same'} stream`);

                        if (!element || !element[0]) {
                            logError(`missing video element`);
                            return;
                        }

                        try {
                            element[0].srcObject = stream;
                        } catch (e) {
                            logError(e);
                        }

                        if (stats)
                            stats.stream == !!stream;

                        if (bitrateTimer) {
                            $interval.cancel(bitrateTimer);
                            bitrateTimer = null;
                        }
                        if (!stats || !statsEnabled || !visibleInViewport) return;
                        bitrateTimer = $interval(() => {
                            //$logger.debug('bitrateTimer', stats, statsEnabled);
                            stats.br = remoteFeed.getBitrate();
                            stats.r = `${element[0].videoWidth}x${element[0].videoHeight}`;
                        }, 1000);
                    },
                    oncleanup: function () {
                        if (statsEnabled)
                            log('cleanup');

                        streamRef = null;

                        // detach stream from media element to prevent leak
                        try {
                            element[0].srcObject = null;
                        } catch (e) {
                            logError(e);
                        }
                        if (bitrateTimer) {
                            $interval.cancel(bitrateTimer);
                            bitrateTimer = null;
                        }
                    },
                    detached: () => {
                        log(`detached`);
                    }
                });
            }, 250, false);
        }
    };
}

export default angular.module('directives.janusSubscribe', [])
    .directive('janusSubscribe', janusSubscribeDirective)
    .name;
