import * as angular from 'angular';
import {StateProvider} from '@uirouter/angularjs';
import ListController from "./ListController";
import ViewController from "./ViewController";

class Routes {
    static $inject = ['$stateProvider'];

    constructor($stateProvider: StateProvider) {
        $stateProvider
            .state('changeLog', {
                abstract: true,
                url:'/changeLog'
            })

            .state('changeLog.list', {
                url: '',
                template: require('./List.html'),
                controller: ListController,
                controllerAs: 'vm',
                data: {title: 'ChangeLog List'}
            })

            .state('changeLog.list.view', {
                url: '/view/:changeLogId',
                onEnter: function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        template: require('./View.html'),
                        controller: ViewController,
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            changeLogId: () => $stateParams.changeLogId
                        }
                    }).result.finally(() => $state.go('^'));
                }
            })

    }
}

export default angular.module('app.changeLog', [])
    .config(Routes)
    .name;
