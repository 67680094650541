// Generated by Typewriter / Const.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html


export namespace RoleName {
    export const Admin = 'Admin';
    export const CertificateAdmin = 'CertificateAdmin';
    export const ClassroomAdmin = 'ClassroomAdmin';
    export const ClassroomInstructor = 'ClassroomInstructor';
    export const Developer = 'Developer';
    export const ExamAdmin = 'ExamAdmin';
    export const ExamProctor = 'ExamProctor';
    export const FeedbackAdmin = 'FeedbackAdmin';
    export const InCarInstructor = 'InCarInstructor';
    export const Office = 'Office';
    export const StudentRelease = 'StudentRelease';
    export const UserAdmin = 'UserAdmin';
    export const Refunds = 'Refunds';
    
}