import * as angular from 'angular';

function urlToFilename() {
    return function (input) {
        if(!input) return;
        var x = input.split('/');
        x = x[x.length-1].split('?');
        return x[0];
    };
}

export default angular.module('filters.filename', [])
    .filter('filename', urlToFilename)
    .name;
