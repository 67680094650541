import * as angular from "angular";
import {IScope} from "angular";

class sortableController {

    constructor(private $scope: any) {
    }

    click = (clear: Boolean) => {
        console.log('click', clear);
        let f1 = this.$scope.sortableField;
        let f2 = '-' + this.$scope.sortableField;

        if (!Array.isArray(this.$scope.sortableVar))
            this.$scope.sortableVar = [this.$scope.sortableVar];
        let index = this.$scope.sortableVar.indexOf(f1);
        var reverse = false;

        if (index >= 0)
            reverse = true;
        else
            index = this.$scope.sortableVar.indexOf(f2);

        if (index >= 0)
            this.$scope.sortableVar.splice(index, 1);

        if (clear) {
            this.$scope.caret = null;
            return;
        }


        this.$scope.sortableVar.unshift(reverse ? f2 : f1);
        this.$scope.caret = reverse ? 'up' : 'down';

    }


}

function sortable() {
    return {
        template: require('./Sortable.html'),
        restrict: 'A',
        transclude: true,
        scope: {
            sortableField: '@',
            sortableVar: '=sortable'
        },
        controller: sortableController,
        controllerAs: '$ctrl'
    };
}

export default angular.module('directives.sortable', [])
    .directive('sortable', sortable)
    .name;

