import * as angular from 'angular';
import {addMonths, addYears, intervalToDuration} from "date-fns";

function age() {
    return {
        template: '<span ng-if="valid">{{years}}y {{months}}m {{days}}d <span ng-if="underage" class="text-danger">(underage until {{ofAge|date:"mediumDate"}})</span></span>',
        restrict: 'E',
        scope: {
            dob: '<',
            ofAge: '=?'
        },
        controller: function ($scope) {

            $scope.$watch(() => $scope.dob, function (value) {
                if (!value) {
                    $scope.valid = false;
                    return;
                }

                let duration = intervalToDuration({start: $scope.dob, end: new Date()})
                $scope.years = duration.years;
                $scope.months = duration.months;
                $scope.days = duration.days;
                $scope.valid = true;
                $scope.underage = $scope.years < 15 || $scope.years == 15 && $scope.months < 5;
                $scope.ofAge = addMonths(addYears($scope.dob, 15), 5).toISOString();
            })
        },
    };
}

export default angular.module('directives.age', [])
    .directive('age', age)
    .name;
