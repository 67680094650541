import * as angular from 'angular';

function ngAutoFocus($timeout) {
    return {
        link(scope, element) {
            $timeout(function() {
                element.focus();
                element.select();
            }, 0);

        }
    };
};

export default angular.module('directives.ngAutoFocus', [])
    .directive('ngAutoFocus', ngAutoFocus)
    .name;
