import * as angular from 'angular';

function formGroupInput() {

    function getFieldModelName(attrs) {
        var objectAndField = attrs.ngModel;
        var names = objectAndField.split('.');
        var fieldModelName = names[names.length - 1];
        return fieldModelName;
    }

    return {
        template: require('./FormGroupInput.html'),
        restrict: 'E',
        require: ['^form', 'ngModel'],
        scope: {label: '@', placeholder: '@', ngModel: '=', maxlength: '@', type: '@'},

        link: function (scope, element, attrs, ctrls) {
            scope.form = ctrls[0];
            scope.name = getFieldModelName(attrs);
            var ngModel = ctrls[1];

            if (attrs.required !== undefined) {
                scope.required = true;
            }
            if (attrs.disabled !== undefined) {
                scope.disabled = true;
            }
        },
    };
}

export default angular.module('directives.formGroupInput', [])
    .directive('formGroupInput', formGroupInput)
    .name;
