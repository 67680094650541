import {DataHubService} from "../modules/DataHubModule";
import {SpinnerService} from "../modules/SpinnerModule";
import {StudentDto} from "../model/StudentDto";
import {TransactionDto} from "../model/TransactionDto";
import {StateService} from "@uirouter/angularjs";
import {TransactionTypeDto} from "../model/TransactionTypeDto";
import {ConfigService} from "../modules/ConfigModule";

export default class AddController {
    student: StudentDto;
    txn: TransactionDto;
    transactionTypes: TransactionTypeDto[] = [];
    transactions: TransactionDto[] = [];

    txnDateOptions = {};

    error: any;
    private mode: string;

    constructor(
        private $dataHub: DataHubService,
        private $spinner: SpinnerService,
        private $config: ConfigService,
        private $state: StateService,
        private $stateParams: any
    ) {
        this.txn = new TransactionDto();
        this.txn.txnDate = new Date();

        $dataHub.onStudentUpdated(this.studentUpdated);
        $dataHub.client.onGiftCertificateDetailUpdated(e => {
            this.txn.giftCertificate = e;
            this.txn.amount = e.balanceDue
        });
        $dataHub.bindAllTransactionType(() => this.transactionTypes);
        $dataHub.connected(this.reload);
        this.reload();
    }

    reload = () => {
        if (!this.$dataHub.isConnected) return;
        this.transactionTypes = [];

        let sid = this.$stateParams.studentId;

        if (sid) {
            this.$spinner.show('Loading Student');
            this.$dataHub.invoke('StudentGet', sid);
            this.$dataHub.invoke('TransactionTypeList', sid, this.txn.txnDate);
            return;
        }

        let giftCertificateId = this.$stateParams.giftCertificateId;
        if (giftCertificateId) {
            this.$spinner.show('Loading');
            this.$dataHub.server.giftCertificateSubscribeOne(giftCertificateId)
                .catch(this.$dataHub.defaultErrorHandler);
            this.$dataHub.server.giftCertificatePaymentMethodSubscribe()
                .catch(this.$dataHub.defaultErrorHandler)
                .finally(() => this.$spinner.hide());
            return;
        }
    };

    studentUpdated = s => {
        this.student = s;
        this.txn.student = s;
        this.txn.nameOnCheck = s.lastName;
        this.$spinner.hide();
    };


    save = () => {
        this.$spinner.show('Saving Transaction...');
        console.debug('save txn', this.txn);
        var token = this.$config.locationTokenCurrent;
        if (token)
            this.txn.location = token.location;

        if(this.txn.student) {
            this.$dataHub.invoke('transactionAdd', this.txn)
                .then((result: TransactionDto) => {
                    console.debug('save result', result);
                    if (!result.amount)
                        this.error = result.lastError;
                    else {
                        this.txn = null;
                        this.$state.go('^.view', {studentId: this.student.id, txnId: result.id});
                    }
                })
                .catch(e => {
                    this.error = e;
                    console.error(e);
                })
                .finally(() => this.$spinner.hide());
        } else if (this.txn.giftCertificate) {
            this.$dataHub.server.giftCertificatePaymentProcess(this.txn)
                .then(result => {
                    console.debug('save result', result);
                    if (!result.amount)
                        this.error = result.lastError;
                    else {
                        this.$state.go('^.edit', {giftCertificateId: this.$stateParams.giftCertificateId});
                    }
                })
                .catch(e => {
                    this.$dataHub.defaultErrorHandler(e);
                    this.error = e;
                    console.error(e);
                })
                .finally(() => this.$spinner.hide());
        }

    };

    transactionTypeChanged = (transactionType: TransactionTypeDto) => {
        if (transactionType.defaultAmount)
            this.txn.amount = transactionType.defaultAmount;
        else if (transactionType.payment)
            this.txn.amount = this.student.balance;

        this.txn.btwHours = transactionType.btwHours;
    };

    shouldFieldBeVisible = (fieldName: string) => {
        if (!this.txn || !this.txn.transactionType || !this.txn.transactionType.fieldsArray) return false;
        return this.txn.transactionType.fieldsArray.indexOf(fieldName) >= 0;
    };

    swipe = (trackData: string) => {
        this.txn.swipeData = trackData;

    };

    dump = (...args) => console.log(args);

};
