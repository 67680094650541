import * as angular from 'angular';

function stringToDate() {
    return {
        restrict: 'A',
        require: '^ngModel',
        link: function (scope, element, attrs, ngModel: ng.INgModelController) {
            ngModel.$formatters.unshift(function (value) {
                if (!value) return null;
                return new Date(value);
            });

            ngModel.$parsers.push(function (value) {
                if (!value) return null;
                let r =  new Date(value);
                return r;
            });

        }
    };
}

export default angular.module('directives.stringToDate', [])
    .directive('stringToDate', stringToDate)
    .name;
