// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html

import { StudentDisplayDto } from './StudentDisplayDto';
import { UserDisplayDto } from './UserDisplayDto';
import { DrivingRecordTypeDto } from './DrivingRecordTypeDto';
import { EntityBaseDto } from './EntityBaseDto';

// Myers.StudentManagementSystem.Data.Models.DrivingRecordDto
export class DrivingRecordDto extends EntityBaseDto {
    public student: StudentDisplayDto = null;
    public instructor: UserDisplayDto = null;
    public drivingRecordType: DrivingRecordTypeDto = null;
    public recordDate: Date = null;
    public recordStart: string = null;
    public recordEnd: string = null;
    public vehiclePlateNumber: string = null;
    public lessonStart: Date = null;
    public lessonEnd: Date = null;
    public duration: string = null;
    public durationEffective: string = null;
}


    



