// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html



// Myers.StudentManagementSystem.Data.Models.RosterReportQueryDto
export class RosterReportQueryDto {
    public locationIds: number[] = [];
    public classTypeIds: number[] = [];
    public date: Date = new Date(0);
    public includeWeek: boolean = false;
}


    



