import {Ng1Controller, Transition, TargetState, StateService} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {UserDto} from "../model/UserDto";
import {StudentDto} from "../model/StudentDto";

export default class AssignController implements Ng1Controller {
    private error: any;
    private instructors: UserDto[] = [];
    private student: StudentDto;

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
    }

    constructor(private $dataHub: DataHubService,
                private toaster: any,
                private $state: StateService,
                private $stateParams: any) {

        $dataHub.client.bindAllUser(() => this.instructors);
        $dataHub.client.onStudentUpdated(e => this.student = e);

        $dataHub.connected(this.refresh)
    }

    refresh = () => {
        if (!this.$dataHub.isConnected) return;

        this.$dataHub.server.studentGet(this.$stateParams.studentId);
        this.$dataHub.server.userDrivingInstructorsSubscribe();
    };

    instructorTypeAllowed = (instructor: UserDto) => {
        return !instructor.driverAllowedStudentTypesArray.some(x => x == this.student.studentType.id)
    };

    assign = (instructor: UserDto) => {
        this.$dataHub.server.studentInstructorAssign(this.student.id, instructor.id)
            .then(() => window.close())
            .catch(e => {
                console.error(e);
                this.toaster.error(e.toString());
            });
    };

    unassign = () => {
        var reason = prompt('Enter reason for unassigning');
        if(!reason) return;
        this.$dataHub.server.studentSignIn(this.student.id, reason)
            .then(() => window.close())
            .catch(e => {
                console.error(e);
                this.toaster.error(e.toString());
            });
    }
}
