import * as angular from 'angular';

function ngSrcObject() {
    return {
        link(scope, element, attrs) {
            scope.$watch(attrs.ngSrcObject, newValue => {
                element[0].srcObject = newValue;
            })
        }
    };
}

export default angular.module('directives.ngSrcObject', [])
    .directive('ngSrcObject', ngSrcObject)
    .name;
