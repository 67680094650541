import {Calendar} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';

import {LocationDto} from "../model/LocationDto";
import {ClassDto} from "../model/ClassDto";
import {DataHubService} from "../modules/DataHubModule";
import {DebounceFactory} from "../modules/DebounceModule";
import {EventInput} from "@fullcalendar/core/structs/event";
import {EventSourceFunc} from "@fullcalendar/core/event-sources/func-event-source";
import {EventSourceInput} from "@fullcalendar/core/structs/event-source";
import {StateService} from "@uirouter/angularjs";
import {popWindow} from "../directive/Popout";
import {getWeek} from "date-fns";

export default class CalendarController {

    locations: LocationDto[] = [];
    selectedLocations: number[] = [];
    classes: ClassDto[] = [];
    calendar: Calendar;
    events: EventInput[];
    error;

    constructor(
        $scope: ng.IScope,
        private $http: ng.IHttpService,
        private $location: ng.ILocationService,
        private $dataHub: DataHubService,
        private $debounce: DebounceFactory,
        private $state: StateService
    ) {
        this.$dataHub.onLocationUpdated(this.locationUpdated);
        this.$dataHub.onClassUpdated(this.classUpdated);
        this.$dataHub.onClassRemoved(this.classRemoved);
        this.$dataHub.connected(() => {
            this.refreshLocations();
        });

        $scope.$on('$locationChangeSuccess', this.locationChangeSuccess);

        $dataHub.connected(() => {
            this.locationChangeSuccess();
            if (this.calendar)
                this.calendar.refetchEvents();
        });
        if ($dataHub.isConnected)
            this.locationChangeSuccess();

        var calendarEl = document.getElementById('calendar');
        this.calendar = new Calendar(calendarEl, {
            plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
            header: {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
            },
            navLinks: true, // can click day/week names to navigate views
            eventLimit: true, // allow "more" link when too many events
            events: this.getCalendarEvents,
            eventClick: function (arg) {
                if (!arg.event.url) return;
                popWindow(arg.event.url, arg.event.id);
                arg.jsEvent.preventDefault();
                return false;
            },
            rerenderDelay: 250,
            slotEventOverlap: true,
            weekNumbers: true,
            weekNumberCalculation: (date: Date): number => getWeek(date, {firstWeekContainsDate: 7})
        });

        this.calendar.render();
    }

    inputChanged = () => {
        this.locationChange();
    };

    /// update url to match current model
    locationChange = () => {
        var search = this.$location.search();
        this.$location.search('location', this.selectedLocations.map(x => x.toString()));
    };

    /// respond to changes in the url
    locationChangeSuccess = () => {
        var search = this.$location.search();
        console.debug('locationChangeSuccess', search);
        if (!search.location) {
            this.selectedLocations = [];
            return;
        }
        let locations = search.location.map(x => parseInt(x));
        console.debug(locations);
        this.selectedLocations = locations;
        if (this.calendar)
            this.calendar.refetchEvents();

    };

    refreshLocations = () => {
        this.$dataHub.invoke('Location')
            .catch(this.handleHubError);
    };


    locationUpdated = (location: LocationDto) => {
        this.locations.merge(location, x => x.id);
    };

    classUpdated = (c: ClassDto) => {
        this.calendar.addEvent(this.eventDataTransform(c));
    };

    classRemoved = (c: ClassDto) => {
    };


    handleHubError = (e) => {
        console.error(e);
        this.error = e;
    };

    getCalendarEvents: EventSourceFunc = (info, success, failure) => {
        if (!this.$dataHub.isConnected) return;

        let q = {
            start: info.start,
            end: info.end,
            locations: this.selectedLocations
        };
        console.debug('getCalendarEvents', q);
        if (this.calendar) this.calendar.removeAllEvents();
        this.$dataHub.invoke('classCalendar', q);
        success([]);

    };

    eventDataTransform = (c: ClassDto): EventInput => {
        var e: EventSourceInput = {
            id: c.id,
            start: new Date(c.classStart),
            end: new Date(c.classEnd),
            title: 'Unknown',
            textColor: 'black',
            url: this.$state.href('classroom.attendance', {classId: c.id}),
            extraParams: {target: '_blank'}
        };

        switch (c.classInfo.classType.id) {
            case 1:
            case 8:
                e.title = '#' + c.classInfo.classNumber + ' ' + c.classInfo.classType.name;
                e.color = 'lightgreen';
                break;
            case 2:
                e.title = 'JDIP';
                e.color = 'lightyellow';
                break;
            case 3:
            case 5:
                e.title = 'Adult Remedial';
                e.color = 'lightpink';
                break;
            case 7:
                e.title = '#' + c.classInfo.classNumber + ' Abbr. Adult';
                e.color = 'lightblue';
                break;
            default:
                console.log('unknown classTypeId', e);
                break;
        }
        if (this.selectedLocations.length !== 1)
            e.title = e.title + ' @ ' + c.location.name;
        return e;
    }

};
