import {DataHubService} from "../modules/DataHubModule";
import {CertificateRequestDto} from "../model/CertificateRequestDto";

export default class CertificateRequestsController {
    requests: CertificateRequestDto[] = [];

    constructor(private $dataHub: DataHubService) {
        $dataHub.client.bindAllCertificateRequest(() => this.requests);
        $dataHub.connected(this.reload);
    }

    reload = () => {
        this.requests = [];
        if (!this.$dataHub.isConnected) return;
        this.$dataHub.server.certificateRequestDebugSubscribe();
    };

    reset = (row: CertificateRequestDto) => {
        this.$dataHub.server.certificateRequestReset(row);
    };

    delete = (row: CertificateRequestDto) => {
        this.$dataHub.server.certificateRequestDelete(row);
    };
}
