import * as angular from 'angular';
import * as moment from 'moment';
import {Duration} from "moment";

function format(d: Duration) {
    if (!d.isValid()) return '';
    var h = Math.floor(d.asHours());
    var m = d.minutes();
    return h + "h" + (m < 10 ? '0' : '') + m + "m";
}

/*
 * format input of milliseconds in hhmm format
 */
export function formatDuration(value, unit) {
    return format(moment.duration(value, unit));
}

export function durationHms(value, unit) {
    let d = moment.duration(Math.abs(value), unit);
    if (!d.isValid()) return '';
    var h = Math.floor(d.asHours());
    var m = d.minutes();
    var s = d.seconds();
    return (h > 0 ? h + "h" : "") +
        (m < 10 ? '0' : '') + m + "m" +
        (s < 10 ? '0' : '') + s + "s";
}


// return duration in h:m:s format
export function videoDuration(value, unit) {
    let d = moment.duration(Math.abs(value), unit || 'seconds');
    if (!d.isValid()) return '';
    var h = Math.floor(d.asHours());
    var m = d.minutes();
    var s = d.seconds();
    return (h > 0 ? h + ":" : "") +
        (h > 0 ? '0' : '') + m + ":" +
        (s < 10 ? '0' : '') + s;
}

/*
 * Parse a TimeSpan and return the value in milliseconds
 */
export function parseTimeSpan(ts) {
    return moment.duration(ts).asMilliseconds();
}

export default angular.module('directives.duration2', [])
    .filter('formatDuration', () => formatDuration)
    .filter('durationHms', () => durationHms)
    .filter('videoDuration', () => videoDuration)
    .filter('timeSpan', () => parseTimeSpan)
    .name;
