import * as angular from 'angular';

function ngSinkId() {
    return {
        link(scope, element, attrs) {
            scope.$watch(attrs.ngSinkId, newValue => {
                if (element && element[0] && element[0].setSinkId !== undefined)
                    element[0].setSinkId(newValue);
            })
        }
    };
};

export default angular.module('directives.ngSinkId', [])
    .directive('ngSinkId', ngSinkId)
    .name;
