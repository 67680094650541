// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html



// Myers.StudentManagementSystem.Data.Models.PrintJobResultDto
export class PrintJobResultDto {
    public guid: string = null;
    public message: string = null;
    public error: string = null;
}


    



