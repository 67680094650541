import * as angular from 'angular';

function ngVolume() {
    return {
        link(scope, element, attrs) {
            if (!element || !element[0]) {
                console.error('no element');
                return;
            }
            var mediaElement = element[0] as HTMLMediaElement;
            if (mediaElement.volume == undefined) {
                console.error("element does not support volume");
                return;
            }
            scope.$watch(attrs.ngVolume, newValue => {
                if (newValue >= 0)
                    element[0].volume = newValue;
            })

            mediaElement.onvolumechange = e => {
                scope.$eval(attrs.ngVolume + ' = ' + mediaElement.volume);
            }
        }
    };
};

export default angular.module('directives.ngVolume', [])
    .directive('ngVolume', ngVolume)
    .name;
