// Generated by Typewriter / DataModelGenerator.tst
// https://frhagn.github.io/Typewriter/pages/documentation.html

import { GiftCertificateDeliveryOption } from './GiftCertificateDeliveryOption';
import { StudentDisplayDto } from './StudentDisplayDto';
import { GiftCertificateTypeDto } from './GiftCertificateTypeDto';
import { GiftCertificateTemplateDisplayDto } from './GiftCertificateTemplateDisplayDto';
import { EntityBaseDto } from './EntityBaseDto';

// Myers.StudentManagementSystem.Data.Models.GiftCertificateDto
export class GiftCertificateDto extends EntityBaseDto {
    public certificateNumber: string = null;
    public certificateNumberFormatted: string = null;
    public certificateDate: Date = new Date(0);
    public purchaserName: string = null;
    public purchaserPhone: string = null;
    public purchaserEmail: string = null;
    public recipientName: string = null;
    public recipientPhone: string = null;
    public recipientEmail: string = null;
    public messageFrom: string = null;
    public messageTo: string = null;
    public messageNote: string = null;
    public deliveryOption: GiftCertificateDeliveryOption = null;
    public deliveryAttnTo: string = null;
    public deliveryAddress: string = null;
    public deliveryEmail: string = null;
    public comments: string = null;
    public purchasePrice: number = 0;
    public balanceDue: number = 0;
    public faceValue: number = 0;
    public student: StudentDisplayDto = null;
    public paid: boolean = false;
    public printed: boolean = false;
    public voided: boolean = false;
    public giftCertificateType: GiftCertificateTypeDto = null;
    public giftCertificateTemplate: GiftCertificateTemplateDisplayDto = null;
}


    



