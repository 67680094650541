import * as angular from 'angular';

function panel() {
    return {
        template: require('./panel.html'),
        restrict: 'E',
        transclude: {
            panelHeading: '?panelHeading',
            panelBody: '?panelBody',
            panelTable: '?table',
            panelListGroup: '?li',
            panelFooter: '?panelFooter'
        },
        scope: {context: '@', panelTitle: '@', enableFeedback: '@'},
        controller: function ($transclude) {
            this.isSlotFilled = {
                panelHeading: $transclude.isSlotFilled('panelHeading'),
                panelBody: $transclude.isSlotFilled('panelBody'),
                panelTable: $transclude.isSlotFilled('panelTable'),
                panelListGroup: $transclude.isSlotFilled('panelListGroup'),
                panelFooter: $transclude.isSlotFilled('panelFooter'),
            }
        },
        controllerAs: '$ctrl'
    };
}

export default angular.module('directives.panel', [])
    .directive('panel', panel)
    .name;
