import {Ng1Controller, StateService, TargetState, Transition} from "@uirouter/angularjs";
import {DataHubService} from "../modules/DataHubModule";
import {AsteriskCallDto} from "../model/AsteriskCallDto";
import {UserStorageService} from "../modules/UserStorageModule";
import {SpinnerService} from "../modules/SpinnerModule";
import {ConfigService} from "../modules/ConfigModule";
import {popWindow} from "../directive/Popout";
import {AsteriskQueueStatus} from "../model/AsteriskQueueStatus";
import {AsteriskCallStatus} from "../model/AsteriskCallStatus";
import {AsteriskExtensionDto} from "../model/AsteriskExtensionDto";
import {AsteriskCallBackStatus} from "../model/AsteriskCallBackStatus";
import {AsteriskChannelDto} from "../model/AsteriskChannelDto";
import {AsteriskTransferType} from "../model/AsteriskTransferType";
import {AsteriskQueueMemberSessionDto} from "../model/AsteriskQueueMemberSessionDto";
import {DebounceFactory} from "../modules/DebounceModule";
import {
    addDays,
    addHours, addMinutes,
    endOfHour, endOfMinute,
    format,
    getDayOfYear, isValid,
    max,
    min, parse, parseISO,
    startOfDay,
    startOfHour,
    startOfMinute
} from "date-fns";

export default class CallController implements Ng1Controller {
    private date: Date;
    private calls: AsteriskCallDto[] = [];
    private myCalls: AsteriskCallDto[] = [];
    private queueMembers: AsteriskQueueMemberSessionDto[] = [];
    private pops = {};
    private loaded: boolean;
    private extensions: AsteriskExtensionDto[] = [];
    private channels: AsteriskChannelDto[] = [];
    private myChannels: AsteriskChannelDto[] = [];
    private channelToTransfer: AsteriskChannelDto;

    private callsPerHour: any;
    private callsSimultaneous: any;

    $onInit(): void {
        this.refresh();
    }

    uiCanExit(transition: Transition): boolean | TargetState | void | Promise<boolean | TargetState | void> {
        return undefined;
    }

    uiOnParamsChanged(newValues: any, $transition$: Transition): void {
        if (newValues.date)
            this.date = startOfDay(parseISO(newValues.date));
        this.refresh();
    }

    constructor(private $dataHub: DataHubService,
                private $userStorage: UserStorageService,
                private $spinner: SpinnerService,
                private toaster: any,
                private $debounce: DebounceFactory,
                private $config: ConfigService,
                private $state: StateService, private $stateParams: any) {
        $dataHub.connected(this.refresh);

        $dataHub.onAsteriskCallUpdated(call => {
            // check if this call belongs to current user
            if (call.user && call.user.id == this.$config.userInfo.id && call.callStatus == AsteriskCallStatus.Answered)
                this.myCalls.merge(call, x => x.id);
            else
                this.myCalls.remove(call, x => x.id);


            if (getDayOfYear(call.callStarted) != getDayOfYear(this.date))
                return;
            this.calls.merge(call, x => x.id);
            if ($config.userInfo &&
                $config.userInfo.id &&
                call.user &&
                call.user.id === $config.userInfo.id &&
                call.queueStatus == AsteriskQueueStatus.Answered &&
                call.callStatus == AsteriskCallStatus.Answered) {
                if (this.pops[call.id]) return;
                popWindow($state.href('asterisk.callDetail', {callId: call.id}), 'callPop');
                this.pops[call.id] = true;
            }

            this.updateChartsDebounce();
        });
        $dataHub.bindAsteriskCallRemoved(() => this.calls);
        this.date = startOfDay(parseISO($stateParams.date));
        if (!isValid(this.date))
            this.date = startOfDay(new Date());

        $dataHub.bindAllAsteriskQueueMember(() => this.queueMembers);
        $dataHub.bindAllAsteriskExtension(() => this.extensions);
        $dataHub.bindAllAsteriskChannel(() => this.channels);
        $dataHub.onAsteriskChannelUpdated(channel => {
            if (channel.user && channel.user.id == this.$config.userInfo.id)
                this.myChannels.merge(channel, x => x.id);
        });
        $dataHub.bindAsteriskChannelRemoved(() => this.myChannels);
    }

    dateChanged = () => {
        this.$state.go('.', {date: format(this.date, 'yyyy-MM-dd')});
    };

    dateChange = (val) => {
        this.date = addDays(this.date, val);
        this.dateChanged();
    };

    refresh = () => {
        if (!this.$dataHub.isConnected) return;
        this.calls = [];
        this.callsPerHour = null;
        this.callsSimultaneous = null;
        this.loaded = false;
        this.$dataHub.asteriskCallSubscribe(this.date)
            .then(() => this.loaded = true).catch(this.$dataHub.defaultErrorHandler);
        this.$dataHub.asteriskQueueMemberSubscribe().catch(this.$dataHub.defaultErrorHandler);
        this.$dataHub.asteriskExtensionSubscribe().catch(this.$dataHub.defaultErrorHandler);
        this.$dataHub.asteriskChannelSubscribe().catch(this.$dataHub.defaultErrorHandler);
    };

    dial = (number) => {
        this.$spinner.show('Dialing');
        this.$dataHub.asteriskCallOriginate(this.$userStorage.myExtension, number)
            .finally(() => this.$spinner.hide());
    };

    get queueJoined() {
        if (!this.$userStorage.myExtension) return undefined;
        return this.queueMembers.filter(x => x.user && x.user.id == this.$config.userInfo.id && x.status > 0)[0]
    };

    get queuePaused() {
        if (!this.$userStorage.myExtension) return undefined;
        return this.queueMembers.filter(x => x.user && x.user.id == this.$config.userInfo.id && x.paused)[0]
    };

    queuePause = () => {
        if (this.queuePaused)
            return this.$dataHub.asteriskQueueMemberPause(this.$userStorage.myExtension, null, false, null)
                .catch(this.$dataHub.defaultErrorHandler);

        let reason = prompt('Enter pause reason');
        if (!reason) return;
        this.$dataHub.asteriskQueueMemberPause(this.$userStorage.myExtension, null, true, reason)
            .catch(this.$dataHub.defaultErrorHandler);
    };

    queuePauseMember = (member: AsteriskQueueMemberSessionDto) => {
        if (member.paused)
            return this.$dataHub.asteriskQueueMemberPause(member.interface, member.queue, false, null)
                .catch(this.$dataHub.defaultErrorHandler);

        let reason = prompt('Enter pause reason');
        if (!reason) return;
        this.$dataHub.asteriskQueueMemberPause(member.interface, member.queue, true, reason)
            .catch(this.$dataHub.defaultErrorHandler);
    };

    queueAdd = () => {
        this.$dataHub.asteriskQueueMemberAdd(this.$userStorage.myExtension, null)
            .then(() => this.toaster.success('Joined queue'))
            .catch(this.$dataHub.defaultErrorHandler);
    };
    queueRemove = () => {
        this.$dataHub.asteriskQueueMemberRemove(this.$userStorage.myExtension, null)
            .then(() => this.toaster.success('Left queue'))
            .catch(this.$dataHub.defaultErrorHandler);
    };

    queueRemoveMember = (member: AsteriskQueueMemberSessionDto) => {
        this.$dataHub.asteriskQueueMemberRemove(member.interface, member.queue)
            .then(() => this.toaster.success(member.displayName + ' removed from queue'))
            .catch(this.$dataHub.defaultErrorHandler);
    };

    testPop = () => {
        setTimeout(() => {
            popWindow(this.$state.href('asterisk.callDetail', {callId: 1}), 'call1');
        })
    };

    get callFilter() {
        if (!this.$userStorage.callFilter)
            this.$userStorage.callFilter = {};
        return this.$userStorage.callFilter;
    }

    callFilterFunc = (value: AsteriskCallDto) => {
        let filter = this.callFilter;
        let result = filter.excludeCompleted && value.status == "Completed"
            || filter.excludeHangup && value.status == "Hangup"
            || filter.excludeCustomerCalledBack && value.callBackStatus == AsteriskCallBackStatus.CustomerCalledBack
            || filter.excludeNoAnswer && value.callBackStatus == AsteriskCallBackStatus.NoAnswerLeftMsg
        ;

        return !result;
    };

    chanSpy = (call) => {
        this.$dataHub.asteriskChanSpy(call.id)
            .catch(this.$dataHub.defaultErrorHandler);
    };

    getExtensionClass = (extension) => {
        // noinspection JSBitwiseOperatorUsage
        if (extension.status & 1) // in use
            return 'success';
        // noinspection JSBitwiseOperatorUsage
        if (extension.status & 4) // unavailable
            return 'danger';
        // noinspection JSBitwiseOperatorUsage
        if (extension.status & 8) // ringing
            return 'info';
        // noinspection JSBitwiseOperatorUsage
        if (extension.status & 16) // hold
            return 'warning';
        return '';
    };

    hangup = (channel: AsteriskChannelDto) => {
        this.$dataHub.asteriskCallHangup(channel.callId)
            .catch(this.$dataHub.defaultErrorHandler);
    };

    park = (channel: AsteriskChannelDto) => {
        this.$dataHub.asteriskCallPark(channel.callId)
            .then(msg => this.toaster.success('Call Parked: ' + msg))
            .catch(this.$dataHub.defaultErrorHandler);
    };

    transfer = (channel: AsteriskChannelDto) => {
        this.channelToTransfer = channel;
    };

    transferCancel = () => {
        this.channelToTransfer = null;
    };

    transferComplete = (extension: AsteriskExtensionDto, mode: AsteriskTransferType) => {
        this.$dataHub.asteriskCallTransfer(this.channelToTransfer.callId, extension.exten, mode)
            .catch(this.$dataHub.defaultErrorHandler);
        this.channelToTransfer = null;
    };


    updateCharts = () => {
        this.updateChartCallsPerHour();
        this.updateChartCallsSimultaneous();
    };

    updateChartCallsPerHour = () => {
        if (!this.callsPerHour)
            this.callsPerHour = {
                type: 'bar',
                data: {
                    labels: [],
                    datasets: [
                        {label: 'Incoming', data: []},
                        {label: 'Outgoing', data: []},
                        {label: 'Internal', data: []},
                    ]
                },
                options: {
                    scales: {
                        xAxes: [{
                            stacked: true
                        }],
                        yAxes: [{
                            stacked: true
                        }]
                    },
                    responsive: true,
                    maintainAspectRatio: false
                }
            };

        const times = this.calls.map(c => c.callStarted);
        const startDate = startOfHour(min(times));
        const endDate = endOfHour(max(times));
        console.log('callsperhour', startDate, endDate);
        let i = startDate;

        while (i <= endDate) {
            const label = format(i, 'h a');
            const labelIndex = this.getLabelIndex(this.callsPerHour, label);

            // for all queries, include the range of the entire hour (hh:00:00 through hh:59:59)
            const a = startOfHour(i);
            const b = endOfHour(i);


            // get all calls started this hour
            this.callsPerHour.data.datasets.forEach(dataSet => {
                dataSet.data[labelIndex] = this.calls.filter(x => x.callStarted >= a && x.callStarted <= b && x.directionString == dataSet.label).length;
            });

            i = addHours(i, 1);
        }

        this.callsPerHour.update = true;

    };

    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    getLabelIndex(config, label) {
        const labels = config.data.labels;
        let labelIndex = labels.indexOf(label);
        if (labelIndex < 0) {
            labels.push(label);
            labelIndex = labels.indexOf(label);
        }
        return labelIndex;
    }

    updateChartCallsSimultaneous = () => {
        if (!this.callsSimultaneous)
            this.callsSimultaneous = {
                type: 'bar',
                data: {
                    labels: [],
                    datasets: [
                        {label: 'Waiting', data: []},
                        {label: 'Other', data: []},
                    ]
                },
                options: {
                    scales: {
                        xAxes: [{
                            stacked: true
                        }],
                        yAxes: [{
                            stacked: true
                        }]
                    },
                    responsive: true,
                    maintainAspectRatio: false
                }
            };

        const times = this.calls.map(c => c.callStarted).concat(this.calls.filter(x => x.callEnded).map(c => c.callEnded));
        const startDate = startOfMinute(min(times));
        const endDate = endOfMinute(max(times));
        let i = startDate;
        let datasets = this.callsSimultaneous.data.datasets;

        while (i < endDate) {
            const label = format(i, 'h:mm a');
            const labelIndex = this.getLabelIndex(this.callsSimultaneous, label);

            // for all queries, include the range of the entire minute (hh:mm:00 through hh:mm:59)
            const a = startOfMinute(i);
            const b = endOfMinute(i);

            // get all calls 'in queue'
            const waiting = this.calls.filter(x => x.queueJoined && x.queueJoined <= a && x.queueLeft >= b);
            datasets[0].data[labelIndex] = waiting.length;


            // get all calls 'in progress'
            let c = this.calls.filter(x => x.callStarted <= a && (x.callEnded == null && x.status != 'Completed' || x.callEnded >= b));
            datasets[1].data[labelIndex] = c.length - waiting.length;

            i = addMinutes(i, 1);
        }

        this.callsSimultaneous.update = true;

    };


    resultIncrement(result, series, label, value) {
        if (result.labels.indexOf(label) < 0)
            result.labels.push(label);
        const labelIndex = result.labels.indexOf(label);
        if (result.series.indexOf(series) < 0) result.series.push(series);
        const seriesIndex = result.series.indexOf(series);

        if (!result.data[seriesIndex])
            result.data[seriesIndex] = [];
        if (!result.data[seriesIndex][labelIndex])
            result.data[seriesIndex][labelIndex] = 0;
        result.data[seriesIndex][labelIndex] += value;
    }

    resultSet(result, series, label, value) {
        if (result.labels.indexOf(label) < 0)
            result.labels.push(label);
        const labelIndex = result.labels.indexOf(label);
        if (result.series.indexOf(series) < 0) result.series.push(series);
        const seriesIndex = result.series.indexOf(series);

        if (!result.data[seriesIndex])
            result.data[seriesIndex] = [];
        if (!result.data[seriesIndex][labelIndex])
            result.data[seriesIndex][labelIndex] = 0;
        if (result.data[seriesIndex][labelIndex] != value)
            result.data[seriesIndex][labelIndex] = value;
    }


    updateChartsDebounce = this.$debounce(this.updateCharts, 1000, false);

}
