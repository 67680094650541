import * as angular from "angular";
import {ConfigService} from "../modules/ConfigModule";

function datePicker($config: ConfigService) {
    function getFieldModelName(attrs) {
        var objectAndField = attrs.ngModel;
        var names = objectAndField.split('.');
        return names[names.length - 1];
    }

    return {
        template: require('./DatePicker.html'),
        restrict: 'E',
        require: ['ngModel'],
        scope: {
            title: '@',
            placeholder:'@',
            ngModel: '=',
            ngDisabled: '=',
            ngRequired: '='
        },
        link: (scope, element, attrs) => {
            scope.name = getFieldModelName(attrs);
        },
    };
}

export default angular.module('directives.datePicker', [])
    .directive('datePicker', datePicker)
    .name;
