import {LocationDto} from "../model/LocationDto";
import {DataHubService} from "../modules/DataHubModule";
import {ClassDto} from "../model/ClassDto";
import {ClassInfoDto} from "../model/ClassInfoDto";
import {UserDto} from "../model/UserDto";
import {UserDisplayDto} from "../model/UserDisplayDto";
import {SpinnerService} from "../modules/SpinnerModule";
import {ClassWarningsDto} from "../model/ClassWarningsDto";
import {IFormController} from "angular";

export default class ManageClassesController {
    startDate: Date = new Date();
    endDate: Date = new Date();
    locations: LocationDto[] = [];
    locationId: number;
    classes: ClassDto[] = [];
    classInfos: ClassInfoDto[] = [];
    drivingInstructors: UserDisplayDto[] = [];
    queryError: string = "";
    warnings: string[];

    constructor(private $dataHub: DataHubService,
                private $spinner: SpinnerService) {
        $dataHub.client.bindAllLocation(() => this.locations);
        $dataHub.client.bindAllClass(() => this.classes);
        $dataHub.client.bindAllClassInfo(() => this.classInfos);
        $dataHub.client.bindAllUserDisplay(() => this.drivingInstructors);
        $dataHub.connected(this.load);
        if ($dataHub.isConnected) this.load();
    }

    load = () => {
        this.$dataHub.server.location();
        this.$dataHub.server.classInfoSubscribe();
        this.$dataHub.server.userTeacherSubscribe();
    };

    search() {
        this.$spinner.show("Loading classes...");
        this.classes.length = 0;
        this.$dataHub.server.classManageQuerySubscribe({
            locationId: this.locationId,
            endingDate: this.endDate,
            startingDate: this.startDate
        }).then(value => this.queryError = "").catch(e => {
            this.queryError = e;
        }).finally(() => this.$spinner.hide());
    }

    save = (classDto: ClassDto, form: IFormController) => {
        this.$dataHub.server.classUpdate(classDto)
            .then(value => {
                form.$setPristine();
                this.$dataHub.server.classGetWarnings([classDto.id]).then((warnings: ClassWarningsDto[]) =>
                    this.warnings = warnings[0].warnings).catch(e => console.warn(e));
                classDto["error"] = "";
            }).catch(e => classDto["error"] = e);
    };

    delete = classDto => this.$dataHub.server.classDelete(classDto.id)
        .then(value => classDto.error = "")
        .catch(e => classDto.error = e);

    dismissWarnings = () => this.warnings.length = 0;
};
